import React, { useRef, useState, useEffect } from 'react';
import { SwatchesPicker, CirclePicker } from 'react-color';

import iconManifest from 'icon-manifest.json';

// const HEX_PATH =
//   'M103.409091,15.6392045 C126.420455,28.4232955 148.579545,42.0596591 171.590909,54.84375 C174.147727,56.5482955 175,58.2528409 175,60.8096591 L175,140.071023 C175,142.627841 173.295455,145.184659 171.590909,146.036932 C148.579545,158.821023 126.420455,172.457386 103.409091,185.241477 C100.852273,186.09375 98.2954545,186.09375 96.5909091,185.241477 C73.5795455,171.605114 51.4204545,158.821023 28.4090909,146.036932 C25.8522727,144.332386 25,141.775568 25,140.071023 L25,60.8096591 C25,58.2528409 26.7045455,55.6960227 29.2613636,54.84375 C51.4204545,42.0596591 74.4318182,28.4232955 96.5909091,15.6392045 C99.1477273,14.7869318 101.704545,14.7869318 103.409091,15.6392045 Z';
// const RIBBON_PATH =
//   'M178.050515,99.5842813 C178.050515,93.1912273 189.243627,85.6302843 187.885737,79.6486457 C186.480434,73.4717269 173.077906,71.511342 170.390573,65.9411188 C167.657724,60.2779953 174.449072,48.5896269 170.563154,43.7246872 C166.656375,38.8351005 153.741245,42.8696257 148.850185,38.9640233 C143.98378,35.081172 145.057196,21.5859885 139.394263,18.8558587 C133.822361,16.1655432 123.945416,25.389211 117.768533,23.9843317 C111.783196,22.6268505 106.894032,10 100.500948,10 C94.1059679,10 89.2168045,22.6268505 83.23526,23.9843317 C77.0564801,25.389211 67.1833284,16.1674391 61.6114269,18.8558587 C55.946597,21.5859885 57.0181166,35.0849638 52.1536075,38.9659192 C47.2625476,42.8715216 34.3436253,38.8351005 30.4387426,43.7246872 C26.5528249,48.5896269 33.3460691,60.2798912 30.6151166,65.9430147 C27.9239906,71.513238 14.5252551,73.4736229 13.118056,79.6486457 C11.7563728,85.6302843 22.9494848,93.1912273 22.9494848,99.5842813 C22.9494848,105.975439 11.7563728,113.536382 13.114263,119.518021 C14.5195657,125.69494 27.9220941,127.655325 30.6113236,133.225548 C33.3422761,138.888671 26.5528249,150.578936 30.4368461,155.441979 C34.3436253,160.331566 47.2587546,156.297041 52.1498145,160.200747 C57.0162201,164.087391 55.942804,177.580678 61.6057374,180.312704 C67.177639,183.001123 77.0545836,173.777456 83.231467,175.182335 C89.2168045,176.539816 94.1059679,189.166667 100.500948,189.166667 C106.894032,189.166667 111.783196,176.539816 117.766636,175.182335 C123.945416,173.777456 133.820465,183.001123 139.392366,180.312704 C145.057196,177.580678 143.985676,164.087391 148.848289,160.202643 C153.739349,156.297041 166.660168,160.331566 170.563154,155.441979 C174.449072,150.578936 167.655827,138.888671 170.388676,133.223652 C173.077906,127.653429 186.476641,125.693044 187.88384,119.518021 C189.243627,113.536382 178.050515,105.975439 178.050515,99.5842813 Z';
const SHIELD1_PATH =
  'M25,27.0952672 C25,41.7125813 25,143.824961 25,143.824961 L102.089136,187.468085 L175,143.824961 C175,143.824961 175,54.2417077 175,27.0952672 C131.337047,8.3015776 72.8412256,8.3015776 25,27.0952672 Z';
// const TRIANGLE1_PATH =
//   'M146.715875,178.560976 L53.5402927,178.560976 C39.7782377,178.560976 27.0603213,171.217216 20.1731787,159.292723 C13.2860361,147.36823 13.2749248,132.673136 20.1440269,120.738226 L66.5648364,40.3879993 C73.4165394,28.3982437 86.1601809,21 99.9611023,21 C113.762024,21 126.505665,28.3982437 133.357368,40.3879993 L179.778178,120.604531 C186.68623,132.496055 186.743815,147.169558 179.929308,159.115022 C173.114802,171.060487 160.459616,178.469903 146.715875,178.560976 Z';

const BadgeDesigner = () => {
  const [CANVAS_WIDTH, CANVAS_HEIGHT] = [200, 200];
  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const [icon, setIcon] = useState(iconManifest.icons[0]);
  const [iconColor, setIconColor] = useState(iconManifest.colors[0]);
  const [backgroundColor, setBackgroundColor] = useState('#b71c1c');
  const [dataUrl, setDataUrl] = useState(null);
  const [iconFilter, setIconFilter] = useState('');

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // clear the existing image
    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    // draw the background shape
    ctx.fillStyle = backgroundColor;
    var backgroundShape = new Path2D(SHIELD1_PATH);
    ctx.fill(backgroundShape);

    // draw the icon on top
    if (icon) {
      const img = imgRef.current;
      if (!img.onload) {
        img.onload = () => {
          const scaleFactor = (Math.max(CANVAS_WIDTH, CANVAS_HEIGHT) * 0.5) / Math.max(img.width, img.height);
          const imgWidth = img.width * scaleFactor;
          const imgHeight = img.height * scaleFactor;
          ctx.drawImage(img, (CANVAS_WIDTH - imgWidth) / 2, (CANVAS_HEIGHT - imgHeight) / 2, imgWidth, imgHeight);

          try {
            canvas.toBlob(blob => {
              setDataUrl(URL.createObjectURL(blob));
            });
          } catch {
            setDataUrl(null);
          }
        };
      }
      img.onload();
    }
  }, [icon, iconColor, backgroundColor, CANVAS_WIDTH, CANVAS_HEIGHT]);

  const filteredIcons = iconFilter ? iconManifest.icons.filter(i => i.name.includes(iconFilter)) : iconManifest.icons;

  return (
    <div className="BadgeDesigner">
      <canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
      <img
        width="16"
        height="16"
        style={{ display: 'none' }}
        ref={imgRef}
        src={
          icon ? `${process.env.PUBLIC_URL}/icons/${icon.source}/${icon.name}_${iconColor.replace('#', '')}.svg` : null
        }
        alt="hidden"
      />
      <SwatchesPicker color={backgroundColor} onChange={color => setBackgroundColor(color.hex)} />
      <div style={{ backgroundColor: 'rgba(0,0,0, 0.1)', padding: '5px', display: 'inline-block' }}>
        <CirclePicker width="84px" colors={iconManifest.colors} onChange={color => setIconColor(color.hex)} />
      </div>
      <input type="text" onChange={e => setIconFilter(e.target.value)} />
      <div style={{ width: '320px', height: '240px', overflowY: 'scroll' }}>
        {filteredIcons.map(i => (
          <img
            onClick={() => setIcon(i)}
            width="48"
            height="48"
            src={`${process.env.PUBLIC_URL}/icons/${i.source}/${i.name}_000000.svg`}
            alt={i.name}
          />
        ))}
      </div>
      <br />
      <a download="badge.png" href={dataUrl} disabled={dataUrl ? true : false}>
        Download
      </a>
    </div>
  );
};

export default BadgeDesigner;
