import React, { useEffect, useState } from 'react';
import BadgeRecipients from './BadgeRecipients';
import BadgeInfo from './BadgeInfo';
import BadgeInfoForm from './BadgeInfoForm';
import { getBadge } from 'api/badgesApi';
import { Divider } from 'antd';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import { useUser } from 'auth/UserHooks';
import { trackComponent } from 'telemetry/AppInsights';

const BadgeDetailPage = props => {
  const [isEditMode, setEditMode] = useState(false);
  const [badge, setBadge] = useState(null);
  const badgeId = props.match.params.badgeId;
  const user = useUser();

  useEffect(() => {
    getBadge(badgeId)
      .then(setBadge)
      .catch(displayErrorNotification);
  }, []);

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleUpdate = updatedBadge => {
    setEditMode(false);
    setBadge(updatedBadge);
  };

  const canEdit =
    (badge ? badge.category.owners.some(o => o.id === user.id) : false) || user.permissions.canManageAllBadgeCategories;

  return (
    <Stack>
      {isEditMode ? (
        <BadgeInfoForm badge={badge} badgeId={badgeId} onCancel={toggleEditMode} onSubmit={handleUpdate} />
      ) : (
        <>
          <BadgeInfo badge={badge} badgeId={badgeId} onEdit={canEdit ? toggleEditMode : null} />
          <Divider />
          {badge && user.permissions.canReadEmployees && (
            <BadgeRecipients badge={badge} categoryId={badge.categoryId} isRequired={badge.required} />
          )}
        </>
      )}
    </Stack>
  );
};

export default trackComponent(BadgeDetailPage, 'Badge Detail Page');
