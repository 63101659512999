import { Button, Form, Input, Row } from 'antd'
import {
  createEmployeeBusinessUnit,
  deleteEmployeeBusinessUnit,
  updateEmployeeBusinessUnit
} from 'api/employeeBusinessUnitApi'
import { useUser } from 'auth/UserHooks'
import ButtonRow from 'components/common/ButtonRow'
import React, { useEffect } from 'react'
import { EmployeeBusinessUnit } from 'types/EmployeeBusinessUnit'
import displayErrorNotification from 'utils/displayErrorNotification'
import './EmployeeBusinessUnitForm.css'

type EmployeeBusinessUnitFormProps = {
  businessUnit?: EmployeeBusinessUnit
  onSubmit: Function
  onCancel: Function
  form: any
}

const EmployeeBusinessUnitForm = ({ businessUnit, onSubmit, onCancel, form }: EmployeeBusinessUnitFormProps) => {
  const user = useUser()
  const isCreateMode = !businessUnit
  const { getFieldDecorator } = form
  const initialValues = isCreateMode
    ? {
      name: '',
      abbrev: '',
      description: '',
      displayOrder: null,
      inactive: false
    }
    : { ...businessUnit }

  useEffect(() => {
    form.resetFields()
  }, [businessUnit, onSubmit])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault()

    form.validateFields((err: any, values: any) => {
      if (err) return

      const newBusinessUnit: EmployeeBusinessUnit = {
        id: isCreateMode ? 0 : businessUnit.id,
        name: values.name,
        description: values.description,
      }
      if (isCreateMode) {
        createEmployeeBusinessUnit(newBusinessUnit)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
      } else if (newBusinessUnit.id !== null) {
        updateEmployeeBusinessUnit(newBusinessUnit.id, newBusinessUnit)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
      }
    })
  }

  const _deleteEmployeeBusinessUnit = () => {
    if (businessUnit && businessUnit.id !== null)
      deleteEmployeeBusinessUnit(businessUnit.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification)
  }

  return (
    <div style={{ minWidth: '450px' }}>
      <h2>{isCreateMode ? 'Create' : 'Edit'} Employee BusinessUnit</h2>
      <Form onSubmit={handleSubmit} className="editEmployeeBusinessUnitForm">
        <Form.Item label="Name" required>
          {getFieldDecorator('name', {
            initialValue: initialValues.name,
            rules: [{ required: true, message: 'Name is a required field' }]
          })(<Input maxLength={50} />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: initialValues.description
          })(<Input.TextArea rows={4} maxLength={255} />)}
        </Form.Item>
        <Row>
          <ButtonRow style={{ marginTop: '30px' }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button onClick={() => onCancel()}>Cancel</Button>
            {!isCreateMode && user.permissions.canManageEmployeeBusinessUnit && (
              <Button type="danger" onClick={_deleteEmployeeBusinessUnit}>
                Delete
              </Button>
            )}
          </ButtonRow>
        </Row>
      </Form>
    </div>
  )
}

export default Form.create<EmployeeBusinessUnitFormProps>({ name: 'employeeBusinessUnit_form' })(EmployeeBusinessUnitForm)
