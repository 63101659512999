
import { del, get, post, put } from 'api';
import { EmployeePractice } from 'types/EmployeePractice'

export function getEmployeePractices(): Promise<EmployeePractice[]> {
  return get(`api/employees/practice`);
}

export function createEmployeePractice(practice: EmployeePractice): Promise<EmployeePractice> {
  return post(`api/employees/practice`, practice);
}

export function updateEmployeePractice(practiceId: number, practice: EmployeePractice): Promise<EmployeePractice> {
  return put(`api/employees/practice/${practiceId}`, practice);
}

export function deleteEmployeePractice(practiceId: number) {
  return del(`api/employees/practice/${practiceId}`);
}

export function toggleEmployeePracticeActivation(practiceId: number): Promise<EmployeePractice> {
  return post(`api/employees/practice/${practiceId}`);
}