import { Button, Form, Input, InputNumber, Row, Select, Spin } from 'antd'
import {
  createEmployeePractice,
  deleteEmployeePractice,
  toggleEmployeePracticeActivation,
  updateEmployeePractice
} from 'api/employeePracticeApi'
import { useUser } from 'auth/UserHooks'
import ButtonRow from 'components/common/ButtonRow'
import React, { useEffect, useState } from 'react'
import { EmployeePractice } from 'types/EmployeePractice'
import displayErrorNotification from 'utils/displayErrorNotification'
import './EmployeePracticeForm.css'
import { EmployeeBusinessUnit } from "../../../types/EmployeeBusinessUnit";

type EmployeePracticeFormProps = {
  practice?: EmployeePractice
  businessUnits: EmployeeBusinessUnit[];
  onSubmit: Function
  onCancel: Function
  loading: boolean
  setLoading: Function
  form: any
}

const EmployeePracticeForm = ({ practice, businessUnits, onSubmit, onCancel, loading, setLoading, form }: EmployeePracticeFormProps) => {
  const user = useUser()
  const isCreateMode = !!!practice
  const [inactive, setInactive] = useState<boolean>(false)
  const { getFieldDecorator } = form;
  const initialValues = isCreateMode
    ? {
      name: '',
      abbrev: '',
      description: '',
      businessUnitId: null,
      displayOrder: null,
      inactive: false
    }
    : { ...practice, businessUnitId: practice.businessUnit?.id }

  useEffect(() => {
    form.resetFields();
    if (!isCreateMode) {
      setInactive(practice.inactive);
    }

  }, [practice, onSubmit])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (err) return;

      const newPractice: EmployeePractice = {
        id: isCreateMode ? 0 : practice.id,
        name: values.name,
        abbrev: values.abbrev,
        description: values.description,
        businessUnit: businessUnits.find(bu => bu.id === values.businessUnitId),
        displayOrder: values.displayOrder,
        inactive: isCreateMode ? false : practice.inactive
      };
      setLoading(true);
      if (isCreateMode) {
        createEmployeePractice(newPractice)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
          .then(() => setLoading(false));
      } else if (newPractice.id !== null) {
        updateEmployeePractice(newPractice.id, newPractice)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
          .then(() => setLoading(false));
      }
    });
  };

  const _toggleRoleTypeFamilyActivation = () => {
    if (practice && practice.id !== null)
      toggleEmployeePracticeActivation(practice.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  const _deleteRoleTypeFamily = () => {
    if (practice && practice.id !== null)
      deleteEmployeePractice(practice.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  return (
    <div style={{ minWidth: '450px' }}>
      <h2>{isCreateMode ? 'Create' : 'Edit'} Employee Practice</h2>
      <Spin spinning={loading}>
        <Form onSubmit={handleSubmit} className="editRoleTypeFamilyForm">
          <Form.Item label="Name" required>
            {getFieldDecorator('name', {
              initialValue: initialValues.name,
              rules: [{ required: true, message: 'Name is a required field' }]
            })(<Input disabled={inactive} maxLength={50} />)}
          </Form.Item>
          <Form.Item label="Abbreviation" required>
            {getFieldDecorator('abbrev', {
              initialValue: initialValues.abbrev,
              rules: [{ required: true, message: 'Abbreviation is a required field' }]
            })(<Input disabled={inactive} maxLength={10} />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: initialValues.description
            })(<Input.TextArea disabled={inactive} rows={4} maxLength={255} />)}
          </Form.Item>
          <Form.Item label="Business Unit">
            {getFieldDecorator('businessUnitId', {
              initialValue: initialValues.businessUnitId
            })(
              <Select placeholder="Business Unit" disabled={inactive}>
                {businessUnits.map(p => (
                  <Select.Option key={p.id} value={p.id}>
                    {p.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Display Order">
            {getFieldDecorator('displayOrder', {
              initialValue: initialValues.displayOrder
            })(<InputNumber disabled={inactive} min={1} max={32767} />)}
          </Form.Item>
          <Row>
            <ButtonRow style={{ marginTop: '30px' }}>
              <Button type="primary" htmlType="submit" disabled={inactive}>
                Save
              </Button>
              <Button onClick={() => onCancel()}>Cancel</Button>
              {!isCreateMode && (
                <Button onClick={_toggleRoleTypeFamilyActivation}>{inactive ? 'Activate' : 'Deactivate'}</Button>
              )}
              <Button onClick={() => onCancel()}>Cancel</Button>
              {!isCreateMode && (
                <Button onClick={_toggleRoleTypeFamilyActivation}>{inactive ? "Activate" : "Deactivate"}</Button>
              )}
              {!isCreateMode && inactive && user.permissions.canManageEmployeePractice && (
                <Button type="danger" onClick={_deleteRoleTypeFamily}>
                  Delete
                </Button>
              )}
            </ButtonRow>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default Form.create<EmployeePracticeFormProps>({ name: "roletypefamily_form" })(EmployeePracticeForm);
