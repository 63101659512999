import React, { useEffect, useState } from 'react'
import { Typography, Form, Button, Input, Select, Spin } from 'antd'
import { createCapability, updateCapability, toggleCapabilityActivation, deleteCapability } from 'api/capabilitiesApi'
import ButtonRow from 'components/common/ButtonRow'
import displayErrorNotification from 'utils/displayErrorNotification'
import { useUser } from 'auth/UserHooks'
import CapabilityActivationModal from './CapabilityActivationModal'
import CapabilityDeleteModal from './CapabilityDeleteModal'
import { Capability } from 'types/Capability'
import { CapabilityDetail } from 'types/CapabilityDetail'
import { getEmployeePractices } from 'api/employeePracticeApi'
import { EmployeePractice } from 'types/EmployeePractice'

type CapabilityFormProps = {
  capability?: CapabilityDetail
  onSubmit: (capability: CapabilityDetail) => void
  onCancel: () => void
  onDelete: () => void
  form: any
}

const CapabilityForm = ({ capability, onSubmit, onCancel, onDelete, form }: CapabilityFormProps) => {
  const user = useUser()
  const isCreateMode = !!!capability
  const [isToggleModalVisible, setIsToggleModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const { getFieldDecorator } = form
  const initialValues = !isCreateMode ? { ...capability } : {
    employeePracticeId: 6, // MC
    name: ''
  }
  const [employeePractices, setEmployeePractices] = useState<EmployeePractice[]>([])
  const [inactive, setInactive] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const _toggleModalVisibility = () => {
    setIsToggleModalVisible(!isToggleModalVisible)
  }

  const _deleteModalVisibility = () => {
    setIsDeleteModalVisible(!isDeleteModalVisible)
  }

  useEffect(() => {
    setLoading(true)
    getEmployeePractices()
      .then(response => {
        setEmployeePractices(response.filter(r => r.inactive === false))
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    form.resetFields()
    if (!isCreateMode) {
      setInactive(capability.inactive)
    }

  }, [capability, onSubmit])

  const handleActivation = () => {
    if (capability)
      toggleCapabilityActivation(capability.id)
        .then(onSubmit)
        .catch(displayErrorNotification)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    form.validateFields((err: any, values: any) => {
      if (err) return

      if (isCreateMode) {
        let newCapability: Capability = { ...values }
        createCapability(newCapability)
          .then(onSubmit)
          .catch(displayErrorNotification)
      } else {
        let updatedCapability: CapabilityDetail = { ...capability, name: values.name, employeePracticeId: values.employeePracticeId }
        updateCapability(capability.id, updatedCapability)
          .then(onSubmit)
          .catch(displayErrorNotification)
      }
    })
  }

  const _deleteCapability = () => {
    if (capability && capability.id !== null) {
      deleteCapability(capability.id)
        .then(onDelete)
        .catch(displayErrorNotification)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" onSubmit={handleSubmit} style={{ maxWidth: '40em' }}>
        <Typography.Title>{isCreateMode ? 'Add New' : 'Edit'} Capability Type</Typography.Title>

        <Form.Item label="Practice" required>
          {getFieldDecorator('employeePracticeId', {
            initialValue: initialValues.employeePracticeId,
            rules: [{ required: true, message: 'Practice is a required field' }]
          })(
            <Select showArrow placeholder="Practice">
              {employeePractices.map(p => (
                <Select.Option key={p.id} value={p.id}>
                  {p.name} [{p.abbrev}]
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Capability Name" required>
          {getFieldDecorator('name', {
            initialValue: initialValues.name,
            rules: [
              { required: true, message: 'Name is a required field' },
              { max: 60, message: 'Name must be fewer than 60 characters' }
            ]
          })(<Input />)}
        </Form.Item>

        <ButtonRow style={{ marginTop: '1em' }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
          {!isCreateMode && user.permissions.canToggleCapabilityTypeActivation && (
            <Button onClick={_toggleModalVisibility}>
              {capability && capability.inactive ? 'Activate' : 'Deactivate'}
            </Button>
          )}
          {!isCreateMode && inactive && user.permissions.canDeleteCapabilityType && (
            <Button type="danger" onClick={_deleteModalVisibility}>
              Delete
            </Button>
          )}
        </ButtonRow>

        {!isCreateMode && (
          <>
          <CapabilityActivationModal
            action={capability.inactive ? 'Activate' : 'Deactivate'}
            isModalVisible={isToggleModalVisible}
            capability={capability}
            closeModal={_toggleModalVisibility}
            onSubmit={handleActivation}
          />
          <CapabilityDeleteModal
            isModalVisible={isDeleteModalVisible}
            capability={capability}
            closeModal={_deleteModalVisibility}
            onSubmit={_deleteCapability}
          />
          </>
        )}
      </Form>
    </Spin>
  )
}

export default Form.create<CapabilityFormProps>({ name: 'capability_form' })(CapabilityForm)
