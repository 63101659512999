import React from 'react'
import { Link } from 'react-router-dom'

type EmployeeRendererProps = {
  id: number
  name: string
}

const EmployeeRenderer = (props: EmployeeRendererProps) => {
  if (props.name === '') {
    return <></>
  }
  return <Link to={`/employees/${props.id}`}>{props.name}</Link>
}

export default EmployeeRenderer
