import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd';

import { debounce, isEmpty, map, filter } from 'lodash';

const testValidTags = (tags, skill) => {
  for (let i = 0; i < skill.tags.length; i++) {
    if (tags.indexOf(skill.tags[i].name) > -1) {
      return true;
    }
  }
  return false;
};

const skillPassesFilter = (skill, uppercaseTerm, categories, tags, showInactive) => {
  let haschildren = skill.children && skill.children.length;
  let isNameValid = isEmpty(uppercaseTerm) || skill.name.toUpperCase().indexOf(uppercaseTerm) > -1;
  let isCategoryValid = isEmpty(categories) || categories.indexOf(skill.category) > -1;
  let isTagValid = isEmpty(tags) || testValidTags(tags, skill);
  let isStatusValid = showInactive || skill.active;
  return isStatusValid && (haschildren || (isNameValid && isCategoryValid && isTagValid));
};

export const filterSkills = (props, changedValues, values) => {
  const { skillTree, onSearch, setSettings, skillCategories } = props;
  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const categories = values.selectedCategories;
  const tags = values.selectedTags;
  const showInactive = values.showInactive;

  setSettings({
    search: values.searchTerm,
    category: categories,
    tag: tags,
    inActive: showInactive
  });

  const skillTreeWithFilteredBottomLevel = skillTree.map(topLevelSkill => {
    return {
      ...topLevelSkill,
      children: topLevelSkill.children
        ? topLevelSkill.children.map(midLevelSkill => {
          return {
            ...midLevelSkill,
            children: midLevelSkill.children
              ? midLevelSkill.children.filter(bottomLevelSkill => {
                return skillPassesFilter(bottomLevelSkill, uppercaseTerm, categories, tags, showInactive);
              })
              : ''
          };
        })
        : ''
    };
  });

  const skillTreeWithFilteredMidLevel = skillTreeWithFilteredBottomLevel.map(topLevelSkill => {
    return {
      ...topLevelSkill,
      children: topLevelSkill.children
        ? topLevelSkill.children.filter(midLevelSkill => {
          return skillPassesFilter(midLevelSkill, uppercaseTerm, categories, tags, showInactive);
        })
        : ''
    };
  });

  const filteredSkillTree = skillTreeWithFilteredMidLevel.filter(topLevelSkill => {
    return skillPassesFilter(topLevelSkill, uppercaseTerm, categories, tags, showInactive);
  });
  const filteredSkillCategories = skillCategories.filter(s => !s.inactive || showInactive);
  onSearch(filteredSkillTree, filteredSkillCategories);
};

const SkillsFilters = props => {
  const { form, disabled, settings, searchRef, scoutTags, skillCategories } = props;
  const categories = map(filter(skillCategories, s => settings.inActive || !s.inactive), s => s.name);
  const tags = map(scoutTags, t => t.name);
  const { getFieldDecorator } = form;
  const selectedCategories = form.getFieldValue('selectedCategories') || [];
  const selectedTags = form.getFieldValue('selectedTags') || [];

  const clearFilters = () => {
    form.resetFields();
    filterSkills(
      props,
      {},
      {
        searchTerm: '',
        selectedCategories: [],
        showInactive: false
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input ref={searchRef} disabled={disabled} prefix={<Icon type="filter" />} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCategories', {
            initialValue: settings.category
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCategories.length} Categor${selectedCategories.length === 1 ? 'y' : 'ies'
                } Selected`}
              showArrow
              mode="multiple"
              placeholder="Category"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {categories.map(c => (
                <Select.Option key={c} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedTags', {
            initialvalue: settings.tag
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedTags.length} Tag${selectedTags.length === 1 ? '' : 's'} Selected`}
              showArrow
              mode="multiple"
              placeholder="Tag"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {tags.map(t => (
                <Select.Option key={t} value={t}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showInactive', {
            initialValue: settings.inActive,
            valuePropName: 'checked'
          })(<Checkbox>Show Inactive</Checkbox>)}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

SkillsFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  skillTree: PropTypes.array,
  skillCategories: PropTypes.array,
  scoutTags: PropTypes.array
};

export default Form.create({
  name: 'filter_skills_form',
  onValuesChange: debounce(filterSkills, 500)
})(SkillsFilters);
