import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography } from 'antd';
import CardCollapse from 'components/common/collapse/CardCollapse';
import './CareerCoachCard.css';
import { getCoachDetails, getCoacheeDetails } from 'api/employeeApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import { useUser } from 'auth/UserHooks';
import { employeeType } from 'types';

const CareerCoachCard = ({ employee }) => {
  const [coach, setCoach] = useState(null);
  const [coachees, setCoachees] = useState([]);
  const user = useUser();
  const isMe = employee.id === user.id;

  useEffect(() => {
    if (!!employee.coach) {
      getCoachDetails(employee.id)
        .then(coach => {
          setCoach(coach);
        })
        .catch(displayErrorNotification);
    }

    if (employee.coachees.length > 0) {
      getCoacheeDetails(employee.id)
        .then(coachees => {
          setCoachees(coachees);
        })
        .catch(displayErrorNotification);
    }
  }, []);

  return (
    <CardCollapse.Card>
      <Card hoverable={true} className="career-coach-card">
        <Card.Meta
          title="Career Coach Details"
          description={
            <>
              <Typography.Paragraph>
                {coach && (
                  <div>
                    {isMe ? 'Your career coach is ' : 'Career Coach: '}
                    <Link to={`/employees/${coach.id}`}>{coach.fullName}</Link>
                    {isMe ? '.' : ''}
                  </div>
                )}
              </Typography.Paragraph>

              <Typography.Paragraph>
                {coachees.length > 0 && (
                  <div>
                    {isMe ? 'Here are your team members:' : 'Team Members:'}
                    <ul>
                      {coachees.map(c => (
                        <li key={c.id}>
                          <Link to={`/employees/${c.id}`}>{c.fullName}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Typography.Paragraph>
            </>
          }
        />
      </Card>
    </CardCollapse.Card>
  );
};

CareerCoachCard.defaultProps = {
  employee: {}
};

CareerCoachCard.propTypes = {
  employee: employeeType
};

export default CareerCoachCard;
