import React from 'react';
import { Collapse, Typography, Button, Icon, Tag } from 'antd';
import UserContext from 'auth/UserContext';
import { getAllBadges, getAllBadgeCategories } from 'api/badgesApi';
import { getEmployeeBadgesForCategoryId } from 'api/employeeBadgeApi';
import BadgeCard from 'components/badges/BadgeCard';
import Stack from 'components/common/Stack';
import CardCollapse from 'components/common/collapse/CardCollapse';
import AddNewCard from 'components/common/AddNewCard';
import BadgeCategoryForm from 'components/badges/BadgeCategory/BadgeCategoryForm';
import BadgeFilters from 'components/badges/badge-landing/BadgeFilters';
import DeleteBadgeCategory from 'components/badges/BadgeCategory/DeleteBadgeCategory';
import Toolbar from 'components/common/Toolbar';
import { groupBy } from 'lodash';
import ButtonRow from 'components/common/ButtonRow';
import BadgeStats from './BadgeStats/BadgeStats';
import displayErrorNotification from 'utils/displayErrorNotification';
import { ENABLE_BADGE_STATS } from 'config';
import BadgeCategoryInfo from 'components/badges/BadgeCategory/BadgeCategoryInfo';
import { trackComponent } from 'telemetry/AppInsights';

class BadgeLandingPage extends React.Component {
  constructor() {
    super();

    this.state = {
      categories: [],
      badges: [],

      filteredBadges: [],
      activePanels: [],

      isBadgeCategoryModalVisible: false,
      selectedCategory: null
    };

    this._setFilteredBadges = this._setFilteredBadges.bind(this);
    this._onPanelChange = this._onPanelChange.bind(this);
    this._setCategories = this._setCategories.bind(this);
    this._downloadEmployeeBadges = this._downloadEmployeeBadges.bind(this);
  }

  componentDidMount() {
    getAllBadgeCategories(false)
      .then(categories => this.setState({ categories }))
      .catch(displayErrorNotification);
    getAllBadges(false)
      .then(badges => this.setState({ badges }, () => this._setFilteredBadges(badges)))
      .catch(displayErrorNotification);
  }

  _downloadEmployeeBadges(categoryId) {
    return e => {
      e.stopPropagation();
      getEmployeeBadgesForCategoryId(categoryId, true);
    };
  }

  _onPanelChange(activePanels) {
    this.setState({ activePanels });
  }

  _setFilteredBadges(filteredBadges) {
    const categoryIdsWithBadges = filteredBadges.map(badge => badge.category.id.toString());
    const panelIds = [...new Set(categoryIdsWithBadges)];
    this.setState({ filteredBadges: groupBy(filteredBadges, 'category.id'), activePanels: panelIds });
  }

  _setCategories(categories) {
    this.setState({ categories });
  }

  showBadgeCategoryModal = category => {
    return e => {
      e.stopPropagation();
      this.setState({ isBadgeCategoryModalVisible: true, selectedCategory: category });
    };
  };

  hideBadgeCategoryModal = category => {
    return this.setState({ isBadgeCategoryModalVisible: false, selectedCategory: null });
  };

  // TODO: Move to a reducer
  _onCreateCategory = newCategory => {
    this._setCategories([...this.state.categories, newCategory]);
  };

  _onUpdateCategory = updatedCategory => {
    this._setCategories(this.state.categories.map(c => (c.id === updatedCategory.id ? updatedCategory : c)));
  };

  _onRemoveCategory = category => {
    this._setCategories(this.state.categories.filter(c => c.id !== category.id));
  };

  render() {
    // TODO: Sort categories the logged in user has badges in first
    // TODO: Indicate badges the logged in user has

    const countFilteredBadges = Object.keys(this.state.filteredBadges)
      .map(c => this.state.filteredBadges[c])
      .reduce((a, b) => a + b.length, 0);

    return (
      <UserContext.Consumer>
        {ctx => (
          <Stack>
            <Typography.Title level={1}>
              Badges
              <ButtonRow style={{ float: 'right' }}>
                {ctx.user.permissions.canCreateBadgeCategories && (
                  <Button
                    icon="plus"
                    // type="link"
                    onClick={this.showBadgeCategoryModal()}
                  >
                    Create Category
                  </Button>
                )}
              </ButtonRow>
            </Typography.Title>

            <Toolbar>
              <BadgeFilters
                onSearch={this._setFilteredBadges}
                badges={this.state.badges}
                categories={this.state.categories}
              />

              <div>
                Showing {countFilteredBadges} of {this.state.badges.length} Badges
              </div>
            </Toolbar>

            <Collapse bordered={false} onChange={this._onPanelChange} activeKey={this.state.activePanels}>
              {this.state.categories.map(category => {
                const badges = this.state.filteredBadges[category.id] ? this.state.filteredBadges[category.id] : [];
                const canEdit =
                  category.owners.some(owner => owner.id === ctx.user.id) ||
                  ctx.user.permissions.canManageAllBadgeCategories;
                return (
                  <CardCollapse.Panel
                    header={
                      <>
                        {category.name}
                        <Typography.Text
                          type="secondary"
                          style={{
                            fontStyle: 'italic',
                            fontWeight: 'normal',
                            fontSize: '80%',
                            textTransform: 'none',
                            marginLeft: '1em'
                          }}
                        >
                          {category.description}
                        </Typography.Text>
                      </>
                    }
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag color={badges.length ? 'red' : ''}>
                          {`${badges.length} Badge${badges.length === 1 ? '' : 's'}`}
                        </Tag>
                        <ButtonRow>
                          {canEdit && (
                            <>
                              <Icon title="Edit" type="edit" onClick={this.showBadgeCategoryModal(category)} />
                              <DeleteBadgeCategory category={category} onSuccess={this._onRemoveCategory} />
                            </>
                          )}
                          <Icon title="Export" type="download" onClick={this._downloadEmployeeBadges(category.id)} />
                          <BadgeCategoryInfo category={category} />
                        </ButtonRow>
                      </div>
                    }
                    tagContent={`${badges.length} Badge${badges.length === 1 ? '' : 's'}`}
                    tagProps={{ color: badges.length ? 'red' : '' }}
                    key={category.id}
                  >
                    {badges.length
                      ? badges.map(badge => (
                          <CardCollapse.Card key={badge.id}>
                            <BadgeCard badge={badge} />
                          </CardCollapse.Card>
                        ))
                      : null}
                    <CardCollapse.Card>
                      {canEdit &&
                      (category?.owners.some(o => o.id === ctx.user.id) || ctx.user.permissions.canCreateBadges) ? (
                        <AddNewCard
                          title="Create badge"
                          link={`/badges/new?categoryId=${category.id}`}
                          description={`Add a new badge in the ${category.name} category`}
                        />
                      ) : null}
                    </CardCollapse.Card>
                  </CardCollapse.Panel>
                );
              })}
            </Collapse>
            <BadgeCategoryForm
              isModalVisible={this.state.isBadgeCategoryModalVisible}
              hideModal={this.hideBadgeCategoryModal}
              onUpdate={this._onUpdateCategory}
              onCreate={this._onCreateCategory}
              selectedCategory={this.state.selectedCategory}
            />
            {ENABLE_BADGE_STATS && (
              <div>
                <Typography.Title level={1} style={{ marginBottom: '1.25rem' }}>
                  Badge Stats
                </Typography.Title>
                <BadgeStats allBadges={this.state.badges} />
              </div>
            )}
          </Stack>
        )}
      </UserContext.Consumer>
    );
  }
}

BadgeLandingPage.propTypes = {};

export default trackComponent(BadgeLandingPage, 'Badge Landing Page');
