import { get, post, put, del } from 'api'
import { Capability, LandingPageCapabilityAPI } from 'types/Capability'
import { CapabilityDetail } from 'types/CapabilityDetail'

export function getCapabilities(includeInactive = false): Promise<Capability[]> {
  return get(`api/capabilities`, { includeInactive })
}

export function getLandingPageCapability(): Promise<LandingPageCapabilityAPI[]> {
  return get(`api/capabilities/landingpage`)
}

export function getCapabilityById(id: number): Promise<CapabilityDetail> {
  return get(`api/capabilities/${id}`)
}

export function createCapability(capability: Capability): Promise<CapabilityDetail> {
  return post(`api/capabilities`, capability)
}

export function updateCapability(id: number, capability: CapabilityDetail): Promise<CapabilityDetail> {
  return put(`api/capabilities/${id}`, capability)
}

export function toggleCapabilityActivation(id: number): Promise<CapabilityDetail> {
  return post(`api/capabilities/${id}`)
}

export function deleteCapability(id: number) {
  return del(`api/capabilities/${id}`)
}
