import {Button, Form, Input, InputNumber, Row, Spin} from "antd";
import {
  createEmployeeRegion,
  deleteEmployeeRegion,
  toggleEmployeeRegionActivation,
  updateEmployeeRegion
} from "api/employeeRegionApi";
import {useUser} from "auth/UserHooks";
import ButtonRow from "components/common/ButtonRow";
import React, {useEffect, useState} from "react";
import {EmployeeRegion} from "types/EmployeeRegion";
import displayErrorNotification from "utils/displayErrorNotification";
import "./EmployeeRegionForm.css";

type EmployeeRegionFormProps = {
  region?: EmployeeRegion
  onSubmit: Function
  onCancel: Function
  loading: boolean
  setLoading: Function
  form: any
}

const EmployeeRegionForm = ({
                              region,
                              onSubmit,
                              onCancel,
                              loading,
                              setLoading,
                              form
                            }: EmployeeRegionFormProps) => {
  const user = useUser();
  const isCreateMode = !region;
  const [inactive, setInactive] = useState<boolean>(false);
  const {getFieldDecorator} = form;
  const initialValues = isCreateMode
    ? {
      name: "",
      displayOrder: null,
      inactive: false
    }
    : {...region};

  useEffect(() => {
    form.resetFields();
    if (!isCreateMode) {
      setInactive(region.inactive);
    }
  }, [region, onSubmit]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (err) return;

      const newRegion: EmployeeRegion = {
        id: isCreateMode ? 0 : region.id,
        name: values.name,
        displayOrder: values.displayOrder,
        inactive: isCreateMode ? false : region.inactive
      };
      setLoading(true);
      if (isCreateMode) {
        createEmployeeRegion(newRegion)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
          .then(() => setLoading(false));
      } else if (newRegion.id !== null) {
        updateEmployeeRegion(newRegion.id, newRegion)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
          .then(() => setLoading(false));
      }
    });
  };

  const _toggleRegionActivation = () => {
    if (region && region.id !== null)
      toggleEmployeeRegionActivation(region.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  const _deleteRegion = () => {
    if (region && region.id !== null)
      deleteEmployeeRegion(region.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  return (
    <div style={{minWidth: "450px"}}>
      <h2>{isCreateMode ? "Create" : "Edit"} Employee Region</h2>
      <Spin spinning={loading}>
        <Form onSubmit={handleSubmit} className="editRegionForm">
          <Form.Item label="Name" required>
            {getFieldDecorator("name", {
              initialValue: initialValues.name,
              rules: [{required: true, message: "Name is a required field"}]
            })(<Input disabled={inactive} maxLength={50}/>)}
          </Form.Item>
          <Form.Item label="Display Order">
            {getFieldDecorator("displayOrder", {
              initialValue: initialValues.displayOrder
            })(<InputNumber disabled={inactive} min={1} max={32767}/>)}
          </Form.Item>
          <Row>
            <ButtonRow style={{marginTop: "30px"}}>
              <Button type="primary" htmlType="submit" disabled={inactive}>
                Save
              </Button>
              <Button onClick={() => onCancel()}>Cancel</Button>
              {!isCreateMode && (
                <Button onClick={_toggleRegionActivation}>{inactive ? "Activate" : "Deactivate"}</Button>
              )}
              {!isCreateMode && inactive && user.permissions.canManageEmployeeRegion && (
                <Button type="danger" onClick={_deleteRegion}>
                  Delete
                </Button>
              )}
            </ButtonRow>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default Form.create<EmployeeRegionFormProps>({name: "roletypefamily_form"})(EmployeeRegionForm);
