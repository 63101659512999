import React from 'react';
import EntityForm from './EntityForm';
import Stack from 'components/common/Stack';
import { trackComponent } from 'telemetry/AppInsights';

const NewEntityPage = props => {
  const handleSubmit = response => {
    props.history.push(`/entities/${response.id}`);
  };
  const handleCancel = () => {
    props.history.push('/entities');
  };
  return (
    <Stack>
      <EntityForm isCreateMode onSubmit={handleSubmit} onCancel={handleCancel} />
    </Stack>
  );
};
export default trackComponent(NewEntityPage, 'New Entity Page');
