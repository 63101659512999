import React, { useState, useEffect } from 'react';
import displayErrorNotification from 'utils/displayErrorNotification';
import { getEmployeeDetails } from 'api/employeeApi';
import { useUser } from 'auth/UserHooks';
import AddNewCard from 'components/common/AddNewCard';
import CardCollapse from 'components/common/collapse/CardCollapse';
import CertificationCard from 'components/certifications/CertificationCard';
import CertificationCardModal from 'components/certifications/employee-certification/CertificationCardModal';
import { fetchProfileCompletion } from '../../redux';
import store from '../../redux/store';

const EmployeeCertificationPanel = ({ employeeId, isMe, isCoach, callback }) => {
  const user = useUser();
  const [certifications, setCertifications] = useState([]);
  const [isModalHidden, setIsModalHidden] = useState(false);
  const [modalCertification, setModalCertification] = useState();
  const [modalAction, setModalAction] = useState();

  useEffect(() => _refreshEmployeeCertifications(), []);

  const _refreshEmployeeCertifications = () => {
    setIsModalHidden(false);

    getEmployeeDetails(employeeId)
      .then(resp => {
        setCertifications(resp.certifications);
        if (callback) callback(resp.certifications);
      })
      .catch(displayErrorNotification);
  };

  const _handleChangeModal = (certification, action) => {
    if (certification) setModalCertification(certification);
    if (action) setModalAction(action);

    setIsModalHidden(!isModalHidden);
  };

  return (
    <>
      {certifications
        ? certifications.map(certification => (
            <CardCollapse.Card key={certification.id}>
              <CertificationCard
                certification={certification.certification}
                employeeCertification={certification}
                onEdit={() => {
                  ((isMe && user.permissions.canUpdateOwnCertifications) ||
                    (isCoach && user.permissions.canUpdateCoacheeCertifications) ||
                    user.permissions.canUpdateCertifications) &&
                    _handleChangeModal(certification, 'Edit');
                }}
                onDelete={() => {
                  ((isMe && user.permissions.canDeleteOwnCertifications) ||
                    (isCoach && user.permissions.canDeleteCoacheeCertifications) ||
                    user.permissions.canDeleteCertifications) &&
                    _handleChangeModal(certification, 'Delete');
                }}
                isMe={isMe}
              />
            </CardCollapse.Card>
          ))
        : null}
      {((isMe && user.permissions.canCreateOwnCertifications) ||
        (isCoach && user.permissions.canCreateCoacheeCertifications) ||
        user.permissions.canCreateCertifications) && (
        <CardCollapse.Card>
          <AddNewCard
            title="Add certification"
            link={{}}
            onClick={() => {
              _handleChangeModal(null, 'Add');
            }}
          />
        </CardCollapse.Card>
      )}
      {!isMe && (!certifications || !certifications.length) && 'No certifications'}
      <CertificationCardModal
        action={modalAction}
        modalState={isModalHidden}
        employeeId={employeeId}
        certification={modalCertification}
        setCertification={setModalCertification}
        closeModal={_handleChangeModal}
        onSubmit={() => {
          _refreshEmployeeCertifications();
          store.dispatch(fetchProfileCompletion(employeeId));
        }}
      />
    </>
  );
};

export default EmployeeCertificationPanel;
