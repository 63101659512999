import React from 'react';
import './SelectStack.css';

const SelectStack = ({ children, ...props }) => {
  return (
    <div className="select-stack" {...props}>
      {children}
    </div>
  );
};

export default SelectStack;
