import React from 'react';
import { Link } from 'react-router-dom';
import { List, Avatar } from 'antd';
import _ from 'lodash';
import moment from 'moment';

const NewBadges = props => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={_.reverse(_.sortBy(props.allBadges, 'dateCreated').slice(0, 25))}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={item.imagePath ? item.imagePath : item.image} />}
            title={<Link to={`badges/${item.id}`}>{item.name}</Link>}
            description={moment(item.dateCreated).calendar()}
          />
        </List.Item>
      )}
    />
  );
};

export default NewBadges;
