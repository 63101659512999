import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form, Icon, Checkbox } from 'antd';
import { debounce, get, isEmpty, sum } from 'lodash';

function checkValidName(item, uppercaseTerm) {
  return isEmpty(uppercaseTerm) || item.toUpperCase().indexOf(uppercaseTerm) > -1;
}

const filterRoleTypesInFamily = (family, uppercaseTerm, showInactive) => {
  return {
    ...family,
    contains: family.contains.filter(roleType => {
      return checkValidName(roleType.name, uppercaseTerm) && (showInactive || roleType.active);
    }),
    showInactive
  };
};

export const filterRoleTypeTree = (props, changedValues, values) => {
  const { roleTypes, onSearch, setSettings, roleTypeFamilies } = props;
  const uppercaseTerm = get(values, 'searchTerm', '').toUpperCase();
  const showInactive = values.showInactive;

  setSettings({
    search: values.searchTerm,
    inActive: showInactive
  });

  const filteredRoleTypeTree = roleTypes
    .map(family => {
      if (uppercaseTerm !== '' && checkValidName(family.name, uppercaseTerm)) {
        return {
          ...family,
          contains: family.contains.filter(pr => pr.active || showInactive)
        };
      }
      const filteredFamily = filterRoleTypesInFamily(family, uppercaseTerm, showInactive);
      let containsResults = filteredFamily.contains.length > 0;

      return containsResults ? filteredFamily : {};
    })
    .filter(family => family.name != null);
  recountRoleTypes(filteredRoleTypeTree, roleTypeFamilies, showInactive, onSearch);
};

const recountRoleTypes = (filteredRoleTypeTree, roleTypeFamilies, showInactive, onSearch) => {
  filteredRoleTypeTree.forEach(f => {
    f.employeeCount = sum(f.contains.map(pr => pr.employeeCount));
    f.roleTypeCount = f.contains.length;
  });
  const filterRoleTypeFamiles = roleTypeFamilies.filter(s => !s.inactive || showInactive);
  onSearch(filteredRoleTypeTree, filterRoleTypeFamiles);
};

const RoleTypeFilters = props => {
  const { form, disabled, settings, searchRef } = props;
  const { getFieldDecorator } = form;

  const clearFilters = () => {
    form.resetFields();
    filterRoleTypeTree(
      props,
      {},
      {
        showInactive: false,
        searchTerm: ''
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showInactive', {
            initialValue: settings.inActive,
            valuePropName: 'checked'
          })(<Checkbox>Show Inactive</Checkbox>)}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

RoleTypeFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  roleTypes: PropTypes.array,
  roleTypeFamilies: PropTypes.array
};

export default Form.create({
  name: 'filter_role_types_form',
  onValuesChange: debounce(filterRoleTypeTree, 500)
})(RoleTypeFilters);
