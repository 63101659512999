import React, { useState, useEffect } from 'react';
import { getPrompts, updatePrompt } from 'api/promptApi';
import { Table, Icon, Typography, Button, Checkbox, Tag } from 'antd';
import Modal from 'components/common/Modal';
import PromptForm from 'components/admin/prompts/PromptForm';
import displayErrorNotification from 'utils/displayErrorNotification';
import Stack from 'components/common/Stack';
import ButtonRow from 'components/common/ButtonRow';
import { Link } from 'react-router-dom';
import { useUser } from 'auth/UserHooks';
import enumToDisplayText from 'utils/enumToDisplayText';
import DndTableWrapper from 'components/common/drag-and-drop/DndTableWrapper';
import { Prompt } from 'types/Prompt';
import { ColumnProps, TableComponents } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SavePreferencesButton from 'components/common/SavePreferencesButton';
import useLocalStorage from 'utils/useLocalStorage';
import { useFeatures } from 'hooks/FeatureHooks';
import { ProjectorUdf } from 'types/Projector';
import { getProjectorUdfs } from 'api/projectorApi';
import { getEmployeePractices } from 'api/employeePracticeApi'
import { EmployeePractice } from 'types/EmployeePractice'
import { textOverflow } from 'utils/skillDetails'

const AdminPromptsTable = () => {
  const user = useUser();
  const canCreatePrompt = user.permissions.canCreatePrompt;
  const canUpdatePrompt = user.permissions.canUpdatePrompt;
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [udfs, setUdfs] = useState<ProjectorUdf[]>([]);
  const [practices, setPractices] = useState<EmployeePractice[]>([]);
  const [filteredPrompts, setFilteredPrompts] = useState<Prompt[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showInactive, setShowInactive] = useLocalStorage<any>('AdminPromptsTable_InactiveSetting', false);
  const [loading, setLoading] = useState(false);
  const features = useFeatures();

  const _refreshPrompts = () => {
    return Promise.all([getPrompts(true), getProjectorUdfs(), getEmployeePractices()])
      .then(response => {
        setPrompts(response[0]);
        filterPrompts(response[0], showInactive);

        const filtered: ProjectorUdf[] = response[1].filter(
          u => u.isDeleted === false && !response[0].some(p => p.udf !== undefined && p.udf.id === u.id)
        );
        setUdfs(filtered);
        setPractices(response[2]);
      })
      .catch(displayErrorNotification);
  };

  const filterPrompts = (prompts: Prompt[], showInactive: boolean) => {
    const filtered = prompts.filter((p: Prompt) => showInactive || !p.inactive);
    setFilteredPrompts(filtered);
  };

  const handleInactiveChange = (e: CheckboxChangeEvent) => {
    setShowInactive(e.target.checked);
    filterPrompts(prompts, e.target.checked);
  };

  const categories = new Set<string>();
  for (const prompt of prompts) {
    if (prompt.category) {
      prompt.category.forEach(c => categories.add(c));
    }
  }

  const tableColumns: ColumnProps<Prompt>[] = [
    {
      key: 'question',
      title: 'Question',
      render: (record: Prompt) => <Link to={`/prompts/${record.id}`}>{record.question}</Link>
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      render: (desc:string) => textOverflow(desc, 40)
    },
    {
      key: 'type',
      title: 'Type',
      render: (item: Prompt) => enumToDisplayText(item.type)
    },
    {
      key: 'udf',
      title: 'UDF',
      render: (item: Prompt) => {
        if (item.udf === undefined) {
          return '';
        } else {
          if (item.udf.isDeleted) {
            return (
              <>
                {item.udf.name}&nbsp;<Tag color="red">Inactive</Tag>
              </>
            );
          } else {
            return item.udf.name;
          }
        }
      },
      hidden: !(user.permissions.canViewProjectorUdf && features && features.isEnabled('ENABLE_PROJECTOR_FEATURES'))
    },
    {
      key: 'practiceId',
      title: 'Practice',
      render: (item: Prompt) => {
        if (item.practiceId === undefined || item.practiceId === null) {
          return "";
        }
        const find = practices.find(p => item.practiceId === p.id);
        if (find === undefined) {
          return "Error: Practice not found";
        } else {
          if (find.inactive) {
            return (
              <>
              {find.abbrev}&nbsp;<Tag color="red">Inactive</Tag>
              </>
            )
          } else {
          return find.abbrev;
          }
        }
      }
    },
    {
      key: 'category',
      title: 'Category',
      render: (item: Prompt) => item.category === undefined || item.category === null ? "" : Array.from(item.category).join(', '),
      hidden: !(user.permissions.canReadConfidentialPromptResponses || user.permissions.canReadStaffingPromptResponses)
    },
    {
      key: 'personal',
      title: 'Private',
      render: (item: Prompt) => item.personal && (<Icon type="check-circle" />),
      hidden: !(user.permissions.canReadPersonalPromptResponses)
    },
    {
      key: 'inactive',
      title: 'Inactive',
      render: (item: Prompt) => item.inactive && <Icon type="check-circle" />
    }
  ].filter(item => !item.hidden) as ColumnProps<Prompt>[];

  if (canUpdatePrompt) {
    tableColumns.push({
      render: (item: Prompt) => (
        <Modal
          item={item}
          trigger={({ openModal }: { openModal: () => void }) => (
            <Button onClick={openModal} size="small" icon="edit">
              Edit
            </Button>
          )}
        >
          {({ item, closeModal }: { item: Prompt; closeModal: () => void }) => (
            <PromptForm
              prompt={item}
              udfs={udfs}
              practices={practices}
              categories={categories}
              onSubmit={() => {
                _refreshPrompts().then(closeModal);
              }}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )
    });
  }

  useEffect(() => {
    setLoading(true);
    _refreshPrompts().then(() => {
      setLoading(false);
    });
  }, []);

  const _handleModalState = () => {
    setShowModal(!showModal);
  };

  const moveRow = (fromIndex: number, toIndex: number) => {
    const promptMoving = prompts[fromIndex];
    prompts.splice(fromIndex, 1);
    prompts.splice(toIndex, 0, promptMoving);
    prompts.forEach((p, idx) => (p.displayOrder = idx + 1));

    const apiRequests: Promise<Prompt>[] = [];
    const promptsToUpdate = prompts.slice(Math.min(fromIndex, toIndex), Math.max(fromIndex, toIndex) + 1);
    promptsToUpdate.forEach(p => {
      apiRequests.push(updatePrompt(Number(p.id), p));
    });

    Promise.all(apiRequests)
      .then(() => setPrompts([...prompts]))
      .catch(displayErrorNotification);
  };

  return (
    <>
      {canCreatePrompt && (
        <Modal isOpen={showModal} onClose={_handleModalState}>
          {({ closeModal }: { closeModal: () => void }) => (
            <PromptForm
              udfs={udfs}
              practices={practices}
              categories={categories}
              onSubmit={() => {
                _refreshPrompts().then(closeModal);
              }}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )}
      <Stack>
        <Typography.Title level={1}>
          Prompts
          <ButtonRow style={{ float: 'right' }}>
            {canCreatePrompt && (
              <Button icon="plus" onClick={_handleModalState}>
                Create Prompt
              </Button>
            )}
            <SavePreferencesButton keys={['AdminPromptsTable_InactiveSetting']} disabled={loading} />
          </ButtonRow>
        </Typography.Title>
        <Typography.Title level={4} type="secondary">
          Some prompts are marked "private" and responses can only be viewed by Admins, Partners, and Practice Leaders.
        </Typography.Title>
        <Checkbox checked={showInactive} onChange={handleInactiveChange}>
          Show Inactive
        </Checkbox>
        <DndTableWrapper inactive={!canUpdatePrompt}>
          {(components: TableComponents) => (
            <Table
              columns={tableColumns}
              dataSource={filteredPrompts}
              components={components}
              loading={loading}
              onRow={(record, index) => ({
                index,
                moveRow
              })}
              rowKey="id"
              pagination={false}
            />
          )}
        </DndTableWrapper>
      </Stack>
    </>
  );
};

export default AdminPromptsTable;
