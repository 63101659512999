export interface IndustryExperienceReportFilterSettings {
    search: string;
    practice: string[];
    position: string[];
    office: string[];
    industryExperience: string[];
  }
  
  export function defaultIndustryExperienceReportFilterSettings(): IndustryExperienceReportFilterSettings {
    return {
      search: '',
      practice: [],
      position: [],
      office: [],
      industryExperience: []
    };
  }