import { useEffect } from 'react';

const Page = props => {
  useEffect(() => {
    document.title = `Scout - ${props.title}` || 'Scout';
  }, [props.title]);
  return props.children;
};

export default Page;
