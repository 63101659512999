export const getRoleTypeFamilyTree = (families, roleTypes) => {
  const Tree = [];
  function roleTypeFilters(roleTypes, family) {
    let filteredRoleTypes = roleTypes.filter(pr => pr.family_id === family.id);
    return filteredRoleTypes;
  }
  for (let family of families) {
    const familyContainer = {
      name: family.name,
      employeeCount: 0,
      id: `family${family.id}`,
      roleTypeCount: 0,
      contains: []
    };
    const filteredRoleTypes = roleTypeFilters(roleTypes, family);
    for (let roleType of filteredRoleTypes) {
      familyContainer.employeeCount += roleType.employeeCount;
      familyContainer.roleTypeCount += 1;
      familyContainer.contains.push(roleType);
    }
    Tree.push(familyContainer);
  }
  return Tree;
};
