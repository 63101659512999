import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Select } from 'antd';
import { addBadgeCategory, updateBadgeCategory } from 'api/badgesApi';
import { getAllEmployees } from 'api/employeeApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import { useUser } from 'auth/UserHooks';

const { Option } = Select;

const BadgeCategoryForm = ({ selectedCategory, onUpdate, onCreate, hideModal, ...props }) => {
  const [employeeOptions, setEmployeeOptions] = useState([]);

  useEffect(() => {
    getAllEmployees()
      .then(setEmployeeOptions)
      .catch(displayErrorNotification);
  }, []);

  const handleSubmit = e => {
    e && e.preventDefault();

    props.form.validateFields((err, values) => {
      if (err) return;

      const formValues = {
        ...values,
        id: selectedCategory ? selectedCategory.id : null,
        owners: values.owners.map(id => ({ id })) // convert format from [443, 543] to [{id: 443}, {id: 543}]
      };

      if (selectedCategory) {
        updateBadgeCategory(formValues.id, formValues)
          .then(() => onUpdate(formValues))
          .then(props.form.resetFields)
          .then(hideModal)
          .catch(displayErrorNotification);
      } else {
        addBadgeCategory(formValues)
          .then(onCreate)
          .then(props.form.resetFields)
          .then(hideModal)
          .catch(displayErrorNotification);
      }
    });
  };

  const handleCancel = () => {
    hideModal();
    props.form.resetFields();
  };

  const { isModalVisible, form } = props;
  const { getFieldDecorator } = form;
  const user = useUser();

  const isCreateMode = !selectedCategory;

  const initialValues = isCreateMode
    ? {
        owners: [user.id]
      }
    : {
        ...selectedCategory,
        owners: selectedCategory.owners.map(o => o.id)
      };

  return (
    <Modal
      visible={isModalVisible}
      title={isCreateMode ? 'Create a new badge category' : 'Edit badge category'}
      okText={isCreateMode ? 'Create' : 'Save'}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: initialValues.name,
            rules: [
              { required: true, message: 'Name is a required field' },
              { max: 255, message: 'Name must be fewer than 255 characters' }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: initialValues.description,
            rules: [{ max: 255, message: 'Description must be fewer than 255 characters' }]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Owners">
          {getFieldDecorator('owners', {
            initialValue: initialValues.owners,
            rules: [
              { required: true, message: 'Owners is a required field' },
              { max: 15, type: 'array', message: 'There must be fewer than 15 owners' }
            ]
          })(
            <Select
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: '100%' }}
              placeholder="Please select"
            >
              {employeeOptions.map(e => (
                <Option key={e.id} value={e.id}>
                  {e.fullName}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

BadgeCategoryForm.propTypes = {
  selectedCategory: PropTypes.object,
  isModalVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default Form.create({ name: 'badge_category' })(BadgeCategoryForm);
