import moment from "moment";

const sortString = (a, b, sortOrder = true) => {
  if (sortOrder) {
    if (!a) return -1;
    if (!b) return 1;

    return a.toLowerCase().localeCompare(b.toLowerCase());
  } else {
    if (!a) return 1;
    if (!b) return -1;
    return b.toLowerCase().localeCompare(a.toLowerCase());
  }
};

const sortDate = (a, b, sortOrder = true) => {
  if (a !== undefined && b !== undefined) {
    if (sortOrder) {
      return moment(a).isSameOrAfter(moment(b)) ? 1 : -1;
    } else {
      return moment(b).isSameOrAfter(moment(a)) ? 1 : -1;
    }
  } else {
    if (a === undefined) {
      return sortOrder ? -1 : 1;
    } else {
      return sortOrder ? 1 : -1;
    }
  }
};

const sortDateIndex = (aDate, aIndex, bDate, bIndex, sortOrder = true) => {
  if (
    aDate !== undefined &&
    aIndex !== undefined &&
    bDate !== undefined &&
    bIndex !== undefined
  ) {
    if (moment(aDate).isSame(moment(bDate))) {
      return sortNumber(aIndex, bIndex, sortOrder);
    } else {
      return sortDate(aDate, bDate, sortOrder);
    }
  } else if (
    aDate !== undefined &&
    bIndex === undefined &&
    bDate !== undefined &&
    bIndex === undefined
  ) {
    return sortDate(aDate, bDate, sortOrder);
  } else if (
    aDate === undefined &&
    aIndex !== undefined &&
    bDate === undefined &&
    bIndex !== undefined
  ) {
    return sortNumber(aIndex, bIndex, sortOrder);
  } else {
    if (aDate === undefined) {
      return sortOrder ? -1 : 1;
    } else {
      return sortOrder ? 1 : -1;
    }
  }
};

const sortNumber = (a, b, sortOrder = true) => {
  if (sortOrder) {
    return a - b;
  } else {
    return b - a;
  }
};

const sortMulti = (
  a,
  b,
  column,
  type = sorters.TYPE_STRING,
  sortOrder = true
) => {
  switch (type) {
    case sorters.TYPE_STRING:
      return sorters.string(a[column], b[column], sortOrder);
    case sorters.TYPE_NUMBER:
      return sorters.number(a[column], b[column], sortOrder);
    case sorters.TYPE_DATE:
      return sorters.date(a[column], b[column], sortOrder);
    default:
      return 0;
  }
};

const sorters = {
  TYPE_STRING: 0,
  TYPE_NUMBER: 1,
  TYPE_DATE: 2,
  string: sortString,
  date: sortDate,
  dateIndex: sortDateIndex,
  number: sortNumber,
  multi: sortMulti
};

export default sorters;
