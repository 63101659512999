import React, { useEffect, useState } from 'react';
import { useUser } from 'auth/UserHooks';
import { getEmployeeDetails, getProjects } from 'api/employeeApi';
import EmployeeDetails from 'components/employees/EmployeeDetails';
import { Typography } from 'antd';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import ProfileCompletion from '../profileCompletion/ProfileCompletion';
import { trackComponent } from 'telemetry/AppInsights';

const HomePage = () => {
  const user = useUser();
  const [employee, setEmployee] = useState({});
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getEmployeeDetails(user.id)
      .then(setEmployee)
      .catch(displayErrorNotification);
    getProjects(user.id)
      .then(setProjects)
      .catch(displayErrorNotification);
  }, []);

  return (
    <Stack>
      <Typography.Title className="left">Hello, {user.firstName}!</Typography.Title>
      <ProfileCompletion className="right" id={user.id} />
      {employee && employee.id && <EmployeeDetails employee={employee} projects={projects} />}
    </Stack>
  );
};

export default trackComponent(HomePage, 'Home Page');
