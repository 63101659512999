import React from 'react';
import { Modal, Typography, Button } from 'antd';
import ButtonRow from 'components/common/ButtonRow';
import { Capability } from 'types/Capability';

type CapabilityDeleteModalProps = {
  capability: Capability;
  isModalVisible: boolean;
  onSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closeModal: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const CapabilityDeleteModal = (props: CapabilityDeleteModalProps) => {
  return (
    <Modal
      title={'Delete Capability'}
      visible={props.isModalVisible}
      onCancel={props.closeModal}
      footer={null}
    >
      <Typography.Paragraph strong>
        Are you sure you want to delete this capability? 
      </Typography.Paragraph>
      <Typography.Paragraph>{props.capability.name}</Typography.Paragraph>
      <Typography.Paragraph>This will return an error if this capability is in use.</Typography.Paragraph>
      <ButtonRow style={{ marginTop: '2em' }}>
        <Button type="primary" onClick={props.onSubmit}>
          Delete
        </Button>
        <Button onClick={props.closeModal}>Cancel</Button>
      </ButtonRow>
    </Modal>
  );
};

export default CapabilityDeleteModal;
