import React from "react";
import { Input, Select, Button, Form, Icon, TreeSelect } from "antd";
import { debounce, uniq } from "lodash";
import { PromptFilterSettings } from "types/PromptPreferences";
import { EmployeePromptResponse } from "../../types/EmployeePromptResponse";
import { PromptTypes, promptTypeValues } from "types/Prompt";

type PromptResponseFiltersProps = {
  promptResps: EmployeePromptResponse[];
  promptType: typeof promptTypeValues[number];
  onSearch: (filteredPromptResps: EmployeePromptResponse[]) => void;
  form: any;
  searchRef: any;
  disabled: boolean;
  settings: PromptFilterSettings;
  setSettings: (settings: PromptFilterSettings) => void;
};

export const filterResponses = (
  props: PromptResponseFiltersProps,
  changedValues: any,
  values: any
) => {
  const { promptResps, promptType, onSearch, setSettings } = props;

  const uppercaseTerm = values.searchTerm
    ? values.searchTerm.toUpperCase()
    : "";
  const practices = values.selectedPractices;
  const positions = values.selectedPositions;
  const locations = values.selectedLocations;
  const responseSearch = values.selectedSearchResponses
    ? (Array.isArray(values.selectedSearchResponses)
      ? values.selectedSearchResponses.map(r => r.toUpperCase())
      : [values.selectedSearchResponses.toUpperCase()])
    : [];
  const responseDropdown = values.selectedDropdownResponses || [];
  const responseBirthday = values.selectedBirthdayResponses || [];

  setSettings({
    search: values.searchTerm,
    practice: practices,
    position: positions,
    location: locations,
    responseSearch: values.selectedSearchResponses,
    responseDropdown: responseDropdown,
    responseBirthday: responseBirthday
  });

  const filteredResponses = promptResps.filter(pr => {
    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      pr.fullName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      pr.sortName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      (promptType === PromptTypes.BIRTHDAY
        ? (() => {
          const [month, day] = uppercaseTerm.split(' ');
          if (!day) { return pr.response.toUpperCase().startsWith(month.toUpperCase()); } 
          else { return pr.response.toUpperCase() === `${month.toUpperCase()} ${day.toUpperCase()}`; }
        })()
        : pr.response.toUpperCase().indexOf(uppercaseTerm) > -1);
    let isPracticeValid =
      !practices || !practices.length || practices.indexOf(pr.practice) > -1;
    let isPositionValid =
      !positions || !positions.length || positions.indexOf(pr.position) > -1;
    let isLocationValid =
      !locations || !locations.length || locations.indexOf(pr.location) > -1;
    let isSearchResponseValid =
      !responseSearch ||
      !responseSearch.length ||
      responseSearch.some(rs => pr.response.toUpperCase().indexOf(rs) > -1);
    let isDropdownResponseValid =
      !responseDropdown ||
      !responseDropdown.length ||
      responseDropdown.some(rd => pr.value.includes(rd)) ||
      responseDropdown.some(rd => pr.response.includes(rd));
    let isBirthdayResponseValid =
      !responseBirthday ||
      !responseBirthday.length ||
      responseBirthday.some(rb => {
        const [month, day] = rb.split(' ');
        if (!day) { return pr.response.startsWith(month); }
        else { return pr.response === rb; }
      });
    return isNameValid && isPracticeValid && isPositionValid && isLocationValid && isSearchResponseValid && isDropdownResponseValid && isBirthdayResponseValid;
  });
  onSearch(filteredResponses);
};

const PromptResponseFilters = (props: PromptResponseFiltersProps) => {
  const { promptResps = [], form, searchRef, disabled, settings } = props;
  const practices = uniq(
    promptResps
      .sort((a, b) => (a.practice < b.practice ? -1 : 1))
      .map(pr => pr.practice)
  );

  const positions = uniq(
    promptResps
      .sort((a, b) => (a.position < b.position ? -1 : 1))
      .map(pr => pr.position)
  );

  const offices = uniq(
    promptResps
      .sort((a, b) => (a.location < b.location ? -1 : 1))
      .map(r => r.location)
  );

  if (props.promptType === PromptTypes.SELECT_MULTIPLE) {
    var resps = promptResps
      .sort((a, b) => (a.value < b.value ? -1 : 1))
      .flatMap((r) => r.value.split(','))
      .map((r) => r.trim())
  }
  else {
    resps = promptResps
      .sort((a, b) => (a.response < b.response ? -1 : 1))
      .map((r) => r.response)
  }

  const responses = uniq(resps.map(r => r.replace('N/A', 'None')));

  const clearFilters = () => {
    form.resetFields();
    filterResponses(
      props,
      {},
      {
        searchTerm: "",
        selectedPractices: [],
        selectedPositions: [],
        selectedLocations: [],
        selectedSearchResponses: "",
        selectedDropdownResponses: [],
        selectedBirthdayResponses: []
      }
    );
  };

  const { getFieldDecorator } = form;

  const selectedPractices = form.getFieldValue("selectedPractices") || [];
  const selectedPositions = form.getFieldValue("selectedPositions") || [];
  const selectedLocations = form.getFieldValue("selectedLocations") || [];
  const selectedDropdownResponses = form.getFieldValue("selectedDropdownResponses") || [];

  const monthOrder = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const groupByMonth = responses.reduce((acc, response) => {
    const [month, day] = response.split(' ');
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push({ title: response, value: response });
    return acc;
  }, {});

  const birthdayData = monthOrder
    .filter(month => groupByMonth[month])
    .map(month => ({
      title: month,
      value: month,
      children: groupByMonth[month]
        .sort((a, b) => parseInt(a.title.split(' ')[1]) - parseInt(b.title.split(' ')[1]))
    }));


  return (
    <div style={disabled ? { pointerEvents: "none", opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator("searchTerm", {
            initialValue: settings.search
          })(
            <Input
              prefix={<Icon type="filter" />}
              ref={searchRef}
              disabled={disabled}
              placeholder="Filter"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedPractices", {
            initialValue: settings.practice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPractices.length} Practice${selectedPractices.length === 1 ? "" : "s"
                } Selected`}
              showArrow
              mode="multiple"
              placeholder="Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {practices.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedPositions", {
            initialValue: settings.position
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPositions.length} Position${selectedPositions.length === 1 ? "" : "s"
                } Selected`}
              showArrow
              mode="multiple"
              placeholder="Position"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {positions.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedLocations", {
            initialValue: settings.location
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedLocations.length} Location${selectedLocations.length === 1 ? "" : "s"
                } Selected`}
              showArrow
              mode="multiple"
              placeholder="Location"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {offices.map(o => (
                <Select.Option key={o} value={o}>
                  {o}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {props.promptType === PromptTypes.FREE_RESPONSE ? (
          <Form.Item>
            {getFieldDecorator("selectedSearchResponses", {
              initialValue: settings.responseSearch
            })(
              <Input
                prefix={<Icon type="response" />}
                ref={searchRef}
                disabled={disabled}
                placeholder="Response"
              />
            )}
          </Form.Item>
        ) : props.promptType === PromptTypes.SELECT_ONE || props.promptType === PromptTypes.SELECT_MULTIPLE ? (
          <Form.Item>
            {getFieldDecorator("selectedDropdownResponses", {
              initialValue: settings.responseDropdown
            })(
              <Select
                maxTagCount={0}
                maxTagPlaceholder={`${selectedDropdownResponses.length} Response${selectedDropdownResponses.length === 1 ? "" : "s"
                  } Selected`}
                showArrow
                mode="multiple"
                placeholder="Response"
                dropdownClassName="filter-bar-select-dropdown"
                className="filter-bar-select"
              >
                {responses.map(r => (
                  <Select.Option key={r} value={r}>
                    {r}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : props.promptType === PromptTypes.BIRTHDAY ? (
          <Form.Item>
            {getFieldDecorator("selectedBirthdayResponses", {
              initialValue: settings.responseBirthday
            })(
              <TreeSelect
                showSearch
                showArrow
                className="filter-bar-select"
                dropdownClassName="filter-bar-select-dropdown"
                dropdownStyle={{ height: 250 }}
                placeholder="Response"
                allowClear
                multiple
                treeDefaultExpandAll={false}
                treeData={birthdayData}
              />
            )}
          </Form.Item>
        ) : null}
        <Form.Item>
          <Button
            title="Clear filters"
            icon="close"
            type="link"
            onClick={clearFilters}
          >
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create<PromptResponseFiltersProps>({
  name: "filter_prompt_responses_form",
  onValuesChange: debounce(filterResponses, 500)
})(PromptResponseFilters);