import { useContext, useState } from "react";
import UserContext from "auth/UserContext";
import { Employee } from "types/Employee";

// eslint-disable-next-line no-undef
export interface EmployeeWithPermissions extends Omit<Employee, "permissions"> {
  permissions: { [T: string]: boolean };
}

export const useUserState: () => [
  EmployeeWithPermissions | null,
  (user: Employee) => void
] = () => {
  const [user, _setUser] = useState<EmployeeWithPermissions | null>(null);

  const setUser: (user: Employee) => void = user => {
    // direct mapping to back-end permissions
    let p: { [permission: string]: boolean } = {
      canCreatePrompt: user.permissions.includes("CAN_CREATE_PROMPT"),
      canReadPrompt: user.permissions.includes("CAN_READ_PROMPT"),
      canUpdatePrompt: user.permissions.includes("CAN_UPDATE_PROMPT"),
      canDeletePrompt: user.permissions.includes("CAN_DELETE_PROMPT"), // think of this as can toggle activation for now
      canUpdateOwnPromptResponse: user.permissions.includes(
        "CAN_UPDATE_OWN_PROMPT_RESPONSE"
      ),
      canUpdateCoacheePromptResponse: user.permissions.includes(
        "CAN_UPDATE_COACHEE_PROMPT_RESPONSE"
      ),
      canReadPromptResponses: user.permissions.includes(
        "CAN_READ_PROMPT_RESPONSES"
      ),
      canReadPersonalPromptResponses: user.permissions.includes(
        "CAN_READ_PERSONAL_PROMPT_RESPONSES"
      ),
      canUpdatePromptResponses: user.permissions.includes(
        "CAN_UPDATE_PROMPT_RESPONSES"
      ),

      canReadTags: user.permissions.includes("CAN_READ_TAGS"),
      canManageTags: user.permissions.includes("CAN_MANAGE_TAGS"),
      canViewAdminTags: user.permissions.includes("CAN_VIEW_ADMIN_TAGS"),
      canDeleteTags: user.permissions.includes("CAN_DELETE_TAGS"),

      canReadProjects: user.permissions.includes("CAN_READ_PROJECTS"),
      canViewScout: user.permissions.includes("CAN_VIEW_SCOUT"),

      canImportClients: user.permissions.includes("CAN_IMPORT_CLIENTS"),
      canImportEmployees: user.permissions.includes("CAN_IMPORT_EMPLOYEES"),
      canReadEmployees: user.permissions.includes("CAN_READ_EMPLOYEES"),
      canImportProjects: user.permissions.includes("CAN_IMPORT_PROJECTS"),
      canImportBadges: user.permissions.includes("CAN_IMPORT_BADGES"),
      canConvertBadgeImages: user.permissions.includes(
        "CAN_CONVERT_BADGE_IMAGES"
      ),

      canReadCertifications: user.permissions.includes(
        "CAN_READ_CERTIFICATIONS"
      ),
      canCreateCertificationTypes: user.permissions.includes(
        "CAN_CREATE_CERTIFICATION_TYPES"
      ),
      canUpdateCertificationTypes: user.permissions.includes(
        "CAN_UPDATE_CERTIFICATION_TYPES"
      ),
      canDeleteCertificationTypes: user.permissions.includes(
        "CAN_DELETE_CERTIFICATION_TYPES"
      ),
      canCreateOwnCertifications: user.permissions.includes(
        "CAN_CREATE_OWN_CERTIFICATIONS"
      ),
      canUpdateOwnCertifications: user.permissions.includes(
        "CAN_UPDATE_OWN_CERTIFICATIONS"
      ),
      canDeleteOwnCertifications: user.permissions.includes(
        "CAN_DELETE_OWN_CERTIFICATIONS"
      ),
      canCreateCoacheeCertifications: user.permissions.includes(
        "CAN_CREATE_COACHEE_CERTIFICATIONS"
      ),
      canUpdateCoacheeCertifications: user.permissions.includes(
        "CAN_UPDATE_COACHEE_CERTIFICATIONS"
      ),
      canDeleteCoacheeCertifications: user.permissions.includes(
        "CAN_DELETE_COACHEE_CERTIFICATIONS"
      ),
      canCreateCertifications: user.permissions.includes(
        "CAN_CREATE_CERTIFICATIONS"
      ),
      canUpdateCertifications: user.permissions.includes(
        "CAN_UPDATE_CERTIFICATIONS"
      ),
      canDeleteCertifications: user.permissions.includes(
        "CAN_DELETE_CERTIFICATIONS"
      ),
      canExportCertifications: user.permissions.includes(
        "CAN_EXPORT_CERTIFICATIONS"
      ),
      canCreateCertifyingOrganizations: user.permissions.includes(
        "CAN_CREATE_CERTIFYING_ORGANIZATIONS"
      ),
      canUpdateCertifyingOrganizations: user.permissions.includes(
        "CAN_UPDATE_CERTIFYING_ORGANIZATIONS"
      ),

      canCreateOwnSkill: user.permissions.includes("CAN_CREATE_OWN_SKILL"),
      canUpdateOwnSkill: user.permissions.includes("CAN_UPDATE_OWN_SKILL"),
      canDeleteOwnSkill: user.permissions.includes("CAN_DELETE_OWN_SKILL"),
      canCreateCoacheeSkill: user.permissions.includes(
        "CAN_CREATE_COACHEE_SKILL"
      ),
      canUpdateCoacheeSkill: user.permissions.includes(
        "CAN_UPDATE_COACHEE_SKILL"
      ),
      canDeleteCoacheeSkill: user.permissions.includes(
        "CAN_DELETE_COACHEE_SKILL"
      ),
      canCreateSkill: user.permissions.includes("CAN_CREATE_SKILL"),
      canUpdateSkill: user.permissions.includes("CAN_UPDATE_SKILL"),
      canDeleteSkill: user.permissions.includes("CAN_DELETE_SKILL"),
      canCreateSkillType: user.permissions.includes("CAN_CREATE_SKILL_TYPE"),
      canUpdateSkillType: user.permissions.includes("CAN_UPDATE_SKILL_TYPE"),
      canDeleteSkillType: user.permissions.includes("CAN_DELETE_SKILL_TYPE"),
      canReadSkills: user.permissions.includes("CAN_READ_SKILLS"),

      canReadCapabilities: user.permissions.includes("CAN_READ_CAPABILITIES"),
      canCreateOwnCapability: user.permissions.includes(
        "CAN_CREATE_OWN_CAPABILITIES"
      ),
      canCreateCoacheeCapability: user.permissions.includes(
        "CAN_CREATE_COACHEE_CAPABILITIES"
      ),
      canUpdateCapability: user.permissions.includes("CAN_UPDATE_CAPABILITY"),
      canCreateCapability: user.permissions.includes("CAN_CREATE_CAPABILITIES"),
      canCreateCapabilityType: user.permissions.includes(
        "CAN_CREATE_CAPABILITY_TYPE"
      ),
      canUpdateCapabilityType: user.permissions.includes(
        "CAN_UPDATE_CAPABILITY_TYPE"
      ),
      canDeleteCapabilityType: user.permissions.includes(
        "CAN_DELETE_CAPABILITY_TYPE"
      ),
      canToggleCapabilityTypeActivation: user.permissions.includes(
        "CAN_TOGGLE_CAPABILITY_TYPE_ACTIVATION"
      ),
      canUpdateOwnCapability: user.permissions.includes(
        "CAN_UPDATE_OWN_CAPABILITY"
      ),
      canUpdateCoacheeCapability: user.permissions.includes(
        "CAN_UPDATE_COACHEE_CAPABILITY"
      ),

      canReadMajorMinor: user.permissions.includes("CAN_READ_MAJOR_MINOR"),
      canCreateOwnMajorMinor: user.permissions.includes(
        "CAN_CREATE_OWN_MAJOR_MINOR"
      ),
      canCreateCoacheeMajorMinor: user.permissions.includes(
        "CAN_CREATE_COACHEE_MAJOR_MINOR"
      ),
      canUpdateMajorMinor: user.permissions.includes("CAN_UPDATE_MAJOR_MINOR"),
      canCreateMajorMinor: user.permissions.includes("CAN_CREATE_MAJOR_MINOR"),
      canUpdateOwnMajorMinor: user.permissions.includes(
        "CAN_UPDATE_OWN_MAJOR_MINOR"
      ),
      canUpdateCoacheeMajorMinor: user.permissions.includes(
        "CAN_UPDATE_COACHEE_MAJOR_MINOR"
      ),

      canReadEntities: user.permissions.includes("CAN_READ_ENTITIES"),
      canCreateEntity: user.permissions.includes("CAN_CREATE_ENTITY"),
      canUpdateEntity: user.permissions.includes("CAN_UPDATE_ENTITY"),
      canToggleEntityActivation: user.permissions.includes(
        "CAN_TOGGLE_ENTITY_ACTIVATION"
      ),

      canReadIndustries: user.permissions.includes("CAN_READ_INDUSTRIES"),
      canUpdateIndustry: user.permissions.includes("CAN_UPDATE_INDUSTRY"),
      canCreateIndustry: user.permissions.includes("CAN_CREATE_INDUSTRY"),
      canCreateIndustryType: user.permissions.includes(
        "CAN_CREATE_INDUSTRY_TYPE"
      ),
      canUpdateIndustryType: user.permissions.includes(
        "CAN_UPDATE_INDUSTRY_TYPE"
      ),
      canDeleteIndustryType: user.permissions.includes(
        "CAN_DELETE_INDUSTRY_TYPE"
      ),
      canToggleIndustryTypeActivation: user.permissions.includes(
        "CAN_TOGGLE_INDUSTRY_TYPE_ACTIVATION"
      ),

      canViewBadges: user.permissions.includes("CAN_VIEW_BADGES"),
      canCreateBadges: user.permissions.includes("CAN_CREATE_BADGES"),
      canCreateBadgeCategories: user.permissions.includes(
        "CAN_CREATE_BADGE_CATEGORIES"
      ),
      canAddMyIssuedBadges: user.permissions.includes(
        "CAN_ADD_MY_ISSUED_BADGES"
      ),
      canRemoveMyIssuedBadges: user.permissions.includes(
        "CAN_REMOVE_MY_ISSUED_BADGES"
      ),
      canAddOthersIssuedBadges: user.permissions.includes(
        "CAN_ADD_OTHERS_ISSUED_BADGES"
      ),
      canRemoveOthersIssuedBadges: user.permissions.includes(
        "CAN_REMOVE_OTHERS_ISSUED_BADGES"
      ),
      canAddCoacheeIssuedBadges: user.permissions.includes(
        "CAN_ADD_COACHEE_ISSUED_BADGES"
      ),
      canRemoveCoacheeIssuedBadges: user.permissions.includes(
        "CAN_REMOVE_COACHEE_ISSUED_BADGES"
      ),
      canAddIssuedBadges: user.permissions.includes("CAN_ADD_ISSUED_BADGES"),
      canRemoveIssuedBadges: user.permissions.includes(
        "CAN_REMOVE_ISSUED_BADGES"
      ),
      canAddOthersAssignedBadges: user.permissions.includes(
        "CAN_ADD_OTHERS_ASSIGNED_BADGES"
      ),
      canRemoveOthersAssignedBadges: user.permissions.includes(
        "CAN_REMOVE_OTHERS_ASSIGNED_BADGES"
      ),
      canAddCoacheeAssignedBadges: user.permissions.includes(
        "CAN_ADD_COACHEE_ASSIGNED_BADGES"
      ),
      canRemoveCoacheeAssignedBadges: user.permissions.includes(
        "CAN_REMOVE_COACHEE_ASSIGNED_BADGES"
      ),
      canAddAssignedBadges: user.permissions.includes(
        "CAN_ADD_ASSIGNED_BADGES"
      ),
      canRemoveAssignedBadges: user.permissions.includes(
        "CAN_REMOVE_ASSIGNED_BADGES"
      ),
      canManageAllBadgeCategories: user.permissions.includes(
        "CAN_MANAGE_ALL_BADGE_CATEGORIES"
      ),

      canReadOwnKeyMetrics: user.permissions.includes(
        "CAN_READ_OWN_KEY_METRICS"
      ),
      canReadKeyMetrics: user.permissions.includes("CAN_READ_KEY_METRICS"),
      canReadPracticeKeyMetrics: user.permissions.includes(
        "CAN_READ_PRACTICE_KEY_METRICS"
      ),
      canReadCoacheeKeyMetrics: user.permissions.includes(
        "CAN_READ_COACHEE_KEY_METRICS"
      ),

      canManageRoles: user.permissions.includes("CAN_MANAGE_ROLES"),
      canManageLiftoff: user.permissions.includes("CAN_MANAGE_LIFTOFF"),
      canManageEmployees: user.permissions.includes("CAN_MANAGE_EMPLOYEES"),
      canManageFeatures: user.permissions.includes("CAN_MANAGE_FEATURES"),
      canManageSessions: user.permissions.includes("CAN_MANAGE_SESSIONS"),
      canManageProjectorSkills: user.permissions.includes(
        "CAN_MANAGE_PROJECTOR_SKILLS"
      ),
      canViewProjectorSkills: user.permissions.includes(
        "CAN_VIEW_PROJECTOR_SKILLS"
      ),
      canManageProjectorUdf: user.permissions.includes(
        "CAN_MANAGE_PROJECTOR_UDF"
      ),
      canViewProjectorUdf: user.permissions.includes("CAN_VIEW_PROJECTOR_UDF"),
      canViewEmployeePractice: user.permissions.includes(
        "CAN_VIEW_EMPLOYEE_PRACTICE"
      ),
      canManageEmployeePractice: user.permissions.includes(
        "CAN_MANAGE_EMPLOYEE_PRACTICE"
      ),
      canViewEmployeeBusinessUnit: user.permissions.includes(
        "CAN_VIEW_EMPLOYEE_BUSINESS_UNIT"
      ),
      canManageEmployeeBusinessUnit: user.permissions.includes(
        "CAN_MANAGE_EMPLOYEE_BUSINESS_UNIT"
      ),
      canViewEmployeeRegion: user.permissions.includes(
        "CAN_VIEW_EMPLOYEE_REGION"
      ),
      canManageEmployeeRegion: user.permissions.includes(
        "CAN_MANAGE_EMPLOYEE_REGION"
      ),
      canViewEmployeeLocation: user.permissions.includes(
        "CAN_VIEW_EMPLOYEE_LOCATION"
      ),
      canManageEmployeeLocation: user.permissions.includes(
        "CAN_MANAGE_EMPLOYEE_LOCATION"
      ),
      canViewAdminLog: user.permissions.includes("CAN_VIEW_ADMIN_LOG"),

      canReadReports: user.permissions.includes("CAN_READ_REPORTS"),
      canReadManagerReports: user.permissions.includes(
        "CAN_READ_MANAGER_REPORTS"
      ),

      canReadStaffingPromptResponses: user.permissions.includes(
        "CAN_READ_STAFFING_PROMPT_RESPONSES"
      ),
      canReadConfidentialPromptResponses: user.permissions.includes(
        "CAN_READ_CONFIDENTIAL_PROMPT_RESPONSES"
      ),

      canCreateOwnRoleType: user.permissions.includes(
        "CAN_CREATE_OWN_ROLE_TYPE"
      ),
      canUpdateOwnRoleType: user.permissions.includes(
        "CAN_UPDATE_OWN_ROLE_TYPE"
      ),
      canDeleteOwnRoleType: user.permissions.includes(
        "CAN_DELETE_OWN_ROLE_TYPE"
      ),
      canCreateCoacheeRoleType: user.permissions.includes(
        "CAN_CREATE_COACHEE_ROLE_TYPE"
      ),
      canUpdateCoacheeRoleType: user.permissions.includes(
        "CAN_UPDATE_COACHEE_ROLE_TYPE"
      ),
      canDeleteCoacheeRoleType: user.permissions.includes(
        "CAN_DELETE_COACHEE_ROLE_TYPE"
      ),
      canCreateRoleType: user.permissions.includes("CAN_CREATE_ROLE_TYPE"),
      canUpdateRoleType: user.permissions.includes("CAN_UPDATE_ROLE_TYPE"),
      canDeleteRoleType: user.permissions.includes("CAN_DELETE_ROLE_TYPE"),
      canCreateRoleTypeType: user.permissions.includes(
        "CAN_CREATE_ROLE_TYPE_TYPE"
      ),
      canUpdateRoleTypeType: user.permissions.includes(
        "CAN_UPDATE_ROLE_TYPE_TYPE"
      ),
      canDeleteRoleTypeType: user.permissions.includes(
        "CAN_DELETE_ROLE_TYPE_TYPE"
      ),
      canReadRoleTypes: user.permissions.includes("CAN_READ_ROLE_TYPES"),
      canManageRoleTypes: user.permissions.includes("CAN_MANAGE_ROLE_TYPES"),
      canViewAdminRoleTypeFamily: user.permissions.includes(
        "CAN_VIEW_ADMIN_ROLE_TYPE_FAMILY"
      ),

      canReadOwnCalendarFeed: user.permissions.includes(
        "CAN_READ_OWN_CALENDAR_FEED"
      ),
      canCreateOwnCalendarFeed: user.permissions.includes(
        "CAN_CREATE_OWN_CALENDAR_FEED"
      ),

      canEmulateRoles: user.permissions.includes("CAN_EMULATE_ROLES")
    };

    // additional front-end specific permissions
    p.canViewImportPage =
      p.canImportClients ||
      p.canImportEmployees ||
      p.canImportProjects ||
      p.canImportBadges;
    p.canViewAdminMenu = p.canViewImportPage;

    const u: EmployeeWithPermissions = {
      ...user,
      permissions: p
    };
    _setUser(u);
  };

  return [user, setUser];
};

export const useUser = () => {
  const { user } = useContext(UserContext);
  return user;
};

export const useUserPermissions = () => {
  return useUser().permissions;
};
