import React, { useState } from 'react'
import { Form, Button, Select } from 'antd'
import ButtonRow from 'components/common/ButtonRow'
import Stack from 'components/common/Stack'
import { updateEmployee } from './UserAccessUtil'
import { EmployeeRole } from 'types/EmployeeRole'

type BulkEditFormProps = {
  form: any
  employeeRoles: EmployeeRole[]
  roles: string[]
  onUpdate: Function
  onCancel: Function
}

const BulkEditForm = ({ form, employeeRoles, roles, onUpdate, onCancel }: BulkEditFormProps) => {
  const [employees, setEmployees] = useState<EmployeeRole[]>([])

  const handleEmployeesChange = (employeeIds: string | any[]) => {
    const employees = employeeRoles.filter(e => employeeIds.includes(e.employee.id))
    setEmployees(employees)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (err) return
      const addRoles = form.getFieldValue('addRoles'),
        removeRoles = form.getFieldValue('removeRoles')

      employees.forEach(e => updateEmployee(e, addRoles, removeRoles, onUpdate))
      setEmployees([])
      form.resetFields()
    })
  }

  const handleCancel = () => {
    setEmployees([])
    form.resetFields()

    if (onCancel) {
      onCancel()
    }
  }

  const { getFieldDecorator } = form

  return (
    <Stack>
      <Form layout="vertical" style={{ maxWidth: '40em' }} onSubmit={handleSubmit}>
        <Form.Item label="Employee" required>
          {getFieldDecorator('employee', {})(
            <Select
              placeholder="Select an employee"
              mode="multiple"
              optionFilterProp="children"
              onChange={handleEmployeesChange}
            >
              {employeeRoles.map(e => (
                <Select.Option key={e.employee.id} value={e.employee.id}>
                  {e.employee.fullName}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Add Roles" required>
          {getFieldDecorator('addRoles', {})(
            <Select placeholder="Select role(s)" mode="multiple" disabled={employees.length === 0}>
              {roles.map(r => (
                <Select.Option key={r} value={r}>
                  {r}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Remove Roles" required>
          {getFieldDecorator('removeRoles', {})(
            <Select placeholder="Select role(s)" mode="multiple" disabled={employees.length === 0}>
              {roles.map(r => (
                <Select.Option key={r} value={r}>
                  {r}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <ButtonRow style={{ marginTop: '1em' }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </ButtonRow>
      </Form>
    </Stack>
  )
}

export default Form.create({ name: 'bulk_edit_form' })(BulkEditForm)
