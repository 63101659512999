import React from 'react'

const ProjectorLogo = ({ size, background, color, strokeWidth, ...props }) => {
  if (!size || size === '') {
    size = '14px'
  }
  if (!background || background === '') {
    background = '#443763'
  }
  if (!color || color === '') {
    color = '#FFB136'
  }
  if (!strokeWidth || strokeWidth === '') {
    strokeWidth = '8'
  }
  return (
    <div style={{ width: size, height: size }} >
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill={background} />
        <ellipse cx="181" cy="251" rx="60" ry="129" style={{ fill: 'none', stroke: color, strokeWidth: strokeWidth }} transform="rotate(5 181 251)" />
        <ellipse cx="219" cy="261" rx="60" ry="129" style={{ fill: 'none', stroke: color, strokeWidth: strokeWidth }} transform="rotate(27 219 261)" />
        <ellipse cx="250" cy="283" rx="60" ry="129" style={{ fill: 'none', stroke: color, strokeWidth: strokeWidth }} transform="rotate(48 250 283)" />
        <ellipse cx="270" cy="318" rx="60" ry="129" style={{ fill: 'none', stroke: color, strokeWidth: strokeWidth }} transform="rotate(70 270 318)" />
      </svg>
    </div>)
}

export default ProjectorLogo