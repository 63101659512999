export interface CareerCoachReportFilterSettings {
  tmSearch: string;
  tmPractice: string[];
  tmCapability: string[];
  tmPosition: string[];
  tmOffice: string[];
  ccSearch: string;
  ccPractice: string[];
  ccCapability: string[];
  ccPosition: string[];
  ccOffice: string[];
}

export function defaultCareerCoachReportFilterSettings(): CareerCoachReportFilterSettings {
  return {
    tmSearch: '',
    tmPractice: [],
    tmCapability: [],
    tmPosition: [],
    tmOffice: [],
    ccSearch: '',
    ccPractice: [],
    ccCapability: [],
    ccPosition: [],
    ccOffice: []
  };
}
