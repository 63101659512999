import { del, get, post, put } from 'api';
import { IndustrySector } from 'types/IndustrySector'

export function getIndustrySectors(): Promise<IndustrySector[]> {
  return get(`api/industries/sector`);
}

export function createIndustrySector(sector: IndustrySector): Promise<IndustrySector> {
  return post(`api/industries/sector`, sector);
}

export function updateIndustrySector(sectorId: number, sector: IndustrySector): Promise<IndustrySector> {
  return put(`api/industries/sector/${sectorId}`, sector);
}

export function deleteIndustrySector(sectorId: number) {
  return del(`api/industries/sector/${sectorId}`);
}

export function toggleIndustrySectorActivation(sectorId: number): Promise<IndustrySector> {
  return post(`api/industries/sector/${sectorId}`);
}
