import { Button, Form, Row, Select, TreeSelect } from 'antd';
import { deleteProjectorSkillRef, updateProjectorSkillRef } from 'api/projectorApi';
import ButtonRow from 'components/common/ButtonRow';
import TreeSelectRecursive from 'components/skills/skill/TreeSelectRecursive';
import React, { useEffect, useState } from 'react';
import { Capability } from 'types/Capability';
import { Industry } from 'types/Industry';
import { CertificationSummary } from 'types/CertificationSummary';
import { ProjectorSkill } from 'types/Projector';
import displayErrorNotification from 'utils/displayErrorNotification';
import { ProjectorConstants as Const } from 'types/Projector';

type ScoutEntityFormProps = {
  projectorSkill: ProjectorSkill;
  onSubmit: Function;
  onCancel: Function;
  form: any;
  roleTypes: any;
  skills: any;
  certifications: CertificationSummary[];
  certifyingOrgNames: string[];
  capabilities: Capability[];
  industries: Industry[];
};

const ScoutEntityForm = ({
  projectorSkill,
  onSubmit,
  onCancel,
  form,
  roleTypes,
  skills,
  capabilities,
  industries,
  certifications,
  certifyingOrgNames
}: ScoutEntityFormProps) => {
  const { getFieldDecorator } = form;
  const [showEntity, setShowEntity] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const isActive: boolean = projectorSkill.isDeleted === false;

  useEffect(() => {
    setShowEntity(projectorSkill.entity === '' ? Const.ENTITY_CERTIFICATION : projectorSkill.entity);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (err) return;
      let tableId = 0;
      switch (values.scoutEntity) {
        case Const.ENTITY_CERTIFICATION:
          tableId = values.certification;
          break;
        case Const.ENTITY_CAPABILITY:
          tableId = values.minorCapability;
          break;
        case Const.ENTITY_INDUSTRY_MAJOR:
          tableId = values.majorIndustry;
          break;
        case Const.ENTITY_INDUSTRY_MINOR:
          tableId = values.minorIndustry;
          break;
        case Const.ENTITY_ROLE_TYPE:
          tableId = values.roleType;
          break;
        case Const.ENTITY_SKILL:
          tableId = values.skill;
          break;
      }
      const sendRef: ProjectorSkill = {
        id: projectorSkill.id,
        entity: values.scoutEntity,
        tableId: tableId,
        name: '',
        groupName: '',
        isDeleted: false
      };
      setLoading(true);
      updateProjectorSkillRef(sendRef)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
    });
  };

  const _removeEntity = () => {
    if (projectorSkill.isDeleted || projectorSkill.tableId > 0) {
      setLoading(true);
      deleteProjectorSkillRef(projectorSkill.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
    }
  };

  const _onChange = value => {
    setShowEntity(value);
  };

  return (
    <div style={{ minWidth: '750px' }}>
      <h2>Edit Projector Skill</h2>
      <h3>
        <ul>
          <li>
            {projectorSkill.groupName}
            <ul>
              <li>{projectorSkill.name}</li>
            </ul>
          </li>
        </ul>
      </h3>
      <Form onSubmit={handleSubmit} className="editScoutEntityForm">
        {isActive && (
          <Form.Item label="Scout Entity">
            {getFieldDecorator('scoutEntity', {
              initialValue: showEntity,
              rules: [{ required: true, message: 'Scout Entity is a required field' }]
            })(
              <Select onChange={_onChange} placeholder="Scout Entity">
                {Const.entities.map(e => {
                  return (
                    <Select.Option key={e.key} value={e.key}>
                      {e.text}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        )}
        {isActive && showEntity === Const.ENTITY_CERTIFICATION && (
          <Form.Item label="Certification">
            {getFieldDecorator('certification', {
              rules: [{ required: true, message: 'Certification is a required field' }]
            })(
              <TreeSelect
                showArrow={true}
                showSearch
                placeholder="Certification"
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
              >
                {certifyingOrgNames.map(certifyingOrgName => (
                  <TreeSelect.TreeNode
                    key={certifyingOrgName}
                    value={certifyingOrgName}
                    title={certifyingOrgName}
                    selectable={false}
                  >
                    {certifications
                      .filter(c => c.certifyingOrganization.name === certifyingOrgName)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(certification => (
                        <TreeSelect.TreeNode
                          key={certification.id}
                          value={certification.id}
                          title={certification.name}
                          selectable={true}
                        />
                      ))}
                  </TreeSelect.TreeNode>
                ))}
              </TreeSelect>
            )}
          </Form.Item>
        )}
        {isActive && showEntity === Const.ENTITY_CAPABILITY && (
          <Form.Item label="Minor Capability">
            {getFieldDecorator('minorCapability', {
              rules: [{ required: true, message: 'Minor Capability is a required field' }]
            })(
              <Select placeholder="Capability">
                {capabilities.map(c => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {isActive && showEntity === Const.ENTITY_INDUSTRY_MAJOR && (
          <Form.Item label="Major Industry">
            {getFieldDecorator('majorIndustry', {
              rules: [{ required: true, message: 'Major Industry is a required field' }]
            })(
              <Select placeholder="Industry">
                {industries.map(i => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {isActive && showEntity === Const.ENTITY_INDUSTRY_MINOR && (
          <Form.Item label="Minor Industry">
            {getFieldDecorator('minorIndustry', {
              rules: [{ required: true, message: 'Minor Industry is a required field' }]
            })(
              <Select placeholder="Industry">
                {industries.map(i => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {isActive && showEntity === Const.ENTITY_ROLE_TYPE && (
          <Form.Item label="Role Type">
            {getFieldDecorator('roleType', {
              rules: [{ required: true, message: 'Role Type is a required field' }]
            })(
              <TreeSelect
                placeholder="Role Type"
                showSearch
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
              >
                {roleTypes.map(rt => TreeSelectRecursive(rt, 'contains'))}
              </TreeSelect>
            )}
          </Form.Item>
        )}
        {isActive && showEntity === Const.ENTITY_SKILL && (
          <Form.Item label="Skill">
            {getFieldDecorator('skill', {
              rules: [{ required: true, message: 'Skill is a required field' }]
            })(
              <TreeSelect
                placeholder="Skill"
                showSearch
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
              >
                {skills.length > 0 && skills.map(skill => TreeSelectRecursive(skill, 'children'))}
              </TreeSelect>
            )}
          </Form.Item>
        )}
        <Row>
          <ButtonRow style={{ marginTop: '30px' }}>
            {isActive && (
              <Button disabled={loading} type="primary" htmlType="submit">
                Save
              </Button>
            )}
            <Button disabled={loading} onClick={() => onCancel()}>
              Cancel
            </Button>
            {(projectorSkill.isDeleted || projectorSkill.tableId > 0) && (
              <Button disabled={loading} onClick={_removeEntity}>
                Deactivate
              </Button>
            )}
          </ButtonRow>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create<ScoutEntityFormProps>({ name: 'scout_entity_form' })(ScoutEntityForm);
