import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import { useUser } from 'auth/UserHooks';
import { trackComponent } from 'telemetry/AppInsights';
import { getByEmployeeRoleTypeId, getRoleTypeExperienceByEmployeeRoleTypeId } from '../../../api/RoleTypeApi';
import EmployeeRoleTypeForm from './EmployeeRoleTypeForm';
import FullPageSpinner from 'components/common/FullPageSpinner';

const EditEmployeeRoleTypePage = props => {
  const [employeeRoleType, setEmployeeRoleType] = useState(null);
  const [roleTypeExperiences, setRoleTypeExperiences] = useState(null);
  const employeeRoleTypeId = props.match.params.employeeRoleTypeId;
  const user = useUser();

  useEffect(() => {
    getByEmployeeRoleTypeId(employeeRoleTypeId)
      .then(setEmployeeRoleType)
      .catch(displayErrorNotification);
    getRoleTypeExperienceByEmployeeRoleTypeId(employeeRoleTypeId)
      .then(setRoleTypeExperiences)
      .catch(displayErrorNotification);
  }, []);

  if (isNull(employeeRoleType) || isNull(roleTypeExperiences)) return <FullPageSpinner />;

  const employeeId = props.location.state.employeeId;

  const navigateToEmployee = () => {
    if (employeeId === user.id) props.history.push('/');
    else props.history.push(`/employees/${employeeId}`);
  };

  return (
    <Stack>
      <EmployeeRoleTypeForm
        isCreateMode={false}
        employeeId={employeeId}
        roleTypeExperiences={roleTypeExperiences}
        employeeRoleType={employeeRoleType}
        onCancel={navigateToEmployee}
        onSubmit={navigateToEmployee}
      />
    </Stack>
  );
};

export default trackComponent(EditEmployeeRoleTypePage, 'Edit Employee Role Type Page');
