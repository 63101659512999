import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Typography, Collapse, Icon, Tag, Alert } from 'antd';
import { isEmpty } from 'lodash';
import UserContext from 'auth/UserContext';
import CapabilityForm from './CapabilityForm';
import CardCollapse from 'components/common/collapse/CardCollapse';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import { trackComponent } from 'telemetry/AppInsights';
import { getCapabilityById } from 'api/capabilitiesApi';
import { CapabilityDetail } from 'types/CapabilityDetail';
import { ColumnProps } from 'antd/lib/table';
import FullPageSpinner from 'components/common/FullPageSpinner';
import { getEmployeesWithMajorMinor } from 'api/employeeMajorMinorApi';
import { EmployeeMajorMinor } from 'types/EmployeeMajorMinor';

const CapabilityDetailPage = (props: RouteComponentProps) => {
  const [isEditMode, setEditMode] = useState(false);
  const [capability, setCapability] = useState<CapabilityDetail>();
  const [employees, setEmployees] = useState<EmployeeMajorMinor[]>();
  let activePanels = ['employees'];
  // @ts-ignore
  const capabilityId: number = props.match.params.capabilityId;

  useEffect(() => {
    getCapabilityById(capabilityId)
      .then(setCapability)
      .catch(displayErrorNotification);

    getEmployeesWithMajorMinor(capabilityId, true)
      .then(setEmployees)
      .catch(displayErrorNotification);
  }, []);

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = (capability: CapabilityDetail) => {
    setCapability(capability);
    toggleEditMode();
  };

  const handleDelete = () => {
    props.history.push('/capabilities')
  }

  const employeeColumns: ColumnProps<EmployeeMajorMinor>[] = [
    {
      title: 'Name',
      dataIndex: 'employee.fullName',
      key: 'employee.fullName',
      render: (text, record) => <Link to={`/employees/${record.employee.id}`}>{record.employee.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      key: 'employee.practice',
      sorter: (a, b) => a.employee.practice.toLowerCase().localeCompare(b.employee.practice.toLowerCase())
    },
    {
      title: 'Major/Minor',
      dataIndex: 'major',
      render: (text, record) => (record.major ? 'Major' : 'Minor'),
      sorter: (a, b) => (a.major > b.major ? -1 : 1)
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      key: 'employee.location',
      sorter: (a, b) => a.employee.location.toLowerCase().localeCompare(b.employee.location.toLowerCase())
    }
  ];

  if (!capability || !employees) return <FullPageSpinner />;

  if (isEditMode) {
    return (
      <Stack>
        <CapabilityForm onCancel={toggleEditMode} capability={capability} onSubmit={handleSubmit} onDelete={handleDelete}/>
      </Stack>
    );
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          {capability.inactive && (
            <Alert type="warning" showIcon style={{ marginBottom: '1rem' }} message="This capability is inactive" />
          )}

          <SplitPageHeaderLayout.Layout>
            <SplitPageHeaderLayout.Left>
              <Typography.Title level={1} style={{ marginBottom: '0' }}>
                {capability.name}
                {ctx.user.permissions.canUpdateCapabilityType && (
                  <Button
                    style={{ lineHeight: "1.5", fontSize: "20px"}}
                    icon="edit"
                    type="link"
                    onClick={toggleEditMode}
                  />
                )}
              </Typography.Title>
              <Typography.Title level={3} type="secondary" style={{ marginTop: '0', marginBottom: '1rem' }}>
                {!isEmpty(capability) && `${capability.practice} [${capability.practiceAbbrev}]`}
              </Typography.Title>
            </SplitPageHeaderLayout.Left>
          </SplitPageHeaderLayout.Layout>

          <Collapse bordered={false} defaultActiveKey={activePanels}>
            <CardCollapse.Panel
              key="employees"
              header={
                <>
                  <Icon type="contacts" /> <span style={{ marginLeft: '5px' }}>Employees</span>
                </>
              }
              extra={
                <>
                  <Tag>
                    {employees && employees.length} Employee
                    {employees && employees.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                rowKey={record => record.employeeId.toString()}
                dataSource={employees}
                columns={employeeColumns}
                pagination={{ pageSize: 25, position: 'top' }}
                style={{ width: '100%' }}
              />
            </CardCollapse.Panel>
          </Collapse>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(CapabilityDetailPage, 'Capability Detail Page');
