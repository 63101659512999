import { get, put, del } from 'api'
import { ProjectorSkill, ProjectorUdf } from 'types/Projector'

export function getProjectorSkills(): Promise<ProjectorSkill[]> {
  return get('api/projector/skills')
}

export function updateProjectorSkillRef(ref: ProjectorSkill) {
  return put('api/projector/skills', ref)
}

export function deleteProjectorSkillRef(id: number) {
  return del(`api/projector/skills/${id}`)
}

export function getProjectorUdfs(): Promise<ProjectorUdf[]> {
  return get('api/projector/udfs')
}
