
import { del, get, post, put } from 'api';
import { EmployeeBusinessUnit } from 'types/EmployeeBusinessUnit'

export function getEmployeeBusinessUnits(): Promise<EmployeeBusinessUnit[]> {
  return get(`api/employees/business_units`);
}

export function createEmployeeBusinessUnit(businessUnit: EmployeeBusinessUnit): Promise<EmployeeBusinessUnit> {
  return post(`api/employees/business_units`, businessUnit);
}

export function updateEmployeeBusinessUnit(businessUnitId: number, businessUnit: EmployeeBusinessUnit): Promise<EmployeeBusinessUnit> {
  return put(`api/employees/business_units/${businessUnitId}`, businessUnit);
}

export function deleteEmployeeBusinessUnit(businessUnitId: number) {
  return del(`api/employees/business_units/${businessUnitId}`);
}

export function toggleEmployeeBusinessUnitActivation(businessUnitId: number): Promise<EmployeeBusinessUnit> {
  return post(`api/employees/business_units/${businessUnitId}`);
}