import React from 'react';
import { Link } from 'react-router-dom';
import { List, Avatar } from 'antd';
import _ from 'lodash';

const PopularBadges = props => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={_.sortBy(props.allBadges, 'employeesReceived').slice(0, 25)}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={item.imagePath ? item.imagePath : item.image} />}
            title={<Link to={`badges/${item.id}`}>{item.name}</Link>}
            description={`${item.employeesReceived} ${item.employeesReceived === 1 ? 'recipient' : 'recipients'}`}
          />
        </List.Item>
      )}
    />
  );
};

export default PopularBadges;
