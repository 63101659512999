import { get, post } from 'api';
import { Feature } from '../types/Feature';

export function getFeatures(): Promise<Feature[]> {
  return get(`api/features`);
}

export function getFeatureByName(name: string): Promise<Feature> {
  return get(`api/features/${name}`);
}

export function updateFeature(name: string, enabled: boolean): Promise<void> {
  return post(`api/features/${name}`, { enabled });
}
