import { Icon, Spin } from 'antd';
import React from 'react';

const FullPageSpinner = props => {
  return (
    <div
      className="App"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

export default FullPageSpinner;
