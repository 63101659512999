import { del, get, post, put } from 'api';
import { ScoutTag } from 'types/ScoutTag';

export function getScoutTags(): Promise<ScoutTag[]> {
  return get(`api/tags`);
}

export function createScoutTag(tag: ScoutTag): Promise<ScoutTag> {
  return post(`api/tags`, tag);
}

export function updateScoutTag(tagId: number, tag: ScoutTag): Promise<ScoutTag> {
  return put(`api/tags/${tagId}`, tag);
}

export function deleteScoutTag(tagId: number) {
  return del(`api/tags/${tagId}`);
}

export function toggleTagActivation(tagId: number): Promise<ScoutTag> {
  return post(`api/tags/${tagId}`);
}
