import { del, get, post, put } from "api";
import { EmployeeLocation } from "../types/EmployeeLocation";

export function getEmployeeLocations(): Promise<EmployeeLocation[]> {
  return get(`api/employees/location`);
}

export function createEmployeeLocation(
  location: EmployeeLocation
): Promise<EmployeeLocation> {
  return post(`api/employees/location`, location);
}

export function updateEmployeeLocation(
  locationId: number,
  location: EmployeeLocation
): Promise<EmployeeLocation> {
  return put(`api/employees/location/${locationId}`, location);
}

export function deleteEmployeeLocation(locationId: number) {
  return del(`api/employees/location/${locationId}`);
}

export function toggleEmployeeLocationActivation(
  locationId: number
): Promise<EmployeeLocation> {
  return post(`api/employees/location/${locationId}`);
}
