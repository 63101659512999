import React from "react";
import { Route, Switch } from "react-router-dom";
import { useUser } from "auth/UserHooks";
import HomePage from "components/home/HomePage";
import { useFeatures } from "hooks/FeatureHooks";

// Clients
import ClientDetailPage from "components/clients/ClientDetailPage";

// Projects
import ProjectsPage from "components/projects/ProjectLandingPage";
import ProjectDetailPage from "components/projects/ProjectDetailPage";
import AddProjectSkillPage from "components/projects/project-skill/AddProjectSkillPage";
import EngagementDetailPage from "components/projects/EngagementDetailPage";

// Skills
import SkillsPage from "components/skills/skill/SkillsLandingPage";
import SkillDetailPage from "components/skills/skill/SkillDetailPage";
import NewSkillPage from "components/skills/skill/NewSkillPage";
import AddEmployeeSkillPage from "components/skills/employee-skill/AddEmployeeSkillPage";
import EditEmployeeSkillPage from "components/skills/employee-skill/EditEmployeeSkillPage";

//Role Types
import NewRoleTypePage from "components/RoleTypes/NewRoleTypePage";
import AddEmployeeRoleTypePage from "components/RoleTypes/employee-roleType/AddEmployeeRoleTypePage";
import EditEmployeeRoleTypePage from "components/RoleTypes/employee-roleType/EditEmployeeRoleTypePage";
import RoleTypeDetailPage from "components/RoleTypes/RoleTypeDetailPage";
import RoleTypeLandingPage from "components/RoleTypes/RoleTypeLandingPage";

// Capabilities
import CapabilitiesPage from "components/capabilities/CapabilitiesPage";
import NewCapabilityPage from "components/capabilities/NewCapabilityPage";
import CapabilityDetailPage from "components/capabilities/CapabilityDetailPage";

// Major and Minor
import MajorMinorLandingPage from "components/majorMinor/MajorMinorLandingPage";

// Entities
import EntitiesPage from "components/entities/entity/EntitiesPage";
import NewEntityPage from "components/entities/entity/NewEntityPage";
import EntityDetailPage from "components/entities/entity/EntityDetailPage";

// Industries
import IndustriesPage from "components/industries/IndustriesPage";
import NewIndustryPage from "components/industries/NewIndustryPage";
import IndustryDetailPage from "components/industries/IndustryDetailPage";

// Certifications
import NewCertificationPage from "components/certifications/certification/NewCertificationPage";
import CertificationDetailPage from "components/certifications/certification/CertificationDetailPage";
import CertificationsLandingPage from "components/certifications/certification/CertificationsLandingPage";
import CertifyingOrganizationPage from "components/certifications/certifying-organization/CertifyingOrganizationPage";
import NewCertifyingOrganizationPage from "components/certifications/certifying-organization/NewCertifyingOrganizationPage";

// Badges
import BadgeDetailPage from "components/badges/BadgeDetail/BadgeDetailPage";
import BadgeLandingPage from "components/badges/BadgeLandingPage";
import NewBadgePage from "components/badges/BadgeDetail/NewBadgePage";
import BadgeDesigner from "components/badges/BadgeDesigner";

// Employees
import EmployeeLandingPage from "components/employees/EmployeeLandingPage";
import EmployeeDetailPage from "components/employees/EmployeeDetailPage";

// Reports
import ReportsPage from "components/reports/ReportsPage";
import CareerCoachReportListPage from "components/reports/CareerCoachReportList";
import CompletionReportListPage from "components/reports/CompletionReportList";
import ProjectorEntityReportListPage from "components/reports/ProjectorEntityReportList";
import IndustryExperienceReportListPage from "components/reports/IndustryExperienceReportList";
import ClientIndustryReportListPage from "components/reports/ClientIndustryReportList";

// Admin
import PromptResponseListPage from "components/preferences/PromptResponseList";
import KeyMetricsLandingPage from "components/keyMetrics/KeyMetricsLandingPage";
import AdminPromptsPage from "components/admin/prompts/AdminPromptsPage";

// Admin subsection
import ProjectorLandingPage from "components/admin/projector/ProjectorLandingPage";
import TagsLandingPage from "components/admin/tags/TagsLandingPage";
import EmployeePracticeLandingPage from "components/admin/employee_practice/EmployeePracticeLandingPage";
import EmployeeBusinessUnitLandingPage from "components/admin/employee_business_unit/EmployeeBusinessUnitLandingPage";
import EmployeeRegionLandingPage from "components/admin/employee_region/EmployeeRegionLandingPage";
import EmployeeLocationLandingPage from "components/admin/employee_location/EmployeeLocationLandingPage";
import RoleTypeFamilyLandingPage from "components/admin/role_type_family/RoleTypeFamilyLandingPage";
import FeatureLandingPage from "components/admin/features/FeatureLandingPage";
import SessionLandingPage from "components/admin/sessions/SessionLandingPage";
import LogLandingPage from "components/admin/log/LogLandingPage";
import UserAccessLandingPage from "components/admin/roles/UserAccessLandingPage";

// Misc
import Page from "components/common/Page";

const Routes = () => {
  const user = useUser();
  const myId = user.id;
  const myDetailsPath = "/employees/" + myId;
  const features = useFeatures();

  const withTitle = (title, Component, extraProps = []) => {
    return props => (
      <Page title={title}>
        <Component {...props} {...extraProps} />
      </Page>
    );
  };

  return (
    <Switch>
      <Route exact path="/" render={withTitle("Home", HomePage)} />

      {user.permissions.canViewBadges && (
        <Route
          exact
          path="/badges"
          render={withTitle("Badges", BadgeLandingPage)}
        />
      )}
      {user.permissions.canViewBadges && (
        <Route
          path="/badges/new"
          render={withTitle("New Badge", NewBadgePage)}
        />
      )}
      {user.permissions.canViewBadges && (
        <Route
          path="/badges/:badgeId"
          render={withTitle("Badge Details", BadgeDetailPage)}
        />
      )}

      {user.permissions.canReadCertifications && (
        <Route
          exact
          path="/certifications"
          render={withTitle("Certifications", CertificationsLandingPage)}
        />
      )}
      {user.permissions.canReadCertifications && (
        <Route
          path="/certifications/new"
          render={withTitle("New Certification", NewCertificationPage)}
        />
      )}
      {user.permissions.canCreateCertifyingOrganizations && (
        <Route
          path="/certifications/certifying_organizations/new"
          render={withTitle(
            "New Certifying Organization",
            NewCertifyingOrganizationPage
          )}
        />
      )}
      {user.permissions.canReadCertifications && (
        <Route
          path="/certifications/certifying_organizations/:certifyingOrgId"
          render={withTitle(
            "Certifying Organization",
            CertifyingOrganizationPage
          )}
        />
      )}
      {user.permissions.canReadCertifications && (
        <Route
          path="/certifications/:certificationId"
          render={withTitle("Certification Details", CertificationDetailPage)}
        />
      )}

      {!user.permissions.canReadEmployees && (
        <Route
          path={myDetailsPath}
          render={withTitle("Employee Details", EmployeeDetailPage, {
            employeeId: myId
          })}
        />
      )}

      {user.permissions.canReadEmployees && (
        <Route
          path="/employees/:employeeId"
          render={withTitle("Employee Details", EmployeeDetailPage)}
        />
      )}
      {user.permissions.canReadEmployees && (
        <Route
          path="/employee/profile/:employeeEmail"
          render={withTitle("Employee Details", EmployeeDetailPage)}
        />
      )}
      {user.permissions.canReadEmployees && (
        <Route
          exact
          path="/employees"
          render={withTitle("Employees", EmployeeLandingPage)}
        />
      )}
      {user.permissions.canReadPromptResponses && (
        <Route
          exact
          path="/prompts"
          render={withTitle("Prompts", AdminPromptsPage)}
        />
      )}
      {user.permissions.canReadPromptResponses && (
        <Route
          path="/prompts/:promptId"
          render={withTitle("Prompt Responses", PromptResponseListPage)}
        />
      )}
      {user.permissions.canManageRoles && (
        <Route
          path="/admin/user_access"
          render={withTitle("User Access", UserAccessLandingPage)}
        />
      )}
      {user.permissions.canManageFeatures && (
        <Route
          path="/admin/features"
          render={withTitle("Features", FeatureLandingPage)}
        />
      )}
      {user.permissions.canManageSessions && (
        <Route
          path="/admin/sessions"
          render={withTitle("Active Sessions", SessionLandingPage)}
        />
      )}
      {user.permissions.canViewAdminLog && (
        <Route path="/admin/log" render={withTitle("Log", LogLandingPage)} />
      )}
      {user.permissions.canViewProjectorSkills && (
        <Route
          path="/admin/projector"
          render={withTitle("Projector Skills", ProjectorLandingPage)}
        />
      )}
      {user.permissions.canViewAdminTags && (
        <Route path="/admin/tags" render={withTitle("Tags", TagsLandingPage)} />
      )}
      {user.permissions.canViewEmployeePractice && (
        <Route
          path="/admin/employeepractice"
          render={withTitle("Employee Practice", EmployeePracticeLandingPage)}
        />
      )}
      {user.permissions.canViewEmployeeBusinessUnit && (
        <Route
          path="/admin/employee_business_units"
          render={withTitle(
            "Employee Business Unit",
            EmployeeBusinessUnitLandingPage
          )}
        />
      )}
      {user.permissions.canViewEmployeeRegion && (
        <Route
          path="/admin/employeeregion"
          render={withTitle("Employee Region", EmployeeRegionLandingPage)}
        />
      )}
      {user.permissions.canViewEmployeeLocation && (
        <Route
          path="/admin/employeelocation"
          render={withTitle("Employee Location", EmployeeLocationLandingPage)}
        />
      )}
      {user.permissions.canViewAdminRoleTypeFamily && (
        <Route
          path="/admin/roletypefamily"
          render={withTitle("Role Type Family", RoleTypeFamilyLandingPage)}
        />
      )}
      {features &&
        !features.isEnabled("DISABLE_KEY_METRICS") &&
        (user.permissions.canReadKeyMetrics ||
          user.permissions.canReadPracticeKeyMetrics ||
          user.permissions.canReadCoacheeKeyMetrics) && (
          <Route
            exact
            path="/key_metrics"
            render={withTitle("Key Metrics", KeyMetricsLandingPage)}
          />
        )}
      {user.permissions.canReadReports && (
        <Route
          exact
          path="/reports"
          render={withTitle("Reports", ReportsPage)}
        />
      )}
      {user.permissions.canReadManagerReports && (
        <Route
          exact
          path="/reports/completion"
          render={withTitle("Completion Report", CompletionReportListPage)}
        />
      )}
      {user.permissions.canReadReports && (
        <Route
          exact
          path="/reports/career_coaches"
          render={withTitle("Career Coach Report", CareerCoachReportListPage)}
        />
      )}
      {user.permissions.canReadManagerReports && (
        <Route
          exact
          path="/reports/unmapped_projector_entities"
          render={withTitle(
            "Unmapped Projector Entities",
            ProjectorEntityReportListPage
          )}
        />
      )}
      {user.permissions.canReadManagerReports && (
        <Route
          exact
          path="/reports/industry_experience"
          render={withTitle("Industry Experience Report", IndustryExperienceReportListPage)}
        />
      )}
      {user.permissions.canReadManagerReports && (
        <Route
          exact
          path="/reports/client_industry"
          render={withTitle("Client Industry Report", ClientIndustryReportListPage)}
        />
      )}

      {user.permissions.canReadProjects && (
        <Route
          exact
          path="/projects"
          render={withTitle("Projects", ProjectsPage)}
        />
      )}
      {user.permissions.canReadProjects && (
        <Route
          exact
          path="/projects/skills/new"
          render={withTitle("Add Project Skill", AddProjectSkillPage)}
        />
      )}
      {user.permissions.canReadProjects && (
        <Route
          path="/projects/:projectId"
          render={withTitle("Project Details", ProjectDetailPage)}
        />
      )}
      {user.permissions.canReadProjects && (
        <Route
          path="/clients/engagement/:projectId"
          render={withTitle("Engagement Details", EngagementDetailPage)}
        />
      )}
      {user.permissions.canReadProjects && (
        <Route
          path="/clients/:clientId"
          render={withTitle("Client Details", ClientDetailPage)}
        />
      )}

      {user.permissions.canReadCapabilities && (
        <Route
          exact
          path="/capabilities"
          render={withTitle("Capabilities", CapabilitiesPage)}
        />
      )}
      {user.permissions.canCreateCapabilityType && (
        <Route
          exact
          path="/capabilities/new"
          render={withTitle("New Capability", NewCapabilityPage)}
        />
      )}
      {user.permissions.canReadCapabilities && (
        <Route
          path="/capabilities/:capabilityId"
          render={withTitle("Capability Details", CapabilityDetailPage)}
        />
      )}

      {(user.permissions.canUpdateOwnMajorMinor ||
        user.permissions.canUpdateMajorMinor ||
        user.permissions.canUpdateCoacheeMajorMinor) && (
        <Route
          path="/employee/major-minor/:employeeId"
          render={withTitle(
            "Employee Major and Minor",
            MajorMinorLandingPage
          )}
        />
      )} 

      {user.permissions.canReadEntities && (
        <Route
          exact
          path="/entities"
          render={withTitle("Entities", EntitiesPage)}
        />
      )}
      {user.permissions.canCreateEntity && (
        <Route
          exact
          path="/entities/new"
          render={withTitle("New Entity", NewEntityPage)}
        />
      )}
      {user.permissions.canReadEntities && (
        <Route
          path="/entities/:entityId"
          render={withTitle("Entity Details", EntityDetailPage)}
        />
      )}

      {user.permissions.canReadIndustries && (     
        <Route
          exact
          path="/industries"
          render={withTitle("Industries", IndustriesPage)}
        />
      )}
      {user.permissions.canCreateIndustryType && (
        <Route
          exact
          path="/industries/new"
          render={withTitle("New Industry", NewIndustryPage)}
        />
      )}
      {user.permissions.canReadIndustries && ( 
        <Route
          path="/industries/:industryId"
          render={withTitle("Industry Details", IndustryDetailPage)}
        />
      )}

      {user.permissions.canReadSkills && (
        <Route exact path="/skills" render={withTitle("Skills", SkillsPage)} />
      )}
      {user.permissions.canCreateSkillType && (
        <Route
          path="/skills/new"
          render={withTitle("New Skill", NewSkillPage)}
        />
      )}
      {(user.permissions.canCreateOwnSkill ||
        user.permissions.canCreateSkill) && (
        <Route
          path="/employee/skills/new"
          render={withTitle("Add Employee Skill", AddEmployeeSkillPage)}
        />
      )}
      {(user.permissions.canUpdateOwnSkill ||
        user.permissions.canUpdateSkill) && (
        <Route
          path="/employee/skills/:employeeSkillId"
          render={withTitle("Edit Employee Skill", EditEmployeeSkillPage)}
        />
      )}

      {user.permissions.canCreateRoleTypeType && (
        <Route
          path="/role-types/new"
          render={withTitle("New Role Type", NewRoleTypePage)}
        />
      )}
      {user.permissions.canReadSkills && (
        <Route
          path="/skills/:skillId"
          render={withTitle("Skill Details", SkillDetailPage)}
        />
      )}

      <Route
        path="/badge-designer"
        render={withTitle("Badge Designer", BadgeDesigner)}
      />

      {user.permissions.canReadRoleTypes && (
        <Route
          exact
          path="/role-types"
          render={withTitle("Role Types", RoleTypeLandingPage)}
        /> //update here
      )}

      {(user.permissions.canCreateOwnRoleType ||
        user.permissions.canCreateCoacheeRoleType ||
        user.permissions.canCreateRoleType) && (
        <Route
          path="/employee/role-types/new"
          render={withTitle("Add Employee Role", AddEmployeeRoleTypePage)}
        />
      )}

      {(user.permissions.canUpdateOwnRoleType ||
        user.permissions.canUpdateCoacheeRoleType ||
        user.permissions.canUpdateRoleType) && (
        <Route
          path="/employee/role-types/:employeeRoleTypeId"
          render={withTitle("Edit Employee Role", EditEmployeeRoleTypePage)}
        />
      )}

      {user.permissions.canReadRoleTypes && (
        <Route
          path="/role-types/:roleTypeId"
          render={withTitle("Role Type Details", RoleTypeDetailPage)}
        />
      )}

      <Route component={_404} />
    </Switch>
  );
};

function _404() {
  return <h1 style={{ marginLeft: "0.67em" }}>404 - Page Not Found</h1>;
}

export default Routes;
