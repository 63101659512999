import { get, post, put, del } from 'api';
import { EmployeeSkill } from 'types/EmployeeSkill';
import { EmployeeSkillRequest } from 'types/EmployeeSkillRequest';

export const getByEmployeeSkillId: (employeeSkillId: number) => Promise<EmployeeSkill> = employeeSkillId =>
  get(`api/employees/skills/${employeeSkillId}`);

export const getByEmployeeIdAndSkillId = (employeeId: number, skillId: number) => {
  return get(`api/employees/${employeeId}/skills/${skillId}`);
};

export const getEmployeeSkillsByEmployeeId = (employeeId: number) => get(`api/employees/${employeeId}/employeeSkills`);

export const addEmployeeSkill: (skill: EmployeeSkillRequest) => Promise<EmployeeSkillRequest> = skill =>
  post(`api/employees/skills`, skill);

export const updateEmployeeSkill: (
  employeeSkillId: number,
  employeeSkill: EmployeeSkillRequest
) => Promise<EmployeeSkillRequest> = (employeeSkillId, employeeSkill) =>
  put(`api/employees/skills/${employeeSkillId}`, employeeSkill);

export const deleteEmployeeSkill: (employeeSkillId: number) => Promise<void> = employeeSkillId =>
  del(`api/employees/skills/${employeeSkillId}`);
