import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout, Icon, Spin } from 'antd';
import { ENABLE_INSIGHTS } from 'config';
import { AppInsightsContextProvider } from './telemetry/AppInsightsContext';
import { Provider } from 'react-redux';

import store from './redux/store';
import { login } from 'api/authApi';
import { getEmployee } from 'api/employeeApi';
import UserContext from 'auth/UserContext';
import { useUserState } from 'auth/UserHooks';
import Header from 'components/common/Header';
import NavMenu from 'components/common/NavMenu';
import Routes from 'routes';

import './App.css';
import displayErrorNotification from './utils/displayErrorNotification';
import FullPageSpinner from 'components/common/FullPageSpinner';
import { useFeatureState } from './hooks/FeatureHooks';
import FeatureContext from './hooks/FeatureContext';
import { getFeatures } from './api/featureApi';

const App = () => {
  const [user, setUser] = useUserState();
  const [features, setFeatures] = useFeatureState();

  useEffect(() => {
    Promise.all([
      login()
        .then(result => {
          if (result !== null) {
            return getEmployee(result.employeeId)
              .then(user => setUser(user))
              .catch(displayErrorNotification);
          }
        })
        .catch(displayErrorNotification),
      getFeatures()
        .then(features => {
          setFeatures(features);
        })
        .catch(displayErrorNotification)
    ]);
  }, []);

  if (!user) {
    return <FullPageSpinner />;
  }

  const insightsWrapper: (body: React.ReactNode) => React.ReactNode = body =>
    ENABLE_INSIGHTS ? <AppInsightsContextProvider>{body}</AppInsightsContextProvider> : body;

  return (
    <Provider store={store}>
      <UserContext.Provider value={{ user, setUser }}>
        <FeatureContext.Provider value={{ features, setFeatures }}>
          {insightsWrapper(
            <Router>
              <Layout style={{ minHeight: '100vh' }}>
                <Layout.Header>
                  <Header />
                </Layout.Header>
                <Layout>
                  <Layout.Sider>
                    <NavMenu />
                  </Layout.Sider>
                  <Layout.Content>
                    <Routes />
                  </Layout.Content>
                </Layout>
              </Layout>
            </Router>
          )}
        </FeatureContext.Provider>
      </UserContext.Provider>
    </Provider>
  );
};

App.propTypes = {};

export default App;
