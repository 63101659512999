import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useUser } from 'auth/UserHooks';
import enumToDisplayText from 'utils/enumToDisplayText';
import formatMonthDate from 'utils/formatMonthDate';
import ProjectorLogo from 'components/common/ProjectorLogo';

const RoleTypeCard = ({ employeeId, roleType = {}, employeeRoleType }) => {
  const user = useUser();
  const isMe = user.id === employeeId;
  const isCoach = user.coachees.includes(employeeId);

  return (
    <Card
      className="avatar-card"
      hoverable={true}
      actions={
        employeeRoleType &&
          ((isMe && user.permissions.canUpdateOwnRoleType) ||
            (isCoach && user.permissions.canUpdateCoacheeRoleType) ||
            user.permissions.canUpdateRoleType)
          ? [
            <Link
              to={{
                pathname: `/employee/role-types/${employeeRoleType.employeeRoleTypeId}`,
                state: { employeeId: employeeId }
              }}
            >
              <Icon type="edit" style={{ display: 'inline' }} />
            </Link>
          ]
          : null
      }
    >
      {employeeRoleType.projector &&
        <div style={{ float: 'right', display: 'flex', alignItems: 'center', marginRight: '-24px', marginTop: '-24px' }}>
          <ProjectorLogo size="48px" strokeWidth="16" background="rgba(0,0,0,0)" />
        </div>}
      <Typography.Text style={{ fontSize: 10 }}>
        <p>{roleType.family}</p>
      </Typography.Text>
      <Link to={`/role-types/${roleType.id}`}>
        <Card.Meta
          title={`${roleType.name}`}
          description={
            <span>
              {employeeRoleType && (
                <>
                  Experience Level: {enumToDisplayText(employeeRoleType.experienceLevel)}
                  <br />
                  Duration of Experience: {employeeRoleType.duration}
                  <br />
                  Last Used: {formatMonthDate(employeeRoleType.lastUsedDate)}
                  <br />
                  Interest: {employeeRoleType.interest ? 'Yes' : 'No'}
                </>
              )}
            </span>
          }
        />
      </Link>
    </Card>
  );
};

RoleTypeCard.propTypes = {
  employeeId: PropTypes.number,
  roleType: PropTypes.object,
  employeeRoleType: PropTypes.object
};

export default RoleTypeCard;
