import { get } from 'api'
import { EngagementDetailAPI, LandingPageProjectAPI, Project } from 'types/Project'

export function getProjects(includeInactive = false): Promise<Project[]> {
  return get(`api/projects`, { includeInactive })
}

export function getProjectById(id: number): Promise<Project> {
  return get(`api/projects/${id}`)
}

export function getLandingPageProjects(): Promise<LandingPageProjectAPI[]> {
  return get(`api/projects/all`)
}

export function getEngagementDetail(id: number): Promise<EngagementDetailAPI> {
  return get(`api/projects/engagement/${id}`)
}
