import React, { useState, useEffect } from 'react';
import { Divider, Table, Typography } from 'antd';
import moment from 'moment';
import { getKeyMetricsForEmployee } from 'api/keyMetricsApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import { formatMonths } from 'components/keyMetrics/keyMetricUtils';

const EmployeeKeyMetrics = ({ employeeId = '' }) => {
  const [keyMetrics, setKeyMetrics] = useState([]);
  const [timeAtLevel, setTimeAtLevel] = useState(0);
  const formatDate = date => {
    return moment(date).format('MMMM D, YYYY');
  };

  useEffect(() => {
    getKeyMetricsForEmployee(employeeId)
      .then(summary => {
        setKeyMetrics(summary.keyMetrics);
        setTimeAtLevel(summary.timeAtLevel);
      })
      .catch(displayErrorNotification);
  }, []);

  const columns = [
    {
      title: 'Period',
      dataIndex: 'period',
      render: p => `${formatDate(p.startDate)} - ${formatDate(p.endDate)}`,
      sorter: (a, b) => (moment(a.period.startDate).isBefore(b.period.startDate) ? -1 : 1)
    },
    {
      title: 'Billable Utilization',
      dataIndex: 'billableUtilization',
      render: billableUtilization => `${billableUtilization.toFixed(2)}%`,
      sorter: (a, b) => a.billableUtilization - b.billableUtilization
    },
    {
      title: 'Client Utilization',
      dataIndex: 'clientUtilization',
      render: clientUtilization => `${clientUtilization.toFixed(2)}%`,
      sorter: (a, b) => a.clientUtilization - b.clientUtilization
    },
    {
      title: 'Time at Level*',
      dataIndex: 'timeAtLevel',
      render: timeAtLevel => (timeAtLevel > 0 ? formatMonths(timeAtLevel) : ''),
      sorter: (a, b) => a.timeAtLevel - b.timeAtLevel
    }
  ];

  return (
    <>
      <Typography.Text style={{ fontStyle: 'italic', fontSize: 'medium' }}>
        Note: Key Metrics is being moved out of Scout and utilization data after 5/1/23 is in Projector.
      </Typography.Text>

      <Divider/>

      {timeAtLevel > 0 && (
        <Typography.Title level={4}>Time at level as of today: {formatMonths(timeAtLevel)}</Typography.Title>
      )}

      <Table
        style={{ width: '100%' }}
        pagination={{ position: 'none', pageSize: keyMetrics.length }}
        rowKey="id"
        columns={columns}
        dataSource={keyMetrics}
      />

      <Typography.Text style={{ marginTop: '8px', fontSize: 'small', fontStyle: 'italic' }}>
        * Time at level on last day of the period
      </Typography.Text>
    </>
  );
};

export default EmployeeKeyMetrics;
