import React, { useEffect, useRef, useState } from "react";
import Stack from "components/common/Stack";
import { Button, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import displayErrorNotification from "utils/displayErrorNotification";
import sorters from "utils/sorters";
import Toolbar from "components/common/Toolbar";
import { getProjectorEntityReport, getReportById } from "api/reportsApi";
import ProjectorEntityReportFilters, {
  filterProjectorEntityReport,
} from "./ProjectorEntityReportFilters";
import { Parser } from "json2csv";
import moment from "moment";
import ButtonRow from "components/common/ButtonRow";
import useLocalStorage from "utils/useLocalStorage";
import { defaultProjectorEntityReportFilterSettings } from "types/ProjectorEntityReportPreferences";
import SavePreferencesButton from "components/common/SavePreferencesButton";

const ProjectorEntityReportList = (props) => {
  const [report, setReport] = useState([]);
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    "ProjectorEntityReport_FilterSettings",
    defaultProjectorEntityReportFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage(
    "ProjectorEntityReport_SortSettings",
    {
      key: "name",
      order: "ascend",
    }
  );
  const searchRef = useRef();

  useEffect(() => {
    setLoading(true);
    getProjectorEntityReport()
      .then((result) => {
        setResponses(result);
        filterProjectorEntityReport(
          {
            onSearch: setFilteredResponses,
            responses: result,
            disabled: loading,
            settings: filterSettings,
            setSettings: setFilterSettings,
            form: null,
            searchRef: null,
          },
          [],
          {
            searchTerm: filterSettings.search,
            selectedCategories: filterSettings.category,
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (
          searchRef.current !== undefined &&
          searchRef.current.input !== null
        ) {
          searchRef.current.focus();
        }
      });
  }, []);

  useEffect(() => {
    setReport(getReportById(3));
  }, []);

  let columns = [
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
      sorter: (a, b) => sorters.string(a.category, b.category),
    },
    {
      title: "Name",
      key: "name",
      render: (record) => <Link to={getLink(record)}>{record.name}</Link>,
      sorter: (a, b) => sorters.string(a.name, b.name),
    },
  ];

  const columnsSortSettings = columns.map((column) => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order,
      });
    }
  }

  const exportProjectorEntityReport = () => {
    const column = columns.find((c) => c.defaultSortOrder !== undefined);
    let columnName = "name";
    let sortOrder = true;
    if (column !== undefined) {
      columnName = column.key;
      sortOrder = column.defaultSortOrder === "ascend";
    }
    const exportable = filteredResponses
      .sort((a, b) =>
        sorters.multi(a, b, columnName, sorters.TYPE_STRING, sortOrder)
      )
      .map((k) => {
        return {
          Category: k.category,
          Name: k.name,
          URL: `${window.location.origin}${getLink(k)}`,
        };
      });

    const json2CsvParser = new Parser();
    const csv = json2CsvParser.parse(exportable);
    const blob = new Blob([csv], { type: "text/csv", encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `UnmappedProjectorEntities-${moment().format(
      "YYYYMMDD-HHmmss"
    )}.csv`;
    link.click();
  };

  const getLink = (entity) => {
    switch (entity.category) {
      case "Capability":
        return `/capabilities/${entity.id}`;
      case "Certification":
        return `/certifications/${entity.id}`;
      case "Industry":
        return `/industries/${entity.id}`;
      case "Role Type":
        return `/role-types/${entity.id}`;
      case "Skill":
        return `/skills/${entity.id}`;
      default:
        return "#";
    }
  };

  return (
    <Stack>
      <ButtonRow style={{ float: "right" }}>
        {!loading && (
          <Button icon="download" onClick={exportProjectorEntityReport}>
            Export
          </Button>
        )}
        <SavePreferencesButton
          keys={[
            "ProjectorEntityReport_FilterSettings",
            "ProjectorEntityReport_SortSettings",
          ]}
          disabled={loading}
        />
      </ButtonRow>
      <Typography.Title>{report.name}</Typography.Title>
      <Typography.Title level={3}>{report.description}</Typography.Title>
      <Toolbar>
        <ProjectorEntityReportFilters
          onSearch={setFilteredResponses}
          responses={responses}
          searchRef={searchRef}
          disabled={loading}
          settings={filterSettings}
          setSettings={setFilterSettings}
        />
        <div>
          <span style={{ margin: "0 10px" }}>
            Showing {filteredResponses.length} of {responses.length} Responses
          </span>
        </div>
      </Toolbar>
      <Table
        pagination={{ pageSize: 25, position: "top" }}
        rowKey="id"
        columns={columns}
        dataSource={filteredResponses}
        loading={loading}
        onChange={onChange}
      />
    </Stack>
  );
};

export default ProjectorEntityReportList;
