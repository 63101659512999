import { Button, Icon, Typography } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import Table, { ColumnProps } from "antd/lib/table";
import { getEmployeeRegions } from "api/employeeRegionApi";
import { useUser } from "auth/UserHooks";
import ButtonRow from "components/common/ButtonRow";
import Modal from "components/common/Modal";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import Stack from "components/common/Stack";
import React, { useEffect, useState } from "react";
import {
  EmployeeRegion,
  EmployeeRegionNotApplicable
} from "types/EmployeeRegion";
import sorters from "utils/sorters";
import useLocalStorage from "utils/useLocalStorage";
import EmployeeRegionForm from "./EmployeeRegionForm";

const EmployeeRegionLandingPage = () => {
  const user = useUser();
  const canManageEmployeeRegion = user.permissions.canManageEmployeeRegion;
  const [regions, setRegions] = useState<EmployeeRegion[]>([]);
  const [filteredRegions, setFilteredRegions] = useState<EmployeeRegion[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInactive, setShowInactive] = useLocalStorage<any>(
    "EmployeeRegionLandingPage_InactiveSetting",
    false
  );
  const [sortSettings, setSortSettings] = useLocalStorage<any>(
    "EmployeeRegionLandingPage_SortSettings",
    {
      key: "displayOrder ",
      order: "ascend"
    }
  );
  const [loading, setLoading] = useState(false);

  const _refreshRegion = () => {
    return getEmployeeRegions().then(response => {
      setRegions(response);
      filterRegions(response, showInactive);
    });
  };

  const filterRegions = (regions: EmployeeRegion[], showInactive: boolean) => {
    setFilteredRegions(
      regions.filter((f: EmployeeRegion) => showInactive || !f.inactive)
    );
  };

  const handleInactiveChange = (e: CheckboxChangeEvent) => {
    setShowInactive(e.target.checked);
    filterRegions(regions, e.target.checked);
  };

  let columns: ColumnProps<EmployeeRegion>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => sorters.string(a.name, b.name)
    },
    {
      title: "Sort",
      key: "displayOrder",
      dataIndex: "displayOrder",
      sorter: (a, b) => a.displayOrder - b.displayOrder
    },
    {
      title: "Inactive",
      key: "inactive",
      render: (item: EmployeeRegion) =>
        item.inactive && <Icon type="check-circle" />
    }
  ];

  if (canManageEmployeeRegion) {
    columns.push({
      render: (item: EmployeeRegion) => (
        <>
          {item.id !== EmployeeRegionNotApplicable && (
            <Modal
              item={item}
              trigger={({ openModal }: { openModal: () => void }) => (
                <Button onClick={openModal} size="small" icon="edit">
                  Edit
                </Button>
              )}
            >
              {({
                item,
                closeModal
              }: {
                item: EmployeeRegion;
                closeModal: () => void;
              }) => (
                <EmployeeRegionForm
                  region={item}
                  onSubmit={() => {
                    _refreshRegion().then(closeModal);
                  }}
                  onCancel={closeModal}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
            </Modal>
          )}
        </>
      )
    });
  }

  columns = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    _refreshRegion().then(() => {
      setLoading(false);
    });
  }, []);

  const _handleModalState = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {canManageEmployeeRegion && (
        <Modal isOpen={showModal} onClose={_handleModalState}>
          {({ closeModal }: { closeModal: () => void }) => (
            <EmployeeRegionForm
              onSubmit={() => {
                _refreshRegion().then(closeModal);
              }}
              onCancel={closeModal}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </Modal>
      )}
      <Stack>
        <Typography.Title level={1}>
          Employee Region
          <ButtonRow style={{ float: "right" }}>
            {canManageEmployeeRegion && (
              <Button icon="plus" onClick={_handleModalState}>
                Create Employee Region
              </Button>
            )}
            <SavePreferencesButton
              keys={[
                "EmployeeRegionLandingPage_InactiveSetting",
                "EmployeeRegionLandingPage_SortSettings"
              ]}
              disabled={loading}
            />
          </ButtonRow>
        </Typography.Title>
        <Checkbox
          checked={showInactive}
          onChange={handleInactiveChange}
          style={{ marginBottom: "20px" }}
        >
          Show Inactive
        </Checkbox>
        <Table
          columns={columns}
          dataSource={filteredRegions}
          loading={loading}
          rowKey="id"
          pagination={false}
          onChange={onChange}
        />
      </Stack>
    </>
  );
};

export default EmployeeRegionLandingPage;
