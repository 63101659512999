import { CategorySortSettings } from './ColumnSortSettings';

export interface SkillFilterSettings {
  search: string;
  category: string[];
  tag: string[];
  inActive: boolean;
}

export function defaultSkillFilterSettings(): SkillFilterSettings {
  return {
    search: '',
    category: [],
    tag: [],
    inActive: false
  };
}

export function defaultSkillSortSettings(): CategorySortSettings {
  return {
    categories: [],
    default: {
      key: 'name',
      order: 'ascend'
    }
  };
}
