import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ENABLE_INSIGHTS, INSIGHTS_KEY } from '../config';

const insightsPlugin = new ReactPlugin();

if (ENABLE_INSIGHTS) {
  const browserHistory = createBrowserHistory({ basename: '' });
  const ai = new ApplicationInsights({
    config: {
      instrumentationKey: INSIGHTS_KEY,
      extensions: [insightsPlugin],
      extensionConfig: {
        [insightsPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  ai.loadAppInsights();
}

const trackComponent = (component, name) => {
  return ENABLE_INSIGHTS ? withAITracking(insightsPlugin, component, name) : component;
};

export { insightsPlugin, trackComponent };
