import React from 'react'
import IndustryForm from './IndustryForm'
import Stack from 'components/common/Stack'
import { trackComponent } from 'telemetry/AppInsights'
import { RouteComponentProps } from 'react-router-dom'
import { IndustryDetail } from 'types/IndustryDetail'

const NewIndustryPage = (props: RouteComponentProps) => {
  const handleSubmit = (response: IndustryDetail) => {
    props.history.push(`/industries/${response.id}`)
  }
  const handleCancel = () => {
    props.history.push('/industries')
  }
  return (
    <Stack>
      <IndustryForm onSubmit={handleSubmit} onCancel={handleCancel} onDelete={handleCancel}/>
    </Stack>
  )
}
export default trackComponent(NewIndustryPage, 'New Industry Page')