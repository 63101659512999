export interface RoleTypeFilterSettings {
  search: string;
  inActive: boolean;
}

export function defaultRoleTypeFilterSettings(): RoleTypeFilterSettings {
  return {
    search: '',
    inActive: false
  };
}
