import React from 'react';
import BadgeInfoForm from './BadgeInfoForm';
import Stack from 'components/common/Stack';
import qs from 'qs';
import { trackComponent } from 'telemetry/AppInsights';

const NewBadgePage = props => {
  let categoryId = qs.parse(props.location.search.substring(1)).categoryId;
  categoryId = isNaN(categoryId) ? null : parseInt(categoryId);

  const handleSubmit = response => {
    props.history.push(`/badges/${response.id}`);
  };

  const handleCancel = () => {
    props.history.push('/badges');
  };

  return (
    <Stack>
      <BadgeInfoForm
        isCreateMode
        badge={{ category: { id: categoryId } }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Stack>
  );
};

export default trackComponent(NewBadgePage, 'New Badge Page');
