import { RangePickerValue } from "antd/lib/date-picker/interface";

export interface Log {
  id: number;
  created: Date;
  category: string;
  level: string;
  message: string;
}

export interface LogFilterSettings {
  search: string;
  category: string[];
  level: string[];
  range: RangePickerValue;
}

export function defaultLogFilterSettings(): LogFilterSettings {
  return {
    search: "",
    category: [],
    level: [],
    range: []
  };
}
