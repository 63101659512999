import {
  FETCH_PROFILE_COMPLETION_REQUEST,
  FETCH_PROFILE_COMPLETION_SUCCESS,
  FETCH_PROFILE_COMPLETION_FAILURE
} from './profileCompletionTypes';

const initialState = {
  loading: false,
  data: null,
  error: ''
};

const profileCompletionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_COMPLETION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PROFILE_COMPLETION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ''
      };
    case FETCH_PROFILE_COMPLETION_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: 'error'
      };
    default:
      return state;
  }
};

export default profileCompletionReducer;
