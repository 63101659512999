import { useState, useContext } from 'react';
import FeatureContext from './FeatureContext';
import { Feature, FeatureMap } from 'types/Feature';
import { find } from 'lodash';

export const useFeatureState: () => [FeatureMap | null, (features: Feature[]) => void] = () => {
  const [features, _setFeatures] = useState<FeatureMap | null>(null);

  const setFeatures: (features: Feature[]) => void = features => {
    const featureMap: FeatureMap = {
      features,
      isEnabled(name: string) {
        const f = find(features, { name });
        return f && f.enabled;
      }
    };
    _setFeatures(featureMap);
  };

  return [features, setFeatures];
};

export const useFeatures = () => {
  const { features } = useContext(FeatureContext);
  return features;
};
