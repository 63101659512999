import React from 'react';
import EmployeeSkillForm from './EmployeeSkillForm';
import Stack from 'components/common/Stack';
import { useUser } from 'auth/UserHooks';
import { trackComponent } from 'telemetry/AppInsights';

const AddEmployeeSkillPage = props => {
  const user = useUser();

  const employeeId = props.location.state.employeeId;

  const navigateToEmployee = () => {
    if (employeeId === user.id) props.history.push('/');
    else props.history.push(`/employees/${employeeId}`);
  };

  return (
    <Stack>
      <EmployeeSkillForm
        isCreateMode
        employeeId={employeeId}
        onCancel={navigateToEmployee}
        onSubmit={navigateToEmployee}
      />
    </Stack>
  );
};

export default trackComponent(AddEmployeeSkillPage, 'Add Employee Skill Page');
