import React from 'react'
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd'
import { debounce, uniq } from 'lodash'
import { Industry } from 'types/Industry'
import { IndustryFilterSettings } from 'types/IndustryPreferences'
import { IndustrySector } from 'types/IndustrySector'
import { showSector } from 'utils/skillDetails'

type IndustryFiltersProps = {
  industries: Industry[]
  onSearch: (filteredIndustries: Industry[]) => void
  form: any
  searchRef: any
  disabled: boolean
  settings: IndustryFilterSettings
  setSettings: (settings: IndustryFilterSettings) => void
  industrySectors: IndustrySector[],  
  sectors: number[]  
}

export const filterIndustries = (props: IndustryFiltersProps, changedValues: any, values: any) => {
  const { industries, onSearch, setSettings } = props
  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : ''
  const sectors = values.selectedSectors
  const showInactive = values.showInactive

  setSettings({
    search: values.searchTerm,
    sector: sectors,
    inActive: showInactive
  })

  const filteredIndustries = industries.filter(industry => {
    let isNameValid =
      !uppercaseTerm || !uppercaseTerm.length || industry.name.toUpperCase().indexOf(uppercaseTerm) > -1
    let isSectorValid = !sectors || !sectors.length || sectors.indexOf(industry.industrySectorId) > -1
    let isStatusValid = showInactive || !industry.inactive
    return isNameValid && isSectorValid && isStatusValid
  })
  onSearch(filteredIndustries)
}

const IndustryFilters = (props: IndustryFiltersProps) => {
  const { industries = [], form, disabled, settings, searchRef, industrySectors = [], sectors = [] } = props
  const { getFieldDecorator } = form
  const selectedSectors = form.getFieldValue('selectedSectors') || []

  const clearFilters = () => {
    form.resetFields()
    filterIndustries(
      props,
      {},
      {
        searchTerm: '',
        selectedSectors: [],
        showInactive: false
      }
    )
  }

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedSectors', {
            initialValue: settings.sector
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedSectors.length} Sector${selectedSectors.length === 1 ? '' : 's'
                } Selected`}
              showArrow
              dropdownStyle={{minWidth: "300px"}}
              mode="multiple"
              placeholder="Sector"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {sectors.map(s => (
                <Select.Option key={s} value={s}>
                  {showSector(industrySectors, s)} 
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showInactive', {
            initialValue: settings.inActive,
            valuePropName: 'checked'
          })(<Checkbox>Show Inactive</Checkbox>)}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Form.create<IndustryFiltersProps>({
  name: 'filter_industries_form',
  onValuesChange: debounce(filterIndustries, 500)
})(IndustryFilters)