import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import Stack from 'components/common/Stack';
import { getByEmployeeSkillId } from 'api/employeeSkillApi';
import { getProjectExperienceByEmployeeSkillId } from 'api/projectExperienceApi';
import EmployeeSkillForm from './EmployeeSkillForm';
import displayErrorNotification from 'utils/displayErrorNotification';
import { useUser } from 'auth/UserHooks';
import { trackComponent } from 'telemetry/AppInsights';
import FullPageSpinner from 'components/common/FullPageSpinner';

const EditEmployeeSkillPage = props => {
  const [employeeSkill, setEmployeeSkill] = useState(null);
  const [projectExperiences, setProjectExperiences] = useState(null);
  const employeeSkillId = props.match.params.employeeSkillId;
  const user = useUser();

  useEffect(() => {
    getByEmployeeSkillId(employeeSkillId)
      .then(setEmployeeSkill)
      .catch(displayErrorNotification);
    getProjectExperienceByEmployeeSkillId(employeeSkillId)
      .then(setProjectExperiences)
      .catch(displayErrorNotification);
  }, []);

  if (isNull(employeeSkill) || isNull(projectExperiences)) return <FullPageSpinner />;

  const employeeId = props.location.state.employeeId;

  const navigateToEmployee = () => {
    if (employeeId === user.id) props.history.push('/');
    else props.history.push(`/employees/${employeeId}`);
  };

  return (
    <Stack>
      <EmployeeSkillForm
        isCreateMode={false}
        employeeId={employeeId}
        projectExperiences={projectExperiences}
        employeeSkill={employeeSkill}
        onCancel={navigateToEmployee}
        onSubmit={navigateToEmployee}
      />
    </Stack>
  );
};

export default trackComponent(EditEmployeeSkillPage, 'Edit Employee Skill Page');
