import React, { useEffect, useState, useRef } from 'react';
import Stack from 'components/common/Stack';
import { Table, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import displayErrorNotification from 'utils/displayErrorNotification';
import Toolbar from 'components/common/Toolbar';
import { getCareerCoachReport, getReportById } from 'api/reportsApi';
import CareerCoachReportFilters, { filterCareerCoachReport } from './CareerCoachReportFilters';
import { Parser } from 'json2csv';
import moment from 'moment';
import ButtonRow from 'components/common/ButtonRow';
import { getMajor } from 'utils/skillDetails';
import useLocalStorage from 'utils/useLocalStorage';
import { defaultCareerCoachReportFilterSettings } from 'types/CareerCoachReportPreferences';
import SavePreferencesButton from 'components/common/SavePreferencesButton';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const CareerCoachReportList = props => {
  const [report, setReport] = useState([]);
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    'CareerCoachReport_FilterSettings',
    defaultCareerCoachReportFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage('CareerCoachReport_SortSettings', {
    key: 'coachee.id',
    order: 'ascend'
  });
  const searchRef = useRef();

  useEffect(() => {
    setLoading(true);
    getCareerCoachReport()
      .then(result => {
        setResponses(result);
        setFilteredResponses(result);
        filterCareerCoachReport(
          {
            onSearch: setFilteredResponses,
            responses: result,
            setSettings: setFilterSettings
          },
          {},
          {
            coacheeSearchTerm: filterSettings.tmSearch,
            selectedTMPractices: filterSettings.tmPractice,
            selectedTMMajor: filterSettings.tmMajor,
            selectedTMPositions: filterSettings.tmPosition,
            selectedTMOffices: filterSettings.tmOffice,
            coachSearchTerm: filterSettings.ccSearch,
            selectedCCPractices: filterSettings.ccPractice,
            selectedCCMajor: filterSettings.ccMajor,
            selectedCCPositions: filterSettings.ccPosition,
            ccOfselectedCCOfficesfice: filterSettings.ccOffice
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus();
        }
      });
  }, []);

  useEffect(() => {
    setReport(getReportById(1));
  }, []);

  let columns = [
    {
      title: 'Team Member (TM)',
      dataIndex: 'coachee',
      key: 'coachee.id',
      render: coachee => <Link to={`/employees/${coachee.id}`}>{coachee.fullName}</Link>,
      sorter: (a, b) => a.coachee.fullName.toLowerCase().localeCompare(b.coachee.fullName.toLowerCase())
    },
    {
      title: 'TM Practice',
      dataIndex: 'coachee.practice',
      key: 'coachee.practice',
      sorter: (a, b) => (a.coachee.practice < b.coachee.practice ? -1 : 1)
    },
    {
      title: 'TM Major',
      key: 'coachee.major',
      render: (text, CCReportRecord) => getMajor(CCReportRecord.coachee),
      sorter: (a, b) => getMajor(a.coachee).localeCompare(getMajor(b.coachee))
    },
    {
      title: 'TM Position',
      dataIndex: 'coachee.title',
      key: 'coachee.title',
      sorter: (a, b) => (a.coachee.title < b.coachee.title ? -1 : 1)
    },
    {
      title: 'TM Office',
      dataIndex: 'coachee.location',
      key: 'coachee.location',
      sorter: (a, b) => (a.coachee.location < b.coachee.location ? -1 : 1)
    },
    {
      title: 'Career Coach (CC)',
      dataIndex: 'coach',
      key: 'coach.id',
      render: coach => (
        <Link to={`/employees/${coach.id}`} key={coach.id}>
          {coach.fullName}
        </Link>
      ),
      sorter: (a, b) => a.coach.fullName.toLowerCase().localeCompare(b.coach.fullName.toLowerCase())
    },
    {
      title: 'CC Practice',
      dataIndex: 'coach.practice',
      key: 'coach.practice',
      sorter: (a, b) => (a.coach.practice < b.coach.practice ? -1 : 1)
    },
    {
      title: 'CC Major',
      key: 'coach.major',
      render: (text, CCReportRecord) => getMajor(CCReportRecord.coach),
      sorter: (a, b) => getMajor(a.coach).localeCompare(getMajor(b.coach))
    },
    {
      title: 'CC Position',
      dataIndex: 'coach.title',
      key: 'coach.position',
      sorter: (a, b) => (a.coach.title < b.coach.title ? -1 : 1)
    },
    {
      title: 'CC Office',
      dataIndex: 'coach.location',
      key: 'coach.location',
      sorter: (a, b) => (a.coach.location < b.coach.location ? -1 : 1)
    }
  ];

  const columnsSortSettings = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  const exportEmployeeCompletion = () => {
    const exportable = filteredResponses
      .sort((a, b) => a.coachee.fullName.toLowerCase().localeCompare(b.coachee.fullName.toLowerCase()))
      .map(k => {
        return {
          'Team Member (TM)': k.coachee.fullName,
          'TM Practice': k.coachee.practice,
          'TM Major': getMajor(k.coachee),
          'TM Position': k.coachee.title,
          'TM Office': k.coachee.location,
          'Career Coach (CC)': k.coach.fullName,
          'CC Practice': k.coach.practice,
          'CC Major': getMajor(k.coach),
          'CC Position': k.coach.title,
          'CC Office': k.coach.location
        };
      });

    const json2CsvParser = new Parser();
    const csv = json2CsvParser.parse(exportable);
    const blob = new Blob([csv], { type: 'text/csv', encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `CareerCoachReport-${moment().format('YYYYMMDD-hhmmss')}.csv`;
    link.click();
  };

  return (
    <Stack>
      <ButtonRow style={{ float: 'right' }}>
        {!loading && (
          <Button icon="download" onClick={exportEmployeeCompletion}>
            Export
          </Button>
        )}
        <SavePreferencesButton
          keys={['CareerCoachReport_FilterSettings', 'CareerCoachReport_SortSettings']}
          disabled={loading}
        />
      </ButtonRow>

      <Typography.Title>{report.name}</Typography.Title>
      <Typography.Title level={3}>{report.description}</Typography.Title>
      <Toolbar>
        <CareerCoachReportFilters
          onSearch={setFilteredResponses}
          responses={responses}
          searchRef={searchRef}
          disabled={loading}
          settings={filterSettings}
          setSettings={setFilterSettings}
        />
        <div>
          <span style={{ margin: '0 10px' }}>
            Showing {filteredResponses.length} of {responses.length} Responses
          </span>
        </div>
      </Toolbar>
      <Table
        pagination={PAGINATION_DEFAULT}
        rowKey={r => r.coachee.id}
        columns={columns}
        dataSource={filteredResponses}
        loading={loading}
        onChange={onChange}
      />
    </Stack>
  );
};

export default CareerCoachReportList;
