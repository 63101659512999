import React from "react";
import UserContext from "auth/UserContext";
import {
  getEmployeeDetails,
  getEmployeeDetailsByEmail,
  getProjects
} from "api/employeeApi";
import { Avatar, Typography, Tag, Spin, Icon, notification } from "antd";
import Stack from "components/common/Stack";
import EmployeeDetails from "components/employees/EmployeeDetails";
import { ENABLE_PROFILE_PHOTOS, S3_IMAGE_BASE_URL } from "config";
import { trackComponent } from "telemetry/AppInsights";
import { hasIn } from "lodash";

class EmployeeDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employee: {},
      projects: [],
      loading: false
    };

    this._loadEmployee = this._loadEmployee.bind(this);
  }

  _loadEmployee(employeeId) {
    this.setState({ loading: true });
    getEmployeeDetails(employeeId)
      .then(employee => this.setState({ employee }))
      .catch(e => {
        notification.error({ message: "Error", description: e.message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
    getProjects(employeeId).then(projects => this.setState({ projects }));
  }

  _loadEmployeeByEmail(email) {
    this.setState({ loading: true });
    getEmployeeDetailsByEmail(email)
      .then(employee => {
        this.setState({ employee });
        getProjects(employee.id).then(projects => this.setState({ projects }));
      })
      .catch(e => {
        notification.error({ message: "Error", description: e.message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevPros) {
    if (
      this.props.match.params.employeeId !== prevPros.match.params.employeeId
    ) {
      this._loadEmployee(this.props.match.params.employeeId);
    }
  }

  componentDidMount() {
    if (hasIn(this.props, "match.params.employeeId")) {
      let employeeId;
      employeeId = this.props.match.params.employeeId;
      this._loadEmployee(employeeId);
    } else if (hasIn(this.props, "match.params.employeeEmail")) {
      let email;
      email = this.props.match.params.employeeEmail;
      this._loadEmployeeByEmail(email);
    } else if (hasIn(this.props, "employeeId")) {
      this._loadEmployee(this.props.employeeId);
    } else if (hasIn(this.props, "employeeEmail")) {
      this._loadEmployeeByEmail(this.props.employeeEmail);
    }
  }

  render() {
    const { employee, projects, loading } = this.state;
    if (!Object.keys(this.state.employee).length) return null;

    return (
      <UserContext.Consumer>
        {ctx => (
          <Stack>
            <Typography.Title
              style={{ display: "flex", alignContent: "center" }}
            >
              {ENABLE_PROFILE_PHOTOS ? (
                <Avatar
                  style={{ marginRight: ".5em" }}
                  size={56}
                  icon="user"
                  src={`${S3_IMAGE_BASE_URL}images/profile/employee-photo-${
                    employee.activeDirectoryId
                  }.jpeg`}
                />
              ) : (
                <Avatar style={{ marginRight: ".5em" }} size={56} icon="user" />
              )}

              <div>
                <span>
                  {employee.firstName} {employee.lastName}
                  {ctx.user.coachees.includes(employee.id) && (
                    <Tag
                      style={{ marginLeft: "20px", verticalAlign: "middle" }}
                      color="red"
                    >
                      Career Coach View
                    </Tag>
                  )}
                  {ctx.user.coach === employee.id && (
                    <Tag
                      style={{ marginLeft: "20px", verticalAlign: "middle" }}
                      color="red"
                    >
                      Career Coach
                    </Tag>
                  )}
                </span>
                <Typography.Title level={4} type="secondary">
                  {employee.practice} ({employee.businessUnit}) ·{" "}
                  {employee.title} · {employee.region} · {employee.location}
                </Typography.Title>
              </div>
            </Typography.Title>

            {loading ? (
              <Spin
                indicator={
                  <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
              />
            ) : (
              <EmployeeDetails employee={employee} projects={projects} />
            )}
          </Stack>
        )}
      </UserContext.Consumer>
    );
  }
}

export default trackComponent(EmployeeDetailPage, "Employee Detail Page");
