import React, { useEffect, useState, useRef } from "react";
import { Typography, Button, Tag, Spin, Tooltip, Icon } from "antd";
import { sum } from "lodash";
import UserContext from "auth/UserContext";
import { Link } from "react-router-dom";
import ButtonRow from "components/common/ButtonRow";
import Stack from "components/common/Stack";
import Toolbar from "components/common/Toolbar";
import displayErrorNotification from "utils/displayErrorNotification";
import { useUser } from "auth/UserHooks";
import { trackComponent } from "telemetry/AppInsights";
import { getRoleTypeFamilyTree } from "../../utils/RoleTypeDetails";
import {
  getAllRoleTypes,
  getRoleTypeFamilyDetails
} from "../../api/RoleTypeApi";
import RoleTypeFilters, { filterRoleTypeTree } from "./RoleTypeFilters";
import { RecursiveTable } from "components/skills/skill/SkillTable";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import useLocalStorage from "utils/useLocalStorage";
import { defaultRoleTypeFilterSettings } from "types/RoleTypePreferences";
import { useFeatures } from "../../hooks/FeatureHooks";

const RoleTypeLandingPage = props => {
  const features = useFeatures();
  const [roleTypes, setRoleTypes] = useState([]);
  const [filteredRoleTypes, setFilteredRoleTypes] = useState([]);
  const [roleTypeFamilies, setRoleTypeFamilies] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    "RoleTypesLandingPage_FilterSettings",
    defaultRoleTypeFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage(
    "RoleTypesLandingPage_SortSettings",
    {
      key: "id",
      order: "ascend"
    }
  );
  const searchRef = useRef();

  useEffect(() => {
    setLoading(true);
    Promise.all([getRoleTypeFamilyDetails(), getAllRoleTypes()])
      .then(response => {
        const roleTypeTree = getRoleTypeFamilyTree(response[0], response[1]);
        setRoleTypeFamilies(response[0]);
        setRoleTypes(roleTypeTree);
        filterRoleTypeTree(
          {
            onSearch: _setFilteredRoleTypes,
            roleTypes: roleTypeTree,
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            roleTypeFamilies: response[0]
          },
          [],
          {
            searchTerm: filterSettings.search,
            showInactive: filterSettings.inActive
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current !== null) {
          searchRef.current.focus();
        }
      });
  }, []);

  const _setFilteredRoleTypes = (roleTypeTree, _filteredRoleTypeFamilies) => {
    roleTypeTree = roleTypeTree.filter(r => {
      const find = _filteredRoleTypeFamilies.find(
        f => parseInt(r.id.substring(6)) === f.id
      );
      return find !== undefined;
    });
    _setExpandedRows(roleTypeTree);
    setFilteredRoleTypes(roleTypeTree);
  };

  const _setExpandedRows = families => {
    const familyGroups = families.map(f => f.id);
    setExpandedRows(familyGroups);
  };

  const toggleExpandAll = () => {
    const expanded = expandedRows.length > 0;
    _setExpandedRows(expanded ? [] : filteredRoleTypes);
  };

  const getFilteredRoleTypesLength = () => {
    return sum(filteredRoleTypes.map(pr => pr.roleTypeCount));
  };

  const getTotalRoleTypesLength = () => {
    return sum(roleTypes.map(pr => pr.roleTypeCount));
  };

  let columns = [
    {
      title: "Family",
      dataIndex: "name",
      key: "id",
      width: "30%",
      render: (text, record) => {
        if (record.contains && record.contains.length > 0) {
          const find = roleTypeFamilies.find(
            f => parseInt(record.id.substring(6)) === f.id
          );
          if (find.description === "") {
            return record.name;
          } else {
            return (
              <Tooltip title={find.description}>
                {record.name} <Icon type="info-circle" />
              </Tooltip>
            );
          }
        } else {
          return <Link to={`/role-types/${record.id}`}>{record.name}</Link>;
        }
      },
      sorter: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onHeaderCell: () => {
        return {
          style: { position: "absolute", left: 0, width: "calc(30% + 50px)" }
        };
      }
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Employees",
      dataIndex: "employeeCount",
      key: "employeeCount",
      width: "150px"
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: "150px",
      align: "right",
      render: active =>
        active != null && (
          <Tag color={active ? "green" : "red"}>
            {active ? "Active" : "Inactive"}
          </Tag>
        ),
      sorter: (a, b) => (a.active < b.active ? 1 : -1)
    }
  ];

  const columnsSortSettings = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <div>
            <Typography.Title level={1}>
              Role Types
              <ButtonRow style={{ float: "right" }}>
                {ctx.user.permissions.canCreateRoleTypeType && (
                  <Button
                    icon="solution"
                    onClick={() => props.history.push("/role-types/new")}
                  >
                    Add Role Type
                  </Button>
                )}
                {(user.permissions.canUpdateOwnRoleType ||
                  user.permissions.canUpdateCoacheeRoleType ||
                  user.permissions.canUpdateRoleType) && (
                  <Button
                    icon="plus"
                    onClick={() =>
                      props.history.push("/employee/role-types/new", {
                        employeeId: user.id
                      })
                    }
                  >
                    Add My Role Type
                  </Button>
                )}
                <SavePreferencesButton
                  disabled={loading}
                  keys={[
                    "RoleTypesLandingPage_FilterSettings",
                    "RoleTypesLandingPage_SortSettings"
                  ]}
                />
              </ButtonRow>
            </Typography.Title>

            <Typography.Paragraph>
              The below roles inventory reflects the standard roles that
              Crederians play across our various projects. As an employee, you
              can represent both the role types you have experience with and
              those you're interested in learning by clicking "Add My Role Type"
              above. Employees can also associate each project role they add to
              their profile with one or more of their past Credera projects,
              further enriching the information we have about past client work.{" "}
            </Typography.Paragraph>

            <Typography.Paragraph>
              New role types may be requested by emailing{" "}
              <a href="mailto:systems@credera.com">systems@credera.com</a> and
              will be reviewed periodically by stakeholders for addition to the
              system.
            </Typography.Paragraph>

            {features && features.isEnabled("ENABLE_PROJECTOR_FEATURES") && (
              <Typography.Paragraph>
                Role Type Experience is periodically synced with Projector as
                Role Type 'Skills'. Projector Role Type Experience of 0 = No
                Experience or No Future Interest, 1 = Desire to Fill, 3 =
                Beginner, 4 = Intermediate, 5 = Advanced.
              </Typography.Paragraph>
            )}

            <Toolbar>
              <RoleTypeFilters
                onSearch={_setFilteredRoleTypes}
                roleTypes={roleTypes}
                settings={filterSettings}
                setSettings={setFilterSettings}
                disabled={loading}
                searchRef={searchRef}
                roleTypeFamilies={roleTypeFamilies}
              />
              {
                <div>
                  Showing {getFilteredRoleTypesLength()} of{" "}
                  {getTotalRoleTypesLength()} Role Types
                </div>
              }
            </Toolbar>

            <Toolbar style={{ background: "transparent", padding: 0 }}>
              <Button
                icon={expandedRows.length > 0 ? "minus" : "plus"}
                onClick={toggleExpandAll}
              >
                {expandedRows.length > 0 ? "Collapse All" : "Expand All"}
              </Button>
            </Toolbar>
          </div>

          <Spin spinning={loading}>
            <RecursiveTable
              itemList={filteredRoleTypes}
              childrenName="contains"
              expandedRows={expandedRows}
              handleExpandedRowChange={setExpandedRows}
              showHeader={true}
              columns={columns}
              onChange={onChange}
            />
          </Spin>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(RoleTypeLandingPage, "Role Types Landing Page");
