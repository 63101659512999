import React, { useEffect, useRef, useState } from "react";
import Stack from "components/common/Stack";
import { Button, Input, Table, Typography } from "antd";
import { Link, RouteComponentProps } from "react-router-dom";
import { getPrompt } from "api/promptApi";
import { getAllEmployeeResponses } from "api/employeePromptApi";
import displayErrorNotification from "utils/displayErrorNotification";
import sorters from "utils/sorters";
import Toolbar from "components/common/Toolbar";
import PromptResponseFilters, {
  filterResponses
} from "./PromptResponseFilters";
import { ColumnProps } from "antd/lib/table";
import { Prompt } from "types/Prompt";
import { convertEmployeePromptResponse } from "utils/convert";
import useLocalStorage from "utils/useLocalStorage";
import {
  defaultPromptFilterSettings,
  PromptFilterSettings
} from "types/PromptPreferences";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import ButtonRow from "components/common/ButtonRow";
import { EmployeePromptResponse } from "../../types/EmployeePromptResponse";
import { Parser } from "json2csv";
import moment from "moment";

const PromptResponseList = (props: RouteComponentProps) => {
  const [prompt, setPrompt] = useState<Prompt>();
  const [promptResps, setPromptResps] = useState<EmployeePromptResponse[]>([]);
  const [filteredPromptResps, setFilteredPromptResps] = useState<
    EmployeePromptResponse[]
  >([]);
  const [loading, setLoading] = useState(true);
  const searchRef = useRef<Input>();
  // @ts-ignore
  const promptId: number = props.match.params.promptId;
  const [filterSettings, setFilterSettings] = useLocalStorage<
    PromptFilterSettings
  >(
    "PromptResponseList_FilterSettings_" + promptId,
    defaultPromptFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage<any>(
    "PromptResponseList_SortSettings_" + promptId,
    {
      key: "name",
      order: "ascend"
    }
  );

  useEffect(() => {
    Promise.all([getPrompt(promptId), getAllEmployeeResponses(promptId)])
      .then(results => {
        const converted = convertEmployeePromptResponse(results[1]);
        setPrompt(results[0]);

        setPromptResps(converted);
        setFilteredPromptResps(converted);
        filterResponses(
          {
            onSearch: setFilteredPromptResps,
            promptResps: converted,
            searchRef: null,
            promptType: prompt?.type || "FREE_RESPONSE",
            disabled: loading,
            settings: filterSettings,
            setSettings: setFilterSettings,
            form: null
          },
          {},
          {
            searchTerm: filterSettings.search,
            selectedPractices: filterSettings.practice,
            selectedPositions: filterSettings.position,
            selectedOffices: filterSettings.location,
            selectedResponses: prompt?.type === "FREE_RESPONSE" ? filterSettings.responseSearch : filterSettings.responseDropdown
          }
        );
        setLoading(false);
        if (
          searchRef.current !== undefined &&
          searchRef.current.input !== null
        ) {
          searchRef.current.focus();
        }
      })
      .catch(displayErrorNotification);
  }, []);

  let columns: ColumnProps<EmployeePromptResponse>[] = [
    {
      title: "Name",
      key: "sortName",
      render: r => <Link to={`/employees/${r.id}`}>{r.sortName}</Link>,
      sorter: (a, b) => sorters.string(a.sortName, b.sortName)
    },
    {
      title: "Email",
      key: "email",
      render: r => <a href={`mailto:${r.email}`}>{r.email}</a>,
      sorter: (a, b) => sorters.string(a.email, b.email)
    },
    {
      title: "Practice",
      key: "practice",
      dataIndex: "practice",
      sorter: (a, b) => sorters.string(a.practice, b.practice)
    },
    {
      title: "Position",
      key: "position",
      dataIndex: "position",
      sorter: (a, b) => sorters.string(a.position, b.position)
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      sorter: (a, b) => sorters.string(a.location, b.location)
    },
    {
      title: "Response",
      key: "response",
      dataIndex: "response",
      sorter: (a, b) => sorters.string(a.response, b.response)
    }
  ];

  columns = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  if (!prompt || !promptResps) return <div>Loading prompt responses...</div>;

  const exportAsCSV = () => {
    const column = columns.find(c => c.defaultSortOrder !== undefined);
    let columnName: React.Key | undefined = "sortName";
    let sortOrder = true; // ascend
    let type = sorters.TYPE_STRING;
    if (column !== undefined) {
      columnName = column.key;
      sortOrder = column.defaultSortOrder === "ascend";
    }
    const exportable = filteredPromptResps
      .sort((a, b) => sorters.multi(a, b, columnName, type, sortOrder))
      .map(k => {
        return {
          "First Name": k.firstName,
          "Last Name": k.lastName,
          Email: k.email,
          Practice: k.practice,
          Position: k.position,
          Location: k.location,
          Response: k.response
        };
      });

    const json2CsvParser = new Parser();
    const csv = json2CsvParser.parse(exportable);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `PromptResponses-${prompt.id}-${moment().format(
      "YYYYMMDD-HHmmss"
    )}.csv`;
    link.click();
  };

  return (
    <Stack>
      <Typography.Title>
        Responses
        <ButtonRow style={{ float: "right" }}>
          <Button icon="download" onClick={exportAsCSV}>
            Export
          </Button>
          <SavePreferencesButton
            keys={[
              "PromptResponseList_FilterSettings_" + promptId,
              "PromptResponseList_SortSettings_" + promptId
            ]}
            disabled={loading}
          />
        </ButtonRow>
      </Typography.Title>
      <Typography.Title level={3}>{prompt.question}</Typography.Title>
      <Toolbar>
        <PromptResponseFilters
          onSearch={setFilteredPromptResps}
          promptResps={promptResps}
          promptType={prompt.type}
          searchRef={searchRef}
          disabled={loading}
          settings={filterSettings}
          setSettings={setFilterSettings}
        />
        <div>
          <span style={{ margin: "0 10px" }}>
            Showing {filteredPromptResps.length} of {promptResps.length}{" "}
            Responses
          </span>
        </div>
      </Toolbar>
      <Table
        pagination={{ pageSize: 25, position: 'top'}}
        rowKey="id"
        columns={columns}
        dataSource={filteredPromptResps}
        loading={loading}
        onChange={onChange}
      />
    </Stack>
  );
};

export default PromptResponseList;
