import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin
} from "antd";
import { isNull } from "lodash";
import { getDayPicker } from "components/constants/Constants";
import { createOwnCalendarFeed, getOwnCalendarFeed } from "api/calendar";
import displayErrorNotification from "utils/displayErrorNotification";
import { CalendarFeedURL } from "types/Calendar";
import { useUser } from "../../../auth/UserHooks";

type BirthdayPromptFormProps = {
  dateStr: string;
  onSubmit: (response: string | string[]) => void;
  employeeId: number;
  form: any;
};

const BirthdayPromptForm = ({
  onSubmit,
  dateStr,
  employeeId,
  form
}: BirthdayPromptFormProps) => {
  const user = useUser();
  const allowCalendarFeedCopy =
    employeeId === user.id &&
    user.permissions.canReadOwnCalendarFeed &&
    user.permissions.canCreateOwnCalendarFeed;
  const { getFieldDecorator, setFields } = form;
  const [loading, setLoading] = useState<boolean>(false);
  const [calendarFeed, setCalendarFeed] = useState<CalendarFeedURL>({
    url: ""
  });
  const disabled =
    (!form.isFieldTouched("noResponse") && !dateStr) ||
    form.getFieldValue("noResponse");
  const months = getDayPicker().months;
  const initialValues = dateStr
    ? {
        month: moment(dateStr).month(),
        day: moment(dateStr).date(),
        noResponse: false
      }
    : { month: null, day: null, noResponse: true };

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);
    getOwnCalendarFeed()
      .then(response => {
        if (response !== undefined && response.url !== "") {
          setCalendarFeed(response);
        }
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
      });
  };
  const onNoResponseChange = () => {
    setFields({ day: { value: null }, month: { value: null } });
  };

  const validateDate = (
    rule: any,
    day: number | string,
    callback: Function
  ) => {
    const isValidOrNull = () => {
      if (form.getFieldValue("noResponse") || isNull(day)) return true;
      const month = form.getFieldValue("month");
      if (!month || !day) return false;
      if (day < 1) return false;
      if (month === 2) {
        return day < 30;
      } else if ([4, 6, 9, 11].includes(month)) {
        return day < 31;
      } else {
        return day < 32;
      }
    };
    if (!isValidOrNull()) {
      callback(new Error("Invalid date"));
      return;
    }
    callback();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (err) return;

      if (values.noResponse) onSubmit("");

      const date = moment()
        .year(1970)
        .month(values.month - 1)
        .date(values.day)
        .format("YYYY-MM-DD");
      onSubmit(date);
    });
  };

  const _writeClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      () => {
        console.log(text);
        Modal.warn({
          title: "Unable to write to clipboard. Copy from here:",
          content: text,
          onOk() {}
        });
      }
    );
  };

  const _clickCopyButton = () => {
    if (calendarFeed.url !== "") {
      _writeClipboard(calendarFeed.url);
    } else {
      setLoading(true);
      createOwnCalendarFeed()
        .then(response => {
          if (response !== undefined && response.url !== "") {
            setCalendarFeed(response);
            _writeClipboard(response.url);
          }
        })
        .catch(displayErrorNotification)
        .then(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Spin spinning={loading}>
      {allowCalendarFeedCopy && (
        <Row>
          <Col span={4}>
            <Button onClick={_clickCopyButton} type="default">
              Copy
            </Button>
          </Col>
          <Col span={20}>
            <p>
              Click Copy to get the calendar feed link for all active Crederians
              birthdays.
            </p>
          </Col>
        </Row>
      )}
      <Form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "1em" }}>
          <em>
            You do not have to answer this question, however leaving it blank
            will opt you out of receiving a birthday email.
          </em>
        </div>
        <Form.Item>
          {getFieldDecorator("noResponse", {
            valuePropName: "checked",
            initialValue: initialValues.noResponse
          })(
            <Checkbox onChange={onNoResponseChange}>Prefer not to say</Checkbox>
          )}
        </Form.Item>
        <div>
          <Form.Item style={{ display: "inline-block" }} required={!disabled}>
            {getFieldDecorator("month", {
              initialValue:
                initialValues && initialValues.month !== null
                  ? initialValues.month + 1
                  : null,
              rules: [
                { required: !disabled, message: "Month is a required field" }
              ]
            })(
              <Select style={{ width: 150 }} disabled={disabled}>
                {months.map((month, idx) => (
                  <Select.Option key={idx + 1} value={idx + 1}>
                    {month}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            style={{ display: "inline-block", marginTop: "1px" }}
            required={!disabled}
          >
            {getFieldDecorator("day", {
              initialValue: initialValues.day,
              rules: [
                { required: !disabled, message: "Day is a required field" },
                { validator: validateDate, message: "Invalid date" }
              ]
            })(
              <InputNumber style={{ marginLeft: "1em" }} disabled={disabled} />
            )}
          </Form.Item>
        </div>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </Spin>
  );
};

export default Form.create<BirthdayPromptFormProps>({
  name: "birthday_prompt_card_form"
})(BirthdayPromptForm);
