import React, { useEffect, useState, useRef } from 'react';
import { Typography, Table, Spin, Button, Tag, Input } from 'antd';
import UserContext from 'auth/UserContext';
import { Link, RouteComponentProps } from 'react-router-dom';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import { getEntities } from '../../../api/entitiesApi';
import { trackComponent } from 'telemetry/AppInsights';
import EntityFilters, { filterEntities } from './EntityFilters';
import Toolbar from '../../common/Toolbar';
import ButtonRow from 'components/common/ButtonRow';
import { Entity } from 'types/Entity';
import { ColumnProps } from 'antd/lib/table';
import useLocalStorage from 'utils/useLocalStorage';
import { defaultEntityFilterSettings, EntityFilterSettings } from 'types/EntityPreferences';
import SavePreferencesButton from 'components/common/SavePreferencesButton';

const EntitiesPage = (props: RouteComponentProps) => {
  const [entities, setEntities] = useState<Entity[]>([]);
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage<EntityFilterSettings>(
    'EntitiesLandingPage_FilterSettings',
    defaultEntityFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage<any>('EntitiesLandingPage_SortSettings', {
    key: 'name',
    order: 'ascend'
  });
  const searchRef = useRef<Input>();

  useEffect(() => {
    setLoading(true);
    getEntities()
      .then(response => {
        setEntities(response);
        filterEntities(
          {
            onSearch: handleFilter,
            entities: response,
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            form: null,
            searchRef: null
          },
          [],
          {
            searchTerm: filterSettings.search,
            selectedEntities: filterSettings.entity,
            showInactive: filterSettings.inActive
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus();
        }
      });
  }, []);

  const handleFilter = (_filteredEntities: Entity[]) => {
    setFilteredEntities(_filteredEntities);
  };

  let columns: ColumnProps<Entity>[] = [
    {
      title: 'Entity',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (text, record) => <Link to={`/entities/${record.id}`}>{record.name}</Link>,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onHeaderCell: () => {
        return {
          style: { position: 'absolute', left: 0, width: 'calc(30%)' }
        };
      }
    },
    {
      title: 'Employees',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'inactive',
      key: 'inactive',
      width: '150px',
      align: 'right',
      render: inactive => <Tag color={inactive ? 'red' : 'green'}>{inactive ? 'Inactive' : 'Active'}</Tag>,
      sorter: (a, b) => (a.inactive < b.inactive ? 1 : -1)
    }
  ];

  const columnsSortSettings = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <div>
            <Typography.Title level={1}>
              Entities
              <ButtonRow style={{ float: 'right' }}>
                {ctx.user.permissions.canCreateEntity && (
                  <Button icon="solution" onClick={() => props.history.push('/entities/new')}>
                    Add Entity
                  </Button>
                )}
                <SavePreferencesButton
                  keys={['EntitiesLandingPage_FilterSettings', 'EntitiesLandingPage_SortSettings']}
                  disabled={loading}
                />
              </ButtonRow>
            </Typography.Title>
            <Typography.Paragraph>
              This inventory represents the current entities in Credera's associated offices. Click on an entity to view
              all of the employees aligned to that group.
            </Typography.Paragraph>
            <Toolbar>
              <EntityFilters
                onSearch={handleFilter}
                entities={entities}
                searchRef={searchRef}
                disabled={loading}
                settings={filterSettings}
                setSettings={setFilterSettings}
              />
              <div>
                Showing {filteredEntities.length} of {entities.length} Entities
              </div>
            </Toolbar>
          </div>

          <Spin spinning={loading}>
            <Table
              columns={columns}
              rowKey="id"
              style={{ width: '100%' }}
              dataSource={filteredEntities}
              // rowClassName={record => (record.length === 0 ? 'hide-expand' : '')}
              pagination={false}
              onChange={onChange}
            />
          </Spin>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(EntitiesPage, 'Entities Landing Page');
