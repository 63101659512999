import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'antd';
import { toggleBadgeCategoryActivation } from 'api/badgesApi';
import displayErrorNotification from 'utils/displayErrorNotification';

const DeleteBadgeCategory = ({ category, onSuccess }) => {
  const removeBadgeCategory = e => {
    e.stopPropagation();
    Modal.error({
      icon: 'exclamation-circle',
      title: 'Are you sure you want to remove this category?',
      content: 'Removing a category invalidates all badges within that category.',
      maskClosable: true,

      onOk: () => {
        toggleBadgeCategoryActivation(category.id)
          .then(onSuccess)
          .catch(displayErrorNotification);
      },
      onCancel: () => {},
      cancelText: 'Cancel'
    });
  };

  return <Icon title="Delete" type="delete" onClick={removeBadgeCategory} />;
};

DeleteBadgeCategory.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  category: PropTypes.object
};

export default DeleteBadgeCategory;
