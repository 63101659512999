import axios from 'axios';
import cookie from 'js-cookie';
import { API_URL, MOCK_API_ENABLED } from 'config';
import mockApiResponses from 'api/_mockApiResponses';
import qs from 'qs';

const authToken = cookie('Authorization') === undefined ? '' : cookie('Authorization');

export function get(url, data) {
  if (MOCK_API_ENABLED && mockApiResponses[url]) {
    return Promise.resolve(mockApiResponses[url]);
  }
  return axios
    .get(`${API_URL}${url}`, {
      params: data,
      headers: {
        Authorization: authToken
      }
    })
    .then(handleResponse);
}

export function post(url, data) {
  return axios
    .post(`${API_URL}${url}`, data, {
      headers: {
        Authorization: authToken
      }
    })
    .then(handleResponse);
}

export function put(url, data) {
  return axios
    .put(`${API_URL}${url}`, data, {
      headers: {
        Authorization: authToken
      }
    })
    .then(handleResponse);
}

export function del(url, params) {
  return axios
    .delete(`${API_URL}${url}`, {
      params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      headers: {
        Authorization: authToken
      }
    })
    .then(handleResponse);
}

function handleResponse(response) {
  if (response.headers['content-type'] && response.headers['content-type'].indexOf('text/csv') > -1) {
    const type = response.headers['content-type'];
    const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'download.csv';
    link.click();
    return;
  }
  return response.data;
}
