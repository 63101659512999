import { Table } from 'antd';
import React from 'react';
import { trackComponent } from 'telemetry/AppInsights';
import './SkillTable.css';

export const RecursiveTable = ({
  itemList,
  childrenName,
  expandedRows,
  handleExpandedRowChange,
  showHeader,
  columns,
  onChange
}) => {
  if (itemList && itemList.length > 0) {
    return (
      <Table
        rowKey="id"
        showHeader={showHeader}
        style={showHeader ? { width: '100%', background: 'none' } : { background: 'none' }}
        expandedRowKeys={expandedRows}
        onExpandedRowsChange={er => handleExpandedRowChange(er)}
        expandedRowRender={item => (
          <RecursiveTable
            itemList={item[childrenName]}
            childrenName={childrenName}
            expandedRows={expandedRows}
            handleExpandedRowChange={handleExpandedRowChange}
            showHeader={false}
            columns={columns}
          />
        )}
        dataSource={itemList}
        columns={columns}
        rowClassName={item => (item[childrenName] && item[childrenName].length ? '' : 'hide-expand')}
        pagination={false}
        onChange={onChange}
      />
    );
  }
  return null;
};

const SkillTable = ({ skillTree, category, expandedRows, handleExpandedRowChange, columns }) => {
  const updateRowsWrapper = expandedRows => {
    handleExpandedRowChange(expandedRows, category);
  };

  return (
    <RecursiveTable
      itemList={skillTree}
      childrenName="childSkills"
      category={category}
      expandedRows={expandedRows[category]}
      handleExpandedRowChange={updateRowsWrapper}
      showHeader={true}
      columns={columns}
    />
  );
};

export default trackComponent(SkillTable, 'Skills Table');
