import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Persona from 'components/common/Persona';
import './Header.css';
import { Divider, Select } from 'antd';
import { getPermissionsForRole } from '../../api/employeeApi';
import UserContext from 'auth/UserContext';

const Header = props => {
  const { user, setUser } = useContext(UserContext);
  const [emulate, setEmulate] = useState(false);

  const roles = [
    { key: 'ADMIN', text: 'Admin' },
    { key: 'BENCH', text: 'Bench' },
    { key: 'CAREER_COACH', text: 'Career Coach' },
    { key: 'CONTRACTOR', text: 'Contractor' },
    { key: 'CORPORATE_ADMIN', text: 'Corporate Admin' },
    { key: 'L_AND_D_MANAGER', text: 'L & D Manager' },
    { key: 'EMPLOYEE', text: 'Employee' },
    { key: 'PARTNER', text: 'Partner' },
    { key: 'PRACTICE_LEADER', text: 'Practice Leader' },
    { key: 'STAFFING', text: 'Staffing Role' },
    { key: 'SQUAD_LEADER', text: 'Squad Leader' }
  ];

  useEffect(() => {
    if (user.permissions.canEmulateRoles) {
      setEmulate(true);
    }
  }, []);

  const _onRoleChange = key => {
    getPermissionsForRole(key).then(result => {
      user.permissions = result;
      setUser(user);
    });
  };

  return (
    <>
      <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
        {emulate && (
          <>
            <Select onChange={_onRoleChange} style={{ width: "200px" }} defaultValue="ADMIN">
              {roles.map(r => {
                return (
                  <Select.Option key={r.key} value={r.key}>
                    {r.text}
                  </Select.Option>
                );
              })}
            </Select>
            <Divider type="vertical" />
          </>
        )}
        <Link to={`/employees/${user.id}`} className="persona-link">
          <Persona
            text={`${user.firstName} ${user.lastName}`}
            secondaryText={user.practice}
            activeDirectoryId={user.activeDirectoryId}
          />
        </Link>
      </div>
      <h2>
        <img style={{ maxWidth: 40, marginRight: 8 }} src={'/images/Scout_Logo.png'} alt="Scout Logo" />
        Scout
      </h2>
    </>
  );
};

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(Header);
