import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon } from 'antd';
import { Link } from 'react-router-dom';

const AddNewCard = ({ title, description, link, onClick }) => {
  return (
    <Link to={link} onClick={onClick}>
      <Card className="avatar-card" hoverable={true} style={{ height: '100%', border: '2px dashed #e8e8e8' }}>
        <Card.Meta
          avatar={<Icon type="plus" style={{ fontSize: '24pt', color: '#E55F4C' }} width={16} />}
          title={title}
          description={description}
        />
      </Card>
    </Link>
  );
};

AddNewCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.object.isRequired
};

export default AddNewCard;
