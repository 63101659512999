import { LandingPageProject } from 'types/Project'

const formatStage = (lpp: LandingPageProject) => {
  if (lpp.stage === '') {
    return lpp.active ? 'Active' : 'Inactive'
  } else {
    let stageSplit = lpp.stage.split(':')
    if (stageSplit.length !== 2) {
      console.log('Incorract stage format: ' + lpp.stage)
      return ''
    }
    return stageSplit[1]
  }
}

export default formatStage
