import React, { useState } from 'react'
import { slice } from 'lodash'
import enumToDisplayText from 'utils/enumToDisplayText'
import formatMonthDate from 'utils/formatMonthDate'
import { Avatar, Popover, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { ENABLE_PROFILE_PHOTOS, S3_IMAGE_BASE_URL } from 'config'
import { Experience } from 'types/Experience'
import ProjectorLogo from 'components/common/ProjectorLogo'

const ProjectExperienceAvatar = ({ experience }: { experience: Experience[] }) => {
  const [showAllAvatars, setShowAllAvatars] = useState(false)

  const employeeAvatars: Experience[] = showAllAvatars ? experience : slice(experience, 0, 5)
  const extraCount: number = experience.length - employeeAvatars.length

  const renderAvatar = (es: Experience) => {
    const initials = `${es.employee.firstName.substring(0, 1)}${es.employee.lastName.substring(0, 1)}`
    const content = (
      <div>
        Experience Level: {enumToDisplayText(es.experienceLevel)}
        <br />
        Duration of Experience: {es.duration}
        <br />
        Last Used: {formatMonthDate(es.lastUsedDate)}
        <br />
        Interest: {es.interest ? 'Yes' : 'No'}
      </div>
    )

    return (
      <Popover key={es.employee.id} content={content} title={
        <>
          {es.employee.fullName}
          {es.projector && <div style={{ float: 'right', display: 'flex', alignItems: 'center', marginRight: '-20px', marginTop: '-9px' }}>
            <ProjectorLogo size="41px" strokeWidth="16" background="rgba(0,0,0,0)" color="" />
          </div>}
        </>
      }>
        <Link to={`/employees/${es.employee.id}`}>
          {ENABLE_PROFILE_PHOTOS ? (
            <Avatar
              style={{ marginRight: 8 }}
              src={`${S3_IMAGE_BASE_URL}/images/profile/employee-photo-${es.employee.activeDirectoryId}.jpeg`}
            />
          ) : (
            <Avatar style={{ backgroundColor: '#E55F4C', marginRight: 8 }}>{initials}</Avatar>
          )}
        </Link>
      </Popover>
    )
  }

  return (
    <>
      {employeeAvatars.map((a: Experience) => renderAvatar(a))}
      {extraCount <= 0 ? null : (
        <Tooltip title={'Show more'}>
          <span onClick={() => setShowAllAvatars(true)}>+{extraCount}</span>
        </Tooltip>
      )}
    </>
  )
}

export default ProjectExperienceAvatar
