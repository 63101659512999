import { getEmployeeProfileCompletion } from 'api/employeeApi';

import {
  FETCH_PROFILE_COMPLETION_FAILURE,
  FETCH_PROFILE_COMPLETION_REQUEST,
  FETCH_PROFILE_COMPLETION_SUCCESS
} from './profileCompletionTypes';

export const fetchProfileCompletionRequest = () => {
  return {
    type: FETCH_PROFILE_COMPLETION_REQUEST
  };
};

export const fetchProfileCompletionSuccess = profileCompletion => {
  return {
    type: FETCH_PROFILE_COMPLETION_SUCCESS,
    payload: profileCompletion
  };
};

export const fetchProfileCompletionFailure = error => {
  return {
    type: FETCH_PROFILE_COMPLETION_FAILURE,
    payload: error
  };
};

export const fetchProfileCompletion = employeeId => {
  return dispatch => {
    dispatch(fetchProfileCompletionRequest());
    getEmployeeProfileCompletion(employeeId)
      .then(response => {
        const completionData = response;
        dispatch(fetchProfileCompletionSuccess(completionData));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchProfileCompletionFailure(errorMsg));
      });
  };
};
