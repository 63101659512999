import React, { useEffect, useState } from 'react'
import { togglePromptActivation, createPrompt, updatePrompt } from 'api/promptApi'
import { Button, Col, Form, Input, Row, Select, Checkbox } from 'antd'
import './PromptForm.css'
import displayErrorNotification from 'utils/displayErrorNotification'
import { useUser } from 'auth/UserHooks'
import RichTextEditor from 'react-rte'
import { richTextEditorToolbarConfig } from 'utils/richTextEditorToolbarConfig'
import VerticalDragAndDrop from 'components/common/drag-and-drop/VerticalDragAndDrop'
import ButtonRow from 'components/common/ButtonRow'
import { PromptOption } from 'types/PromptOption'
import { Prompt, PromptTypes } from 'types/Prompt'
import { useFeatures } from 'hooks/FeatureHooks'
import { ProjectorUdf } from 'types/Projector'
import { EmployeePractice } from 'types/EmployeePractice'

const PromptTypeOptions = [
  { key: PromptTypes.FREE_RESPONSE, text: 'Free Response' },
  { key: PromptTypes.SELECT_ONE, text: 'Select One' },
  { key: PromptTypes.SELECT_MULTIPLE, text: 'Select Multiple' }
]

type PromptFormProps = {
  prompt?: Prompt
  udfs: ProjectorUdf[]
  practices: EmployeePractice[]
  categories: Set<string>
  onSubmit: Function
  onCancel: Function
  form: any
}

const PromptForm = ({ prompt, udfs, practices, categories, onSubmit, onCancel, form }: PromptFormProps) => {
  const user = useUser()
  const isCreateMode = !!!prompt
  const [inactive, setInactive] = useState<boolean>(false)
  const [promptType, setPromptType] = useState<string>(PromptTypes.FREE_RESPONSE)
  const [promptCategory, setPromptCategory] = useState<Set<string>>(new Set())
  const [promptOptions, setPromptOptions] = useState<PromptOption[]>([])
  const [hasNoneOption, setHasNoneOption] = useState<boolean>(false)
  const [newOptionIds, setNewOptionIds] = useState<string[]>([])
  const [filteredUdfs, setFilteredUdfs] = useState<ProjectorUdf[]>([])
  const [filteredPractices, setFilteredPractices] = useState<EmployeePractice[]>([])
  const { getFieldDecorator } = form
  const features = useFeatures()
  const initialValues = isCreateMode
    ? {
      question: '',
      description: '',
      type: '',
      practiceId: undefined,
      category: [],
      personal: false,
      helpText: RichTextEditor.createEmptyValue(),
      udf: undefined
    }
    : {
      ...prompt,
      helpText: prompt.helpText
        ? RichTextEditor.createValueFromString(prompt.helpText, 'markdown')
        : RichTextEditor.createEmptyValue()
    }

  const _setPromptOptions = (newPromptOptions: PromptOption[]) => {
    setPromptOptions(newPromptOptions)
    setHasNoneOption(newPromptOptions.some(o => o.value === 'N/A' && !o.isDeleted))
  }

  useEffect(() => {
    form.resetFields()
    if (!isCreateMode) {
      _setPromptOptions(JSON.parse(JSON.stringify(prompt.options)))
      setPromptType(prompt.type)
      setInactive(prompt.inactive)
    } else {
      _setPromptOptions([])
    }
    const cloneUdfs: ProjectorUdf[] = [...udfs]
    if (prompt !== undefined && prompt.udf !== undefined) {
      cloneUdfs.push(prompt.udf)
    }
    setFilteredUdfs([
      { id: 0, name: 'None', isDeleted: false },
      ...cloneUdfs.sort((a, b) => a.name.localeCompare(b.name))
    ])
    if (!isCreateMode && !(prompt.practiceId === undefined || prompt.practiceId === null)) {
      const find = practices.find(p => p.id === prompt.practiceId)
      if (find !== undefined && find.inactive === true) {
        setFilteredPractices([
          { id: 0, name: 'None', abbrev: 'None', description: '', displayOrder: 0, inactive: false },
          ...practices
        ])
        return
      }
    }
    setFilteredPractices([
      { id: 0, name: 'None', abbrev: 'None', description: '', displayOrder: 0, inactive: false },
      ...practices.filter(p => p.inactive === false)
    ])

  }, [prompt, onSubmit])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault()

    form.validateFields((err: any, values: any) => {
      if (err) return

      let options = promptOptions.filter((opt: PromptOption) => !opt.isDeleted)
      options.forEach((o: PromptOption, idx) => {
        o.displayOrder = idx + 1
        if (newOptionIds.includes(o.id)) o.id = ''
      })

      const newPrompt: Prompt = {
        id: isCreateMode ? null : prompt.id,
        question: values.question,
        description: values.description,
        type: values.type,
        inactive: inactive,
        displayOrder: isCreateMode ? -1 : prompt.displayOrder,
        options: options,
        personal: values.personal,
        category: values.category,
        helpText: values.helpText.toString('markdown')
      }

      if (values.practice > 0) {
        newPrompt.practiceId = values.practice
      }

      if (values.udf > 0) {
        newPrompt.udf = { id: values.udf, name: '', isDeleted: false }
      }

      if (values.category > 0) {
        newPrompt.category = promptCategory;
      }

      if (isCreateMode) {
        createPrompt(newPrompt)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
      } else if (newPrompt.id !== null) {
        updatePrompt(newPrompt.id, newPrompt)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
      }
    })
  }

  const _togglePromptActivation = () => {
    if (prompt && prompt.id !== null)
      togglePromptActivation(prompt.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification)
  }

  const moveRow = (fromIndex: number, toIndex: number) => {
    if (inactive) return
    const optionMoving = promptOptions[fromIndex]
    promptOptions.splice(fromIndex, 1)
    promptOptions.splice(toIndex, 0, optionMoving)
    promptOptions.forEach((o, idx) => (o.displayOrder = idx + 1))
    _setPromptOptions([...promptOptions])
  }

  const handleNoneOptionChange = () => {
    const noneOptions = promptOptions.filter(o => o.value === 'N/A')
    let editedOptions = promptOptions
    if (noneOptions.length) {
      noneOptions.forEach(noneOption => {
        if (newOptionIds.includes(noneOption.id)) {
          const noneOptionIdx = editedOptions.indexOf(noneOption)
          editedOptions = [...editedOptions.slice(0, noneOptionIdx), ...editedOptions.slice(noneOptionIdx + 1)]
        } else {
          noneOption.isDeleted = hasNoneOption
        }
      })
    } else {
      editedOptions.push({
        id: getNewOptionId(),
        response: 'None',
        value: 'N/A',
        displayOrder: editedOptions.length + 1,
        isDeleted: false
      })
    }
    _setPromptOptions(editedOptions)
  }

  const getNewOptionId = () => {
    const newOptionId = `tempId_${newOptionIds.length}`
    setNewOptionIds([...newOptionIds, newOptionId])
    return newOptionId
  }

  const handleCheckboxChange = (checkedValues) => {
    setPromptCategory(new Set(checkedValues))
  };

  return (
    <div style={{ minWidth: '450px' }}>
      <h2>{isCreateMode ? 'Create' : 'Edit'} Prompt</h2>
      <Form onSubmit={handleSubmit} className="editPromptForm">
        <Form.Item label="Question" required>
          {getFieldDecorator('question', {
            initialValue: initialValues.question,
            rules: [{ required: true, message: 'Question is a required field' }]
          })(<Input disabled={inactive} />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: initialValues.description
          })(<Input.TextArea disabled={inactive} rows={4} />)}
        </Form.Item>
        <Form.Item label="Type" required>
          {getFieldDecorator('type', {
            initialValue: initialValues.type,
            rules: [{ required: true, message: 'Type is a required field' }]
          })(
            <Select disabled={inactive} onChange={(type: string) => setPromptType(type)}>
              {PromptTypeOptions.map(type => {
                return (
                  <Select.Option key={type.key} value={type.key}>
                    {type.text}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
        {user.permissions.canViewProjectorUdf && features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') && (
          <Form.Item label="UDF">
            {getFieldDecorator('udf', {
              initialValue: initialValues.udf === undefined ? 0 : initialValues.udf.id
            })(
              <Select disabled={inactive && user.permissions.canManageProjectorUdf}>
                {filteredUdfs.map(u => {
                  return (
                    <Select.Option key={u.id} value={u.id}>
                      {u.name}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item label="Practice">
          {getFieldDecorator('practice', {
            initialValue: initialValues.practiceId === undefined ? 0 : initialValues.practiceId
          })(
            <Select disabled={inactive}>
              {filteredPractices.map(p => {
                return (
                  <Select.Option key={p.id} value={p.id}>
                    {p.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Category" required>
          {getFieldDecorator('category', {
            initialValue: Array.from(initialValues.category) === undefined ? [] : Array.from(initialValues.category),
            rules: [{ required: true, message: 'Category is a required field' }]
          })(
            <Checkbox.Group style={{ width: 'fit-content' }} onChange={handleCheckboxChange}>
              {Array.from(categories)
                .map(c => (
                  <Checkbox
                    key={c}
                    value={c}
                    style={{
                      display: 'block',
                      height: '30px',
                      lineHeight: '30px',
                      marginLeft: '0px',
                      paddingLeft: '5px'
                    }}
                  >
                    {c}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          )}
        </Form.Item>
        <Form.Item label="Help Text">
          {getFieldDecorator('helpText', {
            initialValue: initialValues.helpText
          })(
            // @ts-ignore
            <RichTextEditor
              disabled={inactive}
              className={'rich-text-editor'}
              toolbarConfig={richTextEditorToolbarConfig}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('personal', {
            initialValue: initialValues.personal,
            valuePropName: 'checked'
          })(
            <Checkbox disabled={inactive} style={{ marginTop: '1rem' }}>
              Should answers from this prompt remain private?
            </Checkbox>
          )}
        </Form.Item>

        {[PromptTypes.SELECT_ONE, PromptTypes.SELECT_MULTIPLE].includes(promptType) && (
          <>
            <Row className="editPromptOptionsHeader">
              <Col span={10}>
                <h3>Options</h3>
              </Col>
            </Row>
            <VerticalDragAndDrop moveRow={moveRow}>
              {promptOptions
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((opt, idx) => {
                  return (
                    <div key={opt.id} data-testid={opt.id}>
                      <Row>
                        <Col span={4} className="editPromptOptions">
                          <h4>Option {idx + 1}</h4>
                        </Col>
                        <Col span={2}>
                          <Button
                            icon={opt.isDeleted ? 'undo' : 'delete'}
                            type="link"
                            disabled={inactive}
                            aria-label={opt.isDeleted ? 'Undo' : 'Delete Option'}
                            onClick={() => {
                              if (newOptionIds.includes(opt.id)) {
                                _setPromptOptions([...promptOptions.slice(0, idx), ...promptOptions.slice(idx + 1)])
                              } else {
                                opt.isDeleted = !opt.isDeleted
                                _setPromptOptions([...promptOptions])
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={23} offset={1}>
                          <Form.Item label="Response" required>
                            {getFieldDecorator(`${opt.id}_response`, {
                              initialValue: opt.response,
                              rules: [
                                { required: true, message: 'Response is a required field' },
                                {
                                  validator: (rule: any, resp: string) => {
                                    if (
                                      promptOptions.some(o => o.response === resp && o.id !== opt.id && resp !== '')
                                    ) {
                                      return Promise.reject('Response must be unique')
                                    }
                                    return Promise.resolve()
                                  }
                                }
                              ]
                            })(
                              <Input
                                disabled={opt.isDeleted || inactive}
                                onChange={e => {
                                  promptOptions[idx].response = e.target.value
                                  _setPromptOptions([...promptOptions])
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={23} offset={1}>
                          <Form.Item label="Value" required>
                            {getFieldDecorator(`${opt.id}_value`, {
                              initialValue: opt.value,
                              rules: [
                                { required: true, message: 'Value is a required field' },
                                {
                                  validator: (rule: any, value: string) => {
                                    if (promptOptions.some(o => o.value === value && o.id !== opt.id && value !== '')) {
                                      return Promise.reject('Value must be unique')
                                    }
                                    return Promise.resolve()
                                  }
                                }
                              ]
                            })(
                              <Input
                                disabled={opt.isDeleted || inactive}
                                onChange={e => {
                                  promptOptions[idx].value = e.target.value
                                  _setPromptOptions([...promptOptions])
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
            </VerticalDragAndDrop>
            <Row>
              <Col span={6}>
                <Button
                  type="link"
                  icon="plus"
                  disabled={inactive}
                  onClick={() =>
                    _setPromptOptions([
                      ...promptOptions,
                      {
                        id: getNewOptionId(),
                        response: '',
                        value: '',
                        displayOrder: promptOptions.length + 1,
                        isDeleted: false
                      }
                    ])
                  }
                  style={{ paddingLeft: 0 }}
                >
                  New Option
                </Button>
              </Col>
            </Row>
            <Checkbox
              checked={hasNoneOption}
              onChange={() => handleNoneOptionChange()}
              disabled={inactive}
              style={{ marginTop: '1rem' }}
            >
              Include "None" Option?
            </Checkbox>
          </>
        )}
        <Row>
          <ButtonRow style={{ marginTop: '30px' }}>
            <Button type="primary" htmlType="submit" disabled={inactive}>
              Save
            </Button>
            <Button onClick={() => onCancel()}>Cancel</Button>
            {!isCreateMode && user.permissions.canDeletePrompt && (
              <Button onClick={_togglePromptActivation}>{inactive ? 'Activate' : 'Deactivate'}</Button>
            )}
          </ButtonRow>
        </Row>
      </Form>
    </div>
  )
}

export default Form.create<PromptFormProps>({ name: 'prompt_form' })(PromptForm)
