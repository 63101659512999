import { shape, number, string, oneOf, arrayOf, bool, any } from 'prop-types';

export const roleType = shape({
  duration: string,
  employeeRoleTypeId: number,
  experienceLevel: string,
  employee: any,
  interest: bool,
  lastUsedDate: string,
  roleType: shape({
    active: bool,
    description: string,
    family: string,
    id: number,
    name: string
  })
});

export const employeeType = shape({
  id: number.isRequired,
  firstName: string,
  lastName: string,
  fullName: string,
  location: string,
  roleTypes: arrayOf(roleType),
  entity: shape({
    id: number,
    name: string
  }),
  username: string,
  permissions: arrayOf(string),
  practice: string,
  title: string,
  coach: number,
  coachees: arrayOf(number)
});

export const promptOption = shape({
  id: string,
  response: string,
  value: string,
  displayOrder: number,
  isDeleted: bool
});

export const promptType = shape({
  id: number.isRequired,
  inactive: bool,
  question: string,
  type: oneOf(['SELECT_ONE', 'SELECT_MULTIPLE', 'FREE_RESPONSE']),
  options: arrayOf(promptOption)
});

export const employeePromptType = shape({
  prompt: promptType,
  promptId: number,
  response: string,
  value: string
});

export const certifyingOrganization = shape({
  id: number,
  name: string,
  description: string,
  channelPartner: bool,
  imagePath: string
});

export const certificationType = shape({
  id: number.isRequired,
  inactive: bool,
  name: string,
  certifyingOrganization: certifyingOrganization,
  requireExpiration: bool
});

export const employeeCertificationType = shape({
  id: number,
  certification: certificationType,
  certificationId: number,
  confirmationNumber: string,
  employee: employeeType,
  employeeId: number,
  expirationDate: string,
  startDate: string,
  status: oneOf(['Active', 'Inactive'])
});

export const badgeType = shape({
  id: number,
  name: string,
  description: string,
  employeesReceived: number,
  category: string,
  categoryId: number,
  dateCreated: string,
  image: string,
  inactive: bool
});
