import { get, post } from 'api'
import { Employee } from 'types/Employee'
import { EmployeeDetail } from 'types/EmployeeDetail'
import { ProjectEmployee } from 'types/ProjectEmployee'
import { Skill } from 'types/Skill'
import { ProfileCompletion } from 'types/ProfileCompletion'
import { EmployeeBasic } from 'types/EmployeeBasic'
import { EmployeeExport } from 'types/EmployeeExport'
import { AdvancedSearch } from 'types/AdvancedSearch'
import { EmployeeActiveFast } from 'types/EmployeeActiveFast'

export function getEmployee(employeeId: number): Promise<Employee> {
  return get(`api/employees/${employeeId}`);
}

export function getEmployeeDetails(employeeId: number): Promise<EmployeeDetail> {
  return get(`api/employees/${employeeId}/details`);
}

export function getEmployeeDetailsByEmail(email: string): Promise<EmployeeDetail> {
  return get(`api/employees/email/${email}/details`);
}

export function getAllEmployees(): Promise<EmployeeActiveFast[]> {
  return get("api/employees/active");
}

export function getAllEmployeesAdvancedSearch(): Promise<AdvancedSearch[]> {
  return get("api/employees/advancedsearch");
}

// TODO: do we need async/await here?
export async function getEmployeesByName(name: string): Promise<Employee[]> {
  return await get("api/employees/name", { name });
}

export function getRankedEmployees(count: number): Promise<Employee[]> {
  return get(`api/employees/badges?max=${count ? count : 20}`);
}

export function getProjects(employeeId: number): Promise<ProjectEmployee[]> {
  return get(`api/employees/${employeeId}/projects`);
}

export function getEmployeeSkills(employeeId: number): Promise<Skill[]> {
  return get(`api/employees/${employeeId}/skills`);
}

export function getEmployeeProfileCompletion(employeeId: number): Promise<ProfileCompletion> {
  return get(`api/employees/${employeeId}/profilecompletion`);
}

export function getCoachDetails(employeeId: number): Promise<EmployeeBasic> {
  return get(`api/employees/${employeeId}/coach_details`);
}

export function getCoacheeDetails(employeeId: number): Promise<EmployeeBasic> {
  return get(`api/employees/${employeeId}/coachee_details`);
}

export function synchronizeEmployeeAccounts(): Promise<void> {
  return post("api/employees/sync_active_directory");
}

export function synchronizePhotos(): Promise<void> {
  return post("api/employees/sync_photos");
}

export function postEmployeesForExport(
  ids: number[],
  showClients: boolean
): Promise<EmployeeExport[]> {
  const body = {
    ids: ids,
    showClients: showClients
  }
  return post("api/employees/export", body);
}

export function getPermissionsForRole(role: string): Promise<string[]> {
  return get(`api/employees/role/${role}`);
}
