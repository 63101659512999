import React from 'react';
import { Modal } from 'antd';
import UserAccessForm from './UserAccessForm';

const UserAccessModal = ({ user, employeeRoles, roles, modalState, onUpdate, onCancel }) => {
  const handleUpdate = () => {
    onUpdate();
    onCancel();
  };

  return (
    <Modal title={`Edit roles for ${user.employee.fullName}`} visible={modalState} onCancel={onCancel} footer={null}>
      <UserAccessForm
        employeeId={user.employee.id}
        employeeRoles={employeeRoles}
        roles={roles}
        onUpdate={handleUpdate}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default UserAccessModal;
