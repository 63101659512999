import React, { useEffect, useState, useRef } from 'react';
import { Typography, Button } from 'antd';
import ButtonRow from 'components/common/ButtonRow';
import Stack from 'components/common/Stack';
import BulkEditModal from './BulkEditModal';
import UserAccessTable from './UserAccessTable';
import { getRoles, getEmployeeRoles } from 'api/roleApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import SavePreferencesButton from 'components/common/SavePreferencesButton';

const UserAccessLandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const searchRef = useRef();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);

    getEmployeeRoles()
      .then(employeeRoles => {
        setEmployeeRoles(employeeRoles);
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus();
        }
      })
      .catch(displayErrorNotification);

    getRoles()
      .then(roles => {
        setRoles(roles);
      })
      .catch(displayErrorNotification);
  };

  return (
    <Stack>
      <Typography.Title>
        User Access
        <ButtonRow style={{ float: 'right' }}>
          <Button icon="edit" onClick={() => setShowBulkEditModal(true)}>
            Bulk Edit
          </Button>
          <SavePreferencesButton
            keys={['UserAccesLandingPage_FilterSettings', 'UserAccesLandingPage_SortSettings']}
            disabled={loading}
          />
        </ButtonRow>
      </Typography.Title>

      <BulkEditModal
        employeeRoles={employeeRoles}
        roles={roles}
        modalState={showBulkEditModal}
        onUpdate={load}
        onCancel={() => setShowBulkEditModal(false)}
      />

      <UserAccessTable
        employeeRoles={employeeRoles}
        roles={roles}
        onUpdate={load}
        loading={loading}
        searchRef={searchRef}
      />
    </Stack>
  );
};

export default UserAccessLandingPage;
