import React from 'react'
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd'
import { debounce, uniq } from 'lodash'
import { Capability } from 'types/Capability'
import { CapabilityFilterSettings } from 'types/CapabilityPreferences'
import { EmployeePractice } from 'types/EmployeePractice'
import { showPractice } from 'utils/skillDetails'

type CapabilityFiltersProps = {
  capabilities: Capability[]
  onSearch: (filteredCapabilities: Capability[]) => void
  form: any
  searchRef: any
  disabled: boolean
  settings: CapabilityFilterSettings
  setSettings: (settings: CapabilityFilterSettings) => void
  employeePractices: EmployeePractice[],
  practices: number[]
}

export const filterCapabilities = (props: CapabilityFiltersProps, changedValues: any, values: any) => {
  const { capabilities, onSearch, setSettings } = props
  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : ''
  const practices = values.selectedPractices
  const showInactive = values.showInactive

  setSettings({
    search: values.searchTerm,
    practice: practices,
    inActive: showInactive
  })

  const filteredCapabilities = capabilities.filter(capability => {
    let isNameValid =
      !uppercaseTerm || !uppercaseTerm.length || capability.name.toUpperCase().indexOf(uppercaseTerm) > -1
    let isPracticeValid = !practices || !practices.length || practices.indexOf(capability.employeePracticeId) > -1
    let isStatusValid = showInactive || !capability.inactive
    return isNameValid && isPracticeValid && isStatusValid
  })
  onSearch(filteredCapabilities)
}

const CapabilityFilters = (props: CapabilityFiltersProps) => {
  const { capabilities = [], form, disabled, settings, searchRef, employeePractices = [], practices = [] } = props
  const { getFieldDecorator } = form
  const selectedPractices = form.getFieldValue('selectedPractices') || []

  const clearFilters = () => {
    form.resetFields()
    filterCapabilities(
      props,
      {},
      {
        searchTerm: '',
        selectedPractices: [],
        showInactive: false
      }
    )
  }

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedPractices', {
            initialValue: settings.practice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPractices.length} Practice${selectedPractices.length === 1 ? '' : 's'
                } Selected`}
              showArrow
              dropdownStyle={{minWidth: "300px"}}
              mode="multiple"
              placeholder="Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {practices.map(p => (
                <Select.Option key={p} value={p}>
                  {showPractice(employeePractices,p)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showInactive', {
            initialValue: settings.inActive,
            valuePropName: 'checked'
          })(<Checkbox>Show Inactive</Checkbox>)}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Form.create<CapabilityFiltersProps>({
  name: 'filter_capabilities_form',
  onValuesChange: debounce(filterCapabilities, 500)
})(CapabilityFilters)
