export interface PromptFilterSettings {
  search: string;
  practice: string[];
  position: string[];
  location: string[];
  responseSearch: string;
  responseDropdown: string[];
  responseBirthday: string[];
}

export function defaultPromptFilterSettings(): PromptFilterSettings {
  return {
    search: "",
    practice: [],
    position: [],
    location: [],
    responseSearch: "",
    responseDropdown: [],
    responseBirthday: [],
  };
}
