import { get, post, del } from 'api';
import { ProjectExperienceDetail } from 'types/ProjectExperienceDetail';
import { ProjectExperience } from 'types/ProjectExperience';
import { SkillProjectAPI } from 'types/SkillProject';

export const getProjectExperiences: () => Promise<ProjectExperienceDetail[]> = () => get('api/projectexperience');

export const getProjectExperienceByProjectId: (projectId: number) => Promise<ProjectExperienceDetail[]> = projectId =>
  get(`api/projectexperience/${projectId}`);

export const getProjectExperienceBySkillId: (skillId: number) => Promise<SkillProjectAPI[]> = skillId =>
  get(`api/projectexperience/skills/${skillId}`);

export const getProjectExperienceByEmployeeSkillId: (
  employeeSkillId: number
) => Promise<ProjectExperienceDetail[]> = employeeSkillId =>
  get(`api/projectexperience/employees/skills/${employeeSkillId}`);

export const addProjectExperience: (
  projectExperience: ProjectExperience
) => Promise<ProjectExperience> = projectExperience => post(`api/projectexperience`, projectExperience);

export const deleteProjectExperienceById: (projectExperienceId: number) => Promise<any> = projectExperienceId => {
  return del(`api/projectexperience/${projectExperienceId}`);
};
