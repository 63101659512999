import {Button, Form, Input, InputNumber, Row, Select, Spin} from "antd";
import {
  createEmployeeLocation,
  deleteEmployeeLocation,
  toggleEmployeeLocationActivation,
  updateEmployeeLocation
} from "api/employeeLocationApi";
import {useUser} from "auth/UserHooks";
import ButtonRow from "components/common/ButtonRow";
import React, {useEffect, useState} from "react";
import {EmployeeLocation} from "types/EmployeeLocation";
import displayErrorNotification from "utils/displayErrorNotification";
import "./EmployeeLocationForm.css";
import {EmployeeRegion} from "types/EmployeeRegion";

type EmployeeLocationFormProps = {
  location?: EmployeeLocation
  regions: EmployeeRegion[]
  onSubmit: Function
  onCancel: Function
  loading: boolean
  setLoading: Function
  form: any
}

const EmployeeLocationForm = ({
                                location,
                                regions,
                                onSubmit,
                                onCancel,
                                loading,
                                setLoading,
                                form
                              }: EmployeeLocationFormProps) => {
  const user = useUser();
  const isCreateMode = !location;
  const [inactive, setInactive] = useState<boolean>(false);
  const {getFieldDecorator} = form;
  const initialValues = isCreateMode
    ? {
      name: "",
      region: null,
      displayOrder: null,
      inactive: false
    }
    : {...location};


  useEffect(() => {
    form.resetFields();
    if (!isCreateMode) {
      setInactive(location.inactive);
    }
  }, [location, onSubmit]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (err) return;

      const newLocation: EmployeeLocation = {
        id: isCreateMode ? 0 : location.id,
        name: values.name,
        region: values.region,
        displayOrder: values.displayOrder,
        inactive: isCreateMode ? false : location.inactive
      };
      setLoading(true);
      if (isCreateMode) {
        createEmployeeLocation(newLocation)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
          .then(() => setLoading(false));
      } else if (newLocation.id !== null) {
        updateEmployeeLocation(newLocation.id, newLocation)
          .then(() => onSubmit())
          .catch(displayErrorNotification)
          .then(() => setLoading(false));
      }
    });
  };

  const _toggleLocationActivation = () => {
    if (location && location.id !== null)
      toggleEmployeeLocationActivation(location.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  const _deleteLocation = () => {
    if (location && location.id !== null)
      deleteEmployeeLocation(location.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  return (
    <div style={{minWidth: "450px"}}>
      <h2>{isCreateMode ? "Create" : "Edit"} Employee Location</h2>
      <Spin spinning={loading}>
        <Form onSubmit={handleSubmit} className="editLocationForm">
          <Form.Item label="Name" required>
            {getFieldDecorator("name", {
              initialValue: initialValues.name,
              rules: [{required: true, message: "Name is a required field"}]
            })(<Input disabled={inactive} maxLength={50}/>)}
          </Form.Item>
          <Form.Item label="Employee Region">
            {getFieldDecorator("region", {
              initialValue: initialValues.region,
              rules: [{required: true, message: "Employee Region is a required field"}]
            })(
              <Select disabled={inactive} placeholder="Employee Region">
                {regions.map(e => {
                  return (
                    <Select.Option key={e.id} value={e.id}>
                      {e.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Display Order">
            {getFieldDecorator("displayOrder", {
              initialValue: initialValues.displayOrder
            })(<InputNumber disabled={inactive} min={1} max={32767}/>)}
          </Form.Item>
          <Row>
            <ButtonRow style={{marginTop: "30px"}}>
              <Button type="primary" htmlType="submit" disabled={inactive}>
                Save
              </Button>
              <Button onClick={() => onCancel()}>Cancel</Button>
              {!isCreateMode && (
                <Button onClick={_toggleLocationActivation}>{inactive ? "Activate" : "Deactivate"}</Button>
              )}
              {!isCreateMode && inactive && user.permissions.canManageEmployeeLocation && (
                <Button type="danger" onClick={_deleteLocation}>
                  Delete
                </Button>
              )}
            </ButtonRow>
          </Row>
        </Form></Spin>

    </div>
  );
};

export default Form.create<EmployeeLocationFormProps>({name: "roletypefamily_form"})(EmployeeLocationForm);
