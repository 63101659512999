import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import ProjectExperienceAvatar from '../projects/ProjectExperienceAvatar';
import { Skill } from 'types/Skill';
import { Experience } from 'types/Experience';

export type SkillMap = Skill & { experiences: Experience[] };

const ProjectSkillCard = ({ skill }: { skill: SkillMap }) => {
  return (
    <Card className="avatar-card" hoverable={true}>
      <Link to={`/skills/${skill.id}`}>
        <Card.Meta
          title={skill.name}
          description={
            <span>
              {skill.category.name}
              {!isEmpty(skill.parentSkill) && ` / ${skill.parentSkill.name}`}
            </span>
          }
        />
      </Link>
      <Card.Meta
        description={
          <div style={{ marginTop: '1.5em', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <ProjectExperienceAvatar experience={skill.experiences} />
          </div>
        }
      />
    </Card>
  );
};

ProjectSkillCard.propTypes = {
  skill: PropTypes.object
};

export default ProjectSkillCard;
