import React from 'react';

const SplitPageHeaderLayout = ({ children }) => {
  return <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>{children}</div>;
};

const SplitPageHeaderLayoutLeft = ({ children }) => {
  return <div style={{ width: '100%' }}>{children}</div>;
};

const SplitPageHeaderLayoutRight = ({ children }) => {
  return <div style={{ flexBasis: 400, textAlign: 'right' }}>{children}</div>;
};

export default { Layout: SplitPageHeaderLayout, Left: SplitPageHeaderLayoutLeft, Right: SplitPageHeaderLayoutRight };
