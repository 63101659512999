import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProfileCompletion } from '../../redux';
import './ProfileCompletion.css';
import { Progress, Row, Col, Typography } from 'antd';

function ProfileCompletion({ profileCompletionData, fetchProfileCompletion, id }) {
  useEffect(() => {
    fetchProfileCompletion(id);
  }, []);

  return (
    !profileCompletionData.loading &&
    profileCompletionData.data && (
      <Row className="profile-completion">
        <Col span={8}>
          <div className="percentage-text">
            <b>Profile Strength: </b>
          </div>
          <Progress percent={profileCompletionData.data.profileCompletion} strokeColor="#496a86" />
          {profileCompletionData.data.profileCompletion === 100 && (
            <Typography.Title level={4} type="secondary">
              Congrats on a strong profile! Keep it updated to help staffers match you with the best role for your skill
              set.
            </Typography.Title>
          )}
        </Col>
        {profileCompletionData.data.profileCompletion < 100 && (
          <Col span={15} offset={1} className="percentage-text">
            <Typography.Title level={4} type="secondary">
              &nbsp;
            </Typography.Title>
            {Object.keys(profileCompletionData.data.remainingItems).map(index => {
              return <div key={index}> - {profileCompletionData.data.remainingItems[index]}</div>;
            })}
            {Object.keys(profileCompletionData.data.completedItems).map(index => {
              return (
                <div style={{ textDecorationLine: 'line-through' }} key={index}>
                  {' '}
                  - {profileCompletionData.data.completedItems[index]}
                </div>
              );
            })}
          </Col>
        )}
      </Row>
    )
  );
}

const mapStateToProps = state => {
  return {
    profileCompletionData: state.profileCompletion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProfileCompletion: id => dispatch(fetchProfileCompletion(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileCompletion);
