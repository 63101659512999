import React, { useEffect, useState } from 'react';
import { Form, Select, Button, DatePicker, Input } from 'antd';
import { getCertifications, addEmployeeCertification, updateEmployeeCertification } from 'api/employeeCertificationApi';
import { getEmployeeDetails } from 'api/employeeApi';
import { getAllCertifyingOrganizations } from 'api/certifyingOrganizationApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import ButtonRow from 'components/common/ButtonRow';
import PropTypes from 'prop-types';
import moment from 'moment';

const EmployeeCertificationForm = ({
  employeeId,
  employeeCertification,
  setEmployeeCertification,
  form,
  onSubmit,
  onCancel,
  isCreateMode,
  setIsCreateMode,
  modalState,
  certStack,
  setCertStack
}) => {
  const [certifications, setCertifications] = useState([]);
  const [filteredCerts, setFilteredCerts] = useState([]);
  const [certifyingOrgs, setCertifyingOrgs] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [overlappingCerts, setOverlappingCerts] = useState([]);
  const [needToChooseCert, setNeedToChooseCert] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isFromStack, setIsFromStack] = useState(false);
  const { getFieldDecorator, getFieldValue, resetFields } = form;
  const selectedCertification = certifications.find(c => c.id === getFieldValue('certificationId'));

  useEffect(() => {
    form.resetFields();
    getCertifications()
      .then(setCertifications)
      .catch(displayErrorNotification);
    getAllCertifyingOrganizations()
      .then(setCertifyingOrgs)
      .catch(displayErrorNotification);
    setInitialValues(
      Object.keys(employeeCertification).length === 0 || (isCreateMode && !isFromStack)
        ? { certifyingOrganizationId: null }
        : {
            ...employeeCertification,
            certifyingOrganizationId: employeeCertification.certifyingOrgId
              ? employeeCertification.certifyingOrgId
              : employeeCertification.certification.certifyingOrganization.id,
            startDate: employeeCertification.startDate ? moment(employeeCertification.startDate) : null,
            expirationDate: employeeCertification.expirationDate ? moment(employeeCertification.expirationDate) : null,
            certificationId: employeeCertification.certificationId,
            confirmationNumber: employeeCertification.confirmationNumber
          }
    );
  }, [isCreateMode, employeeCertification]);

  useEffect(() => {
    getEmployeeDetails(employeeId).then(result => {
      if (isCreateMode && !isFromStack) {
        setEmployeeDetails(result.certifications);
      } else {
        setEmployeeDetails(result.certifications.filter(cert => cert.id !== employeeCertification.id));
      }
    });
  }, [modalState, employeeCertification, isCreateMode]);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) return;

      const data = {
        ...values,
        startDate: values.startDate.format('YYYY-MM-DD'),
        expirationDate: values.expirationDate ? values.expirationDate.format('YYYY-MM-DD') : null
      };
      const overlapsOldCert = checkOverlap(values.certificationId, values.startDate, values.expirationDate);
      setShowWarning(overlapsOldCert);
      if (!overlapsOldCert) {
        if (isCreateMode) {
          addEmployeeCertification(employeeId, data)
            .then(handleAfterSubmit)
            .catch(displayErrorNotification);
        } else {
          updateEmployeeCertification(employeeCertification.id, data)
            .then(handleAfterSubmit)
            .catch(displayErrorNotification);
        }
      } else {
        const saveCert = {
          ...data,
          isNew: isCreateMode,
          id: isCreateMode ? 0 : employeeCertification.id
        };
        certStack.push(saveCert);
      }
    });
  };

  const handleAfterSubmit = () => {
    if (certStack != null && certStack.length > 0) {
      setIsFromStack(true);
      const _certification = certStack.pop();
      setEmployeeCertification(_certification);
      setIsCreateMode(_certification.isNew);
    } else {
      onSubmit();
      setIsFromStack(false);
      setIsCreateMode(true);
      setEmployeeCertification({});
    }
    form.resetFields();
  };

  const handleAttemptToEdit = overlappingCerts => {
    if (overlappingCerts.length === 1) {
      handleTransferToEditMode(overlappingCerts[0]);
    } else {
      setNeedToChooseCert(true);
    }
  };

  const handleCancelEdit = () => {
    onCancel();
    setShowWarning(false);
    setNeedToChooseCert(false);
    setCertStack([]);
    setIsFromStack(false);
  };

  const handleCancel = () => {
    onCancel();
    setCertStack([]);
    setIsFromStack(false);
  };

  const handleTransferToEditMode = certification => {
    setIsCreateMode(false);
    setShowWarning(false);
    setNeedToChooseCert(false);
    setOverlappingCerts([]);
    form.resetFields();
    setEmployeeCertification(certification);
  };

  const checkOverlap = (certId, start, end) => {
    const localOverlappingCerts = employeeDetails.filter(element => {
      return (
        element.certificationId === certId &&
        Date.parse(element.startDate + ' 00:00:00 GMT') <= Date.parse(end.format('YYYY-MM-DD') + ' 00:00:00 GMT') &&
        Date.parse(start.format('YYYY-MM-DD') + ' 00:00:00 GMT') <= Date.parse(element.expirationDate + ' 00:00:00 GMT')
      );
    });
    var hasOverlaps = localOverlappingCerts != null && localOverlappingCerts.length !== 0;
    if (hasOverlaps) {
      setOverlappingCerts(localOverlappingCerts);
    }
    return hasOverlaps;
  };

  const handleCertifyingOrgChange = certifyingOrgId => {
    resetFields(['certificationId', 'expirationDate']);
    setFilteredCerts(certifications.filter(c => c.certifyingOrganization.id === certifyingOrgId));
  };

  return !showWarning ? (
    <Form layout="vertical" style={{ maxWidth: '40em' }} onSubmit={handleSubmit}>
      <Form.Item label="Certifying Organization" required>
        {getFieldDecorator('certifyingOrgId', {
          initialValue: initialValues.certifyingOrganizationId,
          rules: [{ required: true, message: 'Certifying Organization is a required field' }]
        })(
          <Select
            showSearch
            allowClear
            placeholder="Select a Certifying Organization"
            optionFilterProp="children"
            disabled={!isCreateMode}
            onChange={handleCertifyingOrgChange}
            filterOption={(inputValue, option) =>
              option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          >
            {certifyingOrgs.map(certifyingOrg => (
              <Select.Option key={certifyingOrg.id} value={certifyingOrg.id}>
                {certifyingOrg.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Certification" required>
        {getFieldDecorator('certificationId', {
          initialValue: initialValues.certificationId,
          rules: [{ required: true, message: 'Certification is a required field' }]
        })(
          <Select
            showSearch
            allowClear
            placeholder="Select a Certification"
            optionFilterProp="children"
            disabled={!isCreateMode}
            filterOption={(inputValue, option) =>
              option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          >
            {filteredCerts.map(cert => (
              <Select.Option key={cert.id} value={cert.id}>
                {cert.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Confirmation Number" required>
        {getFieldDecorator('confirmationNumber', {
          initialValue: initialValues.confirmationNumber,
          rules: [{ required: true, message: 'Confirmation Number is a required field' }]
        })(<Input />)}
      </Form.Item>

      <div style={{ display: 'flex' }}>
        <Form.Item label="Active Starting" required>
          {getFieldDecorator('startDate', {
            initialValue: initialValues.startDate,
            rules: [{ required: true, message: 'Starting Date is a required field' }]
          })(<DatePicker format={'MMM D, YYYY'} />)}
        </Form.Item>

        <Form.Item
          label="Expiration Date"
          style={{ marginLeft: '1em' }}
          required={selectedCertification && selectedCertification.requireExpiration}
        >
          {getFieldDecorator('expirationDate', {
            initialValue: initialValues.expirationDate,
            rules: [
              {
                required: selectedCertification && selectedCertification.requireExpiration,
                message: 'Expiration Date is a required field'
              }
            ]
          })(<DatePicker format={'MMM D, YYYY'} />)}
        </Form.Item>
      </div>

      <ButtonRow style={{ marginTop: '1em' }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </ButtonRow>
    </Form>
  ) : needToChooseCert ? (
    <div>
      You have multiple overlaps with certification:{' '}
      {certifications.find(cert => cert.id === overlappingCerts[0].certificationId).name}
      <br />
      Please select one existing certification to edit.
      <br />
      {overlappingCerts.map(cert => {
        return (
          <Button key={cert.id} onClick={() => handleTransferToEditMode(cert)}>
            {'Start Date: ' + cert.startDate + ', End Date: ' + cert.expirationDate}
          </Button>
        );
      })}
    </div>
  ) : (
    <div>
      This certification overlaps with{' '}
      {overlappingCerts.length === 1 ? 'an existing certification' : 'existing certifications'} of the same type. To
      prevent double counting of certifications, certification dates may not overlap.
      <br />
      Confirmation Number{overlappingCerts.length === 1 ? '' : 's'}:{' '}
      {overlappingCerts
        .map(cert => cert.confirmationNumber)
        .join(', ')
        .toString()}
      <br />
      Start Date{overlappingCerts.length === 1 ? '' : 's'}: {overlappingCerts.map(cert => cert.startDate).join(', ')}
      <br />
      End Date{overlappingCerts.length === 1 ? '' : 's'}: {overlappingCerts.map(cert => cert.expirationDate).join(', ')}
      <br />
      Click edit existing cert below to update the existing record or click cancel to adjust the information on your new
      certification record.
      <ButtonRow>
        <Button type="primary" onClick={() => handleAttemptToEdit(overlappingCerts)}>
          Edit Existing
        </Button>
        <Button onClick={() => handleCancelEdit()}>Cancel</Button>
      </ButtonRow>
    </div>
  );
};

EmployeeCertificationForm.propTypes = {
  employeeCertification: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: 'employee_certification_form' })(EmployeeCertificationForm);
