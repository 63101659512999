export interface ClientIndustryReportFilterSettings {
  search: string;
  practice: string[];
  position: string[];
  office: string[];
  clientIndustry: string[];
}

export function defaultClientIndustryReportFilterSettings(): ClientIndustryReportFilterSettings {
  return {
    search: '',
    practice: [],
    position: [],
    office: [],
    clientIndustry: []
  };
}