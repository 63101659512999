import { get } from 'api';
import { CareerCoach } from 'types/CareerCoach';
import { EmployeeCompletionAPI } from 'types/EmployeeCompletion';
import { EmployeeWithPermissions } from 'auth/UserHooks';
import { Report } from 'types/Report';
import { ProjectorEntity } from '../types/ProjectorEntity';
import { IndustryExperienceAPI } from 'types/IndustryExperience';
import { ClientIndustryAPI } from 'types/ClientIndustry';

export function getCompletionReport(): Promise<EmployeeCompletionAPI[]> {
  return get('api/reports/completion');
}

export function getCareerCoachReport(): Promise<CareerCoach[]> {
  return get('api/reports/career_coaches');
}

export function getProjectorEntityReport(): Promise<ProjectorEntity> {
  return get('api/reports/unmapped_projector_entities');
}

export function getIndustryExperienceReport(): Promise<IndustryExperienceAPI[]> {
  return get('api/reports/industry_experience');
}

export function getClientIndustryReport(): Promise<ClientIndustryAPI[]> {
  return get('api/reports/client_industry');
}

const reports: Report[] = [
  {
    id: 1,
    path: 'career_coaches',
    name: 'Career Coach Report',
    description: 'Career coach assignments for all Credera employees',
    permission: 'canReadReports'
  },
  {
    id: 2,
    path: 'completion',
    name: 'Completion Report',
    description: 'Number of skills, certification, project roles and prompt responses for every active employee',
    permission: 'canReadManagerReports'
  },
  {
    id: 3,
    path: 'unmapped_projector_entities',
    name: 'Unmapped Projector Entities',
    description: 'Active entities in Scout that are not mapped to a Projector Skill',
    permission: 'canReadManagerReports'
  },
  /*{
    id: 4,
    path: 'industry_experience',
    name: 'Industry Experience Report',
    description: 'Industry experience prompt responses for every active employee',
    permission: 'canReadManagerReports'
  }, */ //disabled until it can use skills data instead of prompt response
  {
    id: 5,
    path: 'client_industry',
    name: 'Client Industry Report',
    description: 'Client names and industries from projects worked on by every active employee',
    permission: 'canReadManagerReports'
  }
];

export function getReportsForUser(user: EmployeeWithPermissions): Report[] {
  return reports.filter(r => user.permissions[r.permission]);
}

export function getReportById(reportId: number): Report | undefined {
  return reports.find(e => e.id === reportId);
}
