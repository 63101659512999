import { get, post, del } from 'api';
import { EmployeeBadge } from 'types/EmployeeBadge';

export function getEmployeeBadgesForCategoryId(categoryId: number, csv: boolean): Promise<EmployeeBadge[]> {
  return get(`api/badges/categories/${categoryId}/employees`, { csv });
}

export function issueBadgeToEmployees(badgeId: number, employeeIds: number[]): Promise<EmployeeBadge[]> {
  return post(`api/badges/${badgeId}/employees`, employeeIds);
}

export function revokeBadgeFromEmployees(badgeId: number, employeeIds: number[]): Promise<number[]> {
  return del(`api/badges/${badgeId}/employees`, { employeeIds });
}

export function assignBadgeToEmployees(badgeId: number, employeeIds: number[]): Promise<EmployeeBadge[]> {
  return post(`api/badges/${badgeId}/employees/assignments`, employeeIds);
}

export function unassignBadgeFromEmployees(badgeId: number, employeeIds: number[]): Promise<number[]> {
  return del(`api/badges/${badgeId}/employees/assignments`, { employeeIds });
}
