import React from 'react';
import Chart from 'react-google-charts';
import './ClientProjectTimeline.css';

const ClientProjectTimeline = ({ projects }) => {
  const data = projects.map(p => [p.name, new Date(p.startDate), new Date(p.endDate)]).sort((a, b) => b[2] - a[2]);
  const height = new Set(projects.map(p => p.name)).size * 45;

  return (
    <Chart
      width={'100%'}
      height={height}
      chartType="Timeline"
      loader={<div>Loading Chart</div>}
      data={[[{ type: 'string', id: 'Project' }, { type: 'date', id: 'Start' }, { type: 'date', id: 'End' }], ...data]}
      options={{
        timeline: {
          colorByRowLabel: true,
          showBarLabels: false,
          barLabelStyle: {
            fontSize: 10
          }
        },
        fontName: 'Segoe UI',
        colors: ['#E55F4C', '#1B998B', '#8F2D56', '#FBB13C', '#044B7F', '#F46036', '#413C5E']
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};

export default ClientProjectTimeline;
