import { post, get, put } from 'api';
import { CertifyingOrganization } from 'types/CertifyingOrganization';
import { CertifyingOrganizationDetail } from 'types/CertifyingOrganizationDetail';

export function getAllCertifyingOrganizations(): Promise<CertifyingOrganization[]> {
  return get('api/certifying_organizations');
}

export function createCertifyingOrganization(
  newCertifyingOrganization: CertifyingOrganization
): Promise<CertifyingOrganization> {
  return post('api/certifying_organizations', newCertifyingOrganization);
}

export function getCertifyingOrganizationById(id: number): Promise<CertifyingOrganizationDetail> {
  return get(`api/certifying_organizations/${id}`);
}

export function updateCertifyingOrganization(
  id: number,
  certifyingOrganization: CertifyingOrganization
): Promise<CertifyingOrganization> {
  return put(`api/certifying_organizations/${id}`, certifyingOrganization);
}
