import moment from 'moment';
import _ from 'lodash';

export const getCurrentPeriod = periods => {
  const now = moment().startOf('day');
  let currentPeriod = _.find(periods, p => {
    return now.isSameOrAfter(moment(p.startDate)) && now.isSameOrBefore(moment(p.endDate));
  });

  if (!currentPeriod) {
    currentPeriod = _.last(_.sortBy(periods, [p => moment(p.endDate).valueOf()]));
  }

  return currentPeriod ? currentPeriod : {};
};

export const formatMonths = months => {
  const years = Math.floor(months / 12);
  const remMonths = months % 12;

  if (months > 12) {
    return `${years} ${years > 1 ? 'years' : 'year'}, ${remMonths} ${remMonths > 1 ? 'months' : 'month'}`;
  } else {
    return `${months} ${months > 1 ? 'months' : 'month'}`;
  }
};
