import React, { useEffect, useState } from "react";
import displayErrorNotification from "utils/displayErrorNotification";
import { getEmployeePrompts } from "api/employeePromptApi";
import { Tag } from "antd";
import CardCollapse from "components/common/collapse/CardCollapse";
import PromptCard from "components/preferences/PromptCard";
import PromptCardModal from "components/preferences/PromptCardModal";
import { fetchProfileCompletion } from "../../redux";
import store from "../../redux/store";

const EmployeePromptPanel = ({ employeeId, isMe, isCoach, category = '', callback }) => {
  const [prompts, setPrompts] = useState([]);
  const [isModalHidden, setIsModalHidden] = useState(false);
  const [modalPrompt, setModalPrompt] = useState(null);
  const [modalEmployeePromptValue, setModalEmployeePromptValue] = useState("");

  useEffect(() => {
    _refreshEmployeePreferences();
  }, []);

  const _refreshEmployeePreferences = () => {
    setIsModalHidden(false);
    setModalPrompt(null);
    setModalEmployeePromptValue("");

    getEmployeePrompts(employeeId)
      .then(resp => {
        setPrompts(resp);

        if (callback) {
          const categoryPrompts = resp.filter(p => p.prompt.category?.includes(category));
          const answeredPrompts = categoryPrompts.filter(cp => cp.response).length;
          callback(
            <Tag
              color={answeredPrompts === categoryPrompts.length ? "" : "red"}
            >{`${answeredPrompts} of ${categoryPrompts.length} Answered`}</Tag>
          );
        }
      })
      .catch(displayErrorNotification);
  };

  const _handleChangeModal = prompt => {
    if (prompt) {
      setModalPrompt(prompt.prompt);
      setModalEmployeePromptValue(prompt.value);
    }
    setIsModalHidden(!isModalHidden);
  };

  const closeModal = () => {
    setModalPrompt(null);
    setModalEmployeePromptValue("");
    setIsModalHidden(false);
  };

  return (
    <>
      {prompts.length > 0 &&
        prompts.map(prompt =>
          prompt.prompt.category?.includes(category) ? (
            <CardCollapse.Card key={prompt.prompt.id}>
              <PromptCard
                EmplPrompt={prompt}
                onSelect={() => _handleChangeModal(prompt)}
              />
            </CardCollapse.Card>
          ) : null
        )}
      <PromptCardModal
        modalState={isModalHidden}
        prompt={modalPrompt}
        employeePromptValue={modalEmployeePromptValue}
        employeeId={employeeId}
        closeModal={closeModal}
        category={category}
        onSubmit={() => {
          _refreshEmployeePreferences();
          store.dispatch(fetchProfileCompletion(employeeId));
        }}
      />
    </>
  );
};

export default EmployeePromptPanel;