import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Select
} from "antd";
import { chain, debounce, forOwn, intersection } from "lodash";
import moment from "moment";
import { defaultEmployeeFilterSettings } from "types/EmployeePreferences";
import { EMPLOYEE_ACTIVE_DELIMITER } from "config";
import sorters from "utils/sorters";

export const filterEmployees = (props, changedValues, values) => {
  const { employees, onSearch, setSettings, settings } = props;

  const searchTerm =
    values.searchTerm != null ? values.searchTerm : settings.search;
  const uppercaseTerm = searchTerm ? searchTerm.toUpperCase() : "";
  const practices = values.selectedPractices || settings.practice;
  const businessUnits = values.selectedBusinessUnits || settings.businessUnit;
  const positions = values.selectedPositions || settings.position;
  const levels = values.selectedLevels || settings.level;
  const regions = values.selectedRegions || settings.region;
  const locations = values.selectedLocations || settings.location;
  const entities = values.selectedEntities || settings.entity;
  const majors = values.selectedMajors || settings.major;
  const minors = values.selectedMinors || settings.minor;
  const clients = values.selectedClients || settings.client;
  const projects = values.selectedProjects || settings.project;
  const hours = values.selectedHours || settings.hours;
  const endDate = values.selectedEndDate || settings.endDate;

  setSettings({
    search: searchTerm,
    practice: practices,
    businessUnit: businessUnits,
    position: positions,
    level: levels,
    region: regions,
    location: locations,
    entity: entities,
    major: majors,
    minor: minors,
    client: clients,
    project: projects,
    hours: hours,
    endDate: endDate
  });

  const filteredEmployees = employees.filter(e => {
    const clientNames = chain(e.clients.split(EMPLOYEE_ACTIVE_DELIMITER))
      .map(client => client.trim())
      .filter(client => client !== "")
      .uniq()
      .sort()
      .value();
    const projectNames = chain(e.projects.split(EMPLOYEE_ACTIVE_DELIMITER))
      .map(project => project.trim())
      .filter(project => project !== "")
      .uniq()
      .sort()
      .value();

    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      e.fullName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      e.staffingPreferences.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isPracticeValid =
      !practices || !practices.length || practices.indexOf(e.practice) > -1;
    let isBusinessUnitValid =
      !businessUnits || !businessUnits.length || businessUnits.indexOf(e.businessUnit) > -1;
    let isPositionValid =
      !positions || !positions.length || positions.indexOf(e.title) > -1;
    let isLevelValid =
      !levels || !levels.length || levels.indexOf(e.level) > -1;
    let isRegionValid =
      !regions || !regions.length || regions.indexOf(e.region) > -1;
    let isLocationValid =
      !locations || !locations.length || locations.indexOf(e.location) > -1;
    let isEntityValid =
      !entities || !entities.length || entities.indexOf(e.entity) > -1;
    let isMajorValid =
      !majors || !majors.length || majors.indexOf(e.major) > -1;
    let isMinorValid =
      !minors || !minors.length || minors.indexOf(e.minor) > -1;
    let isClientValid =
      !clients ||
      !clients.length ||
      intersection(clients, clientNames).length > 0;
    let isProjectValid =
      !projects ||
      !projects.length ||
      intersection(projects, projectNames).length > 0;
    let isDateValid = !endDate || moment(e.endDate).isSameOrBefore(endDate);
    let areHoursValid = !hours || 40 - e.hours >= hours;

    return (
      isNameValid &&
      isPracticeValid &&
      isBusinessUnitValid &&
      isPositionValid &&
      isLevelValid &&
      isRegionValid &&
      isLocationValid &&
      isEntityValid &&
      isMajorValid &&
      isMinorValid &&
      isClientValid &&
      isProjectValid &&
      isDateValid &&
      areHoursValid
    );
  });
  onSearch(filteredEmployees);
};

const EmployeeFilters = ({
  employees = [],
  onSearch,
  form,
  handleCheck,
  extraInfoHidden,
  advancedSearchActive,
  settings,
  setSettings,
  disabled,
  searchRef,
  capabilities
}) => {
  // #17168 fix new filter settings, else the page will not load because of missing setting and then trying to get
  // length from it
  forOwn(defaultEmployeeFilterSettings(), (value, key) => {
    if (settings[key] === undefined) {
      settings[key] = value;
    }
  });
  const practices = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.practice, b.practice))
        .map(e => e.practice.trim())
        .filter(e => e !== "")
    )
  ];
  const businessUnits = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.businessUnit, b.businessUnit))
        .map(e => e.businessUnit.trim())
        .filter(e => e !== "")
    )
  ];
  const positions = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.title, b.title))
        .map(e => e.title.trim())
        .filter(e => e !== "")
    )
  ];
  const levels = [
    ...new Set(
      employees
        .sort((a, b) => sorters.number(a.level, b.level))
        .map(e => e.level)
    )
  ];
  const regions = [
    ...new Set(
      employees
        .sort((a,b) => sorters.string(a.region, b.region))
        .map(e => e.region)
    )
  ];
  const locations = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.location, b.location))
        .map(e => e.location)
    )
  ];
  const entities = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.entity, b.entity))
        .map(e => e.entity)
    )
  ];
  const majors = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.major, b.major))
        .map(e => e.major)
    )
  ];
  const minors = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.minor, b.minor))
        .map(e => e.minor)
        .filter(e => e !== "")
    )
  ];
  const clients = chain(employees)
    .flatMap(e => e.clients.split(EMPLOYEE_ACTIVE_DELIMITER))
    .map(client => client.trim())
    .filter(client => client !== "")
    .uniq()
    .sort()
    .value();
  const projects = chain(employees)
    .flatMap(e => e.projects.split(EMPLOYEE_ACTIVE_DELIMITER))
    .map(project => project.trim())
    .filter(project => project !== "")
    .uniq()
    .sort()
    .value();

  const clearFilters = () => {
    setSettings(defaultEmployeeFilterSettings());
    form.resetFields();
    onSearch(employees);
  };

  const handleClientProjectCheck = () => {
    handleCheck();
    if (!extraInfoHidden) {
      let noClientSettings = {
        ...settings,
        client: [],
        project: [],
        hours: "",
        endDate: ""
      };
      setSettings(noClientSettings);
      form.resetFields([
        "selectedClients",
        "selectedProjects",
        "selectedHours",
        "selectedEndDate"
      ]);
      filterEmployees(
        {
          employees: employees,
          onSearch: onSearch,
          settings: noClientSettings,
          setSettings: setSettings
        },
        [],
        []
      );
    }
  };

  const { getFieldDecorator } = form;

  return (
    <div style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
      <Form layout="inline">
        {!advancedSearchActive && (
          <div style={{ display: "inline-block" }}>
            <Form.Item>
              {getFieldDecorator("searchTerm", {
                initialValue: settings.search
              })(
                <Input
                  prefix={<Icon type="filter" />}
                  ref={searchRef}
                  placeholder="Filter"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedPractices", {
                initialValue: settings.practice
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.practice.length} Practice${
                    settings.practice.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Practice"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                >
                  {practices.map(p => (
                    <Select.Option key={p} value={p}>
                      {p}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedBusinessUnits", {
                initialValue: settings.businessUnit
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.businessUnit.length} Business Unit${
                    settings.businessUnit.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Business Unit"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                >
                  {businessUnits.map(p => (
                    <Select.Option key={p} value={p}>
                      {p}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedPositions", {
                initialValue: settings.position
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.position.length} Position${
                    settings.position.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Position"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                  dropdownMatchSelectWidth={false}
                >
                  {positions.map(p => (
                    <Select.Option key={p} value={p}>
                      {p}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedLevels", {
                initialValue: settings.level
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.level.length} Level${
                    settings.level.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Level"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                >
                  {levels.map(l => (
                    <Select.Option key={l} value={l}>
                      {l}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedRegions", {
                initialValue: settings.region
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.region.length} Region${
                    settings.region.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Region"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                  dropdownMatchSelectWidth={false}
                >
                  {regions.map(o => (
                    <Select.Option key={o} value={o}>
                      {o}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedLocations", {
                initialValue: settings.location
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.location.length} Location${
                    settings.location.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Location"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                  dropdownMatchSelectWidth={false}
                >
                  {locations.map(o => (
                    <Select.Option key={o} value={o}>
                      {o}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedEntities", {
                initialValue: settings.entity
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.entity.length} Entit${
                    settings.entity.length === 1 ? "y" : "ies"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Entity"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                  dropdownMatchSelectWidth={false}
                >
                  {entities.map(o => (
                    <Select.Option key={o} value={o}>
                      {o}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedMajors", {
                initialValue: settings.major
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.major.length} Major${
                    settings.major.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Major"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                  dropdownMatchSelectWidth={false}
                >
                  {majors.map(o => (
                    <Select.Option key={o} value={o}>
                      { o === "No Major" ? ""
                      : capabilities.includes(o) ? 
                        <Icon type="wallet" style={{ paddingRight: '5px' }}/>
                        : <Icon type="shop" style={{ paddingRight: '5px' }}/> 
                      }
                      {o}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("selectedMinors", {
                initialValue: settings.minor
              })(
                <Select
                  maxTagCount={0}
                  maxTagPlaceholder={`${settings.minor.length} Minor${
                    settings.minor.length === 1 ? "" : "s"
                  } Selected`}
                  showArrow
                  mode="multiple"
                  placeholder="Minor"
                  dropdownClassName="filter-bar-select-dropdown"
                  className="filter-bar-select"
                  dropdownMatchSelectWidth={false}
                >
                  {minors.map(o => (
                    <Select.Option key={o} value={o}>
                      {o === "No Minor" ? ""
                      : capabilities.includes(o) ? 
                        <Icon type="wallet" style={{ paddingRight: '5px' }}/>
                        : <Icon type="shop" style={{ paddingRight: '5px' }}/> 
                      }
                      {o}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {!extraInfoHidden && (
              <div style={{ display: "inline-block" }}>
                <Form.Item>
                  {getFieldDecorator("selectedClients", {
                    initialValue: settings.client
                  })(
                    <Select
                      maxTagCount={0}
                      maxTagPlaceholder={`${settings.client.length} Client${
                        settings.client.length === 1 ? "" : "s"
                      } Selected`}
                      showArrow
                      mode="multiple"
                      placeholder="Client"
                      dropdownClassName="filter-bar-select-dropdown"
                      className="filter-bar-select"
                      dropdownMatchSelectWidth={false}
                    >
                      {clients.map(o => (
                        <Select.Option key={o} value={o}>
                          {o}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("selectedProjects", {
                    initialValue: settings.project
                  })(
                    <Select
                      maxTagCount={0}
                      maxTagPlaceholder={`${settings.project.length} Client${
                        settings.project.length === 1 ? "" : "s"
                      } Selected`}
                      showArrow
                      mode="multiple"
                      placeholder="Project"
                      dropdownClassName="filter-bar-select-dropdown"
                      className="filter-bar-select"
                      dropdownMatchSelectWidth={false}
                    >
                      {projects.map(o => (
                        <Select.Option key={o} value={o}>
                          {o}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("selectedHours", {
                    initialValue: settings.hours
                  })(
                    <InputNumber
                      style={{ border: "none", backgroundColor: "transparent" }}
                      placeholder="Available Hours"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("selectedEndDate", {
                    initialValue:
                      settings.endDate === "" ? null : moment(settings.endDate)
                  })(
                    <DatePicker
                      placeholder="Roll-Off Date"
                      showTime={{
                        defaultValue: moment("00:00:00", "HH:mm:ss")
                      }}
                      format="MM/DD/YYYY"
                    />
                  )}
                </Form.Item>
              </div>
            )}
            <Form.Item>
              <Button
                title="Clear filters"
                icon="close"
                type="link"
                onClick={clearFilters}
              >
                Clear filters
              </Button>
            </Form.Item>
          </div>
        )}
        <Form.Item name="clientProjectCheckBox">
          <Checkbox
            onChange={handleClientProjectCheck}
            checked={!extraInfoHidden}
          >
            Display Client and Project Info
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

EmployeeFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  employees: PropTypes.array
};

export default Form.create({
  name: "filter_employees_form",
  onValuesChange: debounce(filterEmployees, 500)
})(EmployeeFilters);
