export interface ProjectorEntityReportFilterSettings {
  search: string;
  category: string[];
}

export function defaultProjectorEntityReportFilterSettings(): ProjectorEntityReportFilterSettings {
  return {
    search: '',
    category: []
  };
}
