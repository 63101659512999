import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import EmployeeCertificationForm from './EmployeeCertificationForm';
import DeleteEmployeeCertificationForm from './DeleteEmployeeCertificationForm';

const CertificationCardModal = props => {
  const [createMode, setCreateMode] = useState(false);
  const [employeeCertification, setEmployeeCertification] = useState({});
  const [certStack, setCertStack] = useState([]);

  useEffect(() => setCreateMode(props.action === 'Add'), [props.action, props.certification]);

  const cancelFixState = () => {
    props.closeModal();
    setCreateMode(true);
    setCertStack([]);
  };

  const submitFixState = () => {
    props.onSubmit();
    setCreateMode(true);
    setCertStack([]);
  };

  return (
    <Modal
      title={props.action + ' My Certification'}
      visible={props.modalState}
      onCancel={cancelFixState}
      footer={null}
    >
      {props.action === 'Add' && (
        <EmployeeCertificationForm
          employeeId={props.employeeId}
          employeeCertification={employeeCertification}
          setEmployeeCertification={setEmployeeCertification}
          onSubmit={submitFixState}
          onCancel={cancelFixState}
          isCreateMode={createMode}
          setIsCreateMode={setCreateMode}
          modalState={props.modalState}
          certStack={certStack}
          setCertStack={setCertStack}
        />
      )}
      {props.action === 'Edit' && (
        <EmployeeCertificationForm
          employeeId={props.employeeId}
          employeeCertification={props.certification}
          setEmployeeCertification={props.setCertification}
          onSubmit={submitFixState}
          onCancel={cancelFixState}
          isCreateMode={createMode}
          setIsCreateMode={setCreateMode}
          modalState={props.modalState}
          certStack={certStack}
          setCertStack={setCertStack}
        />
      )}
      {props.action === 'Delete' && (
        <DeleteEmployeeCertificationForm
          employeeCertification={props.certification}
          onSubmit={submitFixState}
          onCancel={cancelFixState}
        />
      )}
    </Modal>
  );
};

export default CertificationCardModal;
