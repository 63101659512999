import React, { useEffect, useState } from 'react';
import { Checkbox, Table } from 'antd';
import Stack from 'components/common/Stack';
import PropTypes from 'prop-types';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const FeatureTable = ({ features, onToggleFeature, loading }) => {
  const [filteredFeatures, setFilteredFeatures] = useState([]);

  useEffect(() => {
    setFilteredFeatures(features);
  }, [features]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Enabled',
      render: record => (
        <Checkbox checked={record.enabled} onChange={value => onToggleFeature(record, value.target.checked)} />
      )
    }
  ];

  return (
    <Stack>
      <Table
        pagination={PAGINATION_DEFAULT}
        rowKey={r => r.name}
        columns={columns}
        dataSource={filteredFeatures}
        loading={loading}
      />
    </Stack>
  );
};

FeatureTable.propTypes = {
  features: PropTypes.array,
  loading: PropTypes.bool
};

export default FeatureTable;
