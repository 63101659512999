import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import { Link } from 'react-router-dom'
import ProjectExperienceAvatar from '../projects/ProjectExperienceAvatar'
import { Experience } from '../../types/Experience'
import { RoleTypes } from '../../types/RoleTypes'
import ProjectorLogo from 'components/common/ProjectorLogo'

export type RoleTypeMap = RoleTypes & { experiences: Experience[] }

const RoleTypeExperienceCard = ({ roleType }: { roleType: RoleTypeMap }) => {

  // only display projector logo if all employee experiences are created by projector
  var projector = true
  roleType.experiences.forEach((r: Experience) => {
    if (r.projector === false) {
      projector = false
    }
  })

  return (
    <Card className="avatar-card" hoverable={true}>
      {projector &&
        <div style={{ float: 'right', display: 'flex', alignItems: 'center', marginRight: '-24px', marginTop: '-24px' }}>
          <ProjectorLogo size="48px" strokeWidth="16" background="rgba(0,0,0,0)" color="" />
        </div>}
      <Link to={`/role-types/${roleType.id}`}>
        <Card.Meta title={roleType.name} description={<span>{roleType.family}</span>} />
      </Link>
      <Card.Meta
        description={
          <div style={{ marginTop: '1.5em', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <ProjectExperienceAvatar experience={roleType.experiences} />
          </div>
        }
      />
    </Card>
  )
}

RoleTypeExperienceCard.propTypes = {
  skill: PropTypes.object
}

export default RoleTypeExperienceCard
