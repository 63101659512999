import React, { useEffect, useState, useRef } from "react";
import { Typography, Table, Tag, Input } from "antd";
import Stack from "components/common/Stack";
import { getLandingPageProjects } from "api/projectApi";
import { Link } from "react-router-dom";
import formatDate from "utils/formatDate";
import Toolbar from "components/common/Toolbar";
import ProjectFilters, { filterProjects } from "./ProjectFilters";
import displayErrorNotification from "utils/displayErrorNotification";
import { trackComponent } from "telemetry/AppInsights";
import { LandingPageProject } from "types/Project";
import { ColumnProps } from "antd/lib/table";
import ButtonRow from "components/common/ButtonRow";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import useLocalStorage from "utils/useLocalStorage";
import { defaultProjectFilterSettings } from "types/ProjectPreferences";
import { convertLandingsPageProject } from "utils/convert";
import formatStage from "utils/formatStage";
import EmployeeRenderer from "components/common/EmployeeRenderer";
import sorters from "utils/sorters";
import { useFeatures } from "hooks/FeatureHooks";

const ProjectLandingPage = () => {
  const [projects, setProjects] = useState<LandingPageProject[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<
    LandingPageProject[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    "ProjectsLandingPage_FilterSettings",
    defaultProjectFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage<any>(
    "ProjectsLandingPage_SortSettings",
    {}
  );
  const searchRef = useRef<Input>();
  const features = useFeatures();
  const projector: boolean =
    features && features.isEnabled("ENABLE_PROJECTOR_FEATURES");

  useEffect(() => {
    setLoading(true);
    getLandingPageProjects()
      .then((projects) => {
        const converted = convertLandingsPageProject(projects);
        setProjects(converted);
        setFilteredProjects(converted);
        filterProjects(
          {
            onSearch: setFilteredProjects,
            projects: converted,
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            projector: projector,
            form: null,
            searchRef: null,
          },
          [],
          {
            searchTerm: filterSettings.search,
            selectedMarkets: filterSettings.market,
            selectedPractices: filterSettings.practice,
            showInactive: filterSettings.inactive,
            showManagers: filterSettings.showManagers,
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (
          searchRef.current !== undefined &&
          searchRef.current.input !== null
        ) {
          searchRef.current.focus();
        }
      });
  }, []);

  let columns: ColumnProps<LandingPageProject>[] = [
    {
      title: "Client",
      key: "client",
      render: (record: LandingPageProject) => (
        <Link to={`/clients/${record.clientId}`}>{record.clientName}</Link>
      ),
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        a.clientName.toLowerCase().localeCompare(b.clientName.toLowerCase()),
    },
    {
      title: "Market",
      key: "location",
      dataIndex: "location",
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        a.location.toLowerCase().localeCompare(b.location.toLowerCase()),
    },
    {
      // @TODO: Adjust when Projector go-live
      title: projector ? "Engagement Name" : "Practice",
      key: projector ? "engagementName" : "practice",
      render: (record: LandingPageProject) => {
        if (projector) {
          return (
            <Link to={`/clients/engagement/${record.projectId}`}>
              {record.engagementName}
            </Link>
          );
        } else {
          return record.practice;
        }
      },
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        projector
          ? a.engagementName
              .toLowerCase()
              .localeCompare(b.engagementName.toLowerCase())
          : a.practice.toLowerCase().localeCompare(b.practice.toLowerCase()),
    },
    {
      title: "Project",
      key: "project",
      render: (record: LandingPageProject) => (
        <Link to={`/projects/${record.projectId}`}>{record.projectName}</Link>
      ),
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        a.projectName.toLowerCase().localeCompare(b.projectName.toLowerCase()),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: formatDate,
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        sorters.date(a.startDate, b.startDate),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: formatDate,
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        sorters.date(a.endDate, b.endDate),
    },
    {
      title: "Stage",
      align: "right",
      key: "active",
      render: (record: LandingPageProject) => (
        <Tag color={record.active ? "green" : "red"}>{formatStage(record)}</Tag>
      ),
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        formatStage(a)
          .toLowerCase()
          .localeCompare(formatStage(b).toLowerCase()),
    },
    {
      title: "Engagement Manager",
      key: "engagement_manager",
      render: (record: LandingPageProject) => (
        <EmployeeRenderer
          id={record.engagementManagerId}
          name={record.engagementManager}
        />
      ),
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        a.engagementManager
          .toLowerCase()
          .localeCompare(b.engagementManager.toLowerCase()),
      hidden: !filterSettings.showManagers,
    },
    {
      title: "Project Manager",
      key: "project_manager",
      render: (record: LandingPageProject) => (
        <EmployeeRenderer
          id={record.projectManagerId}
          name={record.projectManager}
        />
      ),
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        a.projectManager
          .toLowerCase()
          .localeCompare(b.projectManager.toLowerCase()),
      hidden: !filterSettings.showManagers,
    },
    {
      title: "OIC",
      key: "oic",
      render: (record: LandingPageProject) => (
        <EmployeeRenderer id={record.oicId} name={record.oic} />
      ),
      sorter: (a: LandingPageProject, b: LandingPageProject) =>
        a.oic.toLowerCase().localeCompare(b.oic.toLowerCase()),
      hidden: !filterSettings.showManagers,
    },
  ].filter((item) => !item.hidden) as ColumnProps<LandingPageProject>[];

  const columnsSortSettings = columns.map((column) => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order,
      });
    }
  }

  return (
    <Stack>
      <Typography.Title>
        Projects
        <ButtonRow style={{ float: "right" }}>
          <SavePreferencesButton
            disabled={loading}
            keys={[
              "ProjectsLandingPage_FilterSettings",
              "ProjectsLandingPage_SortSettings",
            ]}
          />
        </ButtonRow>
      </Typography.Title>
      <Toolbar>
        <ProjectFilters
          onSearch={setFilteredProjects}
          projects={projects}
          projector={projector}
          settings={filterSettings}
          setSettings={setFilterSettings}
          disabled={loading}
          searchRef={searchRef}
        />
        <div>
          <span style={{ margin: "0 10px" }}>
            Showing {filteredProjects.length} of {projects.length} Projects
          </span>
        </div>
      </Toolbar>
      <Table
        //@ts-ignore
        pagination={{ pageSize: 25, position: "top" }}
        rowKey="projectId"
        columns={columns}
        dataSource={filteredProjects}
        loading={loading}
        onChange={onChange}
      />
    </Stack>
  );
};

export default trackComponent(ProjectLandingPage, "Project Landing Page");
