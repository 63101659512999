import React from 'react';
import { Modal } from 'antd';
import PromptCardForm from './PromptCardForm';
import { Prompt } from 'types/Prompt';

type PromptCardModalProps = {
  prompt: Prompt;
  modalState: boolean;
  closeModal: Function;
  onSubmit: () => void;
  employeeId: number;
  employeePromptValue: string;
  category: string;
};

const PromptCardModal = (props: PromptCardModalProps) => {
  return (
    <Modal title={props.category == "STAFFING_PREFERENCES" ? "Staffing Preferences" :  props.category == "ADDITIONAL_INFORMATION" ? "Additional Information" : "About Me"} 
    visible={props.modalState} onCancel={() => props.closeModal()} footer={null}>
      {props.prompt && (
        <PromptCardForm
          prompt={props.prompt}
          employeePromptValue={props.employeePromptValue}
          employeeId={props.employeeId}
          onSubmit={props.onSubmit}
        />
      )}
    </Modal>
  );
};

export default PromptCardModal;
