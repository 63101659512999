import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Table, Button, Divider } from 'antd';
import ButtonRow from 'components/common/ButtonRow';
import Stack from 'components/common/Stack';
import Toolbar from 'components/common/Toolbar';
import { getKeyMetricPeriods, getKeyMetricsForPeriod } from 'api/keyMetricsApi';
import KeyMetricsFilters from './KeyMetricsFilters';
import { getCurrentPeriod, formatMonths } from './keyMetricUtils';
import { Parser } from 'json2csv';
import moment from 'moment';
import { trackComponent } from 'telemetry/AppInsights';
import useLocalStorage from 'utils/useLocalStorage';
import { defaultKeyMetricFilterSettings } from 'types/KeyMetricPreferences';
import SavePreferencesButton from 'components/common/SavePreferencesButton';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const KeyMetricsLandingPage = () => {
  const [periods, setPeriods] = useState([]);
  const [keyMetrics, setKeyMetrics] = useState([]);
  const [practices, setPractices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [titles, setTitles] = useState([]);
  const [filteredMetrics, setFilteredMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    'KeyMetricsLandingPage_FilterSettings',
    defaultKeyMetricFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage('KeyMetricsLandingPage_SortSettings', {
    key: 'name',
    order: 'ascend'
  });
  const searchRef = useRef();

  useEffect(() => {
    getKeyMetricPeriods().then(periods => {
      setPeriods(periods);
      let period;
      if (filterSettings.period === 0) {
        period = getCurrentPeriod(periods);
      } else {
        period = periods.filter(p => p.id === filterSettings.period)[0];
      }
      loadKeyMetrics(period, {
        name: filterSettings.search,
        practice: filterSettings.practice,
        location: filterSettings.office,
        title: filterSettings.position
      });
    });
  }, []);

  const _onSearch = (keyMetrics, name, practice, location, title) => {
    setFilteredMetrics(
      keyMetrics.filter(k => {
        const isNameValid =
          name === null || name === '' || k.employee.fullName.toLowerCase().includes(name.toLowerCase());
        const isPracticeValid = !practice || !practice.length || practice.indexOf(k.practice) > -1;
        const isLocationValid = !location || !location.length || location.indexOf(k.location) > -1;
        const isTitleValid = !title || !title.length || title.indexOf(k.title) > -1;
        return isNameValid && isPracticeValid && isLocationValid && isTitleValid;
      })
    );
  };

  const onSearch = (name, practice, location, title) => {
    _onSearch(keyMetrics, name, practice, location, title);
  };

  const loadKeyMetrics = (period, filters) => {
    if (period && period.id) {
      setLoading(true);
      getKeyMetricsForPeriod(period.id).then(keyMetrics => {
        setKeyMetrics(keyMetrics);
        setFilteredMetrics(keyMetrics);
        setPractices([
          ...new Set(keyMetrics.sort((a, b) => (a.practice || '').localeCompare(b.practice || '')).map(k => k.practice))
        ]);
        setLocations([
          ...new Set(keyMetrics.sort((a, b) => (a.location || '').localeCompare(b.location || '')).map(k => k.location))
        ]);
        setTitles([...new Set(keyMetrics.sort((a, b) => (a.title || '').localeCompare(b.title || '')).map(k => k.title))]);
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus();
        }
        if (filters) {
          _onSearch(keyMetrics, filters.name, filters.practice, filters.location, filters.level);
        }
      });
    } else {
      setKeyMetrics([]);
    }
  };

  const exportKeyMetrics = () => {
    const keyMetrics = filteredMetrics
      .map(k => {
        return {
          name: k.employee.fullName,
          practice: k.practice,
          location: k.location,
          title: k.title,
          billableUtilization: `${k.billableUtilization.toFixed(2)}%`,
          clientUtilization: `${k.clientUtilization.toFixed(2)}%`,
          timeAtLevel: k.timeAtLevel
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const json2CsvParser = new Parser();
    const csv = json2CsvParser.parse(keyMetrics);
    const blob = new Blob([csv], { type: 'text/csv', encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `KeyMetrics-${moment().format('YYYYMMDD-hhmmss')}.csv`;
    link.click();
  };

  let columns = [
    {
      title: 'Name',
      dataIndex: 'employee',
      key: 'name',
      render: e => <Link to={`employees/${e.id}`}>{e.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'practice',
      key: 'practice',
      sorter: (a, b) => a.practice.toLowerCase().localeCompare(b.practice.toLowerCase())
    },
    {
      title: 'Office',
      dataIndex: 'location',
      key: 'office',
      sorter: (a, b) => a.location.toLowerCase().localeCompare(b.location.toLowerCase())
    },
    {
      title: 'Position',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())
    },
    {
      title: 'Billable Utilization',
      dataIndex: 'billableUtilization',
      key: 'billable_utilization',
      render: billableUtilization => `${billableUtilization.toFixed(2)}%`,
      sorter: (a, b) => a.billableUtilization - b.billableUtilization
    },
    {
      title: 'Client Utilization',
      key: 'client_utilization',
      dataIndex: 'clientUtilization',
      render: clientUtilization => `${clientUtilization.toFixed(2)}%`,
      sorter: (a, b) => a.clientUtilization - b.clientUtilization
    },
    {
      title: 'Time at Position*',
      key: 'time_at_position',
      dataIndex: 'timeAtLevel',
      render: timeAtLevel => (timeAtLevel > 0 ? formatMonths(timeAtLevel) : ''),
      sorter: (a, b) => a.timeAtLevel - b.timeAtLevel
    }
  ];

  const columnsSortSettings = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  return (
    <Stack>
      <Typography.Title>
        Key Metrics
        <ButtonRow style={{ float: 'right' }}>
          {!loading && (
            <Button icon="download" onClick={exportKeyMetrics}>
              Export
            </Button>
          )}
          <SavePreferencesButton
            keys={['KeyMetricsLandingPage_FilterSettings', 'KeyMetricsLandingPage_SortSettings']}
            disabled={loading}
          />
        </ButtonRow>
      </Typography.Title>

      <Typography.Text style={{ fontStyle: 'italic', fontSize: 'medium' }}>
        Note: Our team is working to provide additional views of personal utilization, but for now, please see Projector for utilization metrics after 5/1/23.
      </Typography.Text>

      <Divider/>

      <Toolbar>
        <KeyMetricsFilters
          loadKeyMetrics={loadKeyMetrics}
          onSearch={onSearch}
          periods={periods}
          practices={practices}
          locations={locations}
          titles={titles}
          searchRef={searchRef}
          disabled={loading}
          settings={filterSettings}
          setSettings={setFilterSettings}
        />
        <div>
          Showing {filteredMetrics.length} of {keyMetrics.length} Key Metrics
        </div>
      </Toolbar>

      <Table
        pagination={PAGINATION_DEFAULT}
        rowKey="id"
        columns={columns}
        dataSource={filteredMetrics}
        loading={loading}
        onChange={onChange}
      />

      <Typography.Text style={{ marginTop: '8px', fontSize: 'small', fontStyle: 'italic' }}>
        * Time at level on last day of the period
      </Typography.Text>
    </Stack>
  );
};

export default trackComponent(KeyMetricsLandingPage, 'Key Metrics Landing Page');
