import React, { useEffect, useState } from 'react'
import { Typography, Form, Button, Input, Select, Spin } from 'antd'
import { createIndustry, updateIndustry, toggleIndustryActivation, deleteIndustry } from 'api/industriesApi'
import ButtonRow from 'components/common/ButtonRow'
import displayErrorNotification from 'utils/displayErrorNotification'
import { useUser } from 'auth/UserHooks'
import IndustryActivationModal from './IndustryActivationModal'
import IndustryDeleteModal from './IndustryDeleteModal'
import { Industry } from 'types/Industry'
import { IndustryDetail } from 'types/IndustryDetail'
import { getIndustrySectors } from 'api/industrySectorApi'
import { IndustrySector } from 'types/IndustrySector'

type IndustryFormProps = {
  industry?: IndustryDetail
  onSubmit: (industry: IndustryDetail) => void
  onCancel: () => void
  onDelete: () => void
  form: any
}

const IndustryForm = ({ industry, onSubmit, onCancel, onDelete, form }: IndustryFormProps) => {
  const user = useUser()
  const isCreateMode = !!!industry
  const [isToggleModalVisible, setIsToggleModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const { getFieldDecorator } = form
  const initialValues = !isCreateMode ? { ...industry } : {
    industrySectorId: 1,
    name: ''
  }
  const [industrySectors, setIndustrySectors] = useState<IndustrySector[]>([])
  const [inactive, setInactive] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const _toggleModalVisibility = () => {
    setIsToggleModalVisible(!isToggleModalVisible)
  }

  const _deleteModalVisibility = () => {
    setIsDeleteModalVisible(!isDeleteModalVisible)
  }

  useEffect(() => {
    setLoading(true)
    getIndustrySectors()
      .then(response => {
        setIndustrySectors(response.filter(r => r.inactive === false))
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    form.resetFields()
    if (!isCreateMode) {
      setInactive(industry.inactive)
    }

  }, [industry, onSubmit])

  const handleActivation = () => {
    if (industry)
      toggleIndustryActivation(industry.id)
        .then(onSubmit)
        .catch(displayErrorNotification)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    form.validateFields((err: any, values: any) => {
      if (err) return

      if (isCreateMode) {
        let newIndustry: Industry = { ...values }
        createIndustry(newIndustry)
          .then(onSubmit)
          .catch(displayErrorNotification)
      } else {
        let updatedIndustry: IndustryDetail = { ...industry, name: values.name, industrySectorId: values.industrySectorId }
        updateIndustry(industry.id, updatedIndustry)
          .then(onSubmit)
          .catch(displayErrorNotification)
      }
    })
  }

  const _deleteIndustry = () => {
    if (industry && industry.id !== null) {
      deleteIndustry(industry.id)
        .then(onDelete)
        .catch(displayErrorNotification)
    }
  }

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" onSubmit={handleSubmit} style={{ maxWidth: '40em' }}>
        <Typography.Title>{isCreateMode ? 'Add New' : 'Edit'} Industry Type</Typography.Title>

        <Form.Item label="Sector" required>
          {getFieldDecorator('industrySectorId', {
            initialValue: initialValues.industrySectorId,
            rules: [{ required: true, message: 'Sector is a required field' }]
          })(
            <Select showArrow placeholder="Sector">
              {industrySectors.map(s => (
                <Select.Option key={s.id} value={s.id}>
                  {s.name} 
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Industry Name" required>
          {getFieldDecorator('name', {
            initialValue: initialValues.name,
            rules: [
              { required: true, message: 'Name is a required field' },
              { max: 60, message: 'Name must be fewer than 60 characters' }
            ]
          })(<Input />)}
        </Form.Item>

        <ButtonRow style={{ marginTop: '1em' }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
          {!isCreateMode && user.permissions.canToggleIndustryTypeActivation && (
            <Button onClick={_toggleModalVisibility}>
              {industry && industry.inactive ? 'Activate' : 'Deactivate'}
            </Button>
          )}
          {!isCreateMode && inactive && user.permissions.canDeleteIndustryType && (
            <Button type="danger" onClick={_deleteModalVisibility}>
              Delete
            </Button>
          )}
        </ButtonRow>

        {!isCreateMode && (
          <>
          <IndustryActivationModal
            action={industry.inactive ? 'Activate' : 'Deactivate'}
            isModalVisible={isToggleModalVisible}
            industry={industry}
            closeModal={_toggleModalVisibility}
            onSubmit={handleActivation}
          />
          <IndustryDeleteModal
            isModalVisible={isDeleteModalVisible}
            industry={industry}
            closeModal={_deleteModalVisibility}
            onSubmit={_deleteIndustry}
          />
          </>
        )}
      </Form>
    </Spin>
  )
}

export default Form.create<IndustryFormProps>({ name: 'industry_form' })(IndustryForm)