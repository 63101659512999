import { experienceLevels, skillDurations } from 'components/constants/Constants';
import { capitalize, lowerCase, startCase } from 'lodash';

const enumToDisplayText = (enumOption: string) => {
  return startCase(lowerCase(enumOption));
};

export const enumExperienceLevels = (el: number) => {
  if (el > 0) {
    return capitalize(experienceLevels[el]);
  }
  return 'Error ' + el;
};

export const enumSkillDuration = (sd: number) => {
  if (sd > 0) {
    return skillDurations[sd];
  }
  return 'Error ' + sd;
};

export default enumToDisplayText;
