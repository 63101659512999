import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Avatar, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useUser } from 'auth/UserHooks';
import formatDate from 'utils/formatDate';
import { S3_IMAGE_BASE_URL } from 'config';

const CertificationCard = ({ certification, employeeCertification, onEdit, onDelete }) => {
  const user = useUser();
  const isMe = employeeCertification.employeeId === user.id;
  const isCoach = user.coachees.includes(employeeCertification.employeeId);

  const actions = [];

  if (
    (isMe && user.permissions.canUpdateOwnCertifications) ||
    (isCoach && user.permissions.canUpdateCoacheeCertifications) ||
    user.permissions.canUpdateCertifications
  ) {
    actions.push(
      <Button type="link" onClick={onEdit}>
        <Icon type="edit" style={{ display: 'inline' }} />
      </Button>
    );
  }

  if (
    (isMe && user.permissions.canDeleteOwnCertifications) ||
    (isCoach && user.permissions.canDeleteCoacheeCertifications) ||
    user.permissions.canDeleteCertifications
  ) {
    actions.push(
      <Button type="link" onClick={onDelete} style={{ paddingLeft: "5px", paddingRight: "35px" }}>
        <Icon type="delete" style={{ display: 'inline' }} />
      </Button>
    );
  }

  // TODO: Style expired, inactive, and expiring soon
  return (
    <Card className="avatar-card" hoverable={true} actions={actions}>
      <Link to={`/certifications/${certification.id}`}>
        <Card.Meta
          avatar={
            <Avatar
              size="large"
              shape="square"
              src={S3_IMAGE_BASE_URL + certification.certifyingOrganization.imagePath}
            />
          }
          title={`${certification.certifyingOrganization.name} ${certification.name}`}
          description={
            <span>
              {employeeCertification ? (
                <>
                  <Icon type="calendar" /> {formatDate(employeeCertification.startDate)}
                  <br />
                  exp. {formatDate(employeeCertification.expirationDate)}
                </>
              ) : null}
            </span>
          }
        />
      </Link>
    </Card>
  );
};

CertificationCard.propTypes = {
  certification: PropTypes.shape({
    id: PropTypes.number
  })
};

export default CertificationCard;
