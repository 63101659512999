import React from 'react';
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd';
import { debounce } from 'lodash';
import { Entity } from 'types/Entity';
import { EntityFilterSettings } from 'types/EntityPreferences';

type EntityFiltersProps = {
  entities: Entity[];
  onSearch: (filteredEntities: Entity[]) => void;
  form: any;
  searchRef: any;
  disabled: boolean;
  settings: EntityFilterSettings;
  setSettings: (settings: EntityFilterSettings) => void;
};

export const filterEntities = (props: EntityFiltersProps, changedValues: any, values: any) => {
  const { entities, onSearch, setSettings } = props;
  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const selectedEntities = values.selectedEntities;
  const showInactive = values.showInactive;

  setSettings({
    search: values.searchTerm,
    entity: selectedEntities,
    inActive: showInactive
  });

  const filteredEntities = entities.filter(entity => {
    let isNameValid = !uppercaseTerm || !uppercaseTerm.length || entity.name.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isSelectedEntityValid =
      !selectedEntities || !selectedEntities.length || selectedEntities.indexOf(entity.name) > -1;
    let isStatusValid = showInactive || !entity.inactive;
    return isNameValid && isSelectedEntityValid && isStatusValid;
  });
  onSearch(filteredEntities);
};

const EntityFilters = (props: EntityFiltersProps) => {
  const { entities, form, searchRef, disabled, settings } = props;
  const { getFieldDecorator } = form;
  const selectedEntities = form.getFieldValue('selectedEntities') || [];
  const names = entities.map(e => e.name).sort();

  const clearFilters = () => {
    form.resetFields();
    filterEntities(
      props,
      {},
      {
        searchTerm: '',
        selectedEntities: [],
        showInactive: false
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedEntities', {
            initialValue: settings.entity
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedEntities.length} Entit${
                selectedEntities.length === 1 ? 'y' : 'ies'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Entity"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {names.map(n => (
                <Select.Option key={n} value={n}>
                  {n}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showInactive', { initialValue: settings.inActive, valuePropName: 'checked' })(
            <Checkbox>Show Inactive</Checkbox>
          )}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create({
  name: 'filter_entities_form',
  onValuesChange: debounce(filterEntities, 500)
})(EntityFilters);
