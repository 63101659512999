import { SectorSortSettings } from './ColumnSortSettings';

export interface IndustryFilterSettings {
  search: string;
  sector: string[];
  inActive: boolean;
}

export function defaultIndustryFilterSettings(): IndustryFilterSettings {
  return {
    search: '',
    sector: [],
    inActive: false
  };
}

export function defaultIndustrySortSettings(): SectorSortSettings { 
  return {
    sectors: [],
    default: {
      key: 'name',
      order: 'ascend'
    }
  };
}