import { del, get, post, put } from "api";
import { EmployeeRegion } from "types/EmployeeRegion";

export function getEmployeeRegions(): Promise<EmployeeRegion[]> {
  return get(`api/employees/region`);
}

export function createEmployeeRegion(
  region: EmployeeRegion
): Promise<EmployeeRegion> {
  return post(`api/employees/region`, region);
}

export function updateEmployeeRegion(
  regionId: number,
  region: EmployeeRegion
): Promise<EmployeeRegion> {
  return put(`api/employees/region/${regionId}`, region);
}

export function deleteEmployeeRegion(
  regionId: number) {
  return del(`api/employees/region/${regionId}`);
}

export function toggleEmployeeRegionActivation(
  regionId: number
): Promise<EmployeeRegion> {
  return post(`api/employees/region/${regionId}`);
}