import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Button, Form, Icon } from 'antd';
import { debounce } from 'lodash';

export const filterProjectorEntityReport = (props, changedValues, values) => {
  const { responses, onSearch, setSettings } = props;

  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const categories = values.selectedCategories;

  setSettings({
    search: values.searchTerm,
    categories
  });

  const filteredResponses = responses.filter(r => {
    let isNameValid = !uppercaseTerm || !uppercaseTerm.length || r.name.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isCategoryValid = !categories || !categories.length || categories.indexOf(r.category) > -1;
    return isNameValid && isCategoryValid;
  });
  onSearch(filteredResponses);
};

const ProjectorEntityReportFilters = props => {
  const { responses = [], form, settings, searchRef, disabled } = props;
  const categories = [
    ...new Set(
      responses
        .sort((a, b) => a.category.localeCompare(b.category))
        .map(r => r.category)
        .filter(r => r !== undefined)
    )
  ];

  const clearFilters = () => {
    form.resetFields();
    filterProjectorEntityReport(
      props,
      {},
      {
        searchTerm: '',
        selectedCategories: []
      }
    );
  };

  const { getFieldDecorator } = form;

  const selectedCategories = form.getFieldValue('selectedCategories') || [];

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCategories', {
            initialValue: settings.practice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCategories.length} ${
                selectedCategories.length === 1 ? 'Category' : 'Categories'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Category"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {categories.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

ProjectorEntityReportFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  responses: PropTypes.array
};

export default Form.create({
  name: 'filter_comletion_report_form',
  onValuesChange: debounce(filterProjectorEntityReport, 500)
})(ProjectorEntityReportFilters);
