import React, { useState } from 'react';
import { Modal as AntModal } from 'antd';

const Modal = props => {
  const [isOpen, setIsOpen] = useState(false);

  const callBag = {
    item: props.item,
    openModal: () => setIsOpen(true),
    closeModal: () => {
      setIsOpen(false);
      props.onClose && props.onClose();
    }
  };
  return (
    <>
      <AntModal
        visible={props.isOpen != null ? props.isOpen : isOpen}
        onCancel={callBag.closeModal}
        destroyOnClose={props.destroyOnClose !== undefined ? props.destroyOnClose : false}
        footer={null}
        width={props.width !== undefined ? props.width : 520}
        mask={false}
      >
        {props.children && props.children(callBag)}
      </AntModal>
      {props.trigger && props.trigger(callBag)}
    </>
  );
};

export default Modal;
