import React from 'react';
import { Button, Form } from 'antd';
import PropTypes from 'prop-types';

const EmployeeEntityForm = ({ children, onSubmit, value, form }) => {
  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;
      onSubmit(values.response);
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item required>
        {getFieldDecorator('response', {
          initialValue: value,
          rules: [{ required: true, message: 'A response is required' }]
        })(children)}
      </Form.Item>

      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};

EmployeeEntityForm.propTypes = {
  children: PropTypes.object,
  onSubmit: PropTypes.func,
  value: PropTypes.string
};

export default Form.create({ name: 'employee_entity_form' })(EmployeeEntityForm);
