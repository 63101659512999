import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Form,
  Select,
  TreeSelect,
  Button,
  Typography,
  Checkbox,
  Table,
  Modal,
  DatePicker,
  notification,
  Icon,
  Tooltip
} from 'antd';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';
import { getProjects, getEmployeeSkills } from 'api/employeeApi';
import { addEmployeeSkill, updateEmployeeSkill, deleteEmployeeSkill } from 'api/employeeSkillApi';
import { getLandingPageSkill, getSkillCategories } from 'api/skillsApi';
import { addProjectExperience, deleteProjectExperienceById } from 'api/projectExperienceApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import ButtonRow from 'components/common/ButtonRow';
import { experienceLevels, skillDurations } from 'components/constants/Constants';
import enumToDisplayText from 'utils/enumToDisplayText';
import formatDate from 'utils/formatDate';
import { getFullSkillTree, findSkillInTreeById } from 'utils/skillDetails';
import { useUser } from 'auth/UserHooks';
import TreeSelectRecursive from 'components/skills/skill/TreeSelectRecursive';
import { useFeatures } from 'hooks/FeatureHooks';
import { convertLandingsPageSkill } from 'utils/convert';

const EmployeeSkillForm = ({
  employeeId,
  projectExperiences = [],
  employeeSkill,
  form,
  onSubmit,
  onCancel,
  isCreateMode,
  isEditModalMode
}) => {
  const features = useFeatures();
  const projectIds = projectExperiences.map(pe => pe.project.id);
  const [skills, setSkills] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState(projectIds);
  const [filteredDurations, setFilteredDurations] = useState(skillDurations);
  const [addMultiple, setAddMultiple] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const isMe = user.id === employeeId;
  const isCoach = user.coachees.includes(employeeId);

  if (!isCreateMode && !isEditModalMode && !employeeSkill)
    throw new Error('employeeSkill required when isCreateMode is false');
  if (isCreateMode && !employeeId) throw new Error('employeeId required when isCreateMode is true');

  function cleanUpIteration(skills) {
    let count = 0;
    const next = skills.filter(skill => {
      if (skill.employeeCount > 0) {
        const find = skills.filter(c => c.parentId === skill.id);
        if (find === undefined || find.length === 0) {
          count++;
          return false;
        }
      }
      return true;
    });
    return [next, count];
  }

  // all this so I don't get the jslint error:
  // 'Don't make functions within a loop'
  const cleanUpSkillGroups = skills => {
    let count = 1;
    while (count > 0) {
      const iter = cleanUpIteration(skills);
      count = iter[1];
      skills = iter[0];
    }
    return skills;
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getProjects(employeeId),
      isCreateMode && getEmployeeSkills(employeeId),
      isCreateMode && getLandingPageSkill(),
      isCreateMode && getSkillCategories()
    ])
      .then(response => {
        setProjects(response[0].filter(p => p.hoursPerWeek > 0));
        if (isCreateMode) {
          const currentSkillIds = response[1].map(e => e.id);
          const converted = convertLandingsPageSkill(response[2], response[3], []);
          // removing skill groups after all children are removed, so they can not be selected
          const filtered = cleanUpSkillGroups(
            converted
              .filter(skill => !currentSkillIds.includes(skill.id))
              .map(skill => {
                const find = converted.filter(c => c.parentId === skill.id);
                if (find !== undefined) {
                  // using employeeCount to store children count
                  skill.employeeCount = find.length;
                }
                return skill;
              })
          );
          const fullTree = getFullSkillTree(filtered, response[3]);
          setSkills(fullTree);
        } else {
          setSkills([
            {
              id: employeeSkill.skill.id,
              name: employeeSkill.skill.name,
              description: '',
              parentid: 0,
              parentSkill: null,
              category: '',
              active: true,
              tags: [],
              employeeCount: 0
            }
          ]);
        }
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return;

      if (isCreateMode) {
        const data = {
          ...values,
          lastUsedDate: values['lastUsedDate']
            ? values['lastUsedDate'].startOf('month').format('YYYY-MM-DD')
            : undefined,
          interest: !!values.interest,
          employeeId
        };

        addEmployeeSkill(data)
          .then(resp => {
            updateProjectExperiences(resp);
            notification.success({ message: 'You have added ' + findSkillInTreeById(data.skillId, skills).name });
          })
          .catch(displayErrorNotification);
      } else {
        const data = {
          ...values,
          lastUsedDate: values['lastUsedDate']
            ? values['lastUsedDate'].startOf('month').format('YYYY-MM-DD')
            : undefined,
          employeeId,
          employeeSkillId: employeeSkill.employeeSkillId
        };
        updateEmployeeSkill(employeeSkill.employeeSkillId, data)
          .then(updateProjectExperiences)
          .catch(displayErrorNotification);
      }
    });
  };

  const onAddMultipleSkills = () => {
    setAddMultiple(true);
  };

  const onAddOneSkill = () => {
    setAddMultiple(false);
  };

  const updateProjectExperiences = es => {
    // Find projects to add or project experiences to remove
    const projectIdsToAdd = selectedProjectIds.filter(i => !projectIds.includes(i));
    const projectExperienceIdsToRemove = projectExperiences
      .filter(pe => !selectedProjectIds.includes(pe.project.id))
      .map(pe => pe.projectExperienceId);

    const apiRequests = [];

    // Send all requests to add projects
    forEach(projectIdsToAdd, projectId => {
      const data = {
        employeeSkillId: es.employeeSkillId,
        projectId: projectId
      };
      apiRequests.push(addProjectExperience(data));
    });

    // Send all requests to remove project experiences
    forEach(projectExperienceIdsToRemove, id => {
      apiRequests.push(deleteProjectExperienceById(id));
    });
    // Resolve all promises
    if (addMultiple === true) {
      Promise.all(apiRequests)
        .then(form.resetFields())

        .catch(displayErrorNotification);
    } else {
      Promise.all(apiRequests)
        .then(onSubmit)
        .catch(displayErrorNotification);
    }
  };

  const handleRemoveSkill = e => {
    e.stopPropagation();
    Modal.confirm({
      icon: 'exclamation-circle',
      title: 'Are you sure you want to remove this skill?',
      cancelText: 'Cancel',
      maskClosable: true,

      onOk: removeSkill,
      onCancel: () => {}
    });
  };

  const handleExperienceLevelChange = value => {
    form.resetFields(['duration']);
    const durations = skillDurations.filter(d => {
      if (value === 'DESIRE_TO_LEARN') {
        return d === 'N/A';
      } else {
        return d !== 'N/A';
      }
    });
    setFilteredDurations(durations);

    if (value === 'DESIRE_TO_LEARN') {
      form.setFieldsValue({ duration: 'N/A', interest: true });
    }
  };

  const removeSkill = () => {
    const apiRequests = [];

    // Remove project experiences
    const projectExperienceIds = projectExperiences.map(pe => pe.projectExperienceId);
    forEach(projectExperienceIds, id => {
      apiRequests.push(deleteProjectExperienceById(id));
    });

    // Resolve all promises
    Promise.all(apiRequests)
      .then(() => {
        // Remove employee skill
        deleteEmployeeSkill(employeeSkill.employeeSkillId)
          .then(onSubmit)
          .catch(displayErrorNotification);
      })
      .catch(displayErrorNotification);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedProjectIds(selectedRowKeys);
  };

  const columns = [
    {
      title: 'Client',
      key: 'client',
      dataIndex: 'project.client.name'
    },
    {
      title: 'Project',
      dataIndex: 'project.name'
    },
    {
      title: 'Practice',
      dataIndex: 'project.practice'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: formatDate,
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: formatDate,
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1)
    }
  ];

  const { getFieldDecorator } = form;

  const initialValues = isCreateMode
    ? {}
    : {
        ...employeeSkill,
        skillId: employeeSkill.skill ? employeeSkill.skill.id : null,
        lastUsedDate: employeeSkill.lastUsedDate ? moment(employeeSkill.lastUsedDate) : null
      };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      {!isEditModalMode && (
        <>
          <Typography.Title>{isCreateMode ? 'Add' : 'Edit'} Skill</Typography.Title>
          <Typography.Paragraph>
            To indicate interest in using a skill that you don't currently have experience with, select "Desire to
            Learn" in the Experience Level drop-down menu.
          </Typography.Paragraph>
          <Typography.Title level={3}>Skill Details</Typography.Title>
        </>
      )}

      <div style={{ maxWidth: '40em' }}>
        <Form.Item label="Skill" required>
          {getFieldDecorator('skillId', {
            initialValue: initialValues.skillId,
            rules: [{ required: true, message: 'Skill is a required field' }]
          })(
            <TreeSelect
              showSearch
              disabled={loading || !isCreateMode}
              placeholder="Select a Skill"
              treeNodeFilterProp="title"
              dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            >
              {skills.length > 0 && skills.map(skill => TreeSelectRecursive(skill, 'children'))}
            </TreeSelect>
          )}
        </Form.Item>

        <Form.Item
          label={
            features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') ? (
              <>
                Experience Level{' '}
                <Tooltip
                  title="Skill Experience is periodically synced with Projector.
                       Projector Skill Experience of 0 = No skill or No Future Interest,
                       1 = Desire to Learn, 3 = Beginner, 4 = Intermediate, 5 = Advanced."
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </>
            ) : (
              'Experience Level'
            )
          }
          required
        >
          {getFieldDecorator('experienceLevel', {
            initialValue: initialValues.experienceLevel,
            rules: [{ required: true, message: 'Experience Level is a required field' }]
          })(
            <Select disabled={loading} placeholder="Select an Experience Level" onChange={handleExperienceLevelChange}>
              {experienceLevels.map(e => (
                <Select.Option key={e} value={e}>
                  {enumToDisplayText(e)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Duration of Experience" required>
          {getFieldDecorator('duration', {
            initialValue: initialValues.duration,
            rules: [{ required: true, message: 'Duration is a required field' }]
          })(
            <Select
              placeholder="Select a Duration"
              disabled={loading || form.getFieldValue('experienceLevel') === 'DESIRE_TO_LEARN'}
            >
              {filteredDurations.map(d => (
                <Select.Option key={d} value={d}>
                  {d}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {form.getFieldValue('experienceLevel') !== 'DESIRE_TO_LEARN' ? (
          <Form.Item label="Last Used Date" required>
            {getFieldDecorator('lastUsedDate', {
              initialValue: initialValues.lastUsedDate,
              rules: [{ required: true, message: 'Last Used Date is a required field' }]
            })(
              <DatePicker.MonthPicker
                disabled={loading}
                format={'MMM YYYY'}
                disabledDate={d => !d || d.isAfter(moment())}
              />
            )}
          </Form.Item>
        ) : null}

        <Form.Item>
          {getFieldDecorator('interest', {
            initialValue: initialValues.interest,
            valuePropName: 'checked'
          })(
            <Checkbox disabled={loading || form.getFieldValue('experienceLevel') === 'DESIRE_TO_LEARN'}>
              Are you interested in using this in the future?
            </Checkbox>
          )}
        </Form.Item>
      </div>

      {!isEditModalMode && form.getFieldValue('experienceLevel') !== 'DESIRE_TO_LEARN' && (
        <>
          <Typography.Title level={3} style={{ marginTop: '1em' }}>
            Experience on Projects
          </Typography.Title>
          <Table
            rowKey={record => record.project.id}
            style={{ width: '100%' }}
            dataSource={projects}
            columns={columns}
            pagination={false}
            loading={loading}
            rowSelection={{
              selectedRowKeys: selectedProjectIds,
              onChange: onSelectChange
            }}
          />
        </>
      )}

      <ButtonRow style={{ marginTop: '2em' }}>
        <Button type="primary" htmlType="submit" onClick={onAddOneSkill}>
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
        {!isEditModalMode &&
          (!isCreateMode &&
          ((isMe && user.permissions.canDeleteOwnSkill) ||
            (isCoach && user.permissions.canDeleteCoacheeSkill) ||
            user.permissions.canDeleteSkill) ? (
            <Button onClick={handleRemoveSkill}>Remove Skill</Button>
          ) : (
            <Button htmlType="submit" onClick={onAddMultipleSkills}>
              Save {'&'} add another
            </Button>
          ))}
      </ButtonRow>
    </Form>
  );
};

EmployeeSkillForm.propTypes = {
  employeeId: PropTypes.number,
  projectExperiences: PropTypes.array,
  employeeSkill: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: 'employee_skill_form' })(EmployeeSkillForm);
