import { get, post } from "api";
import { EmployeePrompt, EmployeePromptRequest } from "types/EmployeePrompt";
import { EmployeePromptResponseDTO } from "types/EmployeePromptResponse";

export function getEmployeePrompts(
  employeeId: number
): Promise<EmployeePrompt[]> {
  return get(`api/employees/${employeeId}/prompts`);
}

export function savePromptResponse(
  promptId: number,
  employeeId: number,
  value: string
): Promise<EmployeePrompt> {
  const body: EmployeePromptRequest = {
    promptId,
    value
  };
  return post(`api/employees/${employeeId}/prompts`, body);
}

export function getAllEmployeeResponses(
  promptId: number
): Promise<EmployeePromptResponseDTO[]> {
  return get(`api/prompts/${promptId}/responses`);
}
