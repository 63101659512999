import { notification } from 'antd';

const displayErrorNotification = error => {
  if (error instanceof Error) {
    if (error instanceof TypeError) {
      notification.error({
        message: 'An error occured, see console for more information'
      });
      console.error(error.message);
      console.error(error.stack);
      return;
    }
    if (!error.response) {
      notification.error({
        message: `No Response Received. API Server might be down, please try again or else contact systems@credera.com.`
      });
      return;
    }
    // to catch validation errors 
    if (error.response.data.errors !== undefined && error.response.data.errors.length > 0) {
      error.response.data.errors.forEach(e => {
        notification.error({
          message: `${error.response.data.status} ${error.response.data.error}`,
          description: e.defaultMessage
        })
      });
    } else {
      notification.error({
        message: `${error.response.data.status} ${error.response.data.error}`,
        description: error.response.data.message
      });
    }
  } else if (typeof error === 'string') {
    notification.error({
      message: 'An Error Occurred',
      description: error
    });
  } else {
    throw Error('Error type not supported');
  }
};

export default displayErrorNotification;
