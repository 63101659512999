import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import OverflowText from 'components/common/OverflowText';
import formatDate from 'utils/formatDate';

const BadgeCard = ({ badge, assignedOn, issuedOn, isMe }) => {
  return (
    <Link
      to={{
        pathname: `/badges/${badge.id}`,
        state: {
          categoryId: badge.categoryId
        }
      }}
    >
      <Card
        className="avatar-card"
        hoverable={true}
        style={{ height: '100%' }}
        actions={
          assignedOn && !issuedOn && isMe
            ? [
                <Link to={`/badges/${badge.id}`}>
                  <Icon type="check" style={{ display: 'inline' }} /> Complete Badge
                </Link>
              ]
            : null
        }
      >
        <Card.Meta
          avatar={
            (badge.imagePath || badge.image) && (
              <Avatar
                size="large"
                shape="square"
                src={badge.imagePath ? badge.imagePath : badge.image}
                style={{ height: '60px', width: '60px' }}
              />
            )
          }
          title={<OverflowText numLines={2}>{badge.name}</OverflowText>}
          description={
            <div>
              <OverflowText style={{ marginBottom: '8px' }}>{badge.description}</OverflowText>
              {badge.employeesReceived != null ? (
                <>
                  <Icon type="user" /> {badge.employeesReceived} Recipient{badge.employeesReceived === 1 ? '' : 's'}
                </>
              ) : null}
              {assignedOn != null ? (
                <>
                  <Icon type="calendar" /> Assigned on {formatDate(assignedOn)}
                  {issuedOn != null && <br />}
                </>
              ) : null}
              {issuedOn != null ? (
                <>
                  <Icon type="calendar" /> Issued on {formatDate(issuedOn)}
                </>
              ) : null}
            </div>
          }
        />
      </Card>
    </Link>
  );
};

BadgeCard.propTypes = {
  badge: PropTypes.shape({
    id: PropTypes.number
  })
};

export default BadgeCard;
