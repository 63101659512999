import React, { useEffect, useState } from 'react';
import { Button, Card, Icon, Typography, Modal, Radio } from 'antd';
import CardCollapse from 'components/common/collapse/CardCollapse';
import displayErrorNotification from 'utils/displayErrorNotification';
import './EmployeeEntityCard.css';
import { employeeType } from 'types';
import EmployeeEntityForm from './EmployeeEntityForm';
import { getEntitiesSelect, updateEmployeeEntity } from 'api/entitiesApi';
import { EntitySelect } from 'types/EntitySelect';

const EmployeeEntityCard = ({ employee, isEditable }) => {
  const [isModalHidden, setIsModalHidden] = useState(false);
  const [value, setValue] = useState<EntitySelect>(employee.entity);
  const [entitiesSelect, setEntitiesSelect] = useState<EntitySelect[]>([]);
  const style = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginLeft: '0px'
  };

  useEffect(() => {
    closeModal();
  }, []);

  const closeModal = () => {
    setIsModalHidden(false);
  };

  const getEntity = id => {
    let found = entitiesSelect.find(entity => entity.id.toString() === id);
    return found ? found : ({ id: 0, name: 'Entity Not Found' } as EntitySelect);
  };

  const afterSubmit = response => {
    closeModal();
    setValue(getEntity(response));
  };

  const handleSubmit = response => {
    updateEmployeeEntity(employee.id, response)
      .then(() => afterSubmit(response))
      .catch(displayErrorNotification);
  };

  const handleOpenModal = () => {
    setIsModalHidden(!isModalHidden);
    if (entitiesSelect.length === 0) {
      getEntitiesSelect()
        .then(setEntitiesSelect)
        .catch(displayErrorNotification);
    }
  };

  return (
    <>
      <CardCollapse.Card>
        <Card
          className="employee-entity-card"
          hoverable={true}
          style={{ height: '100%' }}
          actions={
            isEditable
              ? [
                  <Button type="link" onClick={handleOpenModal}>
                    <Icon type="edit" style={{ display: 'inline' }} />
                  </Button>
                ]
              : []
          }
        >
          <Card.Meta
            title="Credera Entity"
            description={
              <>
                <Typography.Paragraph>{value.name && <div>{value.name}</div>}</Typography.Paragraph>
              </>
            }
          />
        </Card>
      </CardCollapse.Card>
      <Modal title="About Me" visible={isModalHidden} onCancel={() => closeModal()} footer={null}>
        <h2>Credera Entity</h2>
        <EmployeeEntityForm onSubmit={handleSubmit} value={value.id.toString()}>
          <Radio.Group>
            {entitiesSelect.map(o => (
              <Radio key={o.id} value={o.id.toString()} style={style}>
                {o.name}
              </Radio>
            ))}
          </Radio.Group>
        </EmployeeEntityForm>
      </Modal>
    </>
  );
};

EmployeeEntityCard.propTypes = {
  employee: employeeType
};

export default EmployeeEntityCard;
