import React from 'react';
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd';
import { debounce, uniq } from 'lodash';
import { CertificationSummary } from 'types/CertificationSummary';
import { CertifyingOrganization } from 'types/CertifyingOrganization';
import { CertificationFilterSettings } from 'types/CertificationPreferences';

type CertificationFiltersProps = {
  certifications: CertificationSummary[];
  certifyingOrgs: CertifyingOrganization[];
  onSearch: (filteredCerts: CertificationSummary[], filteredCertOrgs: CertifyingOrganization[]) => void;
  form: any;
  searchRef: any;
  disabled: boolean;
  settings: CertificationFilterSettings;
  setSettings: (settings: CertificationFilterSettings) => void;
};

export const filterCertifications = (props: CertificationFiltersProps, changedValues: any, values: any) => {
  const { certifications, certifyingOrgs, onSearch, setSettings } = props;

  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const certifyingOrgNames = values.selectedCertifyingOrgNames;
  const showOnlyChannelPartners = values.showOnlyChannelPartners;
  const showInactive = values.showInactive;

  setSettings({
    search: values.searchTerm,
    organization: certifyingOrgNames,
    partner: showOnlyChannelPartners,
    inActive: showInactive
  });

  const filteredCertifications = certifications.filter(c => {
    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      c.name.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      c.certifyingOrganization.name.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isCertifyingOrgValid =
      !certifyingOrgNames ||
      !certifyingOrgNames.length ||
      certifyingOrgNames.indexOf(c.certifyingOrganization.name) > -1;
    let isChannelPartnerValid = !showOnlyChannelPartners || c.certifyingOrganization.channelPartner;
    let isStatusValid = showInactive || !c.inactive;
    return isNameValid && isCertifyingOrgValid && isChannelPartnerValid && isStatusValid;
  });

  const filterIsCleared =
    !uppercaseTerm && !showOnlyChannelPartners && (!certifyingOrgNames || !certifyingOrgNames.length);
  if (filterIsCleared) {
    onSearch(filteredCertifications, certifyingOrgs);
  } else {
    const filteredCertsCertOrgIds = uniq(filteredCertifications.map(c => c.certifyingOrganization.id));
    const filteredCertOrgs = certifyingOrgs.filter(co => {
      let inFilteredCerts = filteredCertsCertOrgIds.includes(co.id);
      let isNameValid = !uppercaseTerm || co.name.toUpperCase().indexOf(uppercaseTerm) > -1;
      let isSelectedCertOrgValid =
        !certifyingOrgNames || !certifyingOrgNames.length || certifyingOrgNames.includes(co.name);
      let isChannelPartnerValid = !showOnlyChannelPartners || co.channelPartner;
      return inFilteredCerts || (isChannelPartnerValid && isSelectedCertOrgValid && isNameValid);
    });
    onSearch(filteredCertifications, filteredCertOrgs);
  }
};

const CertificationFilters = (props: CertificationFiltersProps) => {
  const { certifyingOrgs, form, disabled, searchRef, settings } = props;
  const { getFieldDecorator } = form;
  const selectedCertifyingOrgNames = form.getFieldValue('selectedCertifyingOrgNames') || [];

  const clearFilters = () => {
    form.resetFields();
    filterCertifications(
      props,
      {},
      {
        searchTerm: '',
        selectedCertifyingOrgNames: [],
        showOnlyChannelPartners: false,
        showInactive: false
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        {/*<Icon type="filter" />*/}
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} disabled={disabled} ref={searchRef} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCertifyingOrgNames', {
            initialValue: settings.organization
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCertifyingOrgNames.length} Certifying Organization${
                selectedCertifyingOrgNames.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Certifying Organization"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {certifyingOrgs.map(p => (
                <Select.Option key={p.name} value={p.name}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showOnlyChannelPartners', {
            initialValue: settings.partner,
            valuePropName: 'checked'
          })(<Checkbox>Only show Channel Partners</Checkbox>)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('showInactive', { initialValue: settings.inActive, valuePropName: 'checked' })(
            <Checkbox>Show Inactive</Checkbox>
          )}
        </Form.Item>

        <Form.Item>
          {/*<Button htmlType="submit" type="link" icon="search" />*/}
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create<CertificationFiltersProps>({
  name: 'filter_certifications_form',
  onValuesChange: debounce(filterCertifications, 500)
})(CertificationFilters);
