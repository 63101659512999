import React from 'react';
import { Card, Typography } from 'antd';
import CardCollapse from 'components/common/collapse/CardCollapse';
import './MajorMinorCard.css';
import { Employee } from 'types/Employee';
import { getMinor, getMajor } from 'utils/skillDetails';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { useEffect, useState } from 'react';
import displayErrorNotification from 'utils/displayErrorNotification';
import MajorMinorCardModal from './MajorMinorCardModal';
import { getMajorMinorForEmployee } from 'api/employeeMajorMinorApi';
import { getEmployee } from 'api/employeeApi';

type MajorAndMinorCardProps = {
  employee: Employee;
  isMe: boolean;
  canEdit: boolean;
  isAdmin: boolean;
  onCloseForm: () => void;
};

const MajorAndMinorCard = ({ employee, isMe, canEdit, isAdmin, onCloseForm }: MajorAndMinorCardProps) => {
  const [minorName, setMinorName] = useState<string>(getMinor(employee));
  const [majorName, setMajorName] = useState<string>(getMajor(employee));
  const [isModalHidden, setIsModalHidden] = useState(true);
  const [newEmployee, setNewEmployee] = useState<Employee>(employee);

  useEffect(() => {
    closeModal();
  }, []);

  const closeModal = () => {
    setIsModalHidden(true);
  };

  const afterSubmit = async response => {
    const empl = await getEmployee(response.employeeId);
    setMajorName(getMajor(empl));
    setMinorName(getMinor(empl));
    onCloseForm();
    closeModal();
  };

  const handleSubmit = async response => {
    setNewEmployee(await getEmployee(response.employeeId));
    afterSubmit(response);
  };

  const handleOpenModal = () => {
    setIsModalHidden(!isModalHidden);
    getMajorMinorForEmployee(employee.id)
      .catch(displayErrorNotification);
  };

  return (
    <>
      <CardCollapse.Card>
        <Card hoverable={true}
          className="major-minor-card"
          actions={
            canEdit
              ? [
                <Button type="link" onClick={handleOpenModal}>
                  <Icon type="edit" style={{ display: 'inline' }} />
                </Button>
              ]
              : []
          }
        >
          <Card.Meta
            title="Major and Minor"
            description={
              <>
                <Typography.Paragraph>
                  <div>
                    Major:{' '}
                    {majorName === 'No Major' ? (
                      majorName
                    ) : (
                      newEmployee.major.isCapability ?  
                        <Link to={`/capabilities/${newEmployee.major.capability!.id}`}>
                          <Icon type="wallet" style={{paddingLeft: '2px', paddingRight: '5px'}}/>
                          {majorName}
                        </Link>
                        : <Link to={`/industries/${newEmployee.major.industry!.id}`}>
                            <Icon type="shop" style={{paddingLeft: '2px', paddingRight: '5px'}}/>
                            {majorName}
                          </Link>
                    )}
                  </div>
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <div>
                    Minor:{' '}
                    {minorName === 'No Minor' ? (
                      minorName
                    ) : (
                      newEmployee.minor.isCapability ? 
                        <Link to={`/capabilities/${newEmployee.minor.capability!.id}`}>
                          <Icon type="wallet" style={{paddingLeft: '2px', paddingRight: '5px'}}/>
                          {minorName}
                        </Link>
                        : 
                        <Link to={`/industries/${newEmployee.minor.industry!.id}`}>
                          <Icon type="shop" style={{paddingLeft: '2px', paddingRight: '5px'}}/>
                          {minorName}
                        </Link>
                      
                    )}
                  </div>
                </Typography.Paragraph>

                <Typography.Paragraph className="major-minor-help">
                  {(isMe && (
                    <div>Work with your Capability or Squad Leader if you are considering changing or adding a Major or Minor.</div>
                  ))}
                </Typography.Paragraph>
              </>
            }
          />
        </Card>
      </CardCollapse.Card>
      {isModalHidden ? null
        : <MajorMinorCardModal
          modalState={true}
          closeModal={() => setIsModalHidden(true)}
          onSubmit={handleSubmit}
          employeeId={employee.id} />}
    </>
  );
};

export default MajorAndMinorCard;