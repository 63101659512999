import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getRankedEmployees } from 'api/employeeApi';
import { List, Avatar } from 'antd';
import displayErrorNotification from 'utils/displayErrorNotification';

const MostBadges = props => {
  const [employeesList, setEmployeesList] = useState([]);

  useEffect(() => {
    getRankedEmployees(25)
      .then(response => {
        setEmployeesList(response);
      })
      .catch(displayErrorNotification);
  }, []);

  return (
    <List
      itemLayout="horizontal"
      dataSource={employeesList}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
            title={
              <Link to={`employees/${item.id}`}>
                {index + 1}. {item.firstName} {item.lastName}
              </Link>
            }
            description={`${item.employeeBadgeCount} ${item.employeeBadgeCount === 1 ? 'badge' : 'badges'}`}
          />
        </List.Item>
      )}
    />
  );
};

export default MostBadges;
