import React, { useState, useEffect } from 'react';
import { getReportsForUser } from 'api/reportsApi';
import { Table, Typography } from 'antd';
import Stack from 'components/common/Stack';
import { Link } from 'react-router-dom';
import { useUser } from 'auth/UserHooks';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const ReportsTable = props => {
  const user = useUser();
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(false);

  const _refreshReports = () => {
    return setReports(getReportsForUser(user));
  };

  const tableColumns = [
    {
      key: 'name',
      title: 'Name',
      width: '20%',
      render: record => <Link to={`/reports/${record.path}`}>{record.name}</Link>
    },
    {
      key: 'description',
      title: 'Description',
      width: '80%',
      render: record => <Typography>{record.description}</Typography>
    }
  ];

  useEffect(() => {
    setLoading(true);
    _refreshReports();
    setLoading(false);
  }, []);

  return (
    <>
      <Stack>
        <Typography.Title level={1}>Reports</Typography.Title>
        <Table columns={tableColumns} dataSource={reports} loading={loading} rowKey="id" pagination={PAGINATION_DEFAULT}/>
      </Stack>
    </>
  );
};

export default ReportsTable;
