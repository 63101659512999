import { Button, Input, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import { getLog, getLogCategories, getLogLevels } from "api/logApi";
import ButtonRow from "components/common/ButtonRow";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import Stack from "components/common/Stack";
import Toolbar from "components/common/Toolbar";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { defaultLogFilterSettings, Log, LogFilterSettings } from "types/Log";
import displayErrorNotification from "utils/displayErrorNotification";
import sorters from "utils/sorters";
import useLocalStorage from "utils/useLocalStorage";
import LogFilters, { filterLog } from "./LogFilters";

const LogLandingPage = () => {
  const [log, setLog] = useState<Log[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [levels, setLevels] = useState<string[]>([]);
  const [filteredLog, setFilteredLog] = useState<Log[]>([]);
  const [filterSettings, setFilterSettings] = useLocalStorage<
    LogFilterSettings
  >("LogLandingPage_FilterSettings", defaultLogFilterSettings());
  const [sortSettings, setSortSettings] = useLocalStorage<any>(
    "LogLandingPage_SortSettings",
    {
      key: "created",
      order: "descend"
    }
  );
  const [loading, setLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const searchRef = useRef<Input>();

  if (
    filterSettings &&
    filterSettings.range &&
    filterSettings.range.length === 2 &&
    typeof filterSettings.range[0] === "string"
  ) {
    filterSettings.range = [
      moment(filterSettings.range[0]),
      moment(filterSettings.range[1])
    ];
    setFilterSettings(filterSettings);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([getLog(), getLogCategories(), getLogLevels()])
      .then(responses => {
        setLog(responses[0]);
        setCategories(responses[1]);
        setLevels(responses[2]);
        filterLog(
          {
            onSearch: handleFilter,
            log: responses[0],
            categories: responses[1],
            levels: responses[2],
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            form: null,
            searchRef: null
          },
          [],
          {
            searchTerm: filterSettings.search,
            selectedCategories: filterSettings.category,
            selectedLevels: filterSettings.level,
            selectedRange: filterSettings.range
          }
        );
        setLoading(false);
      })
      .catch(displayErrorNotification);
  }, [refreshKey]);

  const refresh = () => {
    setRefreshKey(old => old + 1);
  };

  let columns: ColumnProps<Log>[] = [
    {
      title: "Created",
      key: "created",
      dataIndex: "created",
      width: "160px",
      sorter: (a, b) => sorters.dateIndex(a.created, a.id, b.created, b.id),
      render: created =>
        moment
          .utc(created)
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
      width: "335px",
      sorter: (a, b) => sorters.string(a.category, b.category)
    },
    {
      title: "Level",
      key: "level",
      dataIndex: "level",
      width: "65px",
      sorter: (a, b) => sorters.string(a.level, b.level)
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
      sorter: (a, b) => sorters.string(a.message, b.message)
    }
  ];

  columns = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  const handleFilter = (_filteredLog: Log[]) => {
    setFilteredLog(_filteredLog);
  };

  return (
    <Stack>
      <Typography.Title level={1}>
        Log
        <ButtonRow style={{ float: "right" }}>
          <Button icon="reload" onClick={refresh} loading={loading}>
            Refresh
          </Button>
          <SavePreferencesButton
            keys={[
              "LogLandingPage_FilterSettings",
              "LogLandingPage_SortSettings"
            ]}
            disabled={loading}
          />
        </ButtonRow>
      </Typography.Title>
      <Toolbar>
        <LogFilters
          onSearch={handleFilter}
          log={log}
          categories={categories}
          levels={levels}
          searchRef={searchRef}
          disabled={loading}
          settings={filterSettings}
          setSettings={setFilterSettings}
        />
        <div>
          Showing {filteredLog.length} of {log.length} Log entries
        </div>
      </Toolbar>
      <Table
        columns={columns}
        dataSource={filteredLog}
        loading={loading}
        rowKey="id"
        pagination={false}
        size="small"
        onChange={onChange}
      />
    </Stack>
  );
};

export default LogLandingPage;
