import React from 'react'
import CapabilityForm from './CapabilityForm'
import Stack from 'components/common/Stack'
import { trackComponent } from 'telemetry/AppInsights'
import { RouteComponentProps } from 'react-router-dom'
import { CapabilityDetail } from 'types/CapabilityDetail'

const NewCapabilityPage = (props: RouteComponentProps) => {
  const handleSubmit = (response: CapabilityDetail) => {
    props.history.push(`/capabilities/${response.id}`)
  }
  const handleCancel = () => {
    props.history.push('/capabilities')
  }
  return (
    <Stack>
      <CapabilityForm onSubmit={handleSubmit} onCancel={handleCancel} onDelete={handleCancel}/>
    </Stack>
  )
}
export default trackComponent(NewCapabilityPage, 'New Capability Page')
