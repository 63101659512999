import React, { useState } from "react";
import { Form, Button, Input, Checkbox, Spin } from "antd";
import ButtonRow from "components/common/ButtonRow";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte";
import ImageUpload from "components/common/form/ImageUpload";
import { richTextEditorToolbarConfig } from "utils/richTextEditorToolbarConfig";
import {
  createCertifyingOrganization,
  updateCertifyingOrganization
} from "api/certifyingOrganizationApi";
import displayErrorNotification from "utils/displayErrorNotification";
import { S3_IMAGE_BASE_URL } from "config";

const CertifyingOrganizationForm = ({
  certifyingOrg,
  isCreateMode,
  onSubmit,
  onCancel,
  form
}) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const initialValues = isCreateMode
    ? { description: RichTextEditor.createEmptyValue() }
    : {
        ...certifyingOrg,
        description: certifyingOrg.description
          ? RichTextEditor.createValueFromString(
              certifyingOrg.description,
              "markdown"
            )
          : RichTextEditor.createEmptyValue()
      };
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = form;

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) return;

      setLoading(true);
      const certifyingOrgValues = {
        ...values,
        description: values.description.toString("markdown"),
        channelPartner: !!values.channelPartner,
        imagePath: isCreateMode ? null : certifyingOrg.imagePath,
        image: uploadedImage
      };

      if (isCreateMode) {
        createCertifyingOrganization(certifyingOrgValues)
          .then(onSubmit)
          .catch(error => {
            setLoading(false);
            displayErrorNotification(error);
          });
      } else {
        updateCertifyingOrganization(certifyingOrg.id, certifyingOrgValues)
          .then(onSubmit)
          .catch(error => {
            setLoading(false);
            displayErrorNotification(error);
          });
      }
    });
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        style={{ maxWidth: "40em" }}
        onSubmit={handleSubmit}
      >
        <Form.Item label="Certifying Organization Name" required>
          {getFieldDecorator("name", {
            initialValue: initialValues.name,
            rules: [
              {
                required: true,
                message: "Certifying Organization name is a required field"
              },
              {
                max: 60,
                message:
                  "Certifying Organization name must be fewer than 60 characters"
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: initialValues.description
          })(
            <RichTextEditor
              className={"rich-text-editor"}
              toolbarConfig={richTextEditorToolbarConfig}
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator("channelPartner", {
            initialValue: initialValues.channelPartner,
            valuePropName: "checked"
          })(<Checkbox>Channel Partner</Checkbox>)}
        </Form.Item>

        <Form.Item label="Certifying Organization Logo Icon">
          <ImageUpload maxSize={48000} onChange={setUploadedImage}>
            {(uploadedImage || initialValues.imagePath) && (
              <img
                style={{ width: "100%" }}
                src={
                  uploadedImage || S3_IMAGE_BASE_URL + initialValues.imagePath
                }
                alt="Certifying Organization"
              />
            )}
          </ImageUpload>
        </Form.Item>

        <ButtonRow style={{ marginTop: "1em" }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </ButtonRow>
      </Form>
    </Spin>
  );
};

CertifyingOrganizationForm.propTypes = {
  isCreateMode: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default Form.create({ name: "certifying_organization_form" })(
  CertifyingOrganizationForm
);
