import React from "react";
import PropTypes from "prop-types";
import { Input, Select, Button, Form, Icon } from "antd";
import { debounce } from "lodash";
import sorters from "utils/sorters";

export const filterCompletionReport = (props, changedValues, values) => {
  const { responses, onSearch, setSettings } = props;

  const uppercaseTerm = values.searchTerm
    ? values.searchTerm.toUpperCase()
    : "";
  const practices = values.selectedPractices;
  const positions = values.selectedPositions;
  const offices = values.selectedOffices;

  setSettings({
    search: values.searchTerm,
    practice: practices,
    position: positions,
    office: offices
  });

  const filteredResponses = responses.filter(r => {
    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      r.name.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isPracticeValid =
      !practices || !practices.length || practices.indexOf(r.practice) > -1;
    let isPositionValid =
      !positions || !positions.length || positions.indexOf(r.position) > -1;
    let isOfficeValid =
      !offices || !offices.length || offices.indexOf(r.office) > -1;
    return isNameValid && isPracticeValid && isPositionValid && isOfficeValid;
  });
  onSearch(filteredResponses);
};

const CompletionReportFilters = props => {
  const { responses = [], form, settings, searchRef, disabled } = props;
  const practices = [
    ...new Set(
      responses
        .sort((a, b) => sorters.string(a.practice, b.practice))
        .map(r => r.practice)
        .filter(r => r !== undefined)
    )
  ];
  const positions = [
    ...new Set(
      responses
        .sort((a, b) => sorters.string(a.position, b.position))
        .map(r => r.position)
        .filter(r => r !== undefined)
    )
  ];
  const offices = [
    ...new Set(
      responses
        .sort((a, b) => sorters.string(a.office, b.office))
        .map(r => r.office)
        .filter(r => r !== undefined)
    )
  ];

  const clearFilters = () => {
    form.resetFields();
    filterCompletionReport(
      props,
      {},
      {
        searchTerm: "",
        selectedPractices: [],
        selectedPositions: [],
        selectedOffices: []
      }
    );
  };

  const { getFieldDecorator } = form;

  const selectedPractices = form.getFieldValue("selectedPractices") || [];
  const selectedPositions = form.getFieldValue("selectedPositions") || [];
  const selectedOffices = form.getFieldValue("selectedOffices") || [];

  return (
    <div style={disabled ? { pointerEvents: "none", opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator("searchTerm", {
            initialValue: settings.search
          })(
            <Input
              prefix={<Icon type="filter" />}
              ref={searchRef}
              disabled={disabled}
              placeholder="Filter"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedPractices", {
            initialValue: settings.practice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPractices.length} Practice${
                selectedPractices.length === 1 ? "" : "s"
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {practices.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedPositions", {
            initialValue: settings.position
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPositions.length} Position${
                selectedPositions.length === 1 ? "" : "s"
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Position"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {positions.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedOffices", {
            initialValue: settings.office
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedOffices.length} Office${
                selectedOffices.length === 1 ? "" : "s"
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Location"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {offices.map(o => (
                <Select.Option key={o} value={o}>
                  {o}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            title="Clear filters"
            icon="close"
            type="link"
            onClick={clearFilters}
          >
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

CompletionReportFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  responses: PropTypes.array
};

export default Form.create({
  name: "filter_comletion_report_form",
  onValuesChange: debounce(filterCompletionReport, 500)
})(CompletionReportFilters);
