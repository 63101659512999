import {Button} from "antd";
import {postEmployeesForExport} from "../../api/employeeApi";
import React from "react";

const EmployeeExportButton = ({
  filteredEmployees = [],
  showClients,
  loading,
  setLoading
}) => {
  const handleClick = filteredEmployees => {
    let employeeIDs = filteredEmployees.map(employee => employee.id);
    setLoading(true);
    postEmployeesForExport(employeeIDs, showClients).finally(() =>
      setLoading(false)
    );
  };

  return (
    <Button
      icon="download"
      disabled={loading}
      onClick={() => handleClick(filteredEmployees)}
    >
      Export
    </Button>
  );
};

export default EmployeeExportButton;
