import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form, Icon } from 'antd';
import { debounce } from 'lodash';

const filterUsers = (props, changedValues, values) => {
  const { users, onSearch, setSettings } = props;

  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';

  setSettings({
    search: values.searchTerm
  });

  const filteredUsers = users.filter(u => {
    let isNameValid =
      !uppercaseTerm || !uppercaseTerm.length || u.employee.fullName.toUpperCase().indexOf(uppercaseTerm) > -1;
    return isNameValid;
  });
  onSearch(filteredUsers);
};

const UserFilters = props => {
  const { form, searchRef, settings, disabled } = props;
  const clearFilters = () => {
    form.resetFields();
    filterUsers(
      props,
      {},
      {
        searchTerm: ''
      }
    );
  };

  const { getFieldDecorator } = form;

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

UserFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  users: PropTypes.array
};

export default Form.create({
  name: 'filter_user_access_form',
  onValuesChange: debounce(filterUsers, 500)
})(UserFilters);
