import { get, post, put } from "api";
import { Prompt } from "types/Prompt";

export function getPrompts(includeDeactivated: boolean): Promise<Prompt[]> {
  return get(`api/prompts`, { includeDeactivated });
}

export function getPrompt(promptId: number): Promise<Prompt> {
  return get(`api/prompts/${promptId}`);
}

export function createPrompt(prompt: Prompt): Promise<Prompt> {
  return post(`api/prompts`, prompt);
}

export function updatePrompt(
  promptId: number,
  prompt: Prompt
): Promise<Prompt> {
  return put(`api/prompts/${promptId}`, prompt);
}

export function togglePromptActivation(promptId: number): Promise<Prompt> {
  return post(`api/prompts/${promptId}`);
}
