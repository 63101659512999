import React from 'react';
import { Modal } from 'antd';
import EmployeeSkillForm from 'components/skills/employee-skill/EmployeeSkillForm';

const EditEmployeeSkillModal = props => {
  return (
    <Modal title={'Edit Skill'} visible={props.isModalVisible} onCancel={props.onCancel} footer={null}>
      <EmployeeSkillForm
        isEditModalMode
        employeeId={props.employeeId}
        employeeSkill={props.employeeSkill}
        onCancel={props.onCancel}
        onSubmit={props.onSubmit}
      />
    </Modal>
  );
};

export default EditEmployeeSkillModal;
