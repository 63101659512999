import React, {useEffect, useState} from "react";
import {first, groupBy, map} from "lodash";
import {getProjectById} from "api/projectApi";
import {getProjectExperienceByProjectId} from "api/projectExperienceApi";
import CardCollapse from "components/common/collapse/CardCollapse";
import ProjectSkillCard from "components/skills/ProjectSkillCard";
import Stack from "components/common/Stack";
import formatDate from "utils/formatDate";
import {Collapse, Icon, Table, Tag, Typography} from "antd";
import {Link} from "react-router-dom";
import {trackComponent} from "telemetry/AppInsights";
import AddNewCard from "components/common/AddNewCard";
import {useUser} from "auth/UserHooks";
import {getRoleTypeExperienceByProjectId} from "../../api/RoleTypeApi";
import RoleTypeExperienceCard from "./RoleTypeExperienceCard";
import {useFeatures} from "../../hooks/FeatureHooks";

const ProjectDetailPage = props => {
  const user = useUser();
  const features = useFeatures();
  const [isUsersProject, setIsUsersProject] = useState(false);
  const [project, setProject] = useState({});
  const [skills, setSkills] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const projectId = props.match.params.projectId;

  useEffect(() => {
    getProjectById(projectId).then(response => {
      setProject(response);
      setIsUsersProject(response.employees && response.employees.some(pe => pe.employee.id === user.id));
    });
    getProjectExperienceByProjectId(projectId).then(response => {
      const skillGroups = groupBy(response, 'employeeSkill.skill.id');
      const skillMap = map(skillGroups, s => {
        return {
          ...first(s).employeeSkill.skill,
          experiences: s.map(e => e.employeeSkill)
        };
      });
      setSkills(skillMap);
    });
    getRoleTypeExperienceByProjectId(projectId).then(response => {
      const roleTypeGroups = groupBy(response, 'employeeRoleType.roleType.id');
      const roleTypeMap = map(roleTypeGroups, roleTypeGroup => {
        return {
          ...first(roleTypeGroup).employeeRoleType.roleType,
          experiences: roleTypeGroup.map(e => e.employeeRoleType)
        };
      });
      setRoleTypes(roleTypeMap);
    });
  }, []);

  let activePanels = ['employees', 'skills', 'role types'];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'employee',
      render: record => (
        <Link to={`/employees/${record.id}`}>
          {record.lastName}, {record.firstName}
        </Link>
      ),
      sorter: (a, b) => (`${a.lastName}, ${a.firstName}` < `${b.lastName}, ${b.firstName}` ? -1 : 1)
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      sorter: (a, b) => (a.employee.practice < b.employee.practice ? -1 : 1)
    },
    {
      title: 'Position',
      dataIndex: 'employee.title',
      sorter: (a, b) => (a.employee.title < b.employee.title ? -1 : 1)
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      sorter: (a, b) => (a.employee.location < b.employee.location ? -1 : 1)
    },
    {
      title: 'Role',
      dataIndex: 'role'
    },
    {
      title: 'Role Type',
      dataIndex: 'roleType',
      render: rt => (rt ? <Link to={`/role-types/${rt.id}`}>{rt.name}</Link> : '')
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: formatDate,
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: formatDate,
      sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1)
    },
    {
      title: 'Hours / Week',
      dataIndex: 'hoursPerWeek',
      width: 120,
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      align: 'center',
      defaultSortOrder: 'descend',
      render: record => <Tag color={record ? 'green' : 'red'}>{record ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => (a.active < b.active ? -1 : 1)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      hidden: !showStatusColumn
    }
  ].filter(item => !item.hidden);

  useEffect(() => {
    if (features && features.isEnabled('ENABLE_PROJECTOR_FEATURES')) {
      setShowStatusColumn(true);
    }
  }, [features]);

  return (
    <Stack>
      <Typography.Title level={1} style={{ marginBottom: '0' }}>
        {project.name}
      </Typography.Title>
      <Typography.Title level={3} type="secondary" style={{ marginTop: '0', marginBottom: '2rem' }}>
        {project.client && <Link to={`/clients/${project.client.id}`}>{project.client.name}</Link>} · {project.practice}{' '}
        · {formatDate(project.startDate)}-{formatDate(project.endDate)}
      </Typography.Title>

      <Collapse bordered={false} defaultActiveKey={activePanels}>
        <CardCollapse.Panel
          key="employees"
          header={
            <>
              <Icon type="contacts" /> <span style={{ marginLeft: '5px' }}>Employees</span>
            </>
          }
          extra={
            <>
              <Tag>
                {project.employees && project.employees.length} Employee
                {project.employees && project.employees.length === 1 ? '' : 's'}
              </Tag>
            </>
          }
        >
          <Table
            style={{ width: '100%' }}
            pagination={{ position: 'none', pageSize: project.employees ? project.employees.length : 0 }}
            rowKey={record => record.employee.id}
            columns={columns}
            dataSource={project.employees}
          />
        </CardCollapse.Panel>

        <CardCollapse.Panel
          key="skills"
          header={
            <>
              <Icon type="compass" /> <span style={{ marginLeft: '5px' }}>Skills</span>
            </>
          }
          extra={
            <Tag>
              {skills.length} Skill{skills.length === 1 ? '' : 's'}
            </Tag>
          }
        >
          {skills
            ? skills.map(skill => (
                <CardCollapse.Card key={skill.id}>
                  <ProjectSkillCard skill={skill} />
                </CardCollapse.Card>
              ))
            : null}
          {isUsersProject && (
            <CardCollapse.Card>
              <AddNewCard title="Add New" link={{ pathname: `/projects/skills/new`, state: { project: project } }} />
            </CardCollapse.Card>
          )}
          {!isUsersProject && (!skills || !skills.length) && 'No skills'}
        </CardCollapse.Panel>

        <CardCollapse.Panel
          key="role types"
          header={
            <>
              <Icon type="team" /> <span style={{ marginLeft: '5px' }}>Role Types</span>
            </>
          }
          extra={
            <Tag>
              {roleTypes.length} Role Type{roleTypes.length === 1 ? '' : 's'}
            </Tag>
          }
        >
          {roleTypes
            ? roleTypes.map(roleType => (
                <CardCollapse.Card key={roleType.id}>
                  <RoleTypeExperienceCard roleType={roleType} />
                </CardCollapse.Card>
              ))
            : null}
          {!isUsersProject && (!roleTypes || !roleTypes.length) && 'No role types'}
        </CardCollapse.Panel>
      </Collapse>
    </Stack>
  );
};

export default trackComponent(ProjectDetailPage, 'Project Detail Page');
