export interface EntityFilterSettings {
  search: string;
  entity: string[];
  inActive: boolean;
}

export function defaultEntityFilterSettings(): EntityFilterSettings {
  return {
    search: '',
    entity: [],
    inActive: false
  };
}
