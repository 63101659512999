import { Button } from 'antd';
import React from 'react';
import { savePreferences } from 'utils/useLocalStorage';
import { notification } from 'antd';

type SavePreferencesButtonProps = {
  keys: string[];
  disabled: boolean;
};

const SavePreferencesButton = ({ keys, disabled }: SavePreferencesButtonProps) => {
  const handleClick = (keys: string[]) => {
    savePreferences(keys);
    notification.info({
      message: 'Your preferences for this page have been saved',
      duration: 2,
      placement: 'bottomRight'
    });
  };

  return (
    <Button icon="save" disabled={disabled} onClick={() => handleClick(keys)}>
      Save Preferences
    </Button>
  );
};

export default SavePreferencesButton;
