export interface CompletionReportFilterSettings {
  search: string;
  practice: string[];
  position: string[];
  office: string[];
}

export function defaultCompletionReportFilterSettings(): CompletionReportFilterSettings {
  return {
    search: '',
    practice: [],
    position: [],
    office: []
  };
}
