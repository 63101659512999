import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload, Empty } from 'antd';

// TODO: support drag and drop

const ImageUpload = ({ children, maxSize, onChange }) => {
  const validateImage = file => {
    const isSmolEnough = file.size <= maxSize;
    if (!isSmolEnough) {
      alert(`File is too large. Max file size is ${maxSize / 1000}KB. Consider compressing the file.`);
    }
    return isSmolEnough;
  };

  // instead of uploading to an API, set the data in state
  const readImage = info => {
    const reader = new FileReader();
    reader.onload = e => {
      onChange(e.target.result);
    };
    reader.readAsDataURL(info.file);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {children ? (
        <div style={{ margin: '0 auto', maxWidth: 150, maxHeight: 150 }}>{children}</div>
      ) : (
        <Empty description={false} />
      )}
      <Upload
        name="file"
        accept="image/*"
        beforeUpload={validateImage}
        customRequest={readImage}
        showUploadList={false}
      >
        <Button style={{ width: '100%', position: 'absolute', bottom: 0, left: 0 }}>Upload Image</Button>
      </Upload>
    </div>
  );
};

ImageUpload.propTypes = {
  maxSize: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default ImageUpload;
