import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Button, Form, Icon } from 'antd';
import { debounce } from 'lodash';
import { getMajor } from 'utils/skillDetails';

export const filterCareerCoachReport = (props, changedValues, values) => {
  const { responses, onSearch, setSettings } = props;

  const coacheeTerm = values.coacheeSearchTerm ? values.coacheeSearchTerm.toUpperCase() : '';
  const TMPractices = values.selectedTMPractices;
  const TMMajors = values.selectedTMMajors;
  const TMPositions = values.selectedTMPositions;
  const TMOffices = values.selectedTMOffices;

  const coachTerm = values.coachSearchTerm ? values.coachSearchTerm.toUpperCase() : '';
  const CCPractices = values.selectedCCPractices;
  const CCMajors = values.selectedCCMajors;
  const CCPositions = values.selectedCCPositions;
  const CCOffices = values.selectedCCOffices;

  setSettings({
    tmSearch: values.coacheeSearchTerm,
    tmPractice: TMPractices,
    tmMajors: TMMajors,
    tmPosition: TMPositions,
    tmOffice: TMOffices,
    ccSearch: values.coachSearchTerm,
    ccPractice: CCPractices,
    ccMajors: CCMajors,
    ccPosition: CCPositions,
    ccOffice: CCOffices
  });

  const filteredResponses = responses.filter(r => {
    let isCoacheeValid =
      !coacheeTerm || !coacheeTerm.length || r.coachee.fullName.toUpperCase().indexOf(coacheeTerm) > -1;
    let isTMPracticeValid = !TMPractices || !TMPractices.length || TMPractices.indexOf(r.coachee.practice) > -1;
    let isTMMajorValid =
      !TMMajors || !TMMajors.length || TMMajors.indexOf(getMajor(r.coachee)) > -1;
    let isTMPositionValid = !TMPositions || !TMPositions.length || TMPositions.indexOf(r.coachee.title) > -1;
    let isTMOfficeValid = !TMOffices || !TMOffices.length || TMOffices.indexOf(r.coachee.location) > -1;

    let isCoachValid = !coachTerm || !coachTerm.length || r.coach.fullName.toUpperCase().indexOf(coachTerm) > -1;
    let isCCPracticeValid = !CCPractices || !CCPractices.length || CCPractices.indexOf(r.coach.practice) > -1;
    let isCCMajorValid =
      !CCMajors || !CCMajors.length || CCMajors.indexOf(getMajor(r.coach)) > -1;
    let isCCPositionValid = !CCPositions || !CCPositions.length || CCPositions.indexOf(r.coach.title) > -1;
    let isCCOfficeValid = !CCOffices || !CCOffices.length || CCOffices.indexOf(r.coach.location) > -1;

    return (
      isCoacheeValid &&
      isTMPracticeValid &&
      isTMMajorValid &&
      isTMPositionValid &&
      isTMOfficeValid &&
      isCoachValid &&
      isCCPracticeValid &&
      isCCMajorValid &&
      isCCPositionValid &&
      isCCOfficeValid
    );
  });
  onSearch(filteredResponses);
};

const CareerCoachReportFilters = props => {
  const { responses = [], form, settings, searchRef, disabled } = props;
  const empls = [...responses.map(r => r.coachee), ...responses.map(r => r.coachee)];
  const practices = [
    ...new Set(
      empls
        .sort((a, b) => (a.practice < b.practice ? -1 : 1))
        .map(e => e.practice)
        .filter(e => e !== undefined)
    )
  ];
  const majors = [...new Set(empls.map(e => getMajor(e)))].sort();
  const positions = [...new Set(empls.sort((a, b) => (a.title < b.title ? -1 : 1)).map(l => l.title))];
  const offices = [...new Set(empls.map(e => e.location))].sort();

  const { getFieldDecorator } = form;

  const selectedTMPractices = form.getFieldValue('selectedTMPractices') || [];
  const selectedTMMajors = form.getFieldValue('selectedTMMajors') || [];
  const selectedTMPositions = form.getFieldValue('selectedTMPositions') || [];
  const selectedTMOffices = form.getFieldValue('selectedTMOffices') || [];

  const selectedCCPractices = form.getFieldValue('selectedCCPractices') || [];
  const selectedCCMajors = form.getFieldValue('selectedCCMajors') || [];
  const selectedCCPositions = form.getFieldValue('selectedCCPositions') || [];
  const selectedCCOffices = form.getFieldValue('selectedCCOffices') || [];

  const clearFilters = () => {
    form.resetFields();
    filterCareerCoachReport(
      props,
      {},
      {
        coacheeSearchTerm: '',
        selectedTMPractices: [],
        selectedTMMajors: [],
        selectedTMPositions: [],
        selectedTMOffices: [],
        coachSearchTerm: '',
        selectedCCPractices: [],
        selectedCCMajors: [],
        selectedCCPositions: [],
        ccOfselectedCCOfficesfice: []
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('coacheeSearchTerm', {
            initialValue: settings.tmSearch
          })(
            <Input
              prefix={<Icon type="filter" />}
              ref={searchRef}
              disabled={disabled}
              placeholder="Filter by Team Member"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedTMPractices', {
            initialValue: settings.tmPractice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedTMPractices.length} Practice${
                selectedTMPractices.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="TM Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {practices.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedTMMajors', {
            initialValue: settings.tmMajor
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedTMMajors.length} ${
                selectedTMMajors.length === 1 ? 'Major' : 'Majors'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="TM Major"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {majors.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedTMPositions', {
            initialValue: settings.tmPosition
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedTMPositions.length} Position${
                selectedTMPositions.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="TM Position"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {positions.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedTMOffices', {
            initialValue: settings.tmOffice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedTMOffices.length} Office${
                selectedTMOffices.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="TM Office"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {offices.map(o => (
                <Select.Option key={o} value={o}>
                  {o}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('coachSearchTerm', {
            initialValue: settings.ccSearch
          })(<Input prefix={<Icon type="filter" />} disabled={disabled} placeholder="Filter by Career Coach" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCCPractices', {
            initialValue: settings.ccPractice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCCPractices.length} Practice${
                selectedCCPractices.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="CC Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {practices.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCCMajors', {
            initialValue: settings.ccMajor
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCCMajors.length} ${
                selectedCCMajors.length === 1 ? 'Major' : 'Majors'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="CC Major"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {majors.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCCPositions', {
            initialValue: settings.ccPosition
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCCPositions.length} Position${
                selectedCCPositions.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="CC Position"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {positions.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('selectedCCOffices', {
            initialValue: settings.ccOffice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCCOffices.length} Office${
                selectedCCOffices.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="CC Office"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {offices.map(o => (
                <Select.Option key={o} value={o}>
                  {o}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

CareerCoachReportFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  responses: PropTypes.array
};

export default Form.create({
  name: 'filter_career_coach_report_form',
  onValuesChange: debounce(filterCareerCoachReport, 500)
})(CareerCoachReportFilters);
