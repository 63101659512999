import { CategorySortSettings } from './ColumnSortSettings';

export interface CertificationFilterSettings {
  search: string;
  organization: string[];
  partner: boolean;
  inActive: boolean;
}

export function defaultCertificationFilterSettings(): CertificationFilterSettings {
  return {
    search: '',
    organization: [],
    partner: false,
    inActive: false
  };
}

export function defaultCertificationSortSettings(): CategorySortSettings {
  return {
    categories: [],
    default: {
      key: 'name',
      order: 'ascend'
    }
  };
}
