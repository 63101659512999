import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Input, Icon, Button } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { getCurrentPeriod } from './keyMetricUtils';
import { defaultKeyMetricFilterSettings } from 'types/KeyMetricPreferences';

const filter = (props, changedValues, values) => {
  const { loadKeyMetrics, onSearch, setSettings } = props;

  if (changedValues.period) {
    const period = props.periods.filter(p => p.id === values.period)[0];
    loadKeyMetrics(period, values);
  }

  setSettings({
    search: values.name,
    period: values.period,
    practice: values.practice,
    office: values.location,
    position: values.title
  });

  if (
    changedValues.name ||
    values.name === '' ||
    changedValues.practice ||
    changedValues.location ||
    changedValues.title
  ) {
    onSearch(values.name, values.practice, values.location, values.title);
  }
};

const KeyMetricsFilters = ({
  periods = [],
  practices = [],
  locations = [],
  titles = [],
  loadKeyMetrics,
  onSearch,
  form,
  settings,
  setSettings,
  searchRef,
  disabled
}) => {
  const formatDate = date => {
    return moment(date).format('MMMM D, YYYY');
  };

  const clearFilters = () => {
    const currentPeriod = getCurrentPeriod(periods);
    const reload = currentPeriod.id !== form.getFieldValue('period');
    setSettings(defaultKeyMetricFilterSettings());
    form.resetFields();
    if (reload) {
      loadKeyMetrics(currentPeriod);
    }
    onSearch('');
  };

  const { getFieldDecorator } = form;
  const selectedPractices = form.getFieldValue('practice') || [];
  const selectedOffices = form.getFieldValue('location') || [];
  const selectedPositions = form.getFieldValue('title') || [];
  const periodInitialValue = settings.period === 0 ? getCurrentPeriod(periods).id : settings.period;

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item label="Period">
          {getFieldDecorator('period', { initialValue: periodInitialValue })(
            <Select
              placeholder="Select a period"
              dropdownClassName="filter-bar-select-dropdown"
              className="key-metrics-select"
              dropdownMatchSelectWidth={false}
            >
              {periods.map(p => (
                <Select.Option key={p.id} value={p.id}>
                  {formatDate(p.startDate)} - {formatDate(p.endDate)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <br />

        <Form.Item>
          {getFieldDecorator('name', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('practice', {
            initialValue: settings.practice
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPractices.length} Practice${
                selectedPractices.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {practices.map(p => (
                <Select.Option key={p} value={p}>
                  {p}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('location', {
            initialValue: settings.office
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedOffices.length} Office${selectedOffices.length === 1 ? '' : 's'} Selected`}
              showArrow
              mode="multiple"
              placeholder="Office"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {locations.map(l => (
                <Select.Option key={l} value={l}>
                  {l}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('title', {
            initialValue: settings.position
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedPositions.length} Position${
                selectedPositions.length === 1 ? '' : 's'
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Position"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {titles.map(l => (
                <Select.Option key={l} value={l}>
                  {l}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

KeyMetricsFilters.propTypes = {
  loadKeyMetrics: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  periods: PropTypes.array
};

export default Form.create({
  name: 'filter_key_metrics',
  onValuesChange: debounce(filter, 500)
})(KeyMetricsFilters);
