import { EmployeeBusinessUnit } from "./EmployeeBusinessUnit";

export interface EmployeePractice {
  id: number
  name: string
  abbrev: string
  description: string
  businessUnit?: EmployeeBusinessUnit | undefined
  displayOrder: number
  inactive: boolean 
}

export const EmployeePracticeNotApplicable = 1;