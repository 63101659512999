export interface EmployeeFilterSettings {
  search: string;
  practice: string[];
  businessUnit: string[];
  position: string[];
  level: number[];
  region: string[];
  location: string[];
  entity: string[];
  major: string[];
  minor: string[];
  client: string[];
  project: string[];
  hours: string;
  endDate: string;
}

export function defaultEmployeeFilterSettings(): EmployeeFilterSettings {
  return {
    search: "",
    practice: [],
    businessUnit: [],
    position: [],
    level: [],
    region: [],
    location: [],
    entity: [],
    major: [],
    minor: [],
    client: [],
    project: [],
    hours: "",
    endDate: ""
  };
}

export interface AdvancedEmployeeFilterSettings {
  search: string;
  practice: string[];
  position: string[];
  level: number[];
  region: string[];
  location: string[];
  entity: string[];
  capability: string[];
  minorOrMajor: string[];
  skills: string[];
  skillExpLevel: string[];
  skillSearchType: boolean;
  certification: string[];
  certActive: string[];
  roleType: string[];
  prExpLevel: string[];
  prompt: string[];
  client: string[];
  project: string[];
  hours: string;
  endDate: string | null;
}

export function defaultAdvancedEmployeeFilterSettings(): AdvancedEmployeeFilterSettings {
  return {
    search: "",
    practice: [],
    position: [],
    level: [],
    region: [],
    location: [],
    entity: [],
    capability: [],
    minorOrMajor: [],
    skills: [],
    skillExpLevel: [],
    skillSearchType: false,
    certification: [],
    certActive: [],
    roleType: [],
    prExpLevel: [],
    prompt: [],
    client: [],
    project: [],
    hours: "",
    endDate: ""
  };
}
