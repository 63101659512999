import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { List, Avatar } from 'antd';
import moment from 'moment';
import { getBadgeRecipientsByDate } from 'api/badgesApi';

const BadgeHistory = props => {
  const [badgeAssignments, setBadgeAssignments] = useState([]);

  useEffect(() => {
    getBadgeRecipientsByDate(25).then(response => {
      setBadgeAssignments(response);
    });
  }, []);

  return (
    <List
      itemLayout="horizontal"
      dataSource={badgeAssignments}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={item.badge.imagePath ? item.badge.imagePath : item.badge.image} />}
            title={
              <>
                <Link to={`employees/${item.employee.id}`}>{item.employee.fullName}</Link> received the{' '}
                <Link to={`badges/${item.badge.id}`}>{item.badge.name}</Link> badge
              </>
            }
            description={moment(item.issuedOn).calendar()}
          />
        </List.Item>
      )}
    />
  );
};

export default BadgeHistory;
