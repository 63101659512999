import { get, post } from 'api';
import { Session } from '../types/Session';

export function getActiveSessions(): Promise<Session[]> {
  return get('api/security/active_sessions');
}

export function killSession(token): Promise<void> {
  return post(`api/security/kill_session/${token}`);
}
