import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd';
import { debounce } from 'lodash';

const filterProjects = (props, changedValues, values) => {
  const { projects, onSearch } = props;
  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const practices = values.selectedPractices;
  const showInactive = values.showInactive;
  const filteredProjects = projects.filter(p => {
    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      p.name.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      p.client.name.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isPracticeValid = !practices || !practices.length || practices.indexOf(p.practice) > -1;
    let isStatusValid = showInactive || p.active;
    return isNameValid && isPracticeValid && isStatusValid;
  });
  onSearch(filteredProjects);
};

const ClientProjectFilters = ({ projects = [], onSearch, form }) => {
  const practices = [...new Set(projects.map(e => e.practice))].sort();

  const clearFilters = () => {
    form.resetFields();
    onSearch(projects);
  };

  const { getFieldDecorator } = form;

  const selectedPractices = form.getFieldValue('selectedPractices') || [];

  return (
    <Form layout="inline">
      <Form.Item>
        {getFieldDecorator('searchTerm', {})(<Input prefix={<Icon type="filter" />} autoFocus placeholder="Filter" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('selectedPractices', {})(
          <Select
            maxTagCount={0}
            maxTagPlaceholder={`${selectedPractices.length} Practice${
              selectedPractices.length === 1 ? '' : 's'
            } Selected`}
            showArrow
            mode="multiple"
            placeholder="Practice"
            dropdownClassName="filter-bar-select-dropdown"
            className="filter-bar-select"
          >
            {practices.map(p => (
              <Select.Option key={p} value={p}>
                {p}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('showInactive', { initialValue: true, valuePropName: 'checked' })(
          <Checkbox>Show Inactive Projects</Checkbox>
        )}
      </Form.Item>
      <Form.Item>
        <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
          Clear filters
        </Button>
      </Form.Item>
    </Form>
  );
};

ClientProjectFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  projects: PropTypes.array
};

export default Form.create({
  name: 'filter_projects_form',
  onValuesChange: debounce(filterProjects, 500)
})(ClientProjectFilters);
