import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import SessionTable from './SessionTable';
import { getActiveSessions, killSession } from '../../../api/sessionApi';

const SessionLandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);

    getActiveSessions()
      .then(sessions => {
        setSessions(sessions);
        setLoading(false);
      })
      .catch(displayErrorNotification);
  };

  const _killSession = async session => {
    await killSession(session.token);
    load();
  };

  return (
    <Stack>
      <Typography.Title>Active Sessions</Typography.Title>

      <SessionTable sessions={sessions} onKillSession={_killSession} loading={loading} />
    </Stack>
  );
};

export default SessionLandingPage;
