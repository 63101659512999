import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Typography, Table } from 'antd';
import PropTypes from 'prop-types';
import { forEach, difference } from 'lodash';
import {
  getProjectExperienceByProjectId,
  addProjectExperience,
  deleteProjectExperienceById
} from 'api/projectExperienceApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import ButtonRow from 'components/common/ButtonRow';
import { experienceLevels, skillDurations } from 'components/constants/Constants';
import formatDate from 'utils/formatDate';
import { useUser } from 'auth/UserHooks';
import { getEmployeeSkillsByEmployeeId } from 'api/employeeSkillApi';
import EditEmployeeSkillModal from 'components/skills/employee-skill/EditEmployeeSkillModal';
import enumToDisplayText from 'utils/enumToDisplayText';

const ProjectSkillForm = ({ project, onSubmitOrCancel, form }) => {
  const user = useUser();
  const [emplsSkills, setEmplsSkills] = useState([]);
  const [emplsProjectExperiences, setEmplsProjectExperiences] = useState([]);
  const [emplSkillIdsOnProject, setEmplSkillIdsOnProject] = useState([]);
  const [selectedEmplSkillIds, setSelectedEmplSkillIds] = useState([]);
  const [emplSkillToEdit, setEmplSkillToEdit] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchEmployeeSkills();
    getProjectExperienceByProjectId(project.id)
      .then(resp => {
        const _emplsProjectExperiences = resp.filter(pe => pe.employeeSkill.employee.id === user.id);
        const _emplSkillIdsOnProject = _emplsProjectExperiences.map(pe => pe.employeeSkill.employeeSkillId);
        setEmplsProjectExperiences(_emplsProjectExperiences);
        setEmplSkillIdsOnProject(_emplSkillIdsOnProject);
        setSelectedEmplSkillIds(_emplSkillIdsOnProject);
      })
      .catch(displayErrorNotification);
  }, []);

  const fetchEmployeeSkills = () => {
    getEmployeeSkillsByEmployeeId(user.id)
      .then(setEmplsSkills)
      .catch(displayErrorNotification);
  };

  const updateProjectExperiences = () => {
    const emplSkillIdsToAdd = difference(selectedEmplSkillIds, emplSkillIdsOnProject);
    const emplSkillIdsToRemove = difference(emplSkillIdsOnProject, selectedEmplSkillIds);
    const projectExpIdsToRemove = emplsProjectExperiences
      .filter(pe => emplSkillIdsToRemove.includes(pe.employeeSkill.employeeSkillId))
      .map(pe => pe.projectExperienceId);
    const apiRequests = [];

    forEach(emplSkillIdsToAdd, emplSkillId => {
      apiRequests.push(addProjectExperience({ employeeSkillId: emplSkillId, projectId: project.id }));
    });

    forEach(projectExpIdsToRemove, projectExpId => {
      apiRequests.push(deleteProjectExperienceById(projectExpId));
    });

    Promise.all(apiRequests)
      .then(onSubmitOrCancel)
      .catch(displayErrorNotification);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedEmplSkillIds(selectedRowKeys);
  };

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };

  const launchEditEmplSkillModal = employeeSkill => {
    setEmplSkillToEdit(employeeSkill);
    toggleModalVisibility();
  };

  const handleModalSubmit = () => {
    fetchEmployeeSkills();
    toggleModalVisibility();
  };

  const columns = [
    {
      title: 'Skill',
      dataIndex: 'skill.name',
      key: 'skill.name',
      sorter: (a, b) => a.skill.name.toLowerCase().localeCompare(b.skill.name.toLowerCase())
    },
    {
      title: 'Experience Level',
      dataIndex: 'experienceLevel',
      render: (text, emplSkill) => enumToDisplayText(emplSkill.experienceLevel),
      sorter: (a, b) =>
        experienceLevels.indexOf(a.experienceLevel) > experienceLevels.indexOf(b.experienceLevel) ? -1 : 1
    },
    {
      title: 'Duration of Experience',
      dataIndex: 'duration',
      sorter: (a, b) => (skillDurations.indexOf(a.duration) > skillDurations.indexOf(b.duration) ? -1 : 1)
    },
    {
      title: 'Last Used Date',
      dataIndex: 'lastUsedDate',
      render: formatDate,
      sorter: (a, b) => (a.lastUsedDate > b.lastUsedDate ? -1 : 1)
    },
    {
      title: 'Actions',
      render: emplSkill => <Button onClick={() => launchEditEmplSkillModal(emplSkill)}>Edit Skill</Button>
    }
  ];

  const { getFieldDecorator } = form;

  return (
    <>
      <Form layout="vertical">
        <Typography.Title>Add Skills to Project</Typography.Title>
        <Typography.Title level={3}>Project Details</Typography.Title>

        <div style={{ maxWidth: '40em' }}>
          <Form.Item label="Client" disabled>
            {getFieldDecorator('client', {
              initialValue: project.client.name
            })(<Select disabled={true} />)}
          </Form.Item>
          <Form.Item label="Project" disabled>
            {getFieldDecorator('project', {
              initialValue: project.name
            })(<Select disabled={true} />)}
          </Form.Item>
        </div>

        <Typography.Title level={3} style={{ marginTop: '1em' }}>
          Skills Used
        </Typography.Title>

        <Table
          rowKey={es => es.employeeSkillId}
          style={{ width: '100%' }}
          dataSource={emplsSkills}
          columns={columns}
          pagination={false}
          rowSelection={{
            selectedRowKeys: selectedEmplSkillIds,
            onChange: onSelectChange
          }}
        />

        <ButtonRow style={{ marginTop: '2em' }}>
          <Button type="primary" onClick={updateProjectExperiences}>
            Save
          </Button>
          <Button onClick={onSubmitOrCancel}>Cancel</Button>
        </ButtonRow>
      </Form>
      <EditEmployeeSkillModal
        employeeId={user.id}
        isModalVisible={isModalVisible}
        employeeSkill={emplSkillToEdit}
        onCancel={toggleModalVisibility}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};

ProjectSkillForm.propTypes = {
  project: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default Form.create({ name: 'project_skill_form' })(ProjectSkillForm);
