import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import 'components/common/drag-and-drop/DragAndDrop.css';

const type = 'DragbleItem';

export const DragableItem = ({ index, moveRow, isTableRow = true, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));

  if (isTableRow) {
    return (
      <tr
        ref={ref}
        {...restProps}
        className={`${restProps.className} ${isOver ? dropClassName : ''} drag-and-drop-item`}
      />
    );
  } else {
    return (
      <div
        ref={ref}
        {...restProps}
        className={`${restProps.className} ${isOver ? dropClassName : ''} drag-and-drop-item`}
      />
    );
  }
};
