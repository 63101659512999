import { get, post } from 'api';
import { EmployeeRole } from 'types/EmployeeRole';

export function getRoles(): Promise<string[]> {
  return get('api/roles');
}

export function getEmployeeRoles(): Promise<EmployeeRole[]> {
  return get('api/roles/employee_roles');
}

export function updateEmployeeRoles(employeeId: number, roles: string[]): Promise<void> {
  return post(`api/roles/employee_roles/${employeeId}`, roles);
}
