export interface ProjectFilterSettings {
  search: string;
  market: string[];
  practice: string[];
  inactive: boolean;
  showManagers: boolean;
}

export function defaultProjectFilterSettings(): ProjectFilterSettings {
  return {
    search: '',
    market: [],
    practice: [],
    inactive: false,
    showManagers: false
  };
}
