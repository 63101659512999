import React from 'react';
import { Col, Row } from 'antd';
import StyledCollapsePanel from './StyledCollapsePanel';

const CardCollapse = ({ children, header, extra, ...props }) => {
  return (
    <StyledCollapsePanel {...props} header={header} extra={extra}>
      <Row type="flex" gutter={16}>
        {children}
      </Row>
    </StyledCollapsePanel>
  );
};

const CardCollapseCard = ({ children }) => {
  return (
    <Col xs={12} md={8} xxl={6} style={{ padding: '8px' }}>
      {children}
    </Col>
  );
};

export default { Panel: CardCollapse, Card: CardCollapseCard };
