import React from 'react';
import styles from './BadgeStats.module.css';
import MostBadges from './MostBadges';
import { Row, Col, Card } from 'antd';
import PopularBadges from './PopularBadges';
import NewBadges from './NewBadges';
import BadgeHistory from './BadgeHistory';

const BadgeStats = props => {
  return (
    <div className={styles.statsWrapper}>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={6}>
          <Card title="Most awarded employees" bordered={true} bodyStyle={{ padding: '0 .75rem' }}>
            <div className={styles.scrollWrapper}>
              <MostBadges />
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Most popular badges" bordered={true} bodyStyle={{ padding: '0 .75rem' }}>
            <div className={styles.scrollWrapper}>
              <PopularBadges allBadges={props.allBadges} />
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="New badges" bordered={true} bodyStyle={{ padding: '0 .75rem' }}>
            <div className={styles.scrollWrapper}>
              <NewBadges allBadges={props.allBadges} />
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Recent badge assignments" bordered={true} bodyStyle={{ padding: '0 .75rem' }}>
            <div className={styles.scrollWrapper}>
              <BadgeHistory />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BadgeStats;
