import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import Stack from 'components/common/Stack';
import PropTypes from 'prop-types';
import moment from 'moment';
import sorters from 'utils/sorters';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const SessionTable = ({ sessions, onKillSession, loading }) => {
  const [filteredSessions, setFilteredSessions] = useState([]);

  useEffect(() => {
    setFilteredSessions(sessions);
  }, [sessions]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'employeeName',
      sorter: (a, b) => sorters.string(a.employeeName, b.employeeName),
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Email',
      dataIndex: 'employeeEmail',
      sorter: (a, b) => sorters.string(a.employeeEmail, b.employeeEmail)
    },
    {
      title: 'Created',
      dataIndex: 'created',
      sorter: (a, b) => sorters.date(a.created,b.created),
      render: created => moment(created).format('MM/DD/YY HH:mm:ss')
    },
    {
      title: 'Expiration',
      dataIndex: 'expiration',
      sorter: (a, b) => sorters.date(a.expiration,b.expiration),
      render: expiration => moment(expiration).format('MM/DD/YY HH:mm:ss')
    },
    {
      title: 'Actions',
      render: record => <Button onClick={() => onKillSession(record)}>Kill Session</Button>
    }
  ];

  return (
    <Stack>
      <Table
        pagination={PAGINATION_DEFAULT}
        rowKey={r => r.token}
        columns={columns}
        dataSource={filteredSessions}
        loading={loading}
      />
    </Stack>
  );
};

SessionTable.propTypes = {
  sessions: PropTypes.array,
  loading: PropTypes.bool
};

export default SessionTable;
