import React from "react";
import { Prompt, PromptTypes } from "types/Prompt";
import { Input, Radio, Typography } from "antd";
import { savePromptResponse } from "api/employeePromptApi";
import displayErrorNotification from "utils/displayErrorNotification";
import BirthdayPromptForm from "./response-forms/BirthdayPromptForm";
import PromptResponseForm from "./response-forms/PromptResponseForm";
import SelectMultiplePromptForm from "./response-forms/SelectMultiplePromptForm";
import ReactMarkdown from "react-markdown";

type PromptCardFormProps = {
  prompt: Prompt;
  employeePromptValue: string;
  employeeId: number;
  onSubmit: () => void;
};

const PromptCardForm = ({
  prompt,
  employeePromptValue,
  employeeId,
  onSubmit
}: PromptCardFormProps) => {
  const handleSubmit = (response: string | string[]) => {
    if (response instanceof Array) {
      response = response.join(",");
    }
    savePromptResponse(Number(prompt.id), employeeId, response)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  return (
    <>
      <h2>{prompt.question}</h2>
      {!!prompt.description ? (
        <Typography.Paragraph>{prompt.description}</Typography.Paragraph>
      ) : null}
      {prompt.type === PromptTypes.FREE_RESPONSE && (
        <PromptResponseForm onSubmit={handleSubmit} value={employeePromptValue}>
          <Input.TextArea rows={4} />
        </PromptResponseForm>
      )}

      {prompt.type === PromptTypes.SELECT_ONE && (
        <PromptResponseForm onSubmit={handleSubmit} value={employeePromptValue}>
          <Radio.Group>
            {prompt.options.map(o => (
              <Radio
                key={o.id}
                value={o.value}
                style={{
                  display: "block",
                  height: "30px",
                  lineHeight: "30px",
                  marginLeft: "0px"
                }}
                className={o.value === "N/A" ? "none-prompt-option" : ""}
              >
                {o.response}
              </Radio>
            ))}
          </Radio.Group>
        </PromptResponseForm>
      )}

      {prompt.type === PromptTypes.SELECT_MULTIPLE && (
        <SelectMultiplePromptForm
          prompt={prompt}
          value={employeePromptValue}
          onSubmit={handleSubmit}
        />
      )}

      {prompt.type === PromptTypes.BIRTHDAY && (
        <BirthdayPromptForm
          onSubmit={handleSubmit}
          dateStr={employeePromptValue}
          employeeId={employeeId}
        />
      )}

      {prompt.helpText &&
        prompt.helpText.replace(/\u200B/g, "").trim() !== "" && (
          <ReactMarkdown className="display-help-text">
            {prompt.helpText}
          </ReactMarkdown>
        )}
    </>
  );
};

export default PromptCardForm;
