import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, Select, Table, Typography } from 'antd';
import { getBadgeRecipients } from 'api/badgesApi';
import { getEmployeesByName } from 'api/employeeApi';
import {
  assignBadgeToEmployees,
  issueBadgeToEmployees,
  unassignBadgeFromEmployees,
  revokeBadgeFromEmployees
} from 'api/employeeBadgeApi';
import { useUser } from 'auth/UserHooks';
import { Link } from 'react-router-dom';
import displayErrorNotification from 'utils/displayErrorNotification';
import formatDate from 'utils/formatDate';
import ButtonRow from 'components/common/ButtonRow';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const { Option } = Select;

const BadgeRecipients = ({ badge, isRequired }) => {
  const [recipients, setRecipients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesToIssue, setEmployeesToIssue] = useState([]);
  const [employeesToAssign, setEmployeesToAssign] = useState([]);
  const [employeesToUnassign, setEmployeesToUnassign] = useState([]);
  const [showIssueModal, setShowIssueModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  let picker = useRef();
  const user = useUser();

  const isCategoryOwner = badge ? badge.category.owners.some(o => o.id === user.id) : false;

  const isMe = id => {
    return id === user.id;
  };

  const isCoach = id => {
    return user.coachees.includes(id);
  };

  useEffect(() => {
    fetchBadgeRecipients();
  }, []);

  const assignedColumns = [
    {
      title: 'Name',
      dataIndex: 'employee.fullName',
      key: 'employee.fullName',
      render: (text, record) => <Link to={`/employees/${record.employee.id}`}>{record.employee.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      sorter: (a, b) => (a.practice < b.practice ? -1 : 1)
    },
    {
      title: 'Position',
      dataIndex: 'employee.title',
      sorter: (a, b) => (a.title < b.title ? -1 : 1)
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      sorter: (a, b) => (a.location < b.location ? -1 : 1)
    },
    {
      title: 'Assigned On',
      dataIndex: 'assignedOn',
      key: 'assignedOn',
      render: formatDate,
      sorter: (a, b) => a.assignedOn.localeCompare(b.assignedOn)
    },
    {
      title: 'Assigned By',
      dataIndex: 'assignedBy',
      key: 'assignedBy'
    },
    ...(isCategoryOwner ||
    user.permissions.canRemoveOthersAssignedBadges ||
    user.permissions.canRemoveCoacheeAssignedBadges ||
    user.permissions.canRemoveAssignedBadges
      ? [
          {
            title: 'Unassign',
            key: 'unassign',
            render: (text, record) => {
              if (
                isCategoryOwner ||
                (user.permissions.canRemoveOthersAssignedBadges && !isMe(record.employee.id)) ||
                (user.permissions.canRemoveCoacheeAssignedBadges && isCoach(record.employee.id)) ||
                user.permissions.canRemoveAssignedBadges
              )
                return <Icon type="delete" onClick={unassignUser(record.employee.id)} />;
            }
          }
        ]
      : [])
  ];

  const recipientColumns = [
    {
      title: 'Name',
      dataIndex: 'employee.fullName',
      key: 'employee.fullName',
      render: (text, record) => <Link to={`/employees/${record.employee.id}`}>{record.employee.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      sorter: (a, b) => (a.practice < b.practice ? -1 : 1)
    },
    {
      title: 'Position',
      dataIndex: 'employee.title',
      sorter: (a, b) => (a.title < b.title ? -1 : 1)
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      sorter: (a, b) => (a.location < b.location ? -1 : 1)
    },
    {
      title: 'Issued On',
      dataIndex: 'issuedOn',
      key: 'issuedOn',
      render: formatDate,
      sorter: (a, b) => a.issuedOn.localeCompare(b.issuedOn)
    },
    {
      title: 'Issued By',
      dataIndex: 'issuedBy',
      key: 'issuedBy'
    },
    ...(isCategoryOwner ||
    user.permissions.canRemoveMyIssuedBadges ||
    user.permissions.canRemoveOthersIssuedBadges ||
    user.permissions.canRemoveCoacheeIssuedBadges ||
    user.permissions.canRemoveIssuedBadges
      ? [
          {
            title: 'Remove',
            key: 'remove',
            render: (text, record) => {
              if (
                isCategoryOwner ||
                (user.permissions.canRemoveMyIssuedBadges && isMe(record.employee.id)) ||
                (user.permissions.canRemoveOthersIssuedBadges && !isMe(record.employee.id)) ||
                (user.permissions.canRemoveCoacheeIssuedBadges && isCoach(record.employee.id)) ||
                user.permissions.canRemoveIssuedBadges
              )
                return <Icon type="delete" onClick={removeUser(record.employee.id)} />;
            }
          }
        ]
      : [])
  ];

  const toggleIssueModal = () => {
    setShowIssueModal(!showIssueModal);
  };

  const toggleAssignModal = () => {
    setShowAssignModal(!showAssignModal);
  };

  const toggleUnassignModal = () => {
    setShowUnassignModal(!showUnassignModal);
  };

  const handleSearch = async filterText => {
    const employeeList = await getEmployeesByName(filterText);
    setEmployees(employeeList.map(temp => ({ ...temp, key: temp.id, name: `${temp.firstName} ${temp.lastName}` })));
  };

  const issueBadge = () => {
    const employeeIds = employeesToIssue.map(({ key }) => key);

    issueBadgeToEmployees(badge.id, employeeIds)
      .then(fetchBadgeRecipients)
      .catch(() => {
        displayErrorNotification('Could not issue badge to employee(s)');
      });

    setShowIssueModal(false);
  };

  const assignBadge = () => {
    const employeeIds = employeesToAssign.map(({ key }) => key);

    assignBadgeToEmployees(badge.id, employeeIds)
      .then(fetchBadgeRecipients)
      .catch(() => {
        displayErrorNotification('Could not assign badge to employee(s)');
      });

    setShowAssignModal(false);
  };

  const onDownload = () => {
    getBadgeRecipients(badge.id, true).catch(() => {
      displayErrorNotification('Could not download csv');
    });
  };

  const unassignBadge = () => {
    const employeeIds = employeesToUnassign.map(({ key }) => key);

    unassignBadgeFromEmployees(badge.id, employeeIds)
      .then(fetchBadgeRecipients)
      .catch(() => {
        displayErrorNotification('Could not unassign badge to employee(s)');
      });

    setShowUnassignModal(false);
  };

  const fetchBadgeRecipients = () => {
    getBadgeRecipients(badge.id)
      .then(setRecipients)
      .catch(() => {
        displayErrorNotification('Could not retrieve recipients');
      });
  };

  const removeUser = employeeId => () => {
    revokeBadgeFromEmployees(badge.id, [employeeId])
      .then(() => {
        const newRecipients = recipients.filter(recipient => recipient.employee.id !== employeeId);
        setRecipients(newRecipients);
      })
      .catch(() => {
        displayErrorNotification('Could not remove badge from recipient');
      });
  };

  const unassignUser = employeeId => () => {
    unassignBadgeFromEmployees(badge.id, [employeeId])
      .then(() => {
        const newRecipients = recipients.filter(recipient => recipient.employee.id !== employeeId);
        setRecipients(newRecipients);
      })
      .catch(() => {
        displayErrorNotification('Could not unassign badge from employee');
      });
  };

  return (
    <div>
      <Typography.Title level={2}>
        Employees
        <ButtonRow style={{ display: 'inline-flex', marginLeft: '1rem' }}>
          <Button icon="download" onClick={onDownload}>
            Download Employees
          </Button>
        </ButtonRow>
      </Typography.Title>
      {isRequired && (
        <>
          <div>
            <Typography.Title level={3}>
              Assigned ({recipients.filter(recipient => recipient.assignedOn && !recipient.issuedOn).length})
              <ButtonRow style={{ display: 'inline-flex', marginLeft: '1.5rem' }}>
                {(isCategoryOwner ||
                  user.permissions.canAddOthersAssignedBadges ||
                  user.permissions.canAddCoacheeAssignedBadges ||
                  user.permissions.canAddAssignedBadges) && (
                  <Button icon="user-add" onClick={toggleAssignModal}>
                    Assign badge
                  </Button>
                )}
                {(isCategoryOwner ||
                  user.permissions.canRemoveOthersAssignedBadges ||
                  user.permissions.canRemoveCoacheeAssignedBadges ||
                  user.permissions.canRemoveAssignedBadges) && (
                  <Button icon="user-delete" onClick={toggleUnassignModal}>
                    Unassign badge
                  </Button>
                )}
              </ButtonRow>
            </Typography.Title>
          </div>
          <Table
            rowKey="id"
            dataSource={recipients.filter(recipient => recipient.assignedOn && !recipient.issuedOn)}
            columns={assignedColumns}
            pagination={PAGINATION_DEFAULT}
          />
        </>
      )}
      <div>
        <Typography.Title level={3}>
          {isRequired ? 'Completed' : 'Recipients'} ({recipients.filter(recipient => recipient.issuedOn).length})
          {(isCategoryOwner ||
            user.permissions.canAddMyIssuedBadges ||
            user.permissions.canAddOthersIssuedBadges ||
            user.permissions.canAddCoacheeIssuedBadges ||
            user.permissions.canAddIssuedBadges) && (
            <Button icon="plus" style={{ verticalAlign: 'center', marginLeft: '1.5rem' }} onClick={toggleIssueModal}>
              Issue badge
            </Button>
          )}
        </Typography.Title>
      </div>
      <Table
        rowKey="id"
        dataSource={isRequired ? recipients.filter(recipient => recipient.issuedOn) : recipients}
        columns={recipientColumns}
        pagination={PAGINATION_DEFAULT}
      />
      <Modal
        title="Search for employee by name"
        visible={showIssueModal}
        okText="Issue badge"
        onOk={issueBadge}
        onCancel={toggleIssueModal}
      >
        <Select
          componentRef={picker}
          autoFocus
          mode="multiple"
          labelInValue
          filterOption={false}
          onSearch={handleSearch}
          onChange={setEmployeesToIssue}
          style={{ marginBottom: '1rem', width: '100%' }}
        >
          {employees
            .filter(
              r =>
                isCategoryOwner ||
                (user.permissions.canAddMyIssuedBadges && isMe(r.id)) ||
                (user.permissions.canAddOthersIssuedBadges && !isMe(r.id)) ||
                (user.permissions.canAddCoacheeIssuedBadges && isCoach(r.id)) ||
                user.permissions.canAddIssuedBadges
            )
            .map(r => (
              <Option key={r.key}>{r.name}</Option>
            ))}
        </Select>
      </Modal>
      <Modal
        title="Search for employee by name"
        visible={showAssignModal}
        okText="Assign badge"
        onOk={assignBadge}
        onCancel={toggleAssignModal}
      >
        <Select
          componentRef={picker}
          autoFocus
          mode="multiple"
          labelInValue
          filterOption={false}
          onSearch={handleSearch}
          onChange={setEmployeesToAssign}
          style={{ marginBottom: '1rem', width: '100%' }}
        >
          {employees
            .filter(
              r =>
                isCategoryOwner ||
                (user.permissions.canAddOthersAssignedBadges && !isMe(r.id)) ||
                (user.permissions.canAddCoacheeAssignedBadges && isCoach(r.id)) ||
                user.permissions.canAddAssignedBadges
            )
            .map(r => (
              <Option key={r.key}>{r.name}</Option>
            ))}
        </Select>
      </Modal>
      <Modal
        title="Search for employee by name"
        visible={showUnassignModal}
        okText="Unassign badge"
        onOk={unassignBadge}
        onCancel={toggleUnassignModal}
      >
        <Select
          componentRef={picker}
          autoFocus
          mode="multiple"
          labelInValue
          filterOption={false}
          onSearch={handleSearch}
          onChange={setEmployeesToUnassign}
          style={{ marginBottom: '1rem', width: '100%' }}
        >
          {employees
            .filter(
              r =>
                isCategoryOwner ||
                (user.permissions.canRemoveOthersAssignedBadges && !isMe(r.id)) ||
                (user.permissions.canRemoveCoacheeAssignedBadges && isCoach(r.id)) ||
                user.permissions.canRemoveAssignedBadges
            )
            .map(r => (
              <Option key={r.key}>{r.name}</Option>
            ))}
        </Select>
      </Modal>
    </div>
  );
};

BadgeRecipients.propTypes = {
  badge: PropTypes.object,
  isRequred: PropTypes.bool
};

export default BadgeRecipients;
