import { del, get, post, put } from 'api';
import { RoleTypeFamily } from 'types/RoleTypeFamily';
import { RoleTypeSummary } from 'types/RoleTypeSummary';

export function getAllRoleTypes(): Promise<RoleTypeSummary[]> {
  return get('api/role_types');
}

export function getRoleTypeById(id) {
  return get(`api/role_types/${id}`);
}

export function getAllEmployeesWithRoleTypeId(id) {
  return get(`api/role_types/${id}/employees`);
}

export const createRoleType = roleType => post(`api/role_types`, roleType);

export const toggleRoleTypeActivation = id => del(`api/role_types/${id}`);

export function addRoleTypeToEmployee(request) {
  return post('api/employees/role_types', request);
}

export function updateEmployeeRoleType(request) {
  return put('api/employees/role_types', request);
}

export function deleteEmployeeRoleType(id) {
  return del(`api/employees/role_types/${id}`);
}

export function getRoleTypesByEmployeeId(employeeId) {
  return get(`api/employees/${employeeId}/role_types`);
}

export function addRoleTypeExperience(request) {
  return post('api/role_type_experiences', request);
}

export function updateRoleType(id, roleType) {
  return put(`api/role_types/${id}`, roleType);
}

export function getByEmployeeRoleTypeId(id) {
  return get(`api/employees/role_types/${id}`);
}

export function getEmployeesByRoleTypeId(id) {
  return get(`api/role_types/${id}/employees`);
}

export function getRoleTypeExperienceByRoleTypeId(id) {
  return get(`api/role_type_experiences/role_types/${id}`);
}

export function getRoleTypeExperienceByEmployeeRoleTypeId(id) {
  return get(`api/role_type_experiences/${id}`);
}

export function getRoleTypeExperienceByProjectId(id) {
  return get(`api/role_type_experiences/project/${id}`);
}

export function deleteRoleTypeExperienceById(id) {
  return del(`api/role_type_experiences/${id}`);
}

export function getRoleTypeFamilies(): Promise<RoleTypeFamily[]> {
  return get(`api/role_types/family`);
}

export function getRoleTypeFamilyDetails(): Promise<RoleTypeFamily[]> {
  return get(`api/role_types/family/details`);
}

export function createRoleTypeFamily(family: RoleTypeFamily): Promise<RoleTypeFamily> {
  return post(`api/role_types/family`, family);
}

export function updateRoleTypeFamily(familyId: number, family: RoleTypeFamily): Promise<RoleTypeFamily> {
  return put(`api/role_types/family/${familyId}`, family);
}

export function deleteRoleTypeFamily(familyId: number) {
  return del(`api/role_types/family/${familyId}`);
}

export function toggleRoleTypeFamilyActivation(familyId: number): Promise<RoleTypeFamily> {
  return post(`api/role_types/family/${familyId}`);
}
