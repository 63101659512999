import { get } from 'api'
import { JwtClaims } from 'types/JwtClaims'
import { API_URL } from 'config'

export function login(): Promise<JwtClaims> {
  return get('principalinfo').catch(err => {
    if (err.response === undefined || err.response.status === 401 || err.reponse.status === 403) {
      window.location.href = `${API_URL}sso/login`
      return null
    }
    throw err
  })
}
