import { Button, Icon, Typography } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Table, { ColumnProps } from 'antd/lib/table'
import { getEmployeePractices } from 'api/employeePracticeApi'
import { useUser } from 'auth/UserHooks'
import ButtonRow from 'components/common/ButtonRow'
import Modal from 'components/common/Modal'
import SavePreferencesButton from 'components/common/SavePreferencesButton'
import Stack from 'components/common/Stack'
import React, { useState, useEffect } from 'react'
import { EmployeePractice, EmployeePracticeNotApplicable } from 'types/EmployeePractice'
import { textOverflow } from 'utils/skillDetails'
import sorters from 'utils/sorters'
import useLocalStorage from 'utils/useLocalStorage'
import EmployeePracticeForm from './EmployeePracticeForm'
import { getEmployeeBusinessUnits } from "../../../api/employeeBusinessUnitApi";
import { EmployeeBusinessUnit } from "../../../types/EmployeeBusinessUnit";

const EmployeePracticeLandingPage = () => {
  const user = useUser()
  const canManageEmployeePractice = user.permissions.canManageEmployeePractice
  const [practices, setPractices] = useState<EmployeePractice[]>([])
  const [businessUnits, setBusinessUnits] = useState<EmployeeBusinessUnit[]>([]);
  const [filteredPractices, setFilteredPractices] = useState<EmployeePractice[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showInactive, setShowInactive] = useLocalStorage<any>('EmployeePracticeLandingPage_InactiveSetting', false)
  const [sortSettings, setSortSettings] = useLocalStorage<any>('EmployeePracticeLandingPage_SortSettings', {
    key: 'displayOrder ',
    order: 'ascend'
  })
  const [loading, setLoading] = useState(false)

  const _refreshPractice = () => {
    return getEmployeePractices().then(response => {
      setPractices(response);
      filterPractices(response, showInactive);
    });
  };

  const filterPractices = (
    practices: EmployeePractice[],
    showInactive: boolean
  ) => {
    setFilteredPractices(
      practices.filter((f: EmployeePractice) => showInactive || !f.inactive)
    );
  };

  const handleInactiveChange = (e: CheckboxChangeEvent) => {
    setShowInactive(e.target.checked);
    filterPractices(practices, e.target.checked);
  };

  let columns: ColumnProps<EmployeePractice>[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => sorters.string(a.name, b.name)
    },
    {
      title: "Abbrev",
      key: "abbrev",
      dataIndex: "abbrev",
      sorter: (a, b) => sorters.string(a.abbrev, b.abbrev)
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (desc: string) => textOverflow(desc, 60),
      sorter: (a, b) => sorters.string(a.description, b.description)
    },
    {
      title: 'Business Unit',
      key: 'businessUnit',
      dataIndex: 'businessUnit',
      render: (businessUnit: EmployeeBusinessUnit) => businessUnit?.name || '-',
      sorter: (a, b) => sorters.string(a.businessUnit?.name, b.businessUnit?.name)
    },
    {
      title: 'Sort',
      key: 'displayOrder',
      dataIndex: 'displayOrder',
      sorter: (a, b) => a.displayOrder - b.displayOrder
    },
    {
      title: "Inactive",
      key: "inactive",
      render: (item: EmployeePractice) =>
        item.inactive && <Icon type="check-circle"/>
    }
  ];

  if (canManageEmployeePractice) {
    columns.push({
      render: (item: EmployeePractice) => (
        <>
          {item.id !== EmployeePracticeNotApplicable && (
            <Modal
              item={item}
              trigger={({openModal}: { openModal: () => void }) => (
                <Button onClick={openModal} size="small" icon="edit">
                  Edit
                </Button>
              )}
            >
              {({
                  item,
                  closeModal
                }: {
                item: EmployeePractice;
                closeModal: () => void;
              }) => (
                <EmployeePracticeForm
                  practice={item}
                  businessUnits={businessUnits}
                  onSubmit={() => {
                    _refreshPractice().then(closeModal);
                  }}
                  onCancel={closeModal}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
            </Modal>
          )}
        </>
      )
    });
  }

  columns = columns.map(column => {
    if (column.key === sortSettings.key) {
      return {...column, defaultSortOrder: sortSettings.order};
    }
    return column;
  });

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    _refreshPractice().then(() => {
      setLoading(false)
    })

    // Get business units
    getEmployeeBusinessUnits().then(response => {
      setBusinessUnits(response);
    });
  }, []);

  const _handleModalState = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {canManageEmployeePractice && (
        <Modal isOpen={showModal} onClose={_handleModalState}>
          {({closeModal}: { closeModal: () => void }) => (
            <EmployeePracticeForm
              businessUnits={businessUnits}
              onSubmit={() => {
                _refreshPractice().then(closeModal);
              }}
              onCancel={closeModal}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </Modal>
      )}
      <Stack>
        <Typography.Title level={1}>
          Employee Practice
          <ButtonRow style={{float: "right"}}>
            {canManageEmployeePractice && (
              <Button icon="plus" onClick={_handleModalState}>
                Create Employee Practice
              </Button>
            )}
            <SavePreferencesButton
              keys={[
                "EmployeePracticeLandingPage_InactiveSetting",
                "EmployeePracticeLandingPage_SortSettings"
              ]}
              disabled={loading}
            />
          </ButtonRow>
        </Typography.Title>
        <Checkbox
          checked={showInactive}
          onChange={handleInactiveChange}
          style={{marginBottom: "20px"}}
        >
          Show Inactive
        </Checkbox>
        <Table
          columns={columns}
          dataSource={filteredPractices}
          loading={loading}
          rowKey="id"
          pagination={false}
          onChange={onChange}
        />
      </Stack>
    </>
  );
};

export default EmployeePracticeLandingPage;
