import { get, put, post, del } from 'api';
import { BadgeSummary } from 'types/BadgeSummary';
import { BadgeDetail } from 'types/BadgeDetail';
import { EmployeeBadge } from 'types/EmployeeBadge';
import { BadgeRequest } from 'types/BadgeRequest';
import { BadgeCategory } from 'types/BadgeCategory';
import { BadgeCategoryOwner } from 'types/BadgeCategoryOwner';

export function getAllBadges(includeInactive = false): Promise<BadgeSummary[]> {
  return get(`api/badges`, { includeInactive });
}

export function getBadge(badgeId: number): Promise<BadgeDetail> {
  return get(`api/badges/${badgeId}`);
}

export function getBadgeRecipients(badgeId: number, csv: boolean): Promise<EmployeeBadge[]> {
  return get(`api/badges/${badgeId}/employees`, { csv });
}

export function updateBadge(badgeId: number, body: BadgeRequest): Promise<BadgeDetail> {
  return put(`api/badges/${badgeId}`, body);
}

export function addBadge(body: BadgeRequest): Promise<BadgeDetail> {
  return post(`api/badges`, body);
}

export function toggleBadgeActivation(badgeId: number): Promise<BadgeDetail> {
  return del(`api/badges/${badgeId}`);
}

export function getBadgeRecipientsByDate(max: number): Promise<EmployeeBadge[]> {
  return get(`api/badges/history?max=${max || 25}`);
}

// CATEGORY
export function getAllBadgeCategories(includeInactive = false): Promise<BadgeCategory[]> {
  return get(`api/badges/categories`, { includeInactive });
}

export function addBadgeCategory(body: BadgeCategory): Promise<BadgeCategory> {
  return post(`api/badges/categories`, body);
}

export function updateBadgeCategory(id: number, body: BadgeCategory): Promise<BadgeCategory> {
  return put(`api/badges/categories/${id}`, body);
}

export function getCategoryOwners(badgeCategoryId: number): Promise<BadgeCategoryOwner[]> {
  return get(`api/badges/categories/${badgeCategoryId}/owners`);
}

export function toggleBadgeCategoryActivation(id: number): Promise<BadgeCategory> {
  return del(`api/badges/categories/${id}`);
}
