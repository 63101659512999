import React, { useEffect, useState } from 'react'
import { Form, Button, Select } from 'antd'
import ButtonRow from 'components/common/ButtonRow'
import Stack from 'components/common/Stack'
import { updateEmployee } from './UserAccessUtil'
import { EmployeeRole } from 'types/EmployeeRole'

type UserAccessFormProps = {
  form: any
  employeeId: string
  employeeRoles: EmployeeRole[]
  roles: string[]
  onUpdate: Function
  onCancel: Function
}

const UserAccessForm = ({ form, employeeId, employeeRoles, roles, onUpdate, onCancel }: UserAccessFormProps) => {
  const [employee, setEmployee] = useState<EmployeeRole | undefined>(
    employeeRoles.find(e => e.employee.id === employeeId)
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    form.validateFields((err: any, values: any) => {
      if (err) return
      const roles = form.getFieldValue('roles')
      if (employee !== undefined) {
        updateEmployee(employee, roles, [...employee.roles], onUpdate)
      }
    })
  }

  const handleCancel = () => {
    setEmployee(undefined)
    form.resetFields()

    if (onCancel) {
      onCancel()
    }
  }

  const { getFieldDecorator } = form

  return (
    <Stack>
      <Form layout="vertical" style={{ maxWidth: '40em' }} onSubmit={handleSubmit}>
        <Form.Item label="Roles" required>
          {getFieldDecorator('roles', { initialValue: employee === undefined ? [] : employee.roles })(
            <Select placeholder="Select role(s)" mode="multiple" disabled={!employee}>
              {roles.map(r => (
                <Select.Option key={r} value={r}>
                  {r}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <ButtonRow style={{ marginTop: '1em' }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </ButtonRow>
      </Form>
    </Stack>
  )
}

export default Form.create({ name: 'user_access_form' })(UserAccessForm)
