import { ProjectorUdf } from './Projector'
import { PromptOption } from './PromptOption'

export const PromptTypes = {
  SELECT_ONE: 'SELECT_ONE',
  SELECT_MULTIPLE: 'SELECT_MULTIPLE',
  FREE_RESPONSE: 'FREE_RESPONSE',
  BIRTHDAY: 'BIRTHDAY'
}
export const promptTypeValues = Object.values(PromptTypes)

export interface Prompt {
  id: number | null
  question: string
  type: typeof promptTypeValues[number]
  inactive: boolean
  displayOrder: number
  options: PromptOption[]
  personal: boolean
  practiceId?: number
  description: string
  category: Set<string> 
  helpText: string
  udf?: ProjectorUdf
}
