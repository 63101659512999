import { get, post, put, del } from 'api';
import { CertificationDetail } from 'types/CertificationDetail';
import { CertificationSummary } from 'types/CertificationSummary';
import { EmployeeCertification } from 'types/EmployeeCertification';

export const getCertifications: (includeInactive: boolean) => Promise<CertificationSummary[]> = (
  includeInactive = false
) => get('api/certifications', { includeInactive });

export const getCertificationById: (id: number) => Promise<CertificationDetail> = id => get(`api/certifications/${id}`);

export const createCertification: (
  certification: CertificationDetail
) => Promise<CertificationDetail> = certification => post('api/certifications', certification);

export const updateCertification: (id: number, certification: CertificationDetail) => Promise<CertificationDetail> = (
  id,
  certification
) => put(`api/certifications/${id}`, certification);

export const toggleCertificationActivation: (id: number) => Promise<CertificationDetail> = id =>
  del(`api/certifications/${id}`);

export const getEmployeeCertificationById: (id: number) => Promise<EmployeeCertification> = id =>
  get(`api/employees/certifications/${id}`);

export const deleteEmployeeCertification: (id: number) => Promise<void> = id =>
  del(`api/employees/certifications/${id}`);

export const addEmployeeCertification: (
  employeeId: number,
  cert: EmployeeCertification
) => Promise<EmployeeCertification> = (employeeId, cert) => post(`api/employees/${employeeId}/certifications`, cert);

export const updateEmployeeCertification: (
  id: number,
  cert: EmployeeCertification
) => Promise<EmployeeCertification> = (id, cert) => put(`api/employees/certifications/${id}`, cert);

export function getAllEmployeesCertifications(): Promise<EmployeeCertification> {
  return get(`api/employees/certification`, { csv: true });
}
