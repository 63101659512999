import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragableItem } from 'components/common/drag-and-drop/DragableItem';

type VerticalDragAndDropProps = {
  moveRow: (fromIndex: number, toIndex: number) => void;
  children: React.ReactNode[];
  inactive?: boolean;
};

const VerticalDragAndDrop = ({ moveRow, children, inactive }: VerticalDragAndDropProps) => {
  return (
    <DndProvider backend={HTML5Backend}>
      {children.map((item, idx) => {
        if (inactive) {
          return <div key={idx}>{item}</div>;
        } else {
          return (
            <DragableItem index={idx} moveRow={moveRow} isTableRow={false} key={idx} data-testid="dragableItem">
              {item}
            </DragableItem>
          );
        }
      })}
    </DndProvider>
  );
};

export default VerticalDragAndDrop;
