import React, { createContext, useContext } from 'react';
import { insightsPlugin } from './AppInsights';

const AppInsightsContext = createContext(insightsPlugin);

const AppInsightsContextProvider = ({ children }) => {
  return <AppInsightsContext.Provider value={insightsPlugin}>{children}</AppInsightsContext.Provider>;
};

export { AppInsightsContext, AppInsightsContextProvider };
export const useAppInsightsContext = () => useContext(AppInsightsContext);
