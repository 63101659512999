import { get, post } from "api";
import { CalendarFeedURL } from "types/Calendar";

export function getOwnCalendarFeed(): Promise<CalendarFeedURL> {
  return get(`api/calendar/own`);
}

export function createOwnCalendarFeed(): Promise<CalendarFeedURL> {
  return post(`api/calendar/own`);
}
