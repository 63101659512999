import React, { useState, useEffect } from 'react';
import { useUser } from 'auth/UserHooks';
import { ScoutTag } from 'types/ScoutTag';
import useLocalStorage from 'utils/useLocalStorage';
import { getScoutTags } from 'api/scoutTagApi';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Table, { ColumnProps } from 'antd/lib/table';
import Modal from 'components/common/Modal';
import { Button, Icon, Typography } from 'antd';
import TagForm from './TagForm';
import Stack from 'components/common/Stack';
import ButtonRow from 'components/common/ButtonRow';
import SavePreferencesButton from 'components/common/SavePreferencesButton';

const TagsLandingPage = () => {
  const user = useUser();
  const canManageTag = user.permissions.canManageTags;
  const [tags, setTags] = useState<ScoutTag[]>([]);
  const [filteredTags, setFilteredTags] = useState<ScoutTag[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showInactive, setShowInactive] = useLocalStorage<any>('TagsLandingPage_InactiveSetting', false);
  const [sortSettings, setSortSettings] = useLocalStorage<any>('TagsLandingPage_SortSettings', {
    key: 'name',
    order: 'ascend'
  });
  const [loading, setLoading] = useState(false);

  const _refreshTags = () => {
    return getScoutTags().then(response => {
      setTags(response);
      filterTags(response, showInactive);
    });
  };

  const filterTags = (tags: ScoutTag[], showInactive: boolean) => {
    const filtered = tags.filter((t: ScoutTag) => showInactive || !t.inactive);
    setFilteredTags(filtered);
  };

  const handleInactiveChange = (e: CheckboxChangeEvent) => {
    setShowInactive(e.target.checked);
    filterTags(tags, e.target.checked);
  };

  let columns: ColumnProps<ScoutTag>[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    },
    {
      title: 'Description',
      key: 'description',
      render: (item: ScoutTag) => (
        <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2, expandable: false }}>
          {item.description}
        </Typography.Paragraph>
      ),
      sorter: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())
    },
    {
      title: 'Sort',
      key: 'displayOrder',
      dataIndex: 'displayOrder',
      sorter: (a, b) => a.displayOrder - b.displayOrder
    },
    {
      title: 'Inactive',
      key: 'inactive',
      render: (item: ScoutTag) => item.inactive && <Icon type="check-circle" />
    }
  ];

  if (canManageTag) {
    columns.push({
      render: (item: ScoutTag) => (
        <Modal
          item={item}
          trigger={({ openModal }: { openModal: () => void }) => (
            <Button onClick={openModal} size="small" icon="edit">
              Edit
            </Button>
          )}
        >
          {({ item, closeModal }: { item: ScoutTag; closeModal: () => void }) => (
            <TagForm
              tag={item}
              onSubmit={() => {
                _refreshTags().then(closeModal);
              }}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )
    });
  }

  const columnsSortSettings = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    _refreshTags().then(() => {
      setLoading(false);
    });
  }, []);

  const _handleModalState = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {canManageTag && (
        <Modal isOpen={showModal} onClose={_handleModalState}>
          {({ closeModal }: { closeModal: () => void }) => (
            <TagForm
              onSubmit={() => {
                _refreshTags().then(closeModal);
              }}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )}
      <Stack>
        <Typography.Title level={1}>
          Tags
          <ButtonRow style={{ float: 'right' }}>
            {canManageTag && (
              <Button icon="plus" onClick={_handleModalState}>
                Create Tag
              </Button>
            )}
            <SavePreferencesButton
              keys={['TagsLandingPage_InactiveSetting', 'TagsLandingPage_SortSettings']}
              disabled={loading}
            />
          </ButtonRow>
        </Typography.Title>
        <Checkbox checked={showInactive} onChange={handleInactiveChange} style={{ marginBottom: '20px' }}>
          Show Inactive
        </Checkbox>
        <Table
          columns={columns}
          dataSource={filteredTags}
          loading={loading}
          rowKey="id"
          pagination={false}
          onChange={onChange}
        />
      </Stack>
    </>
  );
};

export default TagsLandingPage;
