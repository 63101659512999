import { get, post } from 'api';
import { EmployeeKeyMetric } from 'types/EmployeeKeyMetric';
import { EmployeeKeyMetricSummary } from 'types/EmployeeKeyMetricSummary';
import { KeyMetricPeriod } from 'types/KeyMetricPeriod';

export function getKeyMetricPeriods(): Promise<KeyMetricPeriod[]> {
  return get(`api/key_metrics/periods`);
}

export function getKeyMetricsForPeriod(id: number): Promise<EmployeeKeyMetric[]> {
  return get(`api/key_metrics/periods/${id}`);
}

export function getKeyMetricsForEmployee(id: number): Promise<EmployeeKeyMetricSummary> {
  return get(`api/key_metrics/employees/${id}`);
}

export function synchronizeKeyMetrics() {
  return post(`api/key_metrics/sync`);
}
