import React from 'react';
import { Modal } from 'antd';
import BulkEditForm from './BulkEditForm';

const BulkEditModal = ({ employeeRoles, roles, modalState, onUpdate, onCancel }) => {
  const handleUpdate = () => {
    onUpdate();
    onCancel();
  };

  return (
    <Modal title={`Bulk edit roles`} visible={modalState} onCancel={onCancel} footer={null}>
      <BulkEditForm employeeRoles={employeeRoles} roles={roles} onUpdate={handleUpdate} onCancel={onCancel} />
    </Modal>
  );
};

export default BulkEditModal;
