import React from 'react';
import { Avatar } from 'antd';
import './Persona.css';
import { ENABLE_PROFILE_PHOTOS, S3_IMAGE_BASE_URL } from 'config';

const Persona = ({ text, secondaryText, activeDirectoryId, ...props }) => {
  const color = getInitialsColorFromName(text || props.primaryText);
  const initials = text ? text.split(' ').map(t => t[0]) : null;

  return (
    <div className="persona">
      {ENABLE_PROFILE_PHOTOS ? (
        <Avatar
          src={`${S3_IMAGE_BASE_URL}images/profile/employee-photo-${activeDirectoryId}.jpeg`}
          icon="user"
          size="large"
        />
      ) : (
        <Avatar {...props} style={{ backgroundColor: color }} size="large">
          {initials}
        </Avatar>
      )}

      <div className="persona-text">
        <div className="persona-primary-text">{text}</div>
        <div className="persona-secondary-text">{secondaryText}</div>
      </div>
    </div>
  );
};

const COLOR_SWATCHES_LOOKUP = {
  red: '#8A181A',
  navy: '#344358',
  blue: '#5B94A4',
  lightBlue: '#AACECB',
  yellow: '#F1E096'
};

const COLOR_SWATCHES_NUM_ENTRIES = Object.keys(COLOR_SWATCHES_LOOKUP).length;

function getInitialsColorFromName(displayName) {
  let color = COLOR_SWATCHES_LOOKUP.red;
  if (!displayName) {
    return color;
  }

  let hashCode = 0;
  for (let iLen = displayName.length - 1; iLen >= 0; iLen--) {
    const ch = displayName.charCodeAt(iLen);
    const shift = iLen % 8;
    // tslint:disable-next-line:no-bitwise
    hashCode ^= (ch << shift) + (ch >> (8 - shift));
  }

  const idx = hashCode % COLOR_SWATCHES_NUM_ENTRIES;
  color = Object.values(COLOR_SWATCHES_LOOKUP)[idx];

  return color;
}

export default Persona;
