import React from 'react';
import CertifyingOrganizationForm from 'components/certifications/certifying-organization/CertifyingOrganizationForm';
import Stack from 'components/common/Stack';
import { trackComponent } from 'telemetry/AppInsights';

const NewCertifyingOrganizationPage = props => {
  const handleSubmit = certifyingOrg => {
    props.history.push(`/certifications/certifying_organizations/${certifyingOrg.id}`);
  };
  const handleCancel = () => {
    props.history.push('/certifications');
  };
  return (
    <Stack>
      <CertifyingOrganizationForm isCreateMode onSubmit={handleSubmit} onCancel={handleCancel} />
    </Stack>
  );
};
export default trackComponent(NewCertifyingOrganizationPage, 'New Certifying Organization Page');
