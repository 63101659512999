import React, { useState, useEffect } from "react";
import { Typography, Form, Button, Input, Select } from "antd";
import PropTypes from "prop-types";
import {
  createRoleType,
  updateRoleType,
  toggleRoleTypeActivation,
  getRoleTypeFamilies
} from "api/RoleTypeApi";
import ButtonRow from "components/common/ButtonRow";
import displayErrorNotification from "utils/displayErrorNotification";
import { useUser } from "auth/UserHooks";
import RoleTypeActivationModal from "./RoleTypeActivationModal";

const RoleTypeForm = ({ roleType, form, onSubmit, onCancel, isCreateMode }) => {
  const user = useUser();
  const [roleTypeFamilies, setRoleTypeFamilies] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const _toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    getRoleTypeFamilies()
      .then(response => {
        setRoleTypeFamilies(response);
      })
      .catch(displayErrorNotification);
  }, []);

  const handleActivation = () => {
    toggleRoleTypeActivation(roleType.id)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) return;
      if (isCreateMode) {
        const newRoleType = {
          name: values.name,
          description: values.description,
          family_id: values.family
        };
        createRoleType(newRoleType)
          .then(onSubmit)
          .catch(displayErrorNotification);
      } else {
        const updatedRoleType = {
          id: roleType.id,
          name: values.name,
          description: values.description,
          family_id: values.family
        };
        updateRoleType(roleType.id, updatedRoleType)
          .then(onSubmit)
          .catch(displayErrorNotification);
      }
    });
  };
  const { getFieldDecorator } = form;
  const initialValues = !isCreateMode
    ? { ...roleType, family: roleType.family_id }
    : {};
  return (
    <Form
      layout="vertical"
      onSubmit={handleSubmit}
      style={{ maxWidth: "40em" }}
    >
      <Typography.Title>
        {isCreateMode ? "Add New" : "Edit"} Role Type
      </Typography.Title>
      <Form.Item label="Family">
        {getFieldDecorator("family", {
          initialValue: initialValues.family,
          rules: [{ required: true, message: "Family is a required field" }]
        })(
          <Select
            showSearch
            allowClear
            placeholder="Select a Role Type Family"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {roleTypeFamilies.map(roleTypeFamily => (
              <Select.Option key={roleTypeFamily.id} value={roleTypeFamily.id}>
                {roleTypeFamily.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Role Type Name" required>
        {getFieldDecorator("name", {
          initialValue: initialValues.name,
          rules: [
            { required: true, message: "Role Type Name is a required field" },
            { max: 60, message: "Name must be fewer than 60 characters" }
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator("description", {
          initialValue: initialValues.description,
          rules: [
            {
              max: 255,
              message: "Description must be fewer than 255 characters"
            }
          ]
        })(<Input />)}
      </Form.Item>
      <ButtonRow style={{ marginTop: "1em" }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
        {!isCreateMode && user.permissions.canDeleteRoleTypeType && (
          <Button onClick={_toggleModalVisibility}>
            {roleType && !roleType.active ? "Activate" : "Deactivate"}
          </Button>
        )}
      </ButtonRow>

      {!isCreateMode && (
        <RoleTypeActivationModal
          action={!roleType.active ? "Activate" : "Deactivate"}
          isModalVisible={isModalVisible}
          roleType={roleType}
          closeModal={_toggleModalVisibility}
          onSubmit={handleActivation}
        />
      )}
    </Form>
  );
};

RoleTypeForm.propTypes = {
  roleType: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};
export default Form.create({ name: "role_type_form" })(RoleTypeForm);
