import React from 'react';
import { TreeSelect } from 'antd';

const TreeSelectRecursive = (item, childrenName) => {
  return (
    <TreeSelect.TreeNode
      key={item.id}
      value={item.id}
      title={item.name}
      selectable={item[childrenName] && item[childrenName].length === 0}
    >
      {item[childrenName] && item[childrenName].map(child => TreeSelectRecursive(child, childrenName))}
    </TreeSelect.TreeNode>
  );
};

export default TreeSelectRecursive;
