import React, { useEffect, useState } from 'react';
import { Table, Tag, Button } from 'antd';
import Stack from 'components/common/Stack';
import Toolbar from 'components/common/Toolbar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserAccessModal from './UserAccessModal';
import UserAccessFilters from './UserAccessFilters';
import useLocalStorage from 'utils/useLocalStorage';
import { defaultUserAccessFilterSettings } from 'types/UserAccessPreferences';
import { PAGINATION_DEFAULT } from 'utils/pagination';

const UserAccessTable = ({ employeeRoles, roles, onUpdate, loading, searchRef }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    'UserAccesLandingPage_FilterSettings',
    defaultUserAccessFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage('UserAccesLandingPage_SortSettings', {
    key: 'name',
    order: 'ascend'
  });

  useEffect(() => {
    if (filteredUsers.length === 0) {
      setFilteredUsers(employeeRoles);
    } else {
      const updated = filteredUsers.map(u => {
        const employeeRole = employeeRoles.find(e => e.employee.id === u.employee.id);
        if (employeeRole) {
          u.roles = employeeRole.roles;
        }
        return u;
      });
      setFilteredUsers(updated);
    }
  }, [employeeRoles]);

  const editRoles = user => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const hideModal = () => {
    setSelectedUser(null);
    setShowModal(false);
  };

  let columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'employee',
      render: e => <Link to={`/employees/${e.id}`}>{e.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Practice',
      key: 'practice',
      dataIndex: 'practice',
      sorter: (a, b) => a.practice.toLowerCase().localeCompare(b.practice.toLowerCase())
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      sorter: (a, b) => a.location.toLowerCase().localeCompare(b.location.toLowerCase())
    },
    {
      title: 'Position',
      key: 'title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())
    },
    {
      title: 'Role(s)',
      dataIndex: 'roles',
      key: 'roles',
      render: roles => roles.map(r => <Tag key={r}>{r}</Tag>)
    },
    {
      title: 'Actions',
      render: user => (
        <Button icon="edit" onClick={() => editRoles(user)}>
          Edit Roles
        </Button>
      )
    }
  ];

  const columnsSortSettings = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  return (
    <Stack>
      <Toolbar>
        <UserAccessFilters
          users={employeeRoles}
          onSearch={setFilteredUsers}
          searchRef={searchRef}
          settings={filterSettings}
          setSettings={setFilterSettings}
          disabled={loading}
        />
        <div>
          <span style={{ margin: '0 10px' }}>
            Showing {filteredUsers.length} of {employeeRoles.length} Users
          </span>
        </div>
      </Toolbar>
      <Table
        pagination={PAGINATION_DEFAULT}
        rowKey={r => r.employee.id}
        columns={columns}
        dataSource={filteredUsers}
        loading={loading}
        onChange={onChange}
      />
      {selectedUser && (
        <UserAccessModal
          user={selectedUser}
          employeeRoles={employeeRoles}
          roles={roles}
          modalState={showModal}
          onUpdate={onUpdate}
          onCancel={hideModal}
        />
      )}
    </Stack>
  );
};

UserAccessTable.propTypes = {
  employeeRoles: PropTypes.array,
  roles: PropTypes.array,
  loading: PropTypes.bool
};

export default UserAccessTable;
