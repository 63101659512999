import { PracticeSortSettings } from './ColumnSortSettings';

export interface CapabilityFilterSettings {
  search: string;
  practice: string[];
  inActive: boolean;
}

export function defaultCapabilityFilterSettings(): CapabilityFilterSettings {
  return {
    search: '',
    practice: [],
    inActive: false
  };
}

export function defaultCapabilitySortSettings(): PracticeSortSettings {
  return {
    practices: [],
    default: {
      key: 'name',
      order: 'ascend'
    }
  };
}
