import React from 'react';
import { Modal, Table } from 'antd';
import { trackComponent } from 'telemetry/AppInsights';
import { changeDelimiter } from 'utils/skillDetails';
import formatDate from 'utils/formatDate';

const EmployeeFullInfoModal = ({ displayModal, closeModal, rowToDisplay }) => {
  const columns = [
    {
      title: 'Detail',
      key: 'detailName',
      render: (text, record) => record.detailName
    },
    {
      title: 'text',
      key: 'text',
      render: (text, record) => record.text
    }
  ];
  if (displayModal) {
    var data = [
      {
        detailName: 'Name',
        text: rowToDisplay.fullName
      },
      {
        detailName: 'Practice',
        text: rowToDisplay.practice
      },
      {
        detailName: 'Position',
        text: rowToDisplay.title
      },
      {
        detailName: 'Major',
        text: rowToDisplay.major
      },
      {
        detailName: 'Minor',
        text: rowToDisplay.minor
      },
      {
        detailName: 'Clients',
        text: rowToDisplay.clients !== '' ? changeDelimiter(rowToDisplay.clients) : 'No Clients'
      },
      {
        detailName: 'Projects',
        text: rowToDisplay.projects !== '' ? changeDelimiter(rowToDisplay.projects) : 'No Projects'
      },
      {
        detailName: 'Available Hours',
        text: 40 - rowToDisplay.hours
      },
      {
        detailName: 'Roll-Off Date',
        text: formatDate(rowToDisplay.endDate)
      }
    ];
    if (rowToDisplay.staffingPreferences.length > 0) {
      data.push({
        detailName: 'Staffing Preference',
        text: rowToDisplay.staffingPreferences
      });
    }
  }
  return (
    <Modal title="Extra Employee Info" visible={displayModal} onCancel={() => closeModal()} footer={null}>
      {displayModal && (
        <Table
          pagination={{ position: 'none', pageSize: data.length }}
          showHeader={false}
          columns={columns}
          dataSource={data}
          rowKey="detailName"
        />
      )}
    </Modal>
  );
};

export default trackComponent(EmployeeFullInfoModal, 'Employee Full Info Modal');
