import { Button, Form, Input, InputNumber, Row } from 'antd';
import { createScoutTag, deleteScoutTag, toggleTagActivation, updateScoutTag } from 'api/scoutTagApi';
import { useUser } from 'auth/UserHooks';
import ButtonRow from 'components/common/ButtonRow';
import React, { useEffect, useState } from 'react';
import { ScoutTag } from 'types/ScoutTag';
import displayErrorNotification from 'utils/displayErrorNotification';
import './TagForm.css';

type TagFormProps = {
  tag?: ScoutTag;
  onSubmit: Function;
  onCancel: Function;
  form: any;
};

const TagForm = ({ tag, onSubmit, onCancel, form }: TagFormProps) => {
  const user = useUser();
  const isCreateMode = !!!tag;
  const [inactive, setInactive] = useState<boolean>(false);
  const { getFieldDecorator } = form;
  const initialValues = isCreateMode
    ? {
        name: '',
        description: '',
        displayOrder: null,
        inactive: false
      }
    : { ...tag };

  useEffect(() => {
    form.resetFields();
    if (!isCreateMode) {
      setInactive(tag.inactive);
    }
  }, [tag, onSubmit]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    form.validateFields((err: any, values: any) => {
      if (err) return;

      const newTag: ScoutTag = {
        id: isCreateMode ? null : tag.id,
        name: values.name,
        description: values.description,
        displayOrder: values.displayOrder,
        inactive: isCreateMode ? false : tag.inactive
      };
      if (isCreateMode) {
        createScoutTag(newTag)
          .then(() => onSubmit())
          .catch(displayErrorNotification);
      } else if (newTag.id !== null) {
        updateScoutTag(newTag.id, newTag)
          .then(() => onSubmit())
          .catch(displayErrorNotification);
      }
    });
  };

  const _toggleTagActivation = () => {
    if (tag && tag.id !== null)
      toggleTagActivation(tag.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  const _deleteTag = () => {
    if (tag && tag.id !== null)
      deleteScoutTag(tag.id)
        .then(() => onSubmit())
        .catch(displayErrorNotification);
  };

  return (
    <div style={{ minWidth: '450px' }}>
      <h2>{isCreateMode ? 'Create' : 'Edit'} Tag</h2>
      <Form onSubmit={handleSubmit} className="editTagForm">
        <Form.Item label="Name" required>
          {getFieldDecorator('name', {
            initialValue: initialValues.name,
            rules: [{ required: true, message: 'Name is a required field' }]
          })(<Input disabled={inactive} maxLength={50} />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: initialValues.description
          })(<Input.TextArea disabled={inactive} rows={4} maxLength={255} />)}
        </Form.Item>
        <Form.Item label="Display Order">
          {getFieldDecorator('displayOrder', {
            initialValue: initialValues.displayOrder
          })(<InputNumber disabled={inactive} min={1} max={32767} />)}
        </Form.Item>
        <Row>
          <ButtonRow style={{ marginTop: '30px' }}>
            <Button type="primary" htmlType="submit" disabled={inactive}>
              Save
            </Button>
            <Button onClick={() => onCancel()}>Cancel</Button>
            {!isCreateMode && <Button onClick={_toggleTagActivation}>{inactive ? 'Activate' : 'Deactivate'}</Button>}
            {!isCreateMode && inactive && user.permissions.canDeleteTags && (
              <Button type="danger" onClick={_deleteTag}>
                Delete
              </Button>
            )}
          </ButtonRow>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create<TagFormProps>({ name: 'tag_form' })(TagForm);
