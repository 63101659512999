import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const LocalStoragePresave: string = '_presaved';
type SetValue<T> = Dispatch<SetStateAction<T>>;

function useLocalStorage<T>(key: string, defaultValue: T): [T, SetValue<T>] {
  const readValue = (): T => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return defaultValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : defaultValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return defaultValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = value => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window == 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key + LocalStoragePresave, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}

export const savePreferences = (keys: string[]) => {
  keys.forEach(key => {
    let value: any, item: string | null;
    item = localStorage.getItem(key + LocalStoragePresave);
    if (item !== null) {
      value = JSON.parse(item);
      localStorage.setItem(key, JSON.stringify(value));
    }
  });
};

export default useLocalStorage;

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ? value : '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}
