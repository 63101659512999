import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import 'components/common/drag-and-drop/DragAndDrop.css';
import { DragableItem } from 'components/common/drag-and-drop/DragableItem';

type DndTableWrapperProps = {
  children: (arg0: {
    body: {
      row: typeof DragableItem;
    };
  }) => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
  inactive: boolean;
};

const DndTableWrapper = (props: DndTableWrapperProps) => {
  let components: any = {};
  if (props.inactive) {
    components = {
      body: {
        row: (props: DndTableWrapperProps) => {
          return <tr>{props.children}</tr>;
        }
      }
    };
  } else {
    components = {
      body: {
        row: DragableItem
      }
    };
  }

  return <DndProvider backend={HTML5Backend}>{props.children(components)}</DndProvider>;
};

export default DndTableWrapper;
