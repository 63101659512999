import _ from 'lodash';

export const API_URL = process.env.REACT_APP_API_URL;
export const INSIGHTS_KEY = process.env.REACT_APP_INSIGHTS_KEY;
export const MOCK_API_ENABLED = _.isEqual(process.env.REACT_APP_MOCK_API_ENABLED, 'true');
export const ENABLE_BADGE_STATS = _.isEqual(process.env.REACT_APP_ENABLE_BADGE_STATS, 'true');
export const ENABLE_INSIGHTS = _.isEqual(process.env.REACT_APP_ENABLE_INSIGHTS, 'true');
export const ENABLE_PROFILE_PHOTOS = _.isEqual(process.env.REACT_APP_ENABLE_PROFILE_PHOTOS, 'true');
export const S3_IMAGE_BASE_URL = process.env.REACT_APP_S3_IMAGE_BASE_URL;
export const EMPLOYEE_ACTIVE_DELIMITER = '^';
export const EMPLOYEE_ACTIVE_DELIMITER_REGEX = /\^/g;
