import React, { useEffect, useState } from 'react';
import { Table, Typography, Collapse, Icon, Tag, Tooltip, Button } from 'antd';
import CardCollapse from 'components/common/collapse/CardCollapse';
import UserContext from 'auth/UserContext';
import Stack from 'components/common/Stack';
import { Link } from 'react-router-dom';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import displayErrorNotification from 'utils/displayErrorNotification';
import formatDate from 'utils/formatDate';
import { trackComponent } from 'telemetry/AppInsights';
import { getCertifyingOrganizationById, updateCertifyingOrganization } from 'api/certifyingOrganizationApi';
import _ from 'lodash';
import { useUser } from 'auth/UserHooks';
import RichTextEditor from 'react-rte';
import ButtonRow from 'components/common/ButtonRow';
import { richTextEditorToolbarConfig } from 'utils/richTextEditorToolbarConfig';
import { S3_IMAGE_BASE_URL } from 'config';
import CertifyingOrganizationForm from 'components/certifications/certifying-organization/CertifyingOrganizationForm';
import 'components/common/EditableTextField.css';
import './CertifyingOrganizationPage.css';
import FullPageSpinner from 'components/common/FullPageSpinner';

const CertifyingOrganizationPage = props => {
  const [certifications, setCertifications] = useState(null);
  const [employeeCertifications, setEmployeeCertifications] = useState(null);
  const [certifyingOrganization, setCertifyingOrganization] = useState(null);
  const [isEditModalMode, setEditModalMode] = useState(false);
  const [isEditDescriptionMode, setIsEditDescriptionMode] = useState(false);
  const [descriptionIsEmpty, setDescriptionIsEmpty] = useState(true);
  const [richText, setRichText] = useState(RichTextEditor.createEmptyValue());
  const certifyingOrgId = props.match.params.certifyingOrgId;
  const user = useUser();
  let activePanels = ['certifications', 'employeeCertifications'];

  const _setCertifyingOrganization = certifyingOrg => {
    setCertifyingOrganization(certifyingOrg);
    setDescriptionIsEmpty(!certifyingOrg.description || certifyingOrg.description.replace(/\u200B/g, '').trim() === '');
  };

  useEffect(() => {
    getCertifyingOrganizationById(certifyingOrgId)
      .then(certifyingOrg => {
        if (certifyingOrg.description)
          setRichText(RichTextEditor.createValueFromString(certifyingOrg.description, 'markdown'));
        _setCertifyingOrganization(certifyingOrg);
        setEmployeeCertifications(certifyingOrg.employeeCertifications);
        setCertifications(certifyingOrg.certifications);
      })
      .catch(displayErrorNotification);
  }, []);

  if (!certifyingOrganization) return <FullPageSpinner />;

  const toggleEditDescriptionMode = () => {
    setIsEditDescriptionMode(!isEditDescriptionMode);
  };

  const handleEditDescriptionSubmit = () => {
    const certifyingOrganizationToSend = {
      ...certifyingOrganization,
      description: richText.toString('markdown'),
      image: null
    };
    updateCertifyingOrganization(certifyingOrganization.id, certifyingOrganizationToSend)
      .then(certifyingOrg => {
        _setCertifyingOrganization(certifyingOrg);
        if (certifyingOrg.description)
          setRichText(RichTextEditor.createValueFromString(certifyingOrg.description, 'markdown'));
        setIsEditDescriptionMode(false);
      })
      .catch(displayErrorNotification);
  };

  const handleEditDescriptionCancel = () => {
    setIsEditDescriptionMode(false);
    if (certifyingOrganization.description)
      setRichText(RichTextEditor.createValueFromString(certifyingOrganization.description, 'markdown'));
  };

  const toggleEditModalMode = () => {
    setEditModalMode(!isEditModalMode);
  };

  const handleEditModalSubmit = certifyingOrg => {
    _setCertifyingOrganization(certifyingOrg);
    if (certifyingOrg.description)
      setRichText(RichTextEditor.createValueFromString(certifyingOrg.description, 'markdown'));
    toggleEditModalMode();
    setIsEditDescriptionMode(false);
  };

  const certTypesColumns = [
    {
      title: 'Certification',
      dataIndex: 'certification.name',
      key: 'certification.name',
      defaultSortOrder: 'ascend',
      render: (text, record) => <Link to={`/certifications/${record.id}`}>{record.name}</Link>,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    },
    {
      title: 'Expired Certifications',
      dataIndex: 'expiredCerts',
      key: 'expiredCerts',
      sorter: (a, b) => a.expiredCerts - b.expiredCerts,
      align: 'right'
    },
    {
      title: () => (
        <>
          Expiring Soon{' '}
          <Tooltip title="Certifications that will expire in less than 3 months">
            <Icon type="info-circle" />
          </Tooltip>
        </>
      ),
      dataIndex: 'expiringCerts',
      key: 'expiringCerts',
      sorter: (a, b) => a.expiringCerts - b.expiringCerts,
      align: 'right'
    },
    {
      title: 'Active Certifications',
      dataIndex: 'activeCerts',
      key: 'activeCerts',
      sorter: (a, b) => a.activeCerts - b.activeCerts,
      align: 'right'
    }
  ];

  const employeeCertsColumns = [
    {
      title: 'Name',
      dataIndex: 'employee.fullName',
      key: 'employee.fullName',
      render: (text, record) => <Link to={`/employees/${record.employee.id}`}>{record.employee.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Certification',
      dataIndex: 'certification.name',
      key: 'certification.name',
      render: (text, record) => (
        <Link to={`/certifications/${record.certification.id}`}>{record.certification.name}</Link>
      ),
      sorter: (a, b) => a.certification.name.toLowerCase().localeCompare(b.certification.name.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      key: 'employee.practice',
      sorter: (a, b) => a.employee.practice.toLowerCase().localeCompare(b.employee.practice.toLowerCase())
    },
    {
      title: 'Position',
      dataIndex: 'employee.title',
      key: 'employee.title',
      sorter: (a, b) => a.employee.title.toLowerCase().localeCompare(b.employee.title.toLowerCase())
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      key: 'employee.location',
      sorter: (a, b) => a.employee.location.title.toLowerCase().localeCompare(b.employee.location.toLowerCase())
    },
    {
      title: 'Confirmation Number',
      dataIndex: 'confirmationNumber',
      key: 'confirmationNumber'
    },
    {
      title: 'Active Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: formatDate,
      sorter: (a, b) => _.defaultTo(a.startDate, '').localeCompare(_.defaultTo(b.startDate, '')),
      align: 'right'
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: formatDate,
      defaultSortOrder: 'descend',
      sorter: (a, b) => _.defaultTo(a.expirationDate, '').localeCompare(_.defaultTo(b.expirationDate, '')),
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: record => <Tag color={record ? 'green' : 'red'}>{record ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => (a.active < b.active ? -1 : 1),
      align: 'right'
    }
  ];

  const canClickToEditDescription = () => {
    return user.permissions.canUpdateCertifyingOrganizations && !isEditDescriptionMode && !descriptionIsEmpty;
  };

  if (isEditModalMode) {
    return (
      <Stack>
        <CertifyingOrganizationForm
          certifyingOrg={certifyingOrganization}
          onSubmit={handleEditModalSubmit}
          onCancel={toggleEditModalMode}
        />
      </Stack>
    );
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <SplitPageHeaderLayout.Layout>
            <SplitPageHeaderLayout.Left>
              <Typography.Title level={1} style={{ marginBottom: '0' }}>
                {certifyingOrganization.name}
                {ctx.user.permissions.canUpdateCertifyingOrganizations && (
                  <Button
                    style={{ lineHeight: '0', height: 0, fontSize: '20px' }}
                    icon="edit"
                    type="link"
                    onClick={toggleEditModalMode}
                  />
                )}
              </Typography.Title>
              <Typography.Title level={3} type="secondary" style={{ marginTop: '0' }}>
                {certifications && certifications.reduce((a, b) => +a + +b.activeCerts, 0)} Active&nbsp;·&nbsp;
                {certifications && certifications.reduce((a, b) => +a + +b.expiringCerts, 0)} Expiring Soon&nbsp;·&nbsp;
                {certifications && certifications.reduce((a, b) => +a + +b.expiredCerts, 0)} Expired
              </Typography.Title>
              {certifyingOrganization.channelPartner && (
                <Tag className="ChannelPartnerTagDetailPage"> Channel Partner</Tag>
              )}
              <Typography.Paragraph>
                <div
                  onClick={() => {
                    if (canClickToEditDescription()) toggleEditDescriptionMode();
                  }}
                >
                  <RichTextEditor
                    className={'rich-text-editor'}
                    editorClassName={canClickToEditDescription() ? 'editable-text-field' : ''}
                    readOnly={!isEditDescriptionMode}
                    onChange={setRichText}
                    value={richText}
                    toolbarConfig={richTextEditorToolbarConfig}
                    rootStyle={isEditDescriptionMode ? {} : { border: 'none' }}
                  />
                </div>
                {user.permissions.canUpdateCertificationTypes &&
                  (isEditDescriptionMode ? (
                    <ButtonRow style={{ marginTop: '1em' }}>
                      <Button type="primary" onClick={handleEditDescriptionSubmit}>
                        Save
                      </Button>
                      <Button onClick={handleEditDescriptionCancel}>Cancel</Button>
                    </ButtonRow>
                  ) : (
                    descriptionIsEmpty && (
                      <Button
                        style={{ lineHeight: '0', fontSize: '20px', margin: '10px 5px', border: 'transparent' }}
                        icon="plus"
                        onClick={toggleEditDescriptionMode}
                      >
                        Add Description{' '}
                      </Button>
                    )
                  ))}
              </Typography.Paragraph>
            </SplitPageHeaderLayout.Left>
            {certifyingOrganization.imagePath && (
              <SplitPageHeaderLayout.Right>
                <img
                  style={{ maxWidth: 150, marginBottom: 20 }}
                  src={S3_IMAGE_BASE_URL + certifyingOrganization.imagePath}
                  alt="Certifying Organization"
                />
              </SplitPageHeaderLayout.Right>
            )}
          </SplitPageHeaderLayout.Layout>
          <Collapse bordered={false} defaultActiveKey={activePanels}>
            <CardCollapse.Panel
              key="certifications"
              header={
                <>
                  <Icon type="solution" /> Certifications
                </>
              }
              extra={
                <>
                  <Tag>
                    {certifications && certifications.length} Certification
                    {certifications && certifications.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                style={{ width: '100%' }}
                rowKey="id"
                dataSource={certifications}
                columns={certTypesColumns}
                pagination={false}
              />
            </CardCollapse.Panel>
            <CardCollapse.Panel
              key="employeeCertifications"
              header={
                <>
                  <Icon type="contacts" /> Employees
                </>
              }
              extra={
                <>
                  <Tag>
                    {employeeCertifications && employeeCertifications.length} Employee
                    {employeeCertifications && employeeCertifications.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                style={{ width: '100%' }}
                rowKey="id"
                dataSource={employeeCertifications}
                columns={employeeCertsColumns}
                pagination={false}
              />
            </CardCollapse.Panel>
          </Collapse>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(CertifyingOrganizationPage, 'Certifying Organization Page');
