import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useUser } from 'auth/UserHooks';
import { showParentSkills } from 'utils/skillDetails';
import enumToDisplayText from 'utils/enumToDisplayText';
import formatMonthDate from 'utils/formatMonthDate';
import { isEmpty } from 'lodash';

const SkillCard = ({ employeeId, skill = {}, employeeSkill }) => {
  const user = useUser();
  const isMe = user.id === employeeId;
  const isCoach = user.coachees.includes(employeeId);

  return (
    <Card
      className="avatar-card"
      hoverable={true}
      actions={
        employeeSkill &&
        ((isMe && user.permissions.canUpdateOwnSkill) ||
          (isCoach && user.permissions.canUpdateCoacheeSkill) ||
          user.permissions.canUpdateSkill)
          ? [
              <Link
                to={{
                  pathname: `/employee/skills/${employeeSkill.employeeSkillId}`,
                  state: { employeeId: employeeId }
                }}
              >
                <Icon type="edit" style={{ display: 'inline' }} />
              </Link>
            ]
          : null
      }
    >
      <Typography.Text style={{ fontSize: 10 }}>
        <p>
          {skill.category.name}
          {!isEmpty(skill.parentSkill) && ` / ${showParentSkills(skill.parentSkill)}`}
        </p>
      </Typography.Text>
      <Link to={`/skills/${skill.id}`}>
        <Card.Meta
          title={`${skill.name}`}
          description={
            <span>
              {employeeSkill ? (
                <>
                  Experience Level: {enumToDisplayText(employeeSkill.experienceLevel)}
                  <br />
                  Duration of Experience: {employeeSkill.duration}
                  <br />
                  Last Used: {formatMonthDate(employeeSkill.lastUsedDate)}
                  <br />
                  Interest: {employeeSkill.interest ? 'Yes' : 'No'}
                </>
              ) : (
                <>{skill.category.name}</>
              )}
            </span>
          }
        />
      </Link>
    </Card>
  );
};

SkillCard.propTypes = {
  employeeId: PropTypes.number,
  skill: PropTypes.object,
  employeeSkill: PropTypes.object
};

export default SkillCard;
