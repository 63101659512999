import React from 'react';
import './ButtonRow.css';

const ButtonRow = ({ children, ...props }) => {
  return (
    <span className="button-row" {...props}>
      {children}
    </span>
  );
};

export default ButtonRow;
