import React, { useEffect, useState } from 'react';
import PromptResponseForm from './PromptResponseForm';
import { Checkbox } from 'antd';
import { Prompt } from 'types/Prompt';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type SelectMultiplePromptFormProps = {
  prompt: Prompt;
  value: string;
  onSubmit: (response: string | string[]) => void;
};

const SelectMultiplePromptForm = ({ prompt, value, onSubmit }: SelectMultiplePromptFormProps) => {
  const [respValues, setRespValues] = useState(value && value.length ? value.split(',') : []);
  const [noneOptSelected, setNoneOptSelected] = useState(respValues.includes('N/A'));

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.value === 'N/A') {
      setNoneOptSelected(e.target.checked);
      if (e.target.checked) {
        setRespValues(['N/A']);
      }
    }
  };

  return (
    <PromptResponseForm onSubmit={onSubmit} value={respValues}>
      <Checkbox.Group style={{ width: 'fit-content' }}>
        {prompt.options
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map(o => (
            <Checkbox
              key={o.value}
              value={o.value}
              style={{
                display: 'block',
                height: '30px',
                lineHeight: '30px',
                marginLeft: '0px',
                paddingLeft: '5px'
              }}
              className={o.value === 'N/A' ? 'none-prompt-option' : ''}
              disabled={noneOptSelected && o.value !== 'N/A'}
              onChange={handleCheckboxChange}
            >
              {o.response}
            </Checkbox>
          ))}
      </Checkbox.Group>
    </PromptResponseForm>
  );
};

export default SelectMultiplePromptForm;
