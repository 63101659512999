import { notification } from 'antd'
import { updateEmployeeRoles } from 'api/roleApi'
import displayErrorNotification from 'utils/displayErrorNotification'
import { chain } from 'lodash'
import { EmployeeRole } from 'types/EmployeeRole'

export const updateEmployee = (
  employee: EmployeeRole,
  addRoles: string[] | undefined,
  removeRoles: string[] | undefined,
  callback: Function
) => {
  let roles = [...employee.roles]
  if (removeRoles !== undefined) {
    removeRoles.forEach(remove => {
      const index = roles.indexOf(remove)
      if (index > -1) {
        roles.splice(index, 1)
      }
    })
  }
  if (addRoles !== undefined) {
    addRoles.forEach(add => {
      roles.push(add)
    })
    roles = chain(roles)
      .uniq()
      .value()
  }
  updateEmployeeRoles(Number(employee.employee.id), roles)
    .then(() => {
      notification.success({
        message: `Roles for ${employee.employee.fullName} have been updated`
      })
      callback()
    })
    .catch(displayErrorNotification)
}
