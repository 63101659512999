export interface KeyMetricFilterSettings {
  search: string | null;
  period: number;
  practice: string[];
  office: string[];
  position: string[];
}

export function defaultKeyMetricFilterSettings(): KeyMetricFilterSettings {
  return {
    search: null,
    period: 0,
    practice: [],
    office: [],
    position: []
  };
}
