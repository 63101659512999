import React from 'react';
import { Form, Typography, Button } from 'antd';
import { deleteEmployeeCertification } from 'api/employeeCertificationApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import ButtonRow from 'components/common/ButtonRow';

const DeleteEmployeeCertificationForm = ({ employeeCertification, onSubmit, onCancel }) => {
  const handleSubmit = e => {
    e.preventDefault();

    deleteEmployeeCertification(employeeCertification.id)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  return (
    <Form layout="vertical" style={{ maxWidth: '40em' }} onSubmit={handleSubmit}>
      <Typography.Paragraph strong>Are you sure you want to delete this certification?</Typography.Paragraph>
      <Typography.Paragraph>
        {employeeCertification.certification.certifyingOrganization.name} {employeeCertification.certification.name}
      </Typography.Paragraph>
      <ButtonRow style={{ marginTop: '2em' }}>
        <Button type="primary" htmlType="submit">
          Delete
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ButtonRow>
    </Form>
  );
};
export default DeleteEmployeeCertificationForm;
