import { get, post, put, del } from 'api';
import { Entity } from 'types/Entity';
import { EntitySelect } from 'types/EntitySelect';
import { EntityDetail } from 'types/EntityDetail';
import { EmployeeEntityRequest } from 'types/EmployeeEntityRequest';
import { EmployeeBasic } from 'types/EmployeeBasic';

export function getEntities(): Promise<Entity[]> {
  return get(`api/entities`);
}

export function getEntitiesSelect(): Promise<EntitySelect[]> {
  return get(`api/entities/select`);
}

export function getEntityById(id: number): Promise<EntityDetail> {
  return get(`api/entities/${id}`);
}

export function createEntity(entity: any) {
  return post(`api/entities`, entity);
}

export function updateEntity(id: number, entity: EntityDetail): Promise<EntityDetail> {
  return put(`api/entities/${id}`, entity);
}

export function toggleEntityActivation(id: number): Promise<EntityDetail> {
  return post(`api/entities/${id}`);
}

export function getEmployeesWithEntity(id: number): Promise<EmployeeBasic[]> {
  return get(`api/entities/employee/find/${id}`);
}

export function updateEmployeeEntity(employeeId: number, id: number): Promise<EmployeeEntityRequest> {
  return put(`api/entities/employee/${employeeId}/${id}`);
}
