import { get, post, put, del } from 'api';
import { SkillSummary } from 'types/SkillSummary';
import { SkillRequest } from 'types/SkillRequest';
import { LandingPageSkillAPI, Skill } from 'types/Skill';
import { SkillDetail } from 'types/SkillDetail';
import { SkillCategory } from 'types/SkillCategory';
import { EmployeeSkillDetailAPI } from 'types/EmployeeSkillDetail';

export const getSkills: (includeInactive: boolean) => Promise<Skill[]> = (includeInactive = false) =>
  get('api/skills', { includeInactive });

export const getSkillsSummary: (includeInactive: boolean) => Promise<SkillSummary[]> = (includeInactive = false) =>
  get('api/skills/summary', { includeInactive });

export function getLandingPageSkill(): Promise<LandingPageSkillAPI[]> {
  return get(`api/skills/landingpage`);
}

export const getSkillById: (id: number) => Promise<SkillDetail> = id => get(`api/skills/${id}`);

export const getEmployeesWithSkill: (id: number) => Promise<EmployeeSkillDetailAPI[]> = id =>
  get(`api/skills/${id}/employees`);

export const createSkill: (skill: SkillRequest) => Promise<SkillDetail> = skill => post(`api/skills`, skill);

export const updateSkill: (id: number, skill: SkillRequest) => Promise<SkillDetail> = (id, skill) =>
  put(`api/skills/${id}`, skill);

export const toggleSkillActivation: (id: number) => Promise<SkillDetail> = id => del(`api/skills/${id}`);

export function getSkillCategories(): Promise<SkillCategory[]> {
  return get(`api/skills/categories`);
}

export function getSkillCategoryDetails(): Promise<SkillCategory[]> {
  return get(`api/skills/categories/details`);
}
