import React from 'react';
import './Toolbar.css';

const Toolbar = ({ children, style = {} }) => {
  return (
    <div className="toolbar" style={{ ...style }}>
      {children}
    </div>
  );
};

export default Toolbar;
