import React, { useState } from 'react';
import { Typography, Form, Button, Input } from 'antd';
import PropTypes from 'prop-types';
import { createEntity, toggleEntityActivation, updateEntity } from 'api/entitiesApi';
import ButtonRow from 'components/common/ButtonRow';
import displayErrorNotification from 'utils/displayErrorNotification';
import { useUser } from '../../../auth/UserHooks';
import EntityActivationModal from './EntityActivationModal';

const EntityForm = ({ entity, form, onSubmit, onCancel, isCreateMode }) => {
  const user = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const _toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleActivation = () => {
    toggleEntityActivation(entity.id)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;

      if (isCreateMode) {
        createEntity(values)
          .then(onSubmit)
          .catch(displayErrorNotification);
      } else {
        updateEntity(entity.id, values)
          .then(onSubmit)
          .catch(displayErrorNotification);
      }
    });
  };

  const { getFieldDecorator } = form;
  const initialValues = !isCreateMode ? { ...entity } : {};
  return (
    <Form layout="vertical" onSubmit={handleSubmit} style={{ maxWidth: '40em' }}>
      <Typography.Title>{isCreateMode ? 'Add New' : 'Edit'} Entity</Typography.Title>

      <Form.Item label="Name" required>
        {getFieldDecorator('name', {
          initialValue: initialValues.name,
          rules: [
            { required: true, message: 'Name is a required field' },
            { max: 60, message: 'Name must be fewer tan 60 characters' }
          ]
        })(<Input />)}
      </Form.Item>

      <Form.Item label="Description">
        {getFieldDecorator('description', {
          initialValue: initialValues.description,
          rules: [{ max: 255, message: 'Description must be fewer than 255 characters' }]
        })(<Input />)}
      </Form.Item>

      <ButtonRow style={{ marginTop: '1em' }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
        {!isCreateMode && user.permissions.canToggleEntityActivation && (
          <Button onClick={_toggleModalVisibility}>{entity && entity.inactive ? 'Activate' : 'Deactivate'}</Button>
        )}
      </ButtonRow>

      {!isCreateMode && (
        <EntityActivationModal
          action={entity.inactive ? 'Activate' : 'Deactivate'}
          isModalVisible={isModalVisible}
          entity={entity}
          closeModal={_toggleModalVisibility}
          onSubmit={handleActivation}
        />
      )}
    </Form>
  );
};

EntityForm.propTypes = {
  entity: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: 'entity_form' })(EntityForm);
