import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'antd';

const BadgeCategoryInfo = ({ category }) => {
  const openInfoModal = e => {
    e.stopPropagation();
    Modal.info({
      title: 'More Info',
      content: (
        <div style={{ paddingTop: '5px' }}>
          <p>
            <b>Owners:</b> {category.owners.length ? category.owners.map(owner => owner.fullName).join(', ') : 'None'}
          </p>
        </div>
      ),
      maskClosable: true
    });
  };

  return <Icon title="More Info" type="info-circle" onClick={openInfoModal} />;
};

BadgeCategoryInfo.propTypes = {
  category: PropTypes.object.isRequired
};

export default BadgeCategoryInfo;
