import React, { useState, useEffect } from 'react';
import { Button, Collapse } from 'antd';
import EmployeeAdvancedSearchForm from './EmployeeAdvancedSearchForm';
import { trackComponent } from 'telemetry/AppInsights';
import Toolbar from 'components/common/Toolbar';
import ButtonRow from 'components/common/ButtonRow';
import './EmployeeAdvancedSearchPanel.css';

const EmployeeAdvancedSearchPanel = ({
  onSubmit,
  employees,
  active,
  setActive,
  extraInfoHidden,
  roleTypes,
  skills,
  certifications,
  certifyingOrgNames,
  prompts,
  disabled,
  searchRef,
  settings,
  setSettings
}) => {
  const [advancedSearchFilteredEmployees, setAdvancedSearchFilteredEmployees] = useState([]);
  const [activePanels, setActivePanels] = useState([]);

  useEffect(() => {
    if (active) {
      setActivePanels(['ActiveSearch']);
    } else {
      setActivePanels([]);
    }
  }, []);

  const handleTogglePanel = () => {
    if (!disabled) {
      if (active) {
        setActivePanels([]);
      } else {
        setActivePanels(['ActiveSearch']);
      }
      setActive(!active);
    }
  };

  return (
    <div>
      <ButtonRow style={{ display: 'flex' }}>
        <Button type="link" onClick={handleTogglePanel} style={{ marginLeft: 'auto' }}>
          Advanced Search
        </Button>
      </ButtonRow>
      <Collapse
        header={null}
        className="no-header-collapse"
        activeKey={activePanels}
        style={active ? { borderTop: 'none' } : { border: 'none' }}
      >
        <Collapse.Panel
          header={null}
          showArrow={false}
          style={{ border: 'none', background: '#fff' }}
          collapsible="disabled"
          key="ActiveSearch"
        >
          <Toolbar style={{ background: '#fff', padding: '0', marginBottom: '0' }}>
            <EmployeeAdvancedSearchForm
              onSearch={setAdvancedSearchFilteredEmployees}
              onSubmit={onSubmit}
              employees={employees}
              advancedSearchFilteredEmployees={advancedSearchFilteredEmployees}
              extraInfoHidden={extraInfoHidden}
              searchRef={searchRef}
              disabled={disabled}
              settings={settings}
              setSettings={setSettings}
              roleTypes={roleTypes}
              skills={skills}
              certifications={certifications}
              certifyingOrgNames={certifyingOrgNames}
              prompts={prompts}
            />
          </Toolbar>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default trackComponent(EmployeeAdvancedSearchPanel, 'Employee Advanced Search Panel');
