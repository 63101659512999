import React, { useEffect, useState, useRef } from 'react'
import { Collapse, Typography, Table, Tag, Spin, Button, Input } from 'antd'
import { get, find } from 'lodash'
import UserContext from 'auth/UserContext'
import { Link, RouteComponentProps } from 'react-router-dom'
import CardCollapse from 'components/common/collapse/CardCollapse'
import Stack from 'components/common/Stack'
import displayErrorNotification from 'utils/displayErrorNotification'
import { showSector } from 'utils/skillDetails'
import { getLandingPageIndustry } from 'api/industriesApi'       
import { EmployeeWithPermissions, useUser } from 'auth/UserHooks'
import { trackComponent } from 'telemetry/AppInsights'
import IndustryFilters, { filterIndustries } from 'components/industries/IndustryFilters'
import Toolbar from 'components/common/Toolbar'
import { uniq, map } from 'lodash'
import ButtonRow from 'components/common/ButtonRow'
import { ColumnProps } from 'antd/lib/table'
import { Industry } from 'types/Industry'
import SavePreferencesButton from 'components/common/SavePreferencesButton'
import useLocalStorage from 'utils/useLocalStorage'  
import {
  defaultIndustrySortSettings,
  defaultIndustryFilterSettings,
  IndustryFilterSettings
} from 'types/IndustryPreferences'  
import { SectorSortSettings } from 'types/ColumnSortSettings'
import { getIndustrySectors } from 'api/industrySectorApi'  
import { convertLandingsPageIndustry } from 'utils/convert' 
import { IndustrySector } from 'types/IndustrySector'

const IndustriesPage = (props: RouteComponentProps) => {
  const [industries, setIndustries] = useState<Industry[]>([])
  const [filteredIndustries, setFilteredIndustries] = useState<Industry[]>([])
  const [industrySectors, setIndustrySectors] = useState<IndustrySector[]>([])
  const [sectors, setSectors] = useState<number[]>([])
  const [activePanels, setActivePanels] = useState<string[]>([])
  const user = useUser()
  const [loading, setLoading] = useState(false)
  const [filterSettings, setFilterSettings] = useLocalStorage<IndustryFilterSettings>(
    'IndustriesLandingPage_FilterSettings',
    defaultIndustryFilterSettings()
  )
  const [sortSettings, setSortSettings] = useLocalStorage<SectorSortSettings>(
    'IndustriesLandingPage_SortSettings',
    defaultIndustrySortSettings()
  )
  const searchRef = useRef<Input>()

  useEffect(() => {
    setLoading(true)
    Promise.all([getLandingPageIndustry(), getIndustrySectors()]) 
      .then(response => {
        let converted = convertLandingsPageIndustry(response[0])
        let sortedSectors = getSortedCategoriesByUser(user, uniq(map(converted, 'industrySectorId')), response[1])
        setIndustries(converted)
        setIndustrySectors(response[1])
        setSectors(sortedSectors)
        filterIndustries(
          {
            onSearch: handleFilter,
            industries: converted,
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            form: null,
            searchRef: null,
            industrySectors: response[1],
            sectors: sortedSectors
          },
          {},
          {
            searchTerm: filterSettings.search,
            selectedSectors: filterSettings.sector,
            showInactive: filterSettings.inActive
          }
        )

      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false)
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus()
        }
      })

  }, [])

  const getSortedCategoriesByUser = (user: EmployeeWithPermissions, usedSectors: number[], industrySectors: IndustrySector[]) => {
    let categories: number[] = []
    const is: IndustrySector | undefined = find(industrySectors)
    if (is !== undefined) {
      if (usedSectors.includes(is.id)) {
        categories.push(is.id)
      }
    }
    industrySectors.forEach(s => {
      if (is === undefined || s.id !== is.id) {
        if (usedSectors.includes(s.id)) {
          categories.push(s.id)
        }
      }
    })
    return categories
  }

  const handlePanelChange = (panels: string[] | string) => {
    if (panels instanceof Array) {
      setActivePanels(panels)
    } else {
      setActivePanels([panels])
    }
  }

  const handleFilter = (_filteredIndustries: Industry[]) => {
    setFilteredIndustries(_filteredIndustries)
    const panelIds = uniq(map(_filteredIndustries, 'industrySectorId')).map(String)
    setActivePanels(panelIds)
  }

  const columns: ColumnProps<Industry>[] = [
    {
      title: 'Industry',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (text, record) => <Link to={`/industries/${record.id}`}>{record.name}</Link>,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onHeaderCell: () => {
        return {
          style: { position: 'absolute', left: 0, width: 'calc(30%)' }
        }
      }
    },
    {
      title: 'Employees',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Major',
      dataIndex: 'majorCount',
      key: 'majorCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Minor',
      dataIndex: 'minorCount',
      key: 'minorCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'inactive',
      key: 'inactive',
      width: '150px',
      align: 'right',
      render: inactive => <Tag color={inactive ? 'red' : 'green'}>{inactive ? 'Inactive' : 'Active'}</Tag>,
      sorter: (a, b) => (a.inactive < b.inactive ? 1 : -1)
    }
  ]

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      const find = sortSettings.sectors.find(o => {
        return o.sector === sorter.column.sector
      })
      if (find === undefined) {
        sortSettings.sectors.push({
          sector: sorter.column.sector,
          column: {
            key: sorter.columnKey,
            order: sorter.order
          }
        })
      } else {
        find.column.key = sorter.columnKey
        find.column.order = sorter.order
      }
      setSortSettings(sortSettings)
    }
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <div>
            <Typography.Title level={1}>
              Industries
              <ButtonRow style={{ float: 'right' }}>
                {ctx.user.permissions.canCreateIndustryType && (
                  <Button icon="solution" onClick={() => props.history.push('/industries/new')}>
                    Add Industry Type
                  </Button>
                )}
                <SavePreferencesButton
                  keys={['IndustriesLandingPage_FilterSettings', 'IndustriesLandingPage_SortSettings']}
                  disabled={loading}
                />
              </ButtonRow>
            </Typography.Title>
            <Typography.Paragraph>
              The below inventory reflects the industry groups that exist across various sectors. Click on
              an industry to view all of the employees aligned to that group.
            </Typography.Paragraph>
            <Toolbar>
              <IndustryFilters
                settings={filterSettings}
                setSettings={setFilterSettings}
                disabled={loading}
                onSearch={handleFilter}
                industries={industries}
                searchRef={searchRef}
                industrySectors={industrySectors}
                sectors={sectors}
              />
              <div>
                Showing {filteredIndustries.length} of {industries.length} Industries
              </div>
            </Toolbar>
          </div>

          <Spin spinning={loading}>
            <Collapse bordered={false} onChange={handlePanelChange} activeKey={activePanels}>
              {sectors.map(industrySectorId => {
                const categoryIndustries = filteredIndustries.filter(i => i.industrySectorId === industrySectorId)
                const categoryIndustriesCount = categoryIndustries.length

                const columnsSortSettings: ColumnProps<Industry>[] = columns.map(column => {
                  const find = sortSettings.sectors.find(o => {
                    return o.sector === industrySectorId
                  })
                  if (find !== undefined) {
                    if (column.key === find.column.key) {
                      return {
                        ...column,
                        defaultSortOrder: find.column.order,
                        sector: industrySectorId
                      }
                    }
                  } else {
                    if (column.key === sortSettings.default.key) {
                      return { ...column, defaultSortOrder: sortSettings.default.order, sector: industrySectorId }
                    }
                  }
                  return { ...column, sector: industrySectorId }
                })

                return (
                  <CardCollapse.Panel
                    header={<>{showSector(industrySectors, industrySectorId)}</>}
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag>{`${categoryIndustriesCount} ${categoryIndustriesCount === 1 ? 'Industry' : 'Industries'
                          }`}</Tag>
                      </div>
                    }
                    key={industrySectorId}
                  >
                    <Table
                      rowKey="id"
                      style={{ width: '100%' }}
                      dataSource={categoryIndustries}
                      columns={columnsSortSettings}
                      pagination={false}
                      onChange={onChange}
                    />
                  </CardCollapse.Panel>
                )
              })}
            </Collapse>
          </Spin>
        </Stack>
      )}
    </UserContext.Consumer>
  )
}

export default trackComponent(IndustriesPage, 'Industries Landing Page')