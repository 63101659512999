import React from 'react';
import { Input, Select, Button, Form, Icon, Checkbox } from 'antd';
import { debounce, uniq } from 'lodash';
import { LandingPageProject } from 'types/Project';
import { ProjectFilterSettings } from 'types/ProjectPreferences';

type ProjectFiltersProps = {
  projects: LandingPageProject[];
  onSearch: (filteredProjects: LandingPageProject[]) => void;
  form: any;
  searchRef: any;
  disabled: boolean;
  settings: ProjectFilterSettings;
  projector: boolean;
  setSettings: (settings: ProjectFilterSettings) => void;
};

export const filterProjects = (props: ProjectFiltersProps, changedValues: any, values: any) => {
  const { projects, onSearch, setSettings, projector } = props;
  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const markets = values.selectedMarkets;
  const practices = values.selectedPractices; // TODO: Remove for Projector go-live
  const showInactive = values.showInactive;
  const showManagers = values.showManagers;

  setSettings({
    search: values.searchTerm,
    market: markets,
    practice: practices, // TODO: Remove for Projector go-live
    inactive: showInactive,
    showManagers: showManagers
  });

  const filteredProjects = projects.filter(p => {
    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      p.projectName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      p.clientName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      p.engagementName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      (showManagers &&
        (p.engagementManager.toUpperCase().indexOf(uppercaseTerm) > -1 ||
          p.projectManager.toUpperCase().indexOf(uppercaseTerm) > -1 ||
          p.oic.toUpperCase().includes(uppercaseTerm)));
    let isMarketValid = !markets || !markets.length || markets.indexOf(p.location) > -1;
    let isPracticeValid = !practices || !practices.length || practices.indexOf(p.practice) > -1; // TODO: Remove for Projector go-live
    let isStatusValid = showInactive || p.active;
    let isProjectorValid = (projector && isMarketValid) || (!projector && isPracticeValid);
    return isNameValid && isProjectorValid && isStatusValid; // TODO: Remove for Projector go-live
  });
  onSearch(filteredProjects);
};

const ProjectFilters = (props: ProjectFiltersProps) => {
  const { projects, form, settings, disabled, searchRef, projector } = props;
  const { getFieldDecorator } = form;
  const markets = uniq<string>(projects.map(p => p.location)).sort(); // TODO: Add for Projector go-live
  const selectedMarkets = form.getFieldValue('selectedMarkets') || []; // TODO: Add for Projector go-live
  const practices = uniq<string>(projects.map(p => p.practice)).sort(); // TODO: Remove for Projector go-live
  const selectedPractices = form.getFieldValue('selectedPractices') || []; // TODO: Remove for Projector go-live

  const clearFilters = () => {
    form.resetFields();
    filterProjects(
      props,
      {},
      {
        searchTerm: '',
        selectedPractices: [],
        showInactive: false
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: 'none', opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator('searchTerm', {
            initialValue: settings.search
          })(<Input prefix={<Icon type="filter" />} ref={searchRef} disabled={disabled} placeholder="Filter" />)}
        </Form.Item>
        {// TODO: Add for Projector go-live
        projector && (
          <Form.Item>
            {getFieldDecorator('selectedMarkets', {
              initialValue: settings.market
            })(
              <Select
                maxTagCount={0}
                maxTagPlaceholder={`${selectedMarkets.length} Market${
                  selectedMarkets.length === 1 ? '' : 's'
                } Selected`}
                showArrow
                mode="multiple"
                placeholder="Market"
                dropdownClassName="filter-bar-select-dropdown"
                className="filter-bar-select"
              >
                {markets.map(m => (
                  <Select.Option key={m} value={m}>
                    {m}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {/* TODO: Remove for Projector go-live */}
        {!projector && (
          <Form.Item>
            {getFieldDecorator('selectedPractices', {
              initialValue: settings.practice
            })(
              <Select
                maxTagCount={0}
                maxTagPlaceholder={`${selectedPractices.length} Practice${
                  selectedPractices.length === 1 ? '' : 's'
                } Selected`}
                showArrow
                mode="multiple"
                placeholder="Practice"
                dropdownClassName="filter-bar-select-dropdown"
                className="filter-bar-select"
              >
                {practices.map(m => (
                  <Select.Option key={m} value={m}>
                    {m}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item>
          {getFieldDecorator('showInactive', {
            initialValue: settings.inactive,
            valuePropName: 'checked'
          })(<Checkbox>Show Inactive</Checkbox>)}
        </Form.Item>
        {// TODO: Add for Projector go-live
        projector && (
          <Form.Item>
            {getFieldDecorator('showManagers', {
              initialValue: settings.showManagers,
              valuePropName: 'checked'
            })(<Checkbox>Show Managers</Checkbox>)}
          </Form.Item>
        )}
        <Form.Item>
          <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create({
  name: 'filter_projects_form',
  onValuesChange: debounce(filterProjects, 500)
})(ProjectFilters);
