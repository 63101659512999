import { get, post, put, del } from 'api'
import { EmployeeMajorMinor } from 'types/EmployeeMajorMinor'
import { EmployeeMajorMinorRequest } from 'types/EmployeeMajorMinorRequest'

export function getEmployeesWithMajorMinor(id: number, capability: boolean): Promise<EmployeeMajorMinor[]> {
  return get(`api/major-minor/employee/find/${id}/${capability}`)
}

export function getMajorMinorForEmployee(id: number, includeInactive = false): Promise<EmployeeMajorMinor[]> {
  return get(`api/major-minor/employee/${id}`, { includeInactive })
}

export function deleteMajorMinorById(employeeId: number, id: number): Promise<void> {
  return del(`api/major-minor/employee/${employeeId}/${id}`)  
}

export function addEmployeeMajorMinor(employeeId: number, majorMinor: EmployeeMajorMinorRequest): Promise<EmployeeMajorMinor> {
  return post(`api/major-minor/employee/${employeeId}`, majorMinor)
}

export function updateEmployeeMajorMinor(
  employeeId: number,
  majorMinor: EmployeeMajorMinorRequest
): Promise<EmployeeMajorMinor> {
  return put(`api/major-minor/employee/${employeeId}`, majorMinor)
}