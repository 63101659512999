import React from "react";
import { Button, Card, Icon, Typography } from "antd";
import "./PromptCard.css";
import { toString } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { EmployeePrompt } from "types/EmployeePrompt";

type PromptCardProps = {
  EmplPrompt: EmployeePrompt;
  onSelect: () => void;
};

const PromptCard: React.FC<PromptCardProps> = ({ EmplPrompt, onSelect }) => {
  function displayPromptResponse(response: string) {
    return response ? toString(response) : "No Response";
  }

  function shouldDisplayAuditData(promptQuestion: string) {
    return promptQuestion === "Staffing Considerations";
  }

  return (
    <Card
      className="prompt-card"
      hoverable={true}
      style={{ height: "100%" }}
      actions={
        EmplPrompt.editable
          ? [
              <Button type="link" onClick={onSelect}>
                <Icon type="edit" style={{ display: "inline" }} />
              </Button>,
            ]
          : undefined
      }
    >
      <Card.Meta
        title={EmplPrompt.prompt.question}
        description={
          <Typography.Paragraph ellipsis={{ rows: 3, expandable: true }}>
            {displayPromptResponse(EmplPrompt.response)}
          </Typography.Paragraph>
        }
      />
      {shouldDisplayAuditData(EmplPrompt.prompt.question) && (
        <div key={EmplPrompt.prompt.id!}>
          <Typography.Paragraph>
            {EmplPrompt.dateModified && (
              <span>
                Last Modified Date:{" "}
                {moment(EmplPrompt.dateModified).format("YYYY-MM-DD")}
              </span>
            )}
            <br />
            {EmplPrompt.lastUpdatedBy && (
              <span>
                Last Modified By:{" "}
                <Link to={`/employees/${EmplPrompt.lastUpdatedBy.id}`}>
                  {EmplPrompt.lastUpdatedBy.fullName}
                </Link>
              </span>
            )}
          </Typography.Paragraph>
        </div>
      )}
    </Card>
  );
};

export default PromptCard;
