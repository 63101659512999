import React, { useEffect, useRef, useState } from 'react';
import { Typography, Table, Button, Icon, Tooltip, Collapse, Tag, Spin, Input } from 'antd';
import CardCollapse from 'components/common/collapse/CardCollapse';
import { uniq } from 'lodash';
import UserContext from 'auth/UserContext';
import { getCertifications } from 'api/employeeCertificationApi';
import Stack from 'components/common/Stack';
import { Link, RouteComponentProps } from 'react-router-dom';
import CertificationFilters, { filterCertifications } from './CertificationFilters';
import Toolbar from 'components/common/Toolbar';
import ButtonRow from 'components/common/ButtonRow';
import displayErrorNotification from 'utils/displayErrorNotification';
import { getAllEmployeesCertifications } from 'api/employeeCertificationApi';
import { getAllCertifyingOrganizations } from 'api/certifyingOrganizationApi';
import CertificationCardModal from '../employee-certification/CertificationCardModal';
import { trackComponent } from 'telemetry/AppInsights';
import { ColumnProps } from 'antd/lib/table';
import { CertificationSummary } from 'types/CertificationSummary';
import { CertifyingOrganization } from 'types/CertifyingOrganization';
import './CertificationsLandingPage.css';
import SavePreferencesButton from 'components/common/SavePreferencesButton';
import useLocalStorage from 'utils/useLocalStorage';
import {
  defaultCertificationFilterSettings,
  defaultCertificationSortSettings,
  CertificationFilterSettings
} from 'types/CertificationPreferences';
import { CategorySortSettings } from 'types/ColumnSortSettings';
import { useFeatures } from 'hooks/FeatureHooks';

const CertificationsLandingPage = (props: RouteComponentProps) => {
  const features = useFeatures();
  const [certifications, setCertifications] = useState<CertificationSummary[]>([]);
  const [filteredCerts, setFilteredCerts] = useState<CertificationSummary[]>([]);
  const [isCertModalVisible, setCertModalVisible] = useState(false);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [certifyingOrgs, setCertifyingOrgs] = useState<CertifyingOrganization[]>([]);
  const [filteredCertOrgs, setFilteredCertOrgs] = useState<CertifyingOrganization[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterSettings, setFilterSettings] = useLocalStorage<CertificationFilterSettings>(
    'CertificationsLandingPage_FilterSettings',
    defaultCertificationFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage<CategorySortSettings>(
    'CertificationsLandingPage_SortSettings',
    defaultCertificationSortSettings()
  );
  const searchRef = useRef<Input>();

  useEffect(() => {
    setLoading(true);

    Promise.all([getCertifications(true), getAllCertifyingOrganizations()])
      .then(results => {
        setCertifications(results[0]);
        setFilteredCerts(results[0]);
        let _certifyingOrgs = results[1].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        let _certifyingOrgNames = _certifyingOrgs.map(certifyingOrg => certifyingOrg.name);
        setCertifyingOrgs(_certifyingOrgs);
        setFilteredCertOrgs(_certifyingOrgs);
        setActivePanels(_certifyingOrgNames);
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus();
        }
        filterCertifications(
          {
            certifications: results[0],
            certifyingOrgs: _certifyingOrgs,
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            form: null,
            searchRef: null,
            onSearch: handleFilter
          },
          {},
          {
            searchTerm: filterSettings.search,
            selectedCertifyingOrgNames: filterSettings.organization,
            showOnlyChannelPartners: filterSettings.partner,
            showInactive: filterSettings.inActive
          }
        );
      })
      .catch(displayErrorNotification);
  }, []);

  const handleFilter = (_filteredCerts: CertificationSummary[], _filteredCertOrgs: CertifyingOrganization[]) => {
    setFilteredCerts(_filteredCerts);
    setFilteredCertOrgs(_filteredCertOrgs);
    const panelIds: string[] = uniq(_filteredCerts.map(c => c.certifyingOrganization.name));
    setActivePanels(panelIds);
  };

  const handlePanelChange = (panels: string[] | string) => {
    if (panels instanceof Array) {
      setActivePanels(panels);
    } else {
      setActivePanels([panels]);
    }
  };

  const toggleExpandAll = () => {
    const expanded = activePanels.length;
    const panelIds = filteredCertOrgs.map(co => co.name);
    setActivePanels(expanded ? [] : panelIds);
  };

  const columns: ColumnProps<CertificationSummary>[] = [
    {
      title: 'Certification',
      dataIndex: 'name',
      key: 'name',
      render: (text, cert) => <Link to={`/certifications/${cert.id}`}>{cert.name}</Link>,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      width: '40%'
    },
    {
      title: 'Certifying Organization',
      dataIndex: 'certifyingOrganization.name',
      key: 'certifyingOrganization.name',
      render: (text, cert) => (
        <Link to={`/certifications/certifying_organizations/${cert.certifyingOrganization.id}`}>
          {cert.certifyingOrganization.name}
        </Link>
      ),
      sorter: (a, b) =>
        a.certifyingOrganization.name.toLowerCase().localeCompare(b.certifyingOrganization.name.toLowerCase())
    },
    {
      title: 'Expired Certifications',
      dataIndex: 'expiredCerts',
      key: 'expiredCerts',
      sorter: (a, b) => a.expiredCerts - b.expiredCerts,
      align: 'right'
    },
    {
      title: () => (
        <>
          Expiring Soon{' '}
          <Tooltip title="Certifications that will expire in less than 3 months">
            <Icon type="info-circle" />
          </Tooltip>
        </>
      ),
      dataIndex: 'expiringCerts',
      key: 'expiringCerts',
      sorter: (a, b) => a.expiringCerts - b.expiringCerts,
      align: 'right'
    },
    {
      title: 'Active Certifications',
      dataIndex: 'activeCerts',
      key: 'activeCerts',
      sorter: (a, b) => a.activeCerts - b.activeCerts,
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'inactive',
      key: 'inactive',
      align: 'right',
      render: inactive => <Tag color={inactive ? 'red' : 'green'}>{inactive ? 'Inactive' : 'Active'}</Tag>,
      sorter: (a, b) => (a.inactive < b.inactive ? 1 : -1)
    }
  ];

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      const find = sortSettings.categories.find(o => {
        return o.category === sorter.column.certifyingOrg;
      });
      if (find === undefined) {
        sortSettings.categories.push({
          category: sorter.column.certifyingOrg,
          column: {
            key: sorter.columnKey,
            order: sorter.order
          }
        });
      } else {
        find.column.key = sorter.columnKey;
        find.column.order = sorter.order;
      }
      setSortSettings(sortSettings);
    }
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <div>
            <Typography.Title level={1}>
              Certifications
              <ButtonRow style={{ float: 'right' }}>
                {ctx.user.permissions.canCreateCertifyingOrganizations && (
                  <Button
                    icon="plus"
                    onClick={() => props.history.push('/certifications/certifying_organizations/new')}
                  >
                    Add Certifying Organization
                  </Button>
                )}
                {ctx.user.permissions.canCreateCertificationTypes && (
                  <Button icon="solution" onClick={() => props.history.push('/certifications/new')}>
                    Add Certification Type
                  </Button>
                )}
                <Button icon="plus" onClick={() => setCertModalVisible(true)}>
                  Add My Certification
                </Button>
                {isCertModalVisible ? (
                  <CertificationCardModal
                    action="Add"
                    modalState={true}
                    closeModal={() => setCertModalVisible(false)}
                    onSubmit={() => setCertModalVisible(false)}
                    employeeId={ctx.user.id}
                  />
                ) : null}
                {ctx.user.permissions.canExportCertifications && (
                  <Button icon="download" onClick={getAllEmployeesCertifications}>
                    Export
                  </Button>
                )}
                <SavePreferencesButton
                  keys={['CertificationsLandingPage_FilterSettings', 'CertificationsLandingPage_SortSettings']}
                  disabled={loading}
                />
              </ButtonRow>
            </Typography.Title>

            <Typography.Paragraph>
              Exam fees are reimbursable upon registration and should be classified as "G&A - Training". It is an
              expectation that one pass the exam in no more than 2 attempts. Credera will <b>not</b> reimburse exam fees
              for a second failure on the same exam so one should wait to pass the exam before requesting reimbursement
              for a second attempt on the same exam.
            </Typography.Paragraph>

            {features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') && (
              <Typography.Paragraph>
                Certifications are periodically synced with Projector as Certification "Skills". Projector Certification
                of 0 = No Certification, 3 = Expired Certification, 5 = Active Certification.
              </Typography.Paragraph>
            )}

            <Toolbar>
              <CertificationFilters
                certifications={certifications}
                certifyingOrgs={certifyingOrgs}
                onSearch={handleFilter}
                settings={filterSettings}
                setSettings={setFilterSettings}
                disabled={loading}
                searchRef={searchRef}
              />
              <div>
                Showing {filteredCerts.length} of {certifications.length} Certifications
              </div>
            </Toolbar>
          </div>

          <Toolbar style={{ background: 'transparent', padding: 0 }}>
            <Button icon={activePanels.length ? 'minus' : 'plus'} onClick={toggleExpandAll}>
              {activePanels.length ? 'Collapse All' : 'Expand All'}
            </Button>
          </Toolbar>

          <Spin spinning={loading}>
            <Collapse bordered={false} onChange={handlePanelChange} activeKey={activePanels}>
              {filteredCertOrgs.map(certifyingOrg => {
                const certifyingOrgCerts = filteredCerts.filter(
                  c => c.certifyingOrganization.name === certifyingOrg.name
                );
                const certifyingOrgCertsCount = certifyingOrgCerts.length;

                const columnsSortSettings: ColumnProps<CertificationSummary>[] = columns.map(column => {
                  const find = sortSettings.categories.find(o => {
                    return o.category === certifyingOrg.name;
                  });
                  if (find !== undefined) {
                    if (column.key === find.column.key) {
                      return {
                        ...column,
                        defaultSortOrder: find.column.order,
                        certifyingOrg: certifyingOrg.name
                      };
                    }
                  } else {
                    if (column.key === sortSettings.default.key) {
                      return {
                        ...column,
                        defaultSortOrder: sortSettings.default.order,
                        certifyingOrg: certifyingOrg.name
                      };
                    }
                  }
                  return { ...column, certifyingOrg: certifyingOrg.name };
                });

                return (
                  <CardCollapse.Panel
                    header={
                      <div style={{ display: 'inline-block', alignItems: 'space-between' }}>
                        <Link
                          to={`/certifications/certifying_organizations/${certifyingOrg.id}`}
                          className="HeaderLinkText"
                        >
                          {certifyingOrg.name}
                        </Link>
                        {certifyingOrg.channelPartner && (
                          <Tag className="ChannelPartnerTagLandingPage">Channel Partner</Tag>
                        )}
                      </div>
                    }
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag>{`${certifyingOrgCertsCount} Certification${
                          certifyingOrgCertsCount === 1 ? '' : 's'
                        }`}</Tag>
                      </div>
                    }
                    key={certifyingOrg.name}
                  >
                    <Table
                      rowKey="id"
                      style={{ width: '100%' }}
                      dataSource={certifyingOrgCerts}
                      columns={columns}
                      pagination={false}
                      onChange={onChange}
                    />
                  </CardCollapse.Panel>
                );
              })}
            </Collapse>
            {!filteredCerts.length && !filteredCertOrgs.length && !loading && <Table columns={columns} />}
          </Spin>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(CertificationsLandingPage, 'Certifications Landing Page');
