import React, { useEffect, useState } from 'react';
import { notification, Typography, Button } from 'antd';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import FeatureTable from './FeatureTable';
import { getFeatures, updateFeature } from 'api/featureApi';
import ButtonRow from 'components/common/ButtonRow';

const FeatureLandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);

    getFeatures()
      .then(features => {
        setFeatures(features);
        setLoading(false);
      })
      .catch(displayErrorNotification);
  };

  const _toggleFeature = async (feature, enabled) => {
    await updateFeature(feature.name, enabled);
    notification.success({
      message: `Feature ${feature.name} has been ${enabled ? 'enabled' : 'disabled'}!`,
      duration: 2,
      placement: 'bottomRight'
    });
    load();
  };

  return (
    <Stack>
      <Typography.Title>
        Features
        <ButtonRow style={{ float: 'right' }}>
          <Button icon="solution" onClick={() => window.location.reload(false)}>
            Apply Feature Changes To Current Session
          </Button>
        </ButtonRow>
      </Typography.Title>

      <FeatureTable features={features} onToggleFeature={_toggleFeature} loading={loading} />
    </Stack>
  );
};

export default FeatureLandingPage;
