import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select, Typography, Checkbox } from 'antd';
import RichTextEditor from 'react-rte';
import { addBadge, toggleBadgeActivation, updateBadge, getAllBadgeCategories } from 'api/badgesApi';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import ImageUpload from 'components/common/form/ImageUpload';
import ButtonRow from 'components/common/ButtonRow';
import displayErrorNotification from 'utils/displayErrorNotification';
import UserContext from 'auth/UserContext';
import FullPageSpinner from 'components/common/FullPageSpinner';

class BadgeInfoForm extends React.Component {
  constructor() {
    super();

    this.state = {
      uploadedImage: null,
      categories: []
    };
  }

  componentDidMount() {
    getAllBadgeCategories()
      .then(categories => this.setState({ categories }))
      .catch(displayErrorNotification);
  }

  handleActivation = () => {
    toggleBadgeActivation(this.props.badgeId)
      .then(this.props.onSubmit)
      .catch(displayErrorNotification);
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) return;

      const formData = {
        ...values,
        image: this.state.uploadedImage || (this.props.badge ? this.props.badge.image : null),
        content: values.content.toString('markdown')
      };

      if (this.props.isCreateMode) {
        addBadge(formData)
          .then(this.props.onSubmit)
          .catch(displayErrorNotification);
      } else {
        updateBadge(this.props.badgeId, formData)
          .then(this.props.onSubmit)
          .catch(displayErrorNotification);
      }
    });
  };

  updateImage = image => {
    this.setState({ uploadedImage: image });
  };

  render() {
    const { badge, onCancel, form, isCreateMode } = this.props;

    if (!badge && !isCreateMode) return <FullPageSpinner />;

    const { uploadedImage, categories } = this.state;
    const { getFieldDecorator } = form;

    const initialValues = {
      name: null,
      description: null,
      ...badge,
      categoryId: badge ? badge.category.id : null
    };
    const content = isCreateMode
      ? RichTextEditor.createEmptyValue()
      : RichTextEditor.createValueFromString(badge.content, 'markdown');

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <SplitPageHeaderLayout.Layout>
          <SplitPageHeaderLayout.Left>
            <Typography.Title
              level={1}
              style={{ marginBottom: '0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div>{badge.name || 'New Badge'}</div>

              <ButtonRow>
                {!isCreateMode && (
                  <Button onClick={this.handleActivation}>{badge && badge.inactive ? 'Activate' : 'Deactivate'}</Button>
                )}
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
              </ButtonRow>
            </Typography.Title>
            <div>{badge.category ? badge.category.name : null}</div>
            <Form.Item label="Name" required>
              {getFieldDecorator('name', {
                initialValue: initialValues.name,
                rules: [
                  { required: true, message: 'Name is a required field' },
                  { max: 255, message: 'Name must be fewer than 255 characters' }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Description" required>
              {getFieldDecorator('description', {
                initialValue: initialValues.description,
                rules: [
                  { required: true, message: 'Description is a required field' },
                  { max: 255, message: 'Description must be fewer than 255 characters' }
                ]
              })(<Input />)}
            </Form.Item>
            <UserContext.Consumer>
              {ctx => (
                <Form.Item label="Category" required>
                  {getFieldDecorator('categoryId', {
                    initialValue: initialValues.categoryId,
                    rules: [{ required: true, message: 'Category is a required field' }]
                  })(
                    <Select placeholder="Select a category" disabled={!isCreateMode}>
                      {categories.map(c => {
                        if (
                          c.owners.filter(owner => owner.id === ctx.user.id).length > 0 ||
                          initialValues.categoryId === c.id
                        ) {
                          return (
                            <Select.Option key={c.id} value={c.id}>
                              {c.name}
                            </Select.Option>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  )}
                </Form.Item>
              )}
            </UserContext.Consumer>
            <Form.Item>
              {getFieldDecorator('required', {
                initialValue: initialValues.required,
                valuePropName: 'checked'
              })(<Checkbox>Enable Requirement</Checkbox>)}
            </Form.Item>
          </SplitPageHeaderLayout.Left>

          <SplitPageHeaderLayout.Right>
            <ImageUpload maxSize={48000} onChange={this.updateImage}>
              {(uploadedImage || initialValues.imagePath) && (
                <img style={{ width: '100%' }} src={uploadedImage || initialValues.imagePath} alt="Badge" />
              )}
            </ImageUpload>
          </SplitPageHeaderLayout.Right>
        </SplitPageHeaderLayout.Layout>
        <div>
          <Form.Item label="Content">
            {getFieldDecorator('content', { initialValue: content })(<RichTextEditor className={'rich-text-editor'} />)}
          </Form.Item>
        </div>
      </Form>
    );
  }
}

BadgeInfoForm.propTypes = {
  badgeId: PropTypes.string,
  badge: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: 'badge_info_form' })(BadgeInfoForm);
