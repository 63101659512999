import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, Button, Form, Icon } from 'antd';
import { debounce } from 'lodash';

const filterBadges = (props, changedValues, values) => {
  const { badges, onSearch } = props;

  const uppercaseTerm = values.searchTerm ? values.searchTerm.toUpperCase() : '';
  const categories = values.selectedCategories;

  const filteredBadges = badges.filter(c => {
    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      c.name.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      c.description.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isCategoryValid = !categories || !categories.length || categories.indexOf(c.category.id) > -1;
    return isNameValid && isCategoryValid;
  });
  onSearch(filteredBadges);
};

const BadgeFilters = ({ badges = [], categories = [], onSearch, form }) => {
  const clearFilters = () => {
    form.resetFields();
    onSearch(badges);
  };

  const { getFieldDecorator } = form;

  const selectedCategories = form.getFieldValue('selectedCategories') || [];

  return (
    <Form layout="inline">
      {/*<Icon type="filter" />*/}
      <Form.Item>
        {getFieldDecorator('searchTerm', {})(<Input prefix={<Icon type="filter" />} autoFocus placeholder="Filter" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('selectedCategories', {})(
          <Select
            maxTagCount={0}
            maxTagPlaceholder={`${selectedCategories.length} Selected`}
            showArrow
            mode="multiple"
            placeholder="Category"
            dropdownClassName="filter-bar-select-dropdown"
            className="filter-bar-select"
          >
            {categories.map(c => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item>
        {/*<Button htmlType="submit" type="link" icon="search" />*/}
        <Button title="Clear filters" icon="close" type="link" onClick={clearFilters}>
          Clear filters
        </Button>
      </Form.Item>
    </Form>
  );
};

BadgeFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  badges: PropTypes.array,
  categories: PropTypes.array
};

export default Form.create({
  name: 'filter_badges_form',
  onValuesChange: debounce(filterBadges, 500)
})(BadgeFilters);
