import React from 'react';
import PropTypes from 'prop-types';
import styles from './OverflowText.module.css';

const OverflowText = ({ children, numLines = 3, style = {}, ...props }) => {
  const elementStyles = {
    ...style,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: numLines
  };

  return (
    <div className={styles.overflowText} style={elementStyles} {...props}>
      {children}
    </div>
  );
};

OverflowText.propTypes = {
  numLines: PropTypes.number
};

export default OverflowText;
