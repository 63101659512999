import React from 'react';
import ProjectSkillForm from 'components/projects/project-skill/ProjectSkillForm';
import Stack from 'components/common/Stack';
import { trackComponent } from 'telemetry/AppInsights';

const AddProjectSkillPage = props => {
  const project = props.location.state.project;

  const handleSubmitOrCancel = () => {
    props.history.push(`/projects/${project.id}`);
  };

  return (
    <Stack>
      <ProjectSkillForm project={project} onSubmitOrCancel={handleSubmitOrCancel} />
    </Stack>
  );
};
export default trackComponent(AddProjectSkillPage, 'Add Project Skill Page');
