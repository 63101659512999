import { Button, Form, Icon, Input, Select, DatePicker } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React from "react";
import { Log, LogFilterSettings } from "types/Log";

const { RangePicker } = DatePicker;

type LogFiltersProps = {
  log: Log[];
  categories: string[];
  levels: string[];
  onSearch: (filteredLog: Log[]) => void;
  form: any;
  searchRef: any;
  disabled: boolean;
  settings: LogFilterSettings;
  setSettings: (settings: LogFilterSettings) => void;
};

export const filterLog = (
  props: LogFiltersProps,
  changedValues: any,
  values: any
) => {
  const { log, onSearch, setSettings } = props;
  const uppercaseTerm = values.searchTerm
    ? values.searchTerm.toUpperCase()
    : "";
  const selectedCategories = values.selectedCategories;
  const selectedLevels = values.selectedLevels;
  const selectedRange = values.selectedRange;

  setSettings({
    search: values.searchTerm,
    category: selectedCategories,
    level: selectedLevels,
    range: selectedRange
  });

  const filteredLog = log.filter(l => {
    let created = moment(l.created);
    let isSearchValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      l.message.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isSelectedCategoryValid =
      !selectedCategories ||
      !selectedCategories.length ||
      selectedCategories.indexOf(l.category) > -1;
    let isSelectedLevelValid =
      !selectedLevels ||
      !selectedLevels.length ||
      selectedLevels.indexOf(l.level) > -1;
    let isSelectedRangeValid =
      !selectedRange ||
      !selectedRange.length ||
      !created ||
      created.isBetween(selectedRange[0], selectedRange[1]);
    return (
      isSearchValid &&
      isSelectedCategoryValid &&
      isSelectedLevelValid &&
      isSelectedRangeValid
    );
  });
  onSearch(filteredLog);
};

const LogFilters = (props: LogFiltersProps) => {
  const { form, searchRef, disabled, settings, categories, levels } = props;
  const { getFieldDecorator } = form;
  const selectedCategories = form.getFieldValue("selectedCategories") || [];
  const selectedLevels = form.getFieldValue("selectedLevels") || [];

  const clearFilters = () => {
    form.resetFields();
    filterLog(
      props,
      {},
      {
        searchTerm: "",
        selectedCategories: [],
        selectedLevels: []
      }
    );
  };

  return (
    <div style={disabled ? { pointerEvents: "none", opacity: 0.4 } : {}}>
      <Form layout="inline">
        <Form.Item>
          {getFieldDecorator("searchTerm", {
            initialValue: settings.search
          })(
            <Input
              prefix={<Icon type="filter" />}
              ref={searchRef}
              disabled={disabled}
              placeholder="Filter"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedCategories", {
            initialValue: settings.category
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedCategories.length} Categor${
                selectedCategories.length === 1 ? "y" : "ies"
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Category"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {categories.map(c => (
                <Select.Option key={c} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedLevels", {
            initialValue: settings.level
          })(
            <Select
              maxTagCount={0}
              maxTagPlaceholder={`${selectedLevels.length} Level${
                selectedLevels.length === 1 ? "" : "s"
              } Selected`}
              showArrow
              mode="multiple"
              placeholder="Level"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {levels.map(l => (
                <Select.Option key={l} value={l}>
                  {l}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("selectedRange", {
            initialValue: settings.range
          })(
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder={["Created Start", "End"]}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            title="Clear filters"
            icon="close"
            type="link"
            onClick={clearFilters}
          >
            Clear filters
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create({
  name: "filter_log_form",
  onValuesChange: debounce(filterLog, 500)
})(LogFilters);
