import React from 'react';
import { Modal, Typography, Button } from 'antd';
import ButtonRow from 'components/common/ButtonRow';

const RoleTypeActivationModal = props => {
  return (
    <Modal title={props.action + ' Role Type'} visible={props.isModalVisible} onCancel={props.closeModal} footer={null}>
      <Typography.Paragraph strong>
        Are you sure you want to {props.action.toLowerCase()} this project role?
      </Typography.Paragraph>
      <Typography.Paragraph>{props.roleType.name}</Typography.Paragraph>
      <ButtonRow style={{ marginTop: '2em' }}>
        <Button type="primary" onClick={props.onSubmit}>
          {props.action}
        </Button>
        <Button onClick={props.closeModal}>Cancel</Button>
      </ButtonRow>
    </Modal>
  );
};

export default RoleTypeActivationModal;
