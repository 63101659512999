import React, { useEffect, useState } from 'react';
import Stack from 'components/common/Stack';
import { Button, Typography, Table, Popconfirm, Icon } from 'antd';
import displayErrorNotification from 'utils/displayErrorNotification';
import ButtonRow from 'components/common/ButtonRow';
import { trackComponent } from 'telemetry/AppInsights';
import { ColumnProps } from 'antd/lib/table';
import { RouteComponentProps } from 'react-router-dom';
import { EmployeeMajorMinor } from 'types/EmployeeMajorMinor';
import { getMajorMinorForEmployee, deleteMajorMinorById } from 'api/employeeMajorMinorApi';
import MajorMinorCardModal from './MajorMinorCardModal';
import { Employee } from 'types/Employee';  
import { getEmployee } from 'api/employeeApi';

const MajorMinorLandingPage = (props: RouteComponentProps) => {
  // @ts-ignore
  const { employeeId, employee } = props.location.state || {};
  const [employeeMajorMinor, setEmployeeMajorMinor] = useState<EmployeeMajorMinor[]>([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [updatedEmployee, setUpdatedEmployee] = useState<Employee>(employee);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeData = await getEmployee(employeeId);
        setUpdatedEmployee(employeeData);
        const majorMinorData = await getMajorMinorForEmployee(employeeId);
        setEmployeeMajorMinor(majorMinorData);
      } catch (error) {
        displayErrorNotification(error);
      }
    };

    fetchData();
  }, [employeeId]);

  const deleteMajorMinor = async (emplMajorMinor: EmployeeMajorMinor) => {
    try {
      await deleteMajorMinorById(employeeId, emplMajorMinor.id);
      setEmployeeMajorMinor(employeeMajorMinor.filter(em => em.id !== emplMajorMinor.id));
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  const handleSubmit = async response => {
    try {
      const updatedEmployeeData = await getEmployee(response.employeeId);
      setUpdatedEmployee(updatedEmployeeData);
      const majorMinorData = await getMajorMinorForEmployee(response.employeeId);
      setEmployeeMajorMinor(majorMinorData);
      setModalVisible(false);
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  const renderName = (emplMajorMinor: EmployeeMajorMinor) => {
    if (!updatedEmployee) return '';
    if (emplMajorMinor.isCapability) {
      if (emplMajorMinor.major) {
        return updatedEmployee.major?.capability?.name || '';
      } else {
        return updatedEmployee.minor?.capability?.name || '';
      }
    } else {
      if (emplMajorMinor.major) {
        return updatedEmployee.major?.industry?.name || '';
      } else {
        return updatedEmployee.minor?.industry?.name || '';
      }
    }
  };

  const columns: ColumnProps<EmployeeMajorMinor>[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text, emplMajorMinor) => renderName(emplMajorMinor)
    },
    {
      title: 'Capability or Industry',
      key: 'isCapability',
      dataIndex: 'isCapability',
      render: (text, emplMajorMinor) => (emplMajorMinor.isCapability ? 'Capability' : 'Industry')
    },
    {
      title: 'Major or Minor',
      dataIndex: 'major',
      key: 'major',
      render: (text, emplMajorMinor) => (emplMajorMinor.major ? 'Major' : 'Minor')
    },
    {
      title: 'Remove',
      key: 'action',
      width: 100,
      align: 'right',
      render: (text, record) =>
        <Popconfirm
          data-testid={'delete-popconfirm'}
          title="Are you sure you want to delete this major or minor?"
          onConfirm={() => deleteMajorMinor(record)}
        >
          <Icon type="delete" />
        </Popconfirm>
    }
  ];

  return (
    <Stack>
      <Typography.Title>Edit Major or Minor</Typography.Title>

      <Table
        rowKey={record => record?.id.toString()}
        style={{ width: '100%' }}
        dataSource={employeeMajorMinor}
        columns={columns}
        pagination={false}
      />

      <ButtonRow style={{ marginTop: '2em' }}>
        <Button type="primary" onClick={() => setModalVisible(true)}>
          Edit Major or Minor
        </Button>
        {isModalVisible ? (
          <MajorMinorCardModal
            modalState={true}
            closeModal={() => setModalVisible(false)}
            onSubmit={handleSubmit}
            employeeId={employeeId}
          />
        ) : null}
      </ButtonRow>
    </Stack>
  );
};

export default trackComponent(MajorMinorLandingPage, 'Employee Major and Minor Landing Page');