import React, { useEffect, useState, useRef } from "react";
import Stack from "components/common/Stack";
import { Table, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import displayErrorNotification from "utils/displayErrorNotification";
import sorters from "utils/sorters";
import Toolbar from "components/common/Toolbar";
import { getCompletionReport, getReportById } from "api/reportsApi";
import CompletionReportFilters, {
  filterCompletionReport,
} from "./CompletionReportFilters";
import { Parser } from "json2csv";
import moment from "moment";
import ButtonRow from "components/common/ButtonRow";
import useLocalStorage from "utils/useLocalStorage";
import { defaultCompletionReportFilterSettings } from "types/CompletionReportPreferences";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import { convertEmployeeCompletion } from "utils/convert";
import formatDate from "utils/formatDate";

const CompletionReportList = (props) => {
  const [report, setReport] = useState([]);
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    "CompletionReport_FilterSettings",
    defaultCompletionReportFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage(
    "CompletionReport_SortSettings",
    {
      key: "name",
      order: "ascend",
    }
  );
  const searchRef = useRef();

  useEffect(() => {
    setLoading(true);
    getCompletionReport()
      .then((result) => {
        const converted = convertEmployeeCompletion(result);
        setResponses(converted);
        setFilteredResponses(converted);
        filterCompletionReport(
          {
            onSearch: setFilteredResponses,
            responses: converted,
            setSettings: setFilterSettings,
          },
          {},
          {
            searchTerm: filterSettings.search,
            selectedPractices: filterSettings.practice,
            selectedPositions: filterSettings.position,
            selectedOffices: filterSettings.office,
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (
          searchRef.current !== undefined &&
          searchRef.current.input !== null
        ) {
          searchRef.current.focus();
        }
      });
  }, []);

  useEffect(() => {
    setReport(getReportById(2));
  }, []);

  let columns = [
    {
      title: "Name",
      key: "name",
      type: sorters.TYPE_STRING,
      render: (record) => (
        <Link to={`/employees/${record.id}`}>{record.name}</Link>
      ),
      sorter: (a, b) => sorters.string(a.name, b.name),
    },
    {
      title: "Practice",
      dataIndex: "practice",
      key: "practice",
      type: sorters.TYPE_STRING,
      sorter: (a, b) => sorters.string(a.practice, b.practice),
    },
    {
      title: "Major",
      dataIndex: "major",
      key: "major",
      type: sorters.TYPE_STRING,
      sorter: (a, b) => sorters.string(a.major, b.major),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      type: sorters.TYPE_STRING,
      sorter: (a, b) => sorters.string(a.position, b.position),
    },
    {
      title: "Location",
      dataIndex: "office",
      key: "office",
      type: sorters.TYPE_STRING,
      sorter: (a, b) => sorters.string(a.office, b.office),
    },
    {
      title: "Project Roles",
      dataIndex: "projectRoles",
      key: "projectRoles",
      align: "right",
      type: sorters.TYPE_NUMBER,
      sorter: (a, b) => a.projectRoles - b.projectRoles,
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      align: "right",
      type: sorters.TYPE_NUMBER,
      sorter: (a, b) => a.skills - b.skills,
    },
    {
      title: "Last Updated",
      dataIndex: "skillsUpdated",
      key: "skillsUpdated",
      type: sorters.TYPE_DATE,
      sorter: (a, b) => sorters.date(a.skillsUpdated, b.skillsUpdated),
      render: formatDate,
    },
    {
      title: "Certifications",
      dataIndex: "certifications",
      key: "certifications",
      align: "right",
      type: sorters.TYPE_NUMBER,
      sorter: (a, b) => a.certifications - b.certifications,
    },
    {
      title: "Last Updated",
      dataIndex: "certificationsUpdated",
      key: "certificationsUpdated",
      type: sorters.TYPE_DATE,
      sorter: (a, b) =>
        sorters.date(a.certificationsUpdated, b.certificationsUpdated),
      render: formatDate,
    },
    {
      title: "Prompts",
      dataIndex: "prompts",
      key: "prompts",
      align: "right",
      type: sorters.TYPE_NUMBER,
      sorter: (a, b) => a.prompts - b.prompts,
    },
    {
      title: "Last Updated",
      dataIndex: "promptsUpdated",
      key: "promptsUpdated",
      type: sorters.TYPE_DATE,
      sorter: (a, b) => sorters.date(a.promptsUpdated, b.promptsUpdated),
      render: formatDate,
    },
  ];

  const columnsSortSettings = columns.map((column) => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });
  columns = columnsSortSettings;

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order,
      });
    }
  }

  const exportEmployeeCompletion = () => {
    const column = columns.find((c) => c.defaultSortOrder !== undefined);
    let columnName = "name";
    let sortOrder = true; // ascend
    let type = sorters.TYPE_STRING;
    if (column !== undefined) {
      columnName = column.key;
      sortOrder = column.defaultSortOrder === "ascend";
      type = column.type;
    }
    const exportable = filteredResponses
      .sort((a, b) => sorters.multi(a, b, columnName, type, sortOrder))
      .map((k) => {
        return {
          Name: k.name,
          Email: k.email,
          Practice: k.practice,
          "Major": k.major,
          Position: k.position,
          Level: k.level,
          Location: k.office,
          "Project Roles": k.projectRoles,
          Skills: k.skills,
          "Skills Last Updated": k.skillsUpdated,
          Certificationst: k.certifications,
          "Certifications Last Updated": k.certificationsUpdated,
          Prompts: k.prompts,
          "Prompts Last Updated": k.promptsUpdated,
          "Career Coach Name": k.careerCoachName,
          "Career Coach Email": k.careerCoachEmail,
        };
      });

    const json2CsvParser = new Parser();
    const csv = json2CsvParser.parse(exportable);
    const blob = new Blob([csv], { type: "text/csv", encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `EmployeeCompletion-${moment().format(
      "YYYYMMDD-HHmmss"
    )}.csv`;
    link.click();
  };

  return (
    <Stack>
      <ButtonRow style={{ float: "right" }}>
        {!loading && (
          <Button icon="download" onClick={exportEmployeeCompletion}>
            Export
          </Button>
        )}
        <SavePreferencesButton
          keys={[
            "CompletionReport_FilterSettings",
            "CompletionReport_SortSettings",
          ]}
          disabled={loading}
        />
      </ButtonRow>
      <Typography.Title>{report.name}</Typography.Title>
      <Typography.Title level={3}>{report.description}</Typography.Title>
      <Toolbar>
        <CompletionReportFilters
          onSearch={setFilteredResponses}
          responses={responses}
          searchRef={searchRef}
          disabled={loading}
          settings={filterSettings}
          setSettings={setFilterSettings}
        />
        <div>
          <span style={{ margin: "0 10px" }}>
            Showing {filteredResponses.length} of {responses.length} Responses
          </span>
        </div>
      </Toolbar>
      <Table
        pagination={{ pageSize: 25, position: "top" }}
        rowKey="id"
        columns={columns}
        dataSource={filteredResponses}
        loading={loading}
        onChange={onChange}
      />
    </Stack>
  );
};

export default CompletionReportList;
