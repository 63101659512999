import { get } from 'api'
import { Log } from 'types/Log'


export function getLog(): Promise<Log[]> {
  return get(`api/log`)
}

export function getLogCategories(): Promise<string[]> {
  return get(`api/log/categories`)
}

export function getLogLevels(): Promise<string[]> {
  return get(`api/log/levels`)
}
