import React, { useEffect, useState } from 'react';
import { Collapse, Icon, Switch, Typography, Table, Tag } from 'antd';
import Stack from 'components/common/Stack';
import { Link } from 'react-router-dom';
import formatDate from 'utils/formatDate';
import Toolbar from 'components/common/Toolbar';
import ClientProjectTimeline from '../clients/ClientProjectTimeline';
import CardCollapse from 'components/common/collapse/CardCollapse';
import EngagementProjectFilters from './EngagementProjectFilters';
import { trackComponent } from 'telemetry/AppInsights';
import { getEngagementDetail } from 'api/projectApi';
import sorters from 'utils/sorters';
import displayErrorNotification from 'utils/displayErrorNotification';

const EngagementDetailPage = props => {
  const [engagementDetail, setEngagementDetail] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showProjectTimeline, setShowProjectTimeline] = useState(false);

  const projectId = props.match.params.projectId;
  let activePanels = ['projects'];

  useEffect(() => {
    getEngagementDetail(projectId)
      .then(engagementDetail => {
        setEngagementDetail(engagementDetail);
        setFilteredProjects(engagementDetail.projects);
      })
      .catch(displayErrorNotification)
  }, []);

  const columns = [
    {
      title: 'Project',
      key: 'project',
      sorter: (a, b) => sorters.string(a, b),
      render: record => <Link to={`/projects/${record.id}`}>{record.name}</Link>
    },
    {
      title: 'Practice',
      dataIndex: 'practice',
      key: 'practice',
      sorter: (a, b) => sorters.string(a, b)
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: formatDate,
      sorter: (a, b) => sorters.date(a, b)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: formatDate,
      defaultSortOrder: 'descend',
      sorter: (a, b) => sorters.date(a, b)
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'right',
      render: record => <Tag color={record ? 'green' : 'red'}>{record ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => (a.active < b.active ? -1 : 1)
    }
  ];

  const onChangeShowProjectTimeline = (checked, e) => {
    e.stopPropagation();
    setShowProjectTimeline(checked);
  };

  return (
    <Stack>
      <Typography.Title level={1} style={{ marginBottom: '0' }}>
        {engagementDetail.name}
      </Typography.Title>
      <Typography.Title level={3} type="secondary" style={{ marginTop: '0' }}>
        {engagementDetail.location}&nbsp;·&nbsp;
        {engagementDetail.client}
      </Typography.Title>
      <Collapse bordered={false} defaultActiveKey={activePanels} style={{ width: '100%' }}>
        {engagementDetail.projects && engagementDetail.projects.length && (
          <CardCollapse.Panel
            key="projects"
            header={
              <>
                <Icon type="project" /> Projects
              </>
            }
            extra={
              <>
                <Switch
                  checkedChildren="Table"
                  unCheckedChildren="Timeline"
                  defaultChecked={showProjectTimeline}
                  onChange={onChangeShowProjectTimeline}
                />
                &nbsp;
                <Tag>
                  {engagementDetail.projects && engagementDetail.projects.length} Project
                  {engagementDetail.projects && engagementDetail.projects.length === 1 ? '' : 's'}
                </Tag>
              </>
            }
          >
            <Toolbar style={{ width: '100%' }}>
              <EngagementProjectFilters onSearch={setFilteredProjects} projects={engagementDetail.projects} />
              <div>
                <span style={{ margin: '0 10px' }}>
                  Showing {filteredProjects.length} of {engagementDetail.projects && engagementDetail.projects.length} Projects
                </span>
              </div>
            </Toolbar>
            {showProjectTimeline && filteredProjects.length && <ClientProjectTimeline projects={filteredProjects} />}
            {!showProjectTimeline && (
              <Table
                pagination={{ position: 'none', pageSize: engagementDetail.projects && engagementDetail.projects.length }}
                style={{ width: '100%' }}
                rowKey={record => record.id}
                columns={columns}
                dataSource={filteredProjects}
              />
            )}
          </CardCollapse.Panel>
        )}
      </Collapse>
    </Stack>
  );
};

export default trackComponent(EngagementDetailPage, 'Engagement Detail Page');
