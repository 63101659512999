import { get, post, put, del } from 'api'
import { Industry, LandingPageIndustryAPI } from 'types/Industry'
import { IndustryDetail } from 'types/IndustryDetail'

export function getIndustries(includeInactive = false): Promise<Industry[]> {
  return get(`api/industries`, { includeInactive })
}

export function getLandingPageIndustry(): Promise<LandingPageIndustryAPI[]> {
  return get(`api/industries/landingpage`)
}

export function getIndustryById(id: number): Promise<IndustryDetail> {
  return get(`api/industries/${id}`)
}

export function createIndustry(industry: Industry): Promise<IndustryDetail> {
  return post(`api/industries`, industry)
}

export function updateIndustry(id: number, industry: IndustryDetail): Promise<IndustryDetail> {
  return put(`api/industries/${id}`, industry)
}

export function toggleIndustryActivation(id: number): Promise<IndustryDetail> {
  return post(`api/industries/${id}`)
}

export function deleteIndustry(id: number) {
  return del(`api/industries/${id}`)
}
