import { Button, Typography } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import { getEmployeeBusinessUnits } from 'api/employeeBusinessUnitApi'
import { useUser } from 'auth/UserHooks'
import ButtonRow from 'components/common/ButtonRow'
import Modal from 'components/common/Modal'
import Stack from 'components/common/Stack'
import React, { useEffect, useState } from 'react'
import { EmployeeBusinessUnit } from 'types/EmployeeBusinessUnit'
import { textOverflow } from 'utils/skillDetails'
import sorters from 'utils/sorters'
import EmployeeBusinessUnitForm from './EmployeeBusinessUnitForm'

const EmployeeBusinessUnitLandingPage = () => {
  const user = useUser()
  const canManageEmployeeBusinessUnit = user.permissions.canManageEmployeeBusinessUnit
  const [businessUnits, setBusinessUnits] = useState<EmployeeBusinessUnit[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const _refreshBusinessUnit = async () => {
    const response = await getEmployeeBusinessUnits()
    setBusinessUnits(response)
  };

  let columns: ColumnProps<EmployeeBusinessUnit>[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => sorters.string(a.name, b.name)
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (desc: string) => textOverflow(desc, 60),
      sorter: (a, b) => sorters.string(a.description, b.description)
    }
  ]

  if (canManageEmployeeBusinessUnit) {
    columns.push({
      render: (item: EmployeeBusinessUnit) => (
        <Modal
          item={item}
          trigger={({ openModal }: { openModal: () => void }) => (
            <Button onClick={openModal} size="small" icon="edit">
              Edit
            </Button>
          )}
        >
          {({ item, closeModal }: { item: EmployeeBusinessUnit; closeModal: () => void }) => (
            <EmployeeBusinessUnitForm
              businessUnit={item}
              onSubmit={() => {
                _refreshBusinessUnit().then(closeModal)
              }}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )
    })
  }

  useEffect(() => {
    setLoading(true)
    _refreshBusinessUnit().then(() => {
      setLoading(false)
    })
  }, [])

  const _handleModalState = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      {canManageEmployeeBusinessUnit && (
        <Modal isOpen={showModal} onClose={_handleModalState}>
          {({ closeModal }: { closeModal: () => void }) => (
            <EmployeeBusinessUnitForm
              onSubmit={() => {
                _refreshBusinessUnit().then(closeModal)
              }}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )}
      <Stack>
        <Typography.Title level={1}>
          Employee Business Unit
          <ButtonRow style={{ float: 'right' }}>
            {canManageEmployeeBusinessUnit && (
              <Button icon="plus" onClick={_handleModalState}>
                Create Employee Business Unit
              </Button>
            )}
          </ButtonRow>
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={businessUnits}
          loading={loading}
          rowKey="id"
          pagination={false}
        />
      </Stack>
    </>
  )
}

export default EmployeeBusinessUnitLandingPage
