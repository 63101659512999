import React, { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { CapabilityDetail } from 'types/CapabilityDetail';
import { IndustryDetail } from 'types/IndustryDetail';
import { EmployeeMajorMinorCore } from 'types/EmployeeMajorMinorCore';
import { getCapabilityById } from 'api/capabilitiesApi';
import { getIndustryById } from 'api/industriesApi';

type MajorOrMinorCardProps = {
  majorMinor: EmployeeMajorMinorCore;
  isCapability: boolean;
  major: string;
};

const MajorOrMinorCard = ({ majorMinor, major, isCapability }: MajorOrMinorCardProps) => {
  const [detailCapability, setDetailCapability] = useState<CapabilityDetail | null>(null);
  const [detailIndustry, setDetailIndustry] = useState<IndustryDetail | null>(null);

  useEffect(() => {
    const fetchDetails = async () => {
      if (isCapability) {
        const fetchedCapability = await getCapabilityById(majorMinor.capability!.id);
        setDetailCapability(fetchedCapability);
      } else {
        const fetchedIndustry = await getIndustryById(majorMinor.industry!.id);
        setDetailIndustry(fetchedIndustry);
      }
    };

    fetchDetails();
  }, [isCapability, majorMinor]);

  if (isCapability && detailCapability) {
    return (
      <Link to={`/capabilities/${detailCapability.id}`}>
        <Card className="avatar-card" hoverable={true}>
          <Typography.Text style={{ fontSize: 10 }}>
            <p>{detailCapability.practice} [{detailCapability.practiceAbbrev}]</p>
            <p>{detailCapability.name} Capability</p>
          </Typography.Text>
          <span>{major}</span>
        </Card>
      </Link>
    );
  } else if (detailIndustry) {
    return (
      <Link to={`/industries/${detailIndustry.id}`}>
        <Card className="avatar-card" hoverable={true}>
          <Typography.Text style={{ fontSize: 10 }}>
            <p>{detailIndustry.sector}</p>
            <p>{detailIndustry.name} Industry</p>
          </Typography.Text>
          <span>{major}</span>
        </Card>
      </Link>
    );
  }

  return null;
};

export default MajorOrMinorCard;